import { Merge } from 'type-fest'
import { RecordEventParams, RecordPageViewParams } from '~/models/analytics'

export const defaultRecordPageViewParams: RecordPageViewParams = {
  includeFacebookPixel: true,
  url: null
}

export const defaultRecordEventParams: Merge<
  Required<RecordEventParams>,
  Pick<RecordEventParams, 'measurementId'>
> = {
  namespace: 'site',
  action: 'event',
  label: '',
  customData: {}
}
