import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const deletedSearchConfig: SearchConfig = Object.freeze({
  settings: {
    isDeletedSearch: true,
    viewType: {
      availableViewTypes: [ViewType.LIST],
      defaultViewTypeDesktop: ViewType.LIST,
      defaultViewTypeMobile: ViewType.LIST
    },
    searchName: 'deleted',
    showAdminMassActions: false,
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/deleted/query/',
      usesNuxtLinks: false
    },
    hideSubtitle: true,
    hideViewTypeDesktop: true,
    showDisableButtons: true
  },
  rootParams: {
    sort: ['dld'],
    deletedonly: ['1'],
    category: [20001]
  }
})
