export const SET_IS_FAVORITE = 'SET_IS_FAVORITE'
export const SET_PARAMS = 'SET_PARAMS'
export const SET_PARAM = 'SET_PARAM'
export const SET_FACETS_SEARCHBAR = 'SET_FACETS_SEARCHBAR'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE'
export const SET_PER_PAGE = 'SET_PER_PAGE'
export const SET_FACETS_DATA = 'SET_FACETS_DATA'
export const SET_LOADING_FACETS = 'SET_LOADING_FACETS'
export const SET_LOADING_RESULTS = 'SET_LOADING_RESULTS'
export const CLEAR_ALL = 'CLEAR_ALL'
export const SET_PAGE = 'SET_PAGE'
export const SET_PAGE_DATA = 'SET_PAGE_DATA'
export const SET_RESULTS_DATA = 'SET_RESULTS_DATA'
export const MODIFY_ROW_TOUCHED = 'MODIFY_ROW_TOUCHED'
export const MODIFY_ROW_DELETED = 'MODIFY_ROW_DELETED'
export const MODIFY_ROW_RESTORED = 'MODIFY_ROW_RESTORED'
export const MODIFY_ROW_REMOVED = 'MODIFY_ROW_REMOVED'
export const DECREMENT_PAGINATION_TOTAL = 'DECREMENT_PAGINATION_TOTAL'
export const MODIFY_ROW_HIDDEN = 'MODIFY_ROW_HIDDEN'
export const MODIFY_ROW_SHOWN = 'MODIFY_ROW_SHOWN'
export const MODIFY_ROW_NON_PUBLIC = 'MODIFY_ROW_NON_PUBLIC'
export const MODIFY_ROW_PUBLIC = 'MODIFY_ROW_PUBLIC'
export const MODIFY_ROW_PARKED = 'MODIFY_ROW_PARKED'
export const MODIFY_ROW_PROMOTION_ACTIVE = 'MODIFY_ROW_PROMOTION_ACTIVE'
export const SET_SHOW_SELLERS = 'SET_SHOW_SELLERS'
export const SET_SELLERS_DATA = 'SET_SELLERS_DATA'
export const SET_MAP_DATA = 'SET_MAP_DATA'
export const CLEAR_MAP_DATA = 'CLEAR_MAP_DATA'
export const SET_MAP_SEARCH_FLAG = 'SET_FLAG'
export const SET_SELLERS_ONLY_FLAG = 'SET_SELLERS_ONLY_FLAG'
export const SET_SHOW_MOBILE_FACETS = 'SET_SHOW_MOBILE_FACETS'
export const SET_ACTIONS_ARE_COLLAPSED = 'SET_ACTIONS_ARE_COLLAPSED'
export const SET_LIST_RESULT_ITEM_LAYOUT = 'SET_LIST_RESULT_ITEM_LAYOUT'
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE'
export const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE'
export const SET_LOADING_MAP = 'SET_LOADING_MAP'
export const SET_CLUSTER_RESULTS_DATA = 'SET_CLUSTER_RESULTS_DATA'
export const SET_LOADING_CLUSTER_RESULTS = 'SET_LOADING_CLUSTER_RESULTS'
export const SET_PAID_SUMMARY = 'SET_PAID_SUMMARY'
export const SET_CLASSIFIED_PAID_INFO = 'SET_CLASSIFIED_PAID_INFO'
export const SET_MASS_ACTIONS_REMAINING_ACTIONS =
  'SET_MASS_ACTIONS_REMAINING_ACTIONS'
export const SET_MASS_ACTIONS_TOTAL_ACTIONS = 'SET_MASS_ACTIONS_TOTAL_ACTIONS'
export const SET_MASS_ACTIONS_RESET_TIMESTAMP =
  'SET_MASS_ACTIONS_RESET_TIMESTAMP'
export const SET_AUTO_TOUCH_DATA_IN_SEARCH = 'SET_AUTO_TOUCH_DATA_IN_SEARCH'
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE'
export const RESET_ROW_RESULTS = 'RESET_ROW_RESULTS'
