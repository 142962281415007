export const SET_MAPPINGS = 'SET_MAPPINGS'
export const SET_FILTERED_MAPPINGS = 'SET_FILTERED_MAPPINGS'
export const PREPEND_MAPPINGS = 'PREPEND_MAPPINGS'
export const SET_UNMAPPED_CATEGORY_SCAN_ERRORS =
  'SET_UNMAPPED_CATEGORY_SCAN_ERRORS'
export const SET_MARKED_CATEGORY_MAPPING_IDS = 'SET_MARKED_CATEGORY_MAPPING_IDS'
export const SET_ALL_CATEGORIES_MAPPED = 'SET_ALL_CATEGORIES_MAPPED'
export const SET_ERROR_ALERT_VISIBILITY = 'SET_ERROR_ALERT_VISIBILITY'
export const SET_MAPPING = 'SET_MAPPING'
export const DELETE_MAPPING = 'DELETE_MAPPING'
export const SET_PAGE = 'SET_PAGE'
export const SET_MAPPING_FILTER_TEXT = 'SET_MAPPING_FILTER_TEXT'
export const ADD_TRANSIT_INTERNAL_CATEGORY = 'ADD_TRANSIT_INTERNAL_CATEGORY'
export const DELETE_TRANSIT_INTERNAL_CATEGORY =
  'DELETE_TRANSIT_INTERNAL_CATEGORY'
export const SET_LAST_PICKER_SELECTED_CATEGORY =
  'SET_LAST_PICKER_SELECTED_CATEGORY'
