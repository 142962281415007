import { ValueOf } from 'type-fest'
import { Route } from 'vue-router'
import { GetterTreeWithRootState } from '~/store/types'
import { getDealerSiteRouteName } from '~/utils/router'
import { trimEnd } from '~/utils/string'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { Conf, Page, Outlet, PageType } from '~/models/dealer/site/types'
import { DealerSiteState } from './state'

export default {
  getTemplateOption: (state, getters) => (
    name: keyof Conf
  ): ValueOf<Conf> | undefined => {
    const value = state.template?.conf[name] || undefined

    if (
      name === 'footer_text_color' &&
      value === getters.getTemplateOption('footer_background_color')
    ) {
      return getters.getTemplateOption('page_text_color')
    }

    return value
  },
  getPageSnippetByPath: ({ pageSnippets, indexPageId }) => (
    path?: string
  ): PageSnippet | undefined => {
    const compareFn: (s: PageSnippet) => boolean = path
      ? s => trimEnd(s.pagePath, '/') === trimEnd(path, '/')
      : s => s.id === indexPageId
    return [...pageSnippets.values()].find(compareFn)
  },
  activePageId: ({ loadedPageId, loadingPageId }): string | undefined =>
    loadingPageId || loadedPageId,
  getPage: ({ pageSnippets, customPageBodies }) => (
    id: string
  ): Page | undefined => {
    const snippet: PageSnippet | undefined = pageSnippets.get(id)
    if (!snippet) {
      return undefined
    }
    return {
      ...snippet,
      body: customPageBodies.get(id)
    }
  },
  activePage: (_, { activePageId, getPage }): Page | undefined => {
    return getPage(activePageId)
  },
  loadingPage: ({ loadingPageId }, { getPage }): Page | undefined => {
    return getPage(loadingPageId)
  },
  indexPage: ({ indexPageId }, { getPage }): Page | undefined => {
    return getPage(indexPageId)
  },
  contactPage: ({ pageSnippets }, { getPage }): Page | undefined => {
    const contactPageSnippet = [...pageSnippets.values()].find(
      ps => ps.type === 'contact'
    )

    return contactPageSnippet && getPage(contactPageSnippet.id)
  },
  activeOutlet: (state): Outlet | undefined => {
    if (!state.activeOutletId) {
      return undefined
    }

    return state.outlets.get(state.activeOutletId)
  },
  firstOutlet: ({ outlets }): Outlet | undefined => {
    if (!outlets || !outlets.size) {
      return undefined
    }
    return [...outlets.values()][0]
  },
  componentShouldRenderPage: ({ loading }, { activePage }, _, __) => (
    ...types: PageType[]
  ): boolean => {
    return Boolean(
      !loading && activePage && types.some(t => t === activePage.type)
    )
  },
  shouldAnimatePageTransition: ({ navigatedToOtherPage }): boolean => {
    return navigatedToOtherPage
  },
  shouldRenderIndexPage: ({ indexPageId }, { activePageId }) => (
    route: Route
  ): boolean => {
    return Boolean(
      route &&
        route.name === getDealerSiteRouteName() &&
        activePageId &&
        indexPageId &&
        activePageId === indexPageId
    )
  },
  loading({ loading }, { loadingPage }): boolean {
    return Boolean(
      loading || (loadingPage && loadingPage.type !== 'classified')
    )
  },
  navPages: (_state, { filterPages }): PageSnippet[] =>
    filterPages('classified', false),
  searchPagesOfNav: (_state, { filterPages }): PageSnippet[] =>
    filterPages(['search', 'search_custom_page']),
  customPagesOfNav: (_state, { filterPages }): PageSnippet[] =>
    filterPages('custom_page'),
  filterPages: ({ pageSnippets }) => (
    type: PageType[] | PageType,
    inclusive = true,
    visible = true
  ): PageSnippet[] => {
    if (typeof type === 'string') {
      type = [type]
    }

    const compareFn: (ps: PageSnippet) => boolean = inclusive
      ? ps => type.includes(ps.type)
      : ps => !type.includes(ps.type)
    // @ts-ignore
    const pages = [...pageSnippets.values()].filter(compareFn)
    return visible ? pages.filter(p => p.visible) : pages
  },
  pageIsActive: (_state, { activePageId }) => (pageId: string): boolean => {
    return activePageId === pageId
  }
} as GetterTreeWithRootState<DealerSiteState>
