import { ref, nextTick } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { PAGE_NS, PageState } from '~/store/modules/shared/page/state'
import { SET_MODAL_OPEN } from '~/store/modules/shared/page/mutation-types'

let onHashChangeCallback: Function | null = null

export function useModalToggle(
  closeModal: Function = () => {},
  afterModalClose: Function = () => {}
) {
  const { dispatch, commit } = useNamespacedStore<PageState>(PAGE_NS)

  const forceGoBack = ref(false)
  const hash = ref('modal')
  const modalInSearch = ref(false)

  const afterModalCloseCallback = ref(null as any)
  const onModalCloseCallback = ref(null as any)

  const modalOpen = async () => {
    dispatch('addBodyClass', 'tw-pr-0') // in case we are in a CModal
    const wbefore = document.body.clientWidth
    dispatch('addBodyClass', 'modal-open')

    if (wbefore < document.body.clientWidth) {
      // this means we had a scrollbar and its gone, so add margin
      const px = (document.body.clientWidth - wbefore).toString() + 'px'
      document.body.style.marginRight = px
    }

    const url = new URL(window.location.href)
    let multiHash = false
    if (window.location.hash) {
      if (window.location.hash === '#' + hash.value) {
        // if we open the modal while the hash already exists in url for some reason (like refresh),
        // delete it before re-adding it so that we have the correct url to return to on backClicked
        url.hash = ''
        window.history.replaceState(
          { resetSearchState: true },
          document.title,
          url.href
        )
      } else {
        // there is already a hash there probably from another component (ie trades)
        multiHash = true

        if (window.location.hash.endsWith('#' + hash.value)) {
          // there is already a modal-toggle hash at the end of the multiHash so remove it
          // in order to re-add it below (same concept as above)
          url.hash = window.location.hash.split('#' + hash.value)[0]
        }
        window.history.replaceState(
          { multiHash: true },
          document.title,
          url.href
        )
      }
    }

    if (modalInSearch.value) {
      window.history.replaceState(
        { isInSearchPage: false, resetSearchState: true },
        '',
        window.location.href
      )
    }

    commit(SET_MODAL_OPEN, true)
    await nextTick()
    if (modalInSearch.value || multiHash) {
      window.history.pushState(
        null,
        document.title,
        window.location.href + '#' + hash.value
      )
    } else {
      url.hash = hash.value
      window.history.pushState(null, document.title, url.href)
    }

    window.addEventListener('keyup', keyup)
    window.addEventListener('hashchange', hashChange, false)

    document.body.style.paddingRight = ''
  }
  const modalClose = async (
    backPressed: boolean,
    onlyRemoveClasses: boolean = false
  ) => {
    if (!onlyRemoveClasses) {
      closeModal()
      if (typeof onModalCloseCallback.value === 'function') {
        onModalCloseCallback.value()
      }
    }

    dispatch('removeBodyClass', 'modal-open')
    document.body.style.marginRight = ''

    if (!backPressed && !onlyRemoveClasses) {
      forceGoBack.value = true
      window.history.go(-1)
    }

    commit(SET_MODAL_OPEN, false)

    await nextTick()
    // we need next tick here in case this is in a CModal (because it also has padding handling that happens around the same time)
    dispatch('removeBodyClass', 'tw-pr-0')
  }
  const hashChange = (event: HashChangeEvent) => {
    if (onHashChangeCallback && window.location.hash !== '') {
      // if we haven't returned to no-hash then do the callback
      if (typeof onHashChangeCallback === 'function') {
        onHashChangeCallback(event)
      }

      // re-think this return if we want all our onHashChangeCallbacks to ignore the code below
      return
    }

    if (!forceGoBack.value) {
      // it came here from navigation-back
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (window.location.hash !== hash.value) {
        modalClose(true)
      }
    } else {
      forceGoBack.value = false
    }

    afterModalClose()
    if (typeof afterModalCloseCallback.value === 'function') {
      afterModalCloseCallback.value()
    }
    removeModalListeners()
  }

  const goToNextHash = (nextHash: string) => {
    const url = new URL(window.location.href)
    url.hash = nextHash
    window.history.pushState(null, document.title, url.href)
  }

  const keyup = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // esc
      modalClose(false)
    }
  }

  const setAfterModalCloseCallback = (cBack: Function) => {
    afterModalCloseCallback.value = cBack
  }

  const setOnModalCloseCallback = (cBack: Function) => {
    onModalCloseCallback.value = cBack
  }

  const onModalHide = (evt: { trigger: string }) => {
    if (
      ['unmount', 'backdrop', 'esc', 'headerclose', 'ok', 'cancel'].includes(
        evt.trigger
      )
    ) {
      modalClose(false)
    }
  }

  const removeModalListeners = () => {
    onHashChangeCallback = null
    window.removeEventListener('hashchange', hashChange)
    window.removeEventListener('keyup', keyup)
  }

  const setHash = (hashValue: string) => {
    hash.value = hashValue || 'modal'
  }

  const setModalInSearch = (inSearch: boolean = false) => {
    modalInSearch.value = inSearch
  }

  const setOnHashChangeCallback = (cBack: Function) => {
    onHashChangeCallback = cBack
  }

  return {
    modalOpen,
    modalClose,
    setAfterModalCloseCallback,
    setOnModalCloseCallback,
    onModalHide,
    setHash,
    setModalInSearch,
    setOnHashChangeCallback,
    goToNextHash
  }
}
