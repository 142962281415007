import { interopDefault } from '~/router/utils'

export const adminMarketplaceRoutes = [
  {
    name: '__admin_marketplace__index',
    path: '/admin/marketplace/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/marketplace/index.vue' /* webpackChunkName: "pages-car-admin-marketplace-index" */
        )
      ),
    redirect: { name: '__admin_marketplace_orders' },
    children: [
      {
        name: '__admin_marketplace_orders',
        path: 'orders',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/orders/index.vue' /* webpackChunkName: "pages-admin-marketplace-orders" */
            )
          )
      },
      {
        name: '__admin_marketplace_orders_order_details',
        path: 'orders/:orderId',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/orders/order.vue' /* webpackChunkName: "pages-car-admin-marketplace-orders-order" */
            )
          ),
        meta: {
          hideTabs: true
        }
      },
      {
        name: '__admin_marketplace_orders_order_logs',
        path: 'orders/:orderId/logs',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/orders/logs.vue' /* webpackChunkName: "pages-car-admin-marketplace-orders-logs" */
            )
          ),
        meta: {
          hideTabs: true
        }
      },
      {
        name: '__admin_marketplace_reviews',
        path: 'reviews',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/reviews/index.vue' /* webpackChunkName: "pages-car-admin-marketplace-reviews" */
            )
          )
      },
      {
        name: '__admin_marketplace_reviews_reports',
        path: 'reviews/reports',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/reviews/reports.vue' /* webpackChunkName: "pages-car-admin-marketplace-reviews-reports" */
            )
          ),
        meta: {
          hideTabs: true
        }
      },
      {
        name: '__admin_marketplace_products',
        path: 'products',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/products/index.vue' /* webpackChunkName: "pages-car-admin-marketplace-products" */
            )
          )
      },
      {
        name: '__admin_marketplace_products_product_logs',
        path: 'products/:productId/logs',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/products/logs.vue' /* webpackChunkName: "pages-car-admin-marketplace-products-logs" */
            )
          ),
        meta: {
          hideTabs: true
        }
      },
      {
        name: '__admin_marketplace_sellers',
        path: 'sellers',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/sellers/index.vue' /* webpackChunkName: "pages-car-admin-marketplace-sellers" */
            )
          )
      },
      {
        name: '__admin_marketplace_sellers_logs',
        path: 'sellers/:sellerId/logs',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/sellers/logs.vue' /* webpackChunkName: "pages-car-admin-marketplace-sellers-logs" */
            )
          ),
        meta: {
          hideTabs: true
        }
      },
      {
        name: '__admin_marketplace_settings',
        path: 'settings',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/admin/marketplace/settings.vue' /* webpackChunkName: "pages-car-admin-marketplace-settings" */
            )
          )
      }
    ]
  }
]
