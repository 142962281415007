import { HierarchicalCategory } from '~/models/category/types'
import { LoadStatus } from '~/store/types'
import { PopularityMap } from '~/models/popularity/types'

export interface AdminCategoriesState {
  categories: Map<string, HierarchicalCategory>
  expandedIds: Set<string>
  focusedId?: string
  idInEdit: string | null
  loadStatus: LoadStatus
  popularityMap?: PopularityMap
}

function getDefaultState(): AdminCategoriesState {
  return {
    idInEdit: null,
    categories: new Map(),
    expandedIds: new Set(),
    focusedId: undefined,
    loadStatus: LoadStatus.UNLOADED,
    popularityMap: undefined
  }
}

export default getDefaultState
