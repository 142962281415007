import {
  StripeActiveSubscription,
  StripeSubscriptionPlan
} from '~/models/payments/stripe'
import { AccountOverview } from '~/models/account'
import { AnalyticsPanelSubPanel } from '~/models/analytics/classifieds'

export const ACCOUNT_NS = 'account'
export interface AccountState {
  loading: boolean
  subscriptionPlans: StripeSubscriptionPlan[]
  activeSubscription: StripeActiveSubscription | null
  stripePortalUrl: ''
  accountOverview: AccountOverview | null
  quickStatsPanels: AnalyticsPanelSubPanel[]
}

function getDefaultState(): AccountState {
  return {
    loading: false,
    subscriptionPlans: [],
    activeSubscription: null,
    stripePortalUrl: '',
    accountOverview: null,
    quickStatsPanels: []
  }
}

export default getDefaultState
