export const directParamsMappings: Record<string, string> = Object.freeze({
  ca: 'category',
  location: 'location2'
})

export const deprecatedLocationParams: string[] = [
  'rg',
  'prefecture',
  'location'
]

export const compatibilityParamsMappings: Record<
  string,
  string
> = Object.freeze({
  rg: 'location2',
  prefecture: 'location2',
  location: 'location2',
  ca: 'category'
})
