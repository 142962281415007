export const SET_LOADING = 'SET_LOADING'
export const SET_CAMPAIGN_SCHEMA = 'SET_CAMPAIGN_SCHEMA'
export const SET_NEW_AD_SCHEMA = 'SET_NEW_AD_SCHEMA'
export const SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA = 'SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA'
export const SET_CAMPAIGN_VALUE = 'SET_CAMPAIGN_VALUE'
export const SET_CAMPAIGN_ERRORS = 'SET_CAMPAIGN_ERRORS'
export const SET_AD_VALUE = 'SET_AD_VALUE'
export const SET_NEW_ITEM_VALUE = 'SET_NEW_ITEM_VALUE'
export const SET_NEW_ITEM_VALUES = 'SET_NEW_ITEM_VALUES'
export const SET_NEW_ITEM_ERRORS = 'SET_NEW_ITEM_ERRORS'
export const UPDATE_CAMPAIGN_AD_VALUE = 'UPDATE_CAMPAIGN_AD_VALUE'
export const UPDATE_CAMPAIGN_AD_ITEM_VALUE = 'UPDATE_CAMPAIGN_AD_ITEM_VALUE'
export const UPDATE_CAMPAIGN_AD_ITEM = 'UPDATE_CAMPAIGN_AD_ITEM'
export const SET_NEW_AD_VALUES = 'SET_NEW_AD_VALUES'
export const ADD_NEW_CAMPAIGN_AD = 'ADD_NEW_CAMPAIGN_AD'
export const ADD_NEW_CAMPAIGN_AD_ITEM = 'ADD_NEW_CAMPAIGN_AD_ITEM'
export const DELETE_CAMPAIGN_AD = 'DELETE_CAMPAIGN_AD'
export const DELETE_CAMPAIGN_AD_ITEM = 'DELETE_CAMPAIGN_AD_ITEM'
export const UPDATE_CAMPAIGN_AD = 'UPDATE_CAMPAIGN_AD'
export const SET_NEW_AD_ERRORS = 'SET_NEW_AD_ERRORS'
export const SET_CAMPAIGN_AD_ERRORS = 'SET_CAMPAIGN_AD_ERRORS'
export const SET_CAMPAIGN_AD_ITEM_ERRORS = 'SET_CAMPAIGN_AD_ITEM_ERRORS'
export const SET_CAMPAIGN_VALUES = 'SET_CAMPAIGN_VALUES'
export const SET_CAMPAIGN_ADS = 'SET_CAMPAIGN_ADS'
export const CLEAR_FORMS = 'CLEAR_FORMS'
