export interface PageError {
  message: string
  statusCode: number
}

export enum CustomErrorType {
  CLASSIFIED_VIEW_GONE = 'CLASSIFIED_VIEW_GONE',
  ANALYTICS_NO_DATA_ERROR = 'ANALYTICS_NO_DATA_ERROR'
}

export interface ErrorPageError {
  message: string
  statusCode: number
  meta: any // meta about the error
  type: CustomErrorType
}
