import { IStringifyOptions } from 'qs'

export enum HttpStatus {
  OK = 200,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  PERFORMANCE_DEGRADATION = 476,
  UNAUTHORIZED = 401,
  NO_DATA = 478,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
  NO_CREDITS = 465,
  BAD_GATEWAY = 502,
  REQUEST_ENTITY_TOO_LARGE = 413,
  TOO_MANY_CLASSIFIEDS_DEALER = 488,
  TOO_MANY_CLASSIFIEDS = 489,
  LOCKED = 423
}

export const defaultQsStringifyOptions: IStringifyOptions = {
  charset: 'utf-8',
  arrayFormat: 'repeat',
  skipNulls: true
}

export const defaultActionResponseValidator = (body: any) =>
  body && body.message

export const defaultActionResponseMapper = (body: any) => body
