import { VNode } from 'vue'

export function useVNode() {
  function addPropertiesToVNodes(
    vNodes: VNode[],
    { props = {}, staticClasses = '', listeners = {} } = {
      props: {},
      staticClasses: '',
      listeners: {}
    }
  ) {
    return vNodes.map(node =>
      addPropertiesToVNode(node, { props, staticClasses, listeners })
    )
  }

  function addPropertiesToVNode(
    node: VNode,
    { props = {}, staticClasses = '', listeners = {} } = {
      props: {},
      staticClasses: '',
      listeners: {}
    }
  ) {
    if (node.componentOptions) {
      node.componentOptions.propsData = {
        ...(node.componentOptions.propsData || {}),
        ...props
      }
      node.componentOptions.listeners = {
        ...(node.componentOptions.listeners || {}),
        ...listeners
      }
    }

    if (node.data) {
      node.data.staticClass = `${node.data?.staticClass || ''} ${staticClasses}`
    }
    return node
  }

  return { addPropertiesToVNodes, addPropertiesToVNode }
}
