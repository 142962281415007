













































import CLocatorModal from '~/components/shared/configurable/location/locator/CLocatorModal.vue'
import { Geolocation } from '~/models/common/types'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import GoogleMapsService from '~/services/GoogleMapsService'
import { defineComponent } from '@nuxtjs/composition-api'

// TODO: add actual types
interface Data {
  city: string
  radius: number
  coordinates: object
  postcode: string | number
  defaultCoordinates: Geolocation
  defaultRadius: number
}

export default defineComponent({
  components: { CLocatorModal },
  props: {
    storedLocation: {
      type: Object,
      required: true
    }
  },
  data(): Data {
    return {
      city: this.storedLocation.city,
      radius: this.storedLocation.radius,
      coordinates: this.storedLocation.coordinates,
      postcode: this.storedLocation.postcode,
      defaultCoordinates: { lat: 37.9818981, lon: 23.7263803 }, // that's Athens
      defaultRadius: 800000 // 800km
    }
  },
  computed: {
    icons() {
      return {
        marker: faMapMarkerAlt,
        remove: faTimesCircle
      }
    },
    formattedRadius() {
      if (this.radius < 1000) {
        return `${this.radius}${this.$t('m').toLowerCase()}`
      }
      return `${this.radius / 1000}${this.$t('km').toLowerCase()}`
    }
  },
  created() {
    if (
      this.storedLocation.coordinates === null &&
      this.storedLocation.postcode === null
    ) {
      // probably anonymous with no set location
      // this.coordinates = this.defaultCoordinates
    }
  },
  mounted() {
    // this.coordinatesCheck()
  },
  methods: {
    async showModal() {
      await this.coordinatesCheck()
      this.$nextTick(() => {
        if (this.$refs.modal) {
          this.$refs.modal.showModal()
        }
      })
    },
    async coordinatesCheck() {
      if (this.coordinates === null) {
        if (this.storedLocation.postcode) {
          this.$analytics.recordEvent({
            namespace: 'n_index_geocoding',
            action: 'geocode',
            label: 'method: coordinatesCheck'
          })

          const googleMapsService = this.$dep(GoogleMapsService)
          const results: google.maps.GeocoderResult[] = await googleMapsService.geocode(
            {
              address: this.storedLocation.postcode
            }
          )
          if (results.length === 0) {
            // there was probably a problem with geolocator
            if (this.storedLocation.city) {
              const cityResults: google.maps.GeocoderResult[] = await googleMapsService.geocode(
                {
                  address: this.storedLocation.city
                }
              )
              if (cityResults.length === 0) {
                this.coordinates = this.defaultCoordinates
              } else {
                this.coordinates = googleMapsService.extractGeolocation(
                  cityResults
                )
              }
            } else {
              this.coordinates = this.defaultCoordinates
            }
          } else {
            this.coordinates = googleMapsService.extractGeolocation(results)
          }
        } else {
          this.coordinates = this.defaultCoordinates
        }
      }
    },
    locationChanged(location) {
      this.city = location.city
      this.radius = location.radius
      this.coordinates = location.coordinates

      this.$emit('new-location', location)

      this.$analytics.recordEvent({
        namespace: 'n_index_recents_location_change',
        action: 'change',
        label: this.city
      })
    },
    async removeLocation() {
      this.coordinates = null // to rerender the modal
      await this.$nextTick()
      const noLocation = {
        city: '',
        radius: this.defaultRadius,
        coordinates: this.defaultCoordinates,
        postcode: null
      }

      this.locationChanged(noLocation)
    }
  }
})
