import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { containerScoped } from '~/decorators/dependency-container'
import { RootState } from '~/store/types'
import { USER_NS } from '~/store/modules/shared/user/state'

@containerScoped()
export default class NotificationService {
  constructor(@inject(Store) private store: Store<RootState>) {}

  userIsEligibleForNotifications(): boolean {
    return !this.store.getters[`${USER_NS}/isAnon`]
  }
}
