import { interopDefault } from '~/router/utils'

export const adminWorkStatsRoute = [
  {
    name: '__admin_stats',
    path: '/admin/stats/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/work-stats.vue' /* webpackChunkName: "pages-admin-work-stats" */
        )
      )
  }
]
