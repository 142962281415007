import { CIcon } from '~/icons/types'
export const ciTimesCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M14.7 9.3c-.4-.4-1-.4-1.4 0L12 10.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l1.3 1.3-1.3 1.3c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l1.3-1.3 1.3 1.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L13.4 12l1.3-1.3c.4-.4.4-1 0-1.4Z'
    },
    {
      d:
        'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8Z'
    }
  ],
  name: 'times-circle',
  type: 'solid'
}
