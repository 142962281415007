import { interopDefault } from '~/router/utils'
import { smoothenRoutesScroll } from '~/utils/router'

export const auditsRequestsRoutes = smoothenRoutesScroll([
  {
    path: 'audits/requests',
    name: '__account_audits_requests',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/requests/index.vue' /* webpackChunkName: "pages-shared-account-audits-requests-index.vue" */
        )
      )
  },
  {
    path: 'audits/requests/certificates/:certificateId',
    name: `__account_audits_requests_certificates_view`,
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/requests/view_certificate.vue' /* webpackChunkName: "pages-shared-account-audits-requests-view_certificate.vue" */
        )
      )
  },
  {
    path: 'audits/requests/:requestId',
    name: '__account_audits_requests_request',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/requests/request.vue' /* webpackChunkName: "pages-shared-account-audits-requests-request.vue" */
        )
      )
  }
])
