import { MutationTree } from 'vuex'
import { I18nState } from './state'
import { SET_LOCALE, SET_LANG } from './mutation-types'
import { I18nLocale } from '~/models/shared/types'

export default {
  [SET_LOCALE](state, locale: I18nLocale) {
    state.locale = locale
  },
  [SET_LANG](state, locale: I18nLocale) {
    state.locale = locale
  }
} as MutationTree<I18nState>
