import { Key, TreeNode } from '~/models/shared/tree/types'

export default class Tree<N extends TreeNode = TreeNode> {
  constructor(private _map: Map<Key, N>) {}

  get map(): Map<Key, N> {
    return this._map
  }

  getChildren(id: Key): N[] {
    const node = this._map.get(id)

    if (!node || !node.childrenIds) {
      return []
    }

    return node.childrenIds.reduce((children: N[], id) => {
      if (!this._map.has(id)) {
        return children
      }
      return [...children, this._map.get(id)!]
    }, [])
  }

  getDescendants(id: Key, filter: (node: N) => boolean = _ => true): N[] {
    const descendants: N[] = []
    const getDescendantsRecursive = (node: N) => {
      const children = this.getChildren(node.id)

      if (!children.length) {
        return
      }

      for (const child of children) {
        if (filter(child)) {
          descendants.push(child)
        }
        getDescendantsRecursive(child)
      }
    }
    getDescendantsRecursive(this._map.get(id)!)
    return descendants
  }

  getRoots(): N[] {
    return [...this._map.values()].filter(
      c => c.parentId === undefined || !this._map.has(c.parentId)
    )
  }

  getAncestors(id: Key): N[] {
    let n: N | undefined = this._map.get(id)
    if (!n || !n.parentId) {
      return []
    }
    // Start from parent so that the target id is not included.
    n = this._map.get(n.parentId)

    const chain: N[] = []
    while (n) {
      chain.unshift(n)
      n = this._map.get(n.parentId || '')
    }
    return chain
  }

  getDepth(id: Key): number {
    return this.getAncestors(id).length
  }
}
