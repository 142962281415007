import { ActionTreeWithRootState } from '~/store/types'
import { OPEN_NAV, CLOSE_NAV } from './mutation-types'
import { DealerSiteLayoutState } from './state'
import { PAGE_NS } from '~/store/modules/shared/page/state'

export default {
  async toggleNav({ state: { navOpen }, dispatch }) {
    const toggle = navOpen ? dispatch('closeNav') : dispatch('openNav')
    await toggle
  },
  openNav({ commit, dispatch }) {
    dispatch(`${PAGE_NS}/addBodyClass`, 'modal-open', { root: true })
    commit(OPEN_NAV)
  },
  closeNav({ commit, dispatch }) {
    dispatch(`${PAGE_NS}/removeBodyClass`, 'modal-open', { root: true })
    commit(CLOSE_NAV)
  }
} as ActionTreeWithRootState<DealerSiteLayoutState>
