import { MutationTree } from 'vuex'
import {
  TradesClassifiedDetails,
  TradesMatches,
  TradesNotifications,
  TradesRecommendations,
  TradesSettings,
  TradesLiked,
  TradesRow,
  TradesRowClassified,
  TradesMatchRow,
  TradeClassified
} from '~/models/trades/types'
import {
  RESET_RECOMMENDATIONS_DATA,
  SET_RECOMMENDATIONS_DATA,
  SET_CATEGORIES,
  SET_LOADING_STATUS,
  SET_SETTINGS,
  SET_CLASSIFIED_DETAILS,
  SET_MATCHES,
  SET_NOTIFICATIONS,
  SET_LIKED,
  UNMATCHED,
  SET_LIKED_BY,
  REMOVE_LIKED_BY,
  ADD_LIKED,
  SET_MATCH_SCREEN_VISIBILITY,
  SET_MATCHED_CLASSIFIED,
  REMOVE_ROW,
  SET_DETAILS_CLASSIFIED_ID,
  SET_NAVBAR_VISIBILITY,
  ADD_MATCH,
  SET_TRADABLE_CLASSIFIEDS,
  SET_ALLOWED_TRADES_MAX,
  SET_MATCH_THREAD_READ,
  SET_ACTIONS_VISIBILITY,
  SET_CURRENT_PAGE,
  ADD_TO_HISTORY,
  REMOVE_TRADABLE_CLASSIFIED
} from '~/store/modules/shared/trades/mutation-types'
import getDefaultState, { TradesState } from './state'
import { ADD_ROW } from './mutation-types'
import { vue3Set } from '~/utils/nuxt3-migration'

export default {
  [SET_RECOMMENDATIONS_DATA](state, data: TradesRecommendations) {
    const newRows = [
      ...data.rows.filter(
        rnew =>
          ![...state.recommendations.rows, ...state.history].some(
            rold => rold.id === rnew.id
          )
      )
    ]

    const rows = state.recommendations.rows.concat(newRows).map(r => {
      const rPrice =
        typeof r.price === 'object'
          ? r.price?.value?.replace(/ €|\./g, '')
          : r.price?.replace(/ €|\./g, '')
      const cPrice = data.classified?.price?.replace(/ €|\./g, '')
      return {
        ...r,
        priceDiff: parseFloat(cPrice as string) - parseFloat(rPrice as string)
      }
    })
    state.recommendations = {
      ...data,
      rows
    }
  },
  [RESET_RECOMMENDATIONS_DATA](state) {
    state.recommendations = { ...getDefaultState().recommendations }
  },
  [SET_CATEGORIES](state, categories: any) {
    state.categories = categories
  },
  [SET_TRADABLE_CLASSIFIEDS](state, classifieds: TradeClassified[]) {
    state.tradableClassifieds = classifieds
  },
  [REMOVE_TRADABLE_CLASSIFIED](state, classifiedId: number | string) {
    state.tradableClassifieds = [
      ...state.tradableClassifieds.filter(c => c.id !== classifiedId)
    ]
  },
  [SET_ALLOWED_TRADES_MAX](state, max: number) {
    state.allowedTradesMax = max
  },
  [SET_LOADING_STATUS](state, status: boolean) {
    state.loading = status
  },
  [SET_SETTINGS](state, settings: TradesSettings) {
    state.settings = settings
  },
  [SET_CLASSIFIED_DETAILS](
    state,
    classifiedDetails: TradesClassifiedDetails | null
  ) {
    state.classifiedDetails = classifiedDetails
  },
  [SET_MATCHES](state, matches: TradesMatches) {
    state.matches = matches
  },
  [SET_NOTIFICATIONS](state, notifications: TradesNotifications) {
    state.notifications = notifications
  },
  [SET_LIKED](state, liked: TradesLiked) {
    state.liked = liked
  },
  [SET_LIKED_BY](state, likedBy: TradesLiked) {
    state.likedBy = likedBy
  },
  [UNMATCHED](state, classifiedId: number) {
    state.matches.rows = state.matches.rows.filter(
      m => m.classified.id !== classifiedId
    )
    state.matches.pagination.total--
  },
  [REMOVE_LIKED_BY](state, classifiedId: number) {
    state.likedBy.rows = state.likedBy.rows.filter(
      m => m.classified.id !== classifiedId
    )
    state.likedBy.pagination.total--
  },
  [ADD_LIKED](state, classified: TradesRowClassified) {
    state.liked.rows.unshift({ classified })
    state.liked.pagination.total++
  },
  [ADD_MATCH](state, row: TradesMatchRow) {
    state.matches.rows.unshift(row)
    state.matches.pagination.total++
  },
  [SET_MATCH_SCREEN_VISIBILITY](state, status: boolean) {
    state.isMatchScreenVisible = status
  },
  [SET_MATCHED_CLASSIFIED](state, classified: TradesRow) {
    state.matchedClassified = classified
    state.detailsClassifiedId = null
  },
  [REMOVE_ROW](state, classifiedId: number) {
    state.recommendations.rows = state.recommendations.rows.filter(
      m => m.id !== classifiedId
    )
  },
  [ADD_ROW](state, row: Array<TradesRowClassified>) {
    state.recommendations.rows = [...row, ...state.recommendations.rows]
  },
  [SET_DETAILS_CLASSIFIED_ID](state, classifiedId: number | null) {
    state.detailsClassifiedId = classifiedId
    if (classifiedId === null) {
      state.detailsClassifiedId = null
    }
  },
  [SET_NAVBAR_VISIBILITY](state, isVisible: boolean) {
    state.isNavbarVisible = isVisible
  },
  [SET_ACTIONS_VISIBILITY](state, isVisible: boolean) {
    state.actionsVisible = isVisible
  },
  [SET_CURRENT_PAGE](state, pageNumber: number) {
    state.currentPage = pageNumber
  },
  [ADD_TO_HISTORY](state, item: TradesRowClassified) {
    state.history.push(item)
    if (state.history.length > 99) {
      // remove the oldest entry to prevent memory issues
      state.history.shift()
    }
  },
  [SET_MATCH_THREAD_READ](
    state,
    { threadId, read }: { threadId: string; read: boolean }
  ) {
    const rowIndex = state.matches.rows.findIndex(m => {
      return m.message?.threadId.toString() === threadId
    })

    if (rowIndex >= 0) {
      const editedRow = state.matches.rows[rowIndex]
      editedRow.message.read = read

      vue3Set(state.matches.rows, rowIndex, editedRow)
    }
  }
} as MutationTree<TradesState>
