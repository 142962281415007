

























































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { Dropdown } from 'floating-vue'
import 'floating-vue/dist/style.css'
import { DropdownPosition, DropdownTrigger } from '~/models/app/dropdown'

export default defineComponent({
  components: {
    Dropdown
  },
  // More props https://floating-vue.starpad.dev/api/
  props: {
    triggers: {
      type: Array as PropType<DropdownTrigger[]>,
      default: () => ['hover']
    },
    // Selector: Container where the popper will be appended (e.g. 'body')
    container: { type: String, default: undefined },
    placement: {
      type: String as PropType<DropdownPosition>,
      default: 'auto'
    },
    delay: {
      type: [String, Object],
      default: () => ({
        hide: 200
      })
    },
    arrowOverflow: { type: Object, default: () => undefined },
    arrowPadding: { type: String, default: undefined },
    // DOM element for the popper position and size boundaries
    boundary: { type: null, default: () => undefined },
    popperTriggers: { type: Array, default: () => undefined },
    maxWidth: { type: [String, Number], default: '300' },
    minWidth: { type: [String, Number], default: undefined },
    minHeight: { type: [String, Number], default: undefined },
    maxHeight: { type: [String, Number], default: undefined },
    noPadding: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    shown: { type: Boolean, default: undefined },
    // From top
    distance: {
      type: [String, Number],
      default: '0'
    },
    // From Left
    skidding: { type: Number, default: undefined },
    wrapperClass: { type: [String, Object], default: undefined },
    contentClass: { type: [String, Object], default: undefined },
    titleClass: { type: [String, Object], default: undefined },
    variant: {
      type: String as PropType<
        'danger' | 'warning' | 'info' | 'light' | 'dark'
      >,
      default: undefined
    },
    showGroup: { type: String, default: undefined },
    popperClass: { type: String, default: undefined },
    triggerClass: { type: [String, Array, Object], default: undefined },
    autoHide: { type: Boolean, default: true },
    noArrow: { type: Boolean, default: false },
    shift: { type: Boolean, default: true },
    autoSize: { type: [Boolean, String], default: undefined },
    hideTriggers: {
      type: Array as PropType<DropdownTrigger[]>,
      default: undefined
    },
    popperHideTriggers: {
      type: Array as PropType<DropdownTrigger[]>,
      default: undefined
    },
    square: {
      type: Boolean,
      default: false
    },
    lowerZ: {
      type: Boolean,
      default: false
    },
    disposeTimeout: {
      type: Number,
      default: undefined
    },
    wrapperStyle: { type: String, default: null }
  },
  setup(props) {
    const {
      maxWidth,
      minWidth,
      minHeight,
      maxHeight,
      shown,
      distance
    } = toRefs(props)
    const dropdownTemplateRef = ref<typeof Dropdown | null>(null)
    const internalShown = ref(shown.value)
    function formatDimension(val: string | number): string | null {
      if (!val) {
        return null
      }

      if (!Number.isFinite(val) && (val as string).match(/%/)) {
        return val as string
      }
      return `${val}px`
    }

    function hide() {
      if (dropdownTemplateRef.value) {
        dropdownTemplateRef.value.hide()
      }
    }

    function show() {
      if (dropdownTemplateRef.value) {
        dropdownTemplateRef.value.show()
      }
    }

    const contentStyle = computed(() => ({
      minWidth: formatDimension(minWidth.value),
      maxWidth: formatDimension(maxWidth.value),
      minHeight: formatDimension(minHeight.value),
      maxHeight: formatDimension(maxHeight.value)
    }))

    const distanceClass = computed(() => {
      // TODO: calculate relative to distance prop
      return `distance-${distance.value}`
    })

    watch(
      shown,
      (newShownValue: boolean) => (internalShown.value = newShownValue)
    )

    return {
      dropdownTemplateRef,
      hide,
      show,
      contentStyle,
      distanceClass,
      internalShown
    }
  }
})
