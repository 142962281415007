import { Context } from '@nuxt/types'
import { registry, inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import CookiesService from '~/services/CookiesService'
import { appendToResponseHeader } from '~/utils/http'
import { RouteConfig } from 'vue-router'
import subdomainDealerSiteRoutes from '~/router/routes/car/dealers/site/subdomain'
import auditsSubdomainRoutes from '~/router/routes/car/subdomains/audits'
import { extractCookie } from '~/utils/cookies'
export const subdomainRouterCreationCookieToken = Symbol(
  'subdomainRouterCreationCookieInjectionToken'
)
export const subdomainRouterCreationHttpHeaders = Symbol(
  'subdomainRouterCreationHttpHeadersInjectionToken'
)

export interface SubdomainRouterHeader {
  header: string
  routes: RouteConfig[]
}

@containerScoped()
@registry([
  {
    token: subdomainRouterCreationCookieToken,
    useValue: 'dedicatedSubdomainRender'
  },
  {
    token: subdomainRouterCreationHttpHeaders,
    useValue: [
      {
        header: 'dealer-site-request-domain',
        routes: [...subdomainDealerSiteRoutes]
      },
      { header: 'audits-iframe', routes: [...auditsSubdomainRoutes] }
    ]
  }
])
export default class SubdomainService {
  private ctx: Context | undefined

  constructor(
    @inject(subdomainRouterCreationCookieToken)
    private routerCreationCookie: string,
    @inject(subdomainRouterCreationHttpHeaders)
    private routerCreationHttpHeaders: SubdomainRouterHeader[]
  ) {}

  private static routerCreationCookiePath = '/'

  setContext(ctx: Context | undefined) {
    this.ctx = ctx
  }

  // TODO: Once container is added, convert this to instance method and use
  //  `container.resolve` to get this instance, f.e in `createRouter`.
  shouldCreateSubdomainRouter(): boolean {
    const { ctx } = this
    try {
      if (this.windowHasDedicatedRenderCookieEnabled()) {
        return true
      }
    } catch {}

    if (ctx?.req?.headers) {
      const supportedHeaderNames = this.routerCreationHttpHeaders.map(
        r => r.header
      )
      return Object.keys(ctx.req.headers).some(r =>
        supportedHeaderNames.includes(r)
      )
    }
    return false
  }

  handleSubdomainRouterCreationCookie() {
    const { ctx } = this
    if (ctx && ctx.res && this.shouldCreateSubdomainRouter()) {
      this.enableSubdomainCreationCookie()
    }

    this.deleteSubdomainRouterCreationCookieFromClient()
  }

  deleteSubdomainRouterCreationCookieFromClient() {
    try {
      if (this.windowHasDedicatedRenderCookieEnabled()) {
        document.cookie = `${this.routerCreationCookie}=; Path=/; ${
          CookiesService.deletionExpirationAttribute
        }; Domain=${
          window.location.hostname
        };${this.getSecureSameSiteCookieAttribute()}`
        // window.document.cookie = `${cookieExpiryCommand}`
      }
    } catch {}
  }

  getRoutes(): RouteConfig[] {
    const currentSubdomain = this.getCurrentSubdomain()
    return currentSubdomain?.routes || []
  }

  private getCurrentSubdomain(): SubdomainRouterHeader | null {
    const { ctx } = this
    for (const h of this.routerCreationHttpHeaders) {
      if (ctx) {
        // server side

        if (ctx?.req?.headers[h.header]) {
          return h
        }
      } else if (window && window.document.cookie) {
        // client side cookie
        const cookie = extractCookie(this.routerCreationCookie, window.document)
        if (cookie === h.header) {
          return h
        }
      }
    }
    return null
  }

  private enableSubdomainCreationCookie() {
    const { ctx } = this
    if (!ctx || !this.shouldCreateSubdomainRouter() || !ctx.res) {
      return
    }

    const currentSubdomain = this.getCurrentSubdomain()
    const cookieValue = currentSubdomain?.header || '1'

    appendToResponseHeader(ctx.res, 'set-cookie', [
      `${this.routerCreationCookie}=${cookieValue}; Path=${
        SubdomainService.routerCreationCookiePath
      };${this.getSecureSameSiteCookieAttribute()}`
    ])
  }

  private getSecureSameSiteCookieAttribute(): string {
    // if you are getting errors set SSL locally -> https://outline.pkw.gr/s/6c248a56-c933-4051-b657-a8b48b631f89
    // and then either run production build or override the check below
    return process.env.NODE_ENV === 'production' ? ' Secure;SameSite=None;' : ''
  }

  private windowHasDedicatedRenderCookieEnabled(): boolean {
    const currentSubdomain = this.getCurrentSubdomain()
    const cookieValue = currentSubdomain?.header || '1'
    return (
      window &&
      window.document.cookie
        .split('; ')
        .includes(`${this.routerCreationCookie}=${cookieValue}`)
    )
  }
}
