import { GetterTreeWithRootState } from '~/store/types'
import { BackgroundJobsState } from './state'
import {
  BackgroundJob,
  BackgroundJobActionTypes,
  BackgroundJobStatusTypes
} from '~/models/background-jobs/types'

export default {
  getBackgroundJobs(state): BackgroundJob[] {
    return state.jobs
  },
  getMassActionsTouchJobs(state): BackgroundJob[] {
    return state.jobs.filter(j => {
      return j.name === BackgroundJobActionTypes.MASS_TOUCH
    })
  },
  getPendingAndRunningIDs(state): any[] {
    return state.jobs
      .filter(j => {
        return (
          j.status === BackgroundJobStatusTypes.PENDING ||
          j.status === BackgroundJobStatusTypes.RUNNING
        )
      })
      .map(job => {
        return job.id
      })
  },
  getPendingAndRunningJobs(state): any[] {
    return state.jobs.filter(j => {
      return (
        j.status === BackgroundJobStatusTypes.PENDING ||
        j.status === BackgroundJobStatusTypes.RUNNING
      )
    })
  },
  getMassActionsPriceChangeJobs(state): BackgroundJob[] {
    return state.jobs.filter(j => {
      return j.name === BackgroundJobActionTypes.PRICE_CHANGE
    })
  },
  getMassActionsShowHideJobs(state): BackgroundJob[] {
    return state.jobs.filter(j => {
      return (
        j.name === BackgroundJobActionTypes.SHOW ||
        j.name === BackgroundJobActionTypes.HIDE
      )
    })
  },
  getMassActionsCustomJobs(state): BackgroundJob[] {
    return state.jobs.filter(j => {
      return j.name === BackgroundJobActionTypes.CUSTOM_PAGE
    })
  }
} as GetterTreeWithRootState<BackgroundJobsState>
