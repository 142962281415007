import { interopDefault } from '~/router/utils'

export const adminInteractionsRoutes = [
  {
    name: '__admin_interactions_search',
    path: '/admin/interactions/search',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/interactions/search.vue' /* webpackChunkName: "pages-admin-shared-interactions-search" */
        )
      ),
    meta: { noFooter: true }
  }
]
