import { getDealerSiteRouteName } from '~/utils/router'

const index = getDealerSiteRouteName()
const page = getDealerSiteRouteName('page')
const classified = getDealerSiteRouteName('classified')
const classifiedWithShortUrl = getDealerSiteRouteName(
  'classified_with_short_url'
)

export const DealerSiteRouteName: Readonly<{
  INDEX: string
  PAGE: string
  CLASSIFIED: string
  CLASSIFIED_WITH_SHORT_URL: string
}> = {
  INDEX: index,
  PAGE: page,
  CLASSIFIED: classified,
  CLASSIFIED_WITH_SHORT_URL: classifiedWithShortUrl
}

export const dealerSiteRouteNames: string[] = [
  index,
  page,
  classified,
  classifiedWithShortUrl
]

export const nonClassifiedDealerSiteRouteNames: string[] = [index, page]
export const classifiedDealerSiteRouteNames: string[] = [
  classified,
  classifiedWithShortUrl
]
