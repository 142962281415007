import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__analytics',
    path: '/analytics/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/analytics/index.vue' /* webpackChunkName: "pages-analytics.vue" */
        )
      ),
    redirect: { name: '__analytics_overview' },
    children: [
      {
        path: 'overview/',
        name: '__analytics_overview',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/analytics/overview.vue' /* webpackChunkName: "pages-analytics-overview.vue" */
            )
          )
      },
      {
        path: 'market/',
        name: '__analytics_market',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/analytics/market.vue' /* webpackChunkName: "pages-analytics-market.vue" */
            )
          )
      },
      {
        path: 'classifieds/',
        name: '__analytics_classifieds',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/analytics/classifieds/index.vue' /* webpackChunkName: "pages-analytics-classifieds.vue" */
            )
          )
      }
    ]
  },
  {
    name: '__classified_analytics',
    path: '/analytics/classifieds/:classifiedId/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/analytics/classifieds/classified.vue' /* webpackChunkName: "pages-analytics-classifieds-classified.vue" */
        )
      )
  }
]
