export const CLASSIFIED_SEARCH_ACTIONS_NS = 'classifieds/search/actions'

export interface SearchActionsState {
  pending: boolean
  noCreditsForClassified: number | null
}
function getDefaultState(): SearchActionsState {
  return {
    pending: false,
    noCreditsForClassified: null
  }
}

export default getDefaultState
