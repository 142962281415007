import { Store } from 'vuex'
import BasePageSnippet from '../BasePageSnippet'

export default class ContactPageSnippet extends BasePageSnippet {
  async onPageLoad(_store: Store<any>): Promise<void> {
    // const dsStore = getNamespacedStore(store, DEALER_SITE_NS)
    // TODO: https://app.asana.com/0/1171899741792753/1179057806470041
    // await dsStore.dispatch('loadOutlets')
  }
}
