import {
  TradesClassifiedDetails,
  TradesRecommendations,
  TradesSettings,
  TradesMatches,
  TradesNotifications,
  TradesLiked,
  TradesRow,
  TradeClassified,
  TradesRowClassified,
  TradesCategory
} from '~/models/trades/types'
import { DEFAULT_NUMBER_OF_RESULTS_PER_PAGE } from '~/constants/search/defaults'

export interface TradesState {
  recommendations: TradesRecommendations
  tradableClassifieds: TradeClassified[]
  allowedTradesMax?: number
  loading: boolean
  categories: Array<TradesCategory>
  settings: TradesSettings
  classifiedDetails: TradesClassifiedDetails | null
  matches: TradesMatches
  liked: TradesLiked
  likedBy: TradesLiked
  notifications: TradesNotifications
  isMatchScreenVisible: boolean
  matchedClassified: TradesRow | null
  detailsClassifiedId: number | null
  isNavbarVisible: boolean
  actionsVisible: boolean
  currentPage: number
  history: TradesRowClassified[]
}

export const TRADES_NS = 'trades'
function getDefaultState(): TradesState {
  const pagination = {
    page: 1,
    pageParam: '',
    perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
    total: 0
  }
  return {
    recommendations: {
      classified: null,
      pagination: { ...pagination },
      rows: [],
      tradableClassifiedCount: 0
    },
    tradableClassifieds: [],
    allowedTradesMax: 20,
    loading: false,
    categories: [],
    settings: { categories: [], maxCategories: 10 },
    classifiedDetails: null,
    matches: {
      pagination: { ...pagination },
      rows: []
    },
    liked: {
      pagination: { ...pagination },
      rows: []
    },
    likedBy: {
      pagination: { ...pagination },
      rows: []
    },
    notifications: {
      tradesLikedBy: 0,
      tradesMessage: 0,
      tradesMatched: 0
    },
    isMatchScreenVisible: false,
    matchedClassified: null,
    detailsClassifiedId: null,
    isNavbarVisible: true,
    actionsVisible: true,
    currentPage: 1,
    history: []
  }
}
export default getDefaultState
