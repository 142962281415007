import { createModule } from '~/factories/store'
import actions from '~/store/modules/shared/parking/searchSubscriptions/actions'
import getters from '~/store/modules/shared/parking/searchSubscriptions/getters'
import mutations from '~/store/modules/shared/parking/searchSubscriptions/mutations'
import state from '~/store/modules/shared/parking/searchSubscriptions/state'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
