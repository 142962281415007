

























































import { ErrorPageError } from '~/models/error'
import Title from '~/components/car/classifieds/view/partials/Header/Title.vue'
import CBreadcrumbs from '~/components/shared/configurable/CBreadcrumbs.vue'
import ClassifiedService from '~/services/classified/ClassifiedService'
import { Classified } from '~/models/classified/types'
import FeedGrid from '~/components/car/classifieds/index/feeds/FeedGrid.vue'
import HeadService from '~/services/HeadService'
import { formatNumber } from '~/utils/number'
import { SimilarClassifiedsEntity } from '~/models/classified/view/similar'
import { DeletedInfoLink } from '~/models/classified/view/deleted'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import CShimmer from '~/components/shared/configurable/shimmer/CShimmer.vue'

interface Data {
  relatedLinks: DeletedInfoLink[]
  classified: Classified | null
  page: any
  similar: SimilarClassifiedsEntity
  seenByOthers: SimilarClassifiedsEntity
}

export default defineComponent({
  components: {
    CShimmer,
    CBadge,
    FeedGrid,
    CBreadcrumbs,
    Title
  },
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  },
  data(): Data {
    return {
      relatedLinks: [],
      classified: null,
      page: null,
      similar: null,
      seenByOthers: null,
      loading: true
    }
  },
  computed: {
    classifiedId() {
      return this.error.meta.classifiedId
    }
  },
  async mounted() {
    const classifiedService = this.$dep(ClassifiedService)

    try {
      const [deletedInfoData, similarClassifiedsData] = await Promise.all([
        classifiedService.getDeletedInfo(this.classifiedId),
        classifiedService.getSimilarClassifieds(this.classifiedId, true)
      ])

      this.relatedLinks = deletedInfoData.relatedLinks
      this.classified = deletedInfoData.classified
      this.page = deletedInfoData.page

      this.similar = similarClassifiedsData.similar
      this.seenByOthers = similarClassifiedsData.seenByOthers
    } catch (error) {
    } finally {
      this.loading = false
    }
  },
  methods: {
    formatNumber,
    showSimilarEntryFeed(similarEntry: SimilarClassifiedsEntity) {
      return (
        similarEntry &&
        similarEntry.classifieds &&
        similarEntry.classifieds.length > 0
      )
    }
  },
  head() {
    if (!this.page) {
      return
    }
    const headService = this.$dep(HeadService)
    const { title, metadata } = this.page
    return headService.createHead({
      title,
      meta: headService.formatBackendMeta(metadata)
    })
  }
})
