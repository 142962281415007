import { singleton } from 'tsyringe'
import { ClientOnly } from '~/decorators'
import VueScrollTo, { ScrollOptions } from 'vue-scrollto'

@singleton()
@ClientOnly
export default class ScrollService {
  scrollToTop(options?: ScrollOptions) {
    VueScrollTo.scrollTo(document.body, options)
  }

  scrollToTopSmooth() {
    window && window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  scrollTo(element: any, options?: ScrollOptions): void {
    VueScrollTo.scrollTo(element, options)
  }

  scrollIfOverflown(element: any, container: any, offset: number = 0): void {
    if (!container || !element) {
      return
    }

    if (
      container?.offsetWidth + container.scrollLeft <
      element.offsetLeft + element.offsetWidth
    ) {
      const scrollToLeft =
        element.offsetLeft +
        element.offsetWidth -
        container.offsetWidth +
        offset

      container.scrollTo({
        top: container.scrollTop,
        left: scrollToLeft,
        behavior: 'smooth'
      })
    } else if (container?.scrollLeft > element.offsetLeft) {
      container.scrollTo({
        top: container.scrollTop,
        left: element.offsetLeft - offset,
        behavior: 'smooth'
      })
    }
  }

  scrollIfOverflownVertical(
    element: any,
    container: any,
    offset: number = 0
  ): void {
    if (!element || !container) {
      return
    }

    if (
      container?.offsetHeight + container.scrollTop <
      element.offsetTop + element.clientHeight
    ) {
      container.scrollTop =
        element.offsetTop +
        element.clientHeight -
        container.offsetHeight +
        offset
    } else if (container?.scrollTop > element.offsetTop) {
      container.scrollTop = element.offsetTop + offset
    }
  }
}
