import { MutationTree } from 'vuex'
import { tree } from '~/models/shared/tree'
import {
  SET_LOAD_STATUS,
  ADD_HIERARCHICAL_CATEGORIES,
  EXPAND_CATEGORY,
  COLLAPSE_CATEGORY,
  FOCUS_CATEGORY,
  SET_CATEGORY_FOR_EDIT,
  CLEAR_CATEGORY_FOR_EDIT,
  SET_POPULARITY_MAP
} from './mutation-types'
import { AdminCategoriesState } from './state'
import { LoadStatus } from '~/store/types'
import { HierarchicalCategory } from '~/models/category/types'
import Tree from '~/models/shared/tree/Tree'
import { PopularityMap } from '~/models/popularity/types'

export default {
  [SET_LOAD_STATUS](state, status: LoadStatus) {
    state.loadStatus = status
  },
  [ADD_HIERARCHICAL_CATEGORIES](
    state,
    categories: Map<string, HierarchicalCategory>
  ) {
    const nextStateCategories = new Map(state.categories)

    categories.forEach(c => {
      const sc: HierarchicalCategory | undefined = state.categories.get(c.id)
      const childrenExist =
        sc && sc.childrenIds && (!c.childrenIds || !c.childrenIds.length)
      if (childrenExist) {
        c.childrenIds = sc!.childrenIds
      }

      nextStateCategories.set(c.id, c)
    })

    state.categories = nextStateCategories
  },
  [EXPAND_CATEGORY](state, id: string) {
    const expandedIds = state.expandedIds
    expandedIds.add(id)

    const tr: Tree<HierarchicalCategory> = tree(state.categories)
    const ancestors = tr.getAncestors(id)
    for (const ancestor of ancestors) {
      expandedIds.add(ancestor.id)
    }

    state.expandedIds = new Set(expandedIds)
  },
  [COLLAPSE_CATEGORY](state, id: string) {
    const expandedIds = state.expandedIds
    expandedIds.delete(id)

    const tr: Tree<HierarchicalCategory> = tree(state.categories)
    const descendants = tr.getDescendants(id)
    for (const descendant of descendants) {
      expandedIds.delete(descendant.id)
    }

    state.expandedIds = new Set(expandedIds)
  },
  [FOCUS_CATEGORY](state, id: string) {
    state.focusedId = id
  },
  [SET_CATEGORY_FOR_EDIT](state, id: string) {
    state.idInEdit = id
  },
  [CLEAR_CATEGORY_FOR_EDIT](state) {
    state.idInEdit = null
  },
  [SET_POPULARITY_MAP](state, popularityMap: PopularityMap) {
    state.popularityMap = popularityMap
  }
} as MutationTree<AdminCategoriesState>
