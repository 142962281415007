import { DealerSiteErrorType } from '~/models/dealer/site/error/types'
import { DEALER_SITE_NS } from '../state'

export interface DealerSiteErrorState {
  type?: DealerSiteErrorType
}

export const DEALER_SITE_ERROR_NS = `${DEALER_SITE_NS}/error`

export default function getDefaultState(): DealerSiteErrorState {
  return {
    type: undefined
  }
}
