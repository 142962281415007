import { Context } from '@nuxt/types'

export function extractCookie(
  key: string,
  source: Document | Context['req']['headers'] | any
): string | null {
  if (!source || !source.cookie) {
    return null
  }
  // the entire cookie value e.g. 'test=<a href="/"> some link </a>'
  const cookie = source.cookie
    .split('; ')
    .find((row: string) => row.startsWith(key))
  if (!cookie) {
    return null
  }
  // the cookie's parts when split with equals e.g. ['test', '<a href', '"/"> some link </a>']
  const parts: string[] = cookie.split('=')
  // the first part is the key (name), so set the initial value as the second part
  let value: string = parts[1]
  // if the parts are more than 2 (more equal signs leading to more complex value) we need to reconstruct the initial
  // value by concatenating with the equal sign.
  if (parts.length > 2) {
    for (let i = 2; i < parts.length; i++) {
      value += `=${parts[i]}`
    }
  }
  return value
}
