export interface ImagePreloadState {
  urls: Set<string>
}

export const IMAGE_PRELOAD_NS = 'preload/image'

export default function getInitialState(): ImagePreloadState {
  return {
    urls: new Set()
  }
}
