import { onMounted, ref, Ref } from '@nuxtjs/composition-api'
import { v4 as uuidV4 } from 'uuid'

export function useId() {
  function createRandomId(seed: string = uuidV4()): Ref<string> {
    const id = ref(seed)

    onMounted(() => {
      id.value = `${seed}-${uuidV4()}`
    })

    return id
  }

  function uuid() {
    return uuidV4()
  }
  return { uuid, createRandomId }
}
