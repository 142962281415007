export const APP_NS = 'app'
export interface AppState {
  name: string
}

function getDefaultState(): AppState {
  return {
    name: 'car'
  }
}

export default getDefaultState
