import { GetterTreeWithRootState } from '~/store/types'
import { DealersMapState } from './state'

export default {
  getFacetsCount(state) {
    let activeFilters = 0
    if (state.params.q) {
      activeFilters += 1
    }
    if (state.params.ca && state.params.ca.length > 0) {
      activeFilters += 1
    }
    if (state.params.postcode) {
      activeFilters += 1
    }
    return activeFilters
  }
} as GetterTreeWithRootState<DealersMapState>
