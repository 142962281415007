import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import {
  ClassifiedsAnalyticsPanelData,
  ClassifiedAnalyticsSubscriptionTiersResult,
  ClassifiedAnalyticsGlimpseResult,
  SingleClassifiedAnalyticsResult,
  ClassifiedsAnalyticsOverviewResult,
  ClassifiedsVehicleModelPriceStats,
  Insight,
  VehiclesAnalyticsParams
} from '~/models/analytics/classified-result'
import { invalidBodyError } from '~/services/errors'
import { toBase64, toCamelCase, toURIBase64 } from '~/utils/object'
import { format } from '~/utils/date'
import { POPULARITY_QUERY_DATE_FORMAT } from '~/constants/popularity'
import {
  formatOverviewResponse,
  formatSectionPanelData,
  formatSingleClassifiedAnalyticsResponse
} from '~/services/analytics/classifieds/formatters'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toSnakeCase } from '~/utils/snake-case'

@containerScoped()
export default class ClassifiedsAnalyticsService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  async getOverview(
    subFilters: Record<string, any> = {},
    queryParams: Record<string, any> = {}
  ): Promise<ClassifiedsAnalyticsOverviewResult> {
    const base64SubFilters = () => {
      const base64SubFilters: Record<string, any> = {}
      for (const [name, value] of Object.entries(subFilters)) {
        base64SubFilters[name] = toBase64(value)
      }
      return base64SubFilters
    }
    const response = await this.http.get('/api/statistics/overview/', {
      params: { ...base64SubFilters() }
    })
    const body = response.data
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }
    const shopValue = queryParams['sf-shop']
    if (shopValue) {
      return formatOverviewResponse(
        this.formatUserSectionsOutlet(body.data, shopValue)
      )
    }
    return formatOverviewResponse(body.data)
  }

  formatUserSectionsOutlet(data: any, outletValue: string) {
    return {
      ...data,
      user_sections: data.user_sections.map((us: any) => ({
        ...us,
        filters: {
          ...us.filters,
          ...this.setOutletInFilters(
            data.outlets.filter_key,
            outletValue,
            us.filters
          )
        }
      }))
    }
  }

  setOutletInFilters(
    key: string | undefined,
    value: string,
    filters: { [key: string]: string }
  ) {
    if (value && key) {
      return {
        ...filters,
        [key]: value
      }
    }
    return filters
  }

  getPanelData(
    url: string,
    params: Record<string, any> = {}
  ): Promise<ClassifiedsAnalyticsPanelData> {
    const q: string = toURIBase64(params)
    return this.requestBuilder
      .request('get', url)
      .params({ q })
      .map(body => formatSectionPanelData(body.data))
      .send()
  }

  async getClassifiedAnalytics(
    classifiedId: number,
    params: Record<string, any>
  ): Promise<SingleClassifiedAnalyticsResult> {
    const response = await this.http.get(
      `/api/statistics/classified/${classifiedId}/`,
      {
        params: {
          q: toBase64(params)
        }
      }
    )
    const body = response.data
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }

    return formatSingleClassifiedAnalyticsResponse(body.data)
  }

  async getGlimpse(
    fetchUrl: string
  ): Promise<ClassifiedAnalyticsGlimpseResult> {
    const response = await this.http.get(fetchUrl)
    const body = response.data
    if (!body?.data) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data)
  }

  async getSubscriptionTiersInfo(): Promise<
    ClassifiedAnalyticsSubscriptionTiersResult
  > {
    const response = await this.http.get('/api/statistics/')
    const body = response.data
    if (!body?.data) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data)
  }

  public createPeriodParam(date: Date) {
    return date && format(date, POPULARITY_QUERY_DATE_FORMAT)
  }

  getVehicleModelPriceStats(
    params?: VehiclesAnalyticsParams
  ): Promise<ClassifiedsVehicleModelPriceStats> {
    // add {live: 1} param to be able to work in local development
    return this.requestBuilder
      .request('get', '/api/classifieds/vehicles/analytics/')
      .params({ ...toSnakeCase(params) })
      .send()
  }

  getVehicleModelPriceInsights(url: string): Promise<{ insights: Insight[] }> {
    return this.requestBuilder.request('get', url).send()
  }
}
