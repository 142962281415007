import { ourDomains } from '~/constants/domain'

export function useUrl() {
  const urlApiIsAvailable = (): boolean => {
    if (process.server) {
      return true
    }

    return 'URL' in window
  }

  const urlIsAbsolute = (url: string): boolean => {
    // TODO: maybe tweak this
    return url.startsWith('http')
  }

  const getUrlDomainWithoutSubdomain = (url: string): string => {
    if (!urlApiIsAvailable()) {
      return ''
    }
    try {
      const urlParts = new URL(url).hostname.split('.')

      return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.')
    } catch (error) {
      // url is invalid, we can't parse it
      return ''
    }
  }

  // refers to 'https://www.car.gr/link?s=' type urls
  function urlIsGatewayLink(url: string): boolean {
    if (!urlIsAbsolute(url) || !urlApiIsAvailable()) {
      return false
    }
    try {
      const path = new URL(url).pathname
      return path === '/link/'
    } catch (error) {
      // url is invalid, we can't parse it
      return false
    }
  }

  const urlIsExternal = (url: string, strict: boolean = false): boolean => {
    if (!urlIsAbsolute(url)) {
      return false
    }
    const nakedDomain = getUrlDomainWithoutSubdomain(url)
    if (strict) {
      const domain = process.env.DOMAIN || 'car.gr'
      return !domain.includes(nakedDomain)
    }
    return !ourDomains.includes(nakedDomain)
  }

  return {
    urlIsExternal,
    urlIsAbsolute,
    getUrlDomainWithoutSubdomain,
    urlIsGatewayLink
  }
}
