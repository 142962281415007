























import FeedClassifiedEntry from './feed-entries/FeedClassifiedEntry.vue'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { APP_NS } from '~/store/modules/shared/app/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    FeedClassifiedEntry
  },
  props: {
    classifieds: {
      type: Array,
      required: true
    },
    enableTransitions: {
      type: Boolean,
      required: false,
      default: false
    },
    transitionName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isMobile: 'isMobile'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    ...mapDeps({
      scrollService: ScrollService
    }),
    transitionNameFormatted() {
      if (this.isMobile) {
        return this.transitionName + '-list'
      }
      return this.transitionName
    }
  },
  methods: {
    scrollToLatest(elem) {
      if (this.isPc) {
        // check if element's top is visible but bottom isn't and only then scroll to it
        // revisit this if users complain (maybe create a button to disable it)
        const docViewTop = document.documentElement.scrollTop
        const docViewBottom = docViewTop + window.innerHeight

        const offsetTop = 25
        const offsetBottom = 5
        let elemTop = 0
        let offsetElem = elem
        if (offsetElem.offsetParent) {
          do {
            elemTop += offsetElem.offsetTop
            offsetElem = offsetElem.offsetParent
          } while (offsetElem)
        }

        if (elemTop < 0) {
          elemTop = 0
        }

        const elemBottom = elemTop + elem.offsetHeight

        if (
          elemTop + offsetTop < docViewBottom &&
          elemBottom - offsetBottom >= docViewBottom
        ) {
          // do scroll

          this.$nextTick(() => {
            this.scrollService.scrollTo(elem, {
              easing: 'ease-out',
              duration: 300
            })
          })
        }
      }
    }
  }
})
