export enum FloatingCornerElementName {
  BACK_TO_TOP_BUTTON = 'back-to-top-button',
  NOTIFICATION_ENABLING_BUTTON = 'notification-enabling-button',
  FACETS_BUTTON = 'facets-button',
  SAVE_BUTTON = 'save-button',
  QNA_QUESTION_BUTTON = 'qna-question-button'
}

export enum Corner {
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  TOP_LEFT = 'top-left'
}
