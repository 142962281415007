import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__unsubscribe',
    path: '/unsubscribe/:token',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/unsubscribe.vue' /* webpackChunkName: "pages-unsubscribe" */
        )
      )
  }
]
