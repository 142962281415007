import { interopDefault } from '~/router/utils'

const BlacklistReason = () =>
  interopDefault(
    import(
      '~/pages/car/blacklist/reason.vue' /* webpackChunkName: "pages-blacklist-reason.vue" */
    )
  )

const BlacklistTooManyClassifieds = () =>
  interopDefault(
    import(
      '~/pages/car/blacklist/too-many-classifieds.vue' /* webpackChunkName: "pages-blacklist-too-many-classifieds.vue" */
    )
  )

export default [
  {
    name: '__blacklist_reason',
    path: '/b/:reasonId/',
    component: BlacklistReason
  },
  {
    name: '__blacklist_too_many_classifieds',
    path: '/too-many-classifieds/',
    component: BlacklistTooManyClassifieds
  }
]
