import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__search_classified_by_id',
    path: '/search-classified-by-id/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/search-classified-by-id.vue' /* webpackChunkName: "pages-search-classified-by-id" */
        )
      )
  }
]
