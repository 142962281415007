import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { containerScoped } from '~/decorators/dependency-container'
import { Classified } from '~/models/classified/types'
import { Layout } from '~/models/dealer/site/types'
import { ViewType } from '~/models/shared/types'
import { StoreWithRootState } from '~/store/types'
import { ADD_PRELOADED_IMAGES } from '~/store/modules/shared/preload/image/mutation-types'
import { IMAGE_PRELOAD_NS } from '~/store/modules/shared/preload/image/state'

@containerScoped()
export default class ImagePreloadRegistrar {
  constructor(@inject(Store) private store: StoreWithRootState) {}

  registerImages(urls: string[]) {
    if (!process.server) {
      return
    }
    this.store.commit(`${IMAGE_PRELOAD_NS}/${ADD_PRELOADED_IMAGES}`, urls)
  }

  registerSearchResultThumbnails(classifieds: Classified[]) {
    if (!process.server) {
      return
    }

    const {
      classifieds: {
        search: { viewType }
      }
    } = this.store.state
    const numberOfPreloadedThumbnails = viewType === ViewType.LIST ? 4 : 8
    this.store.commit(
      `${IMAGE_PRELOAD_NS}/${ADD_PRELOADED_IMAGES}`,
      classifieds.slice(0, numberOfPreloadedThumbnails).map(r => r.thumb)
    )
  }

  registerDealerSiteLayoutImages(layout: Layout) {
    const {
      header_left_photo: leftPhoto,
      header_photo: photo,
      header_right_photo: rightPhoto
    } = layout.template.conf
    const images: string[] = []
    // Prioritize image that is most probable to be the largest so that Google LCP time is reduced.
    const isValidUrl = (url: string | undefined): boolean =>
      Boolean(url && url !== 'none')
    isValidUrl(photo) && images.push(photo!)
    isValidUrl(leftPhoto) && images.push(leftPhoto!)
    isValidUrl(rightPhoto) && images.push(rightPhoto!)
    this.registerImages(images)
  }
}
