import { AppConfirmationModalState, ConfirmationModalOptions } from './state'

import {
  HIDE_CONFIRMATION_MODAL,
  SHOW_CONFIRMATION_MODAL
} from './mutation-types'

export default {
  [SHOW_CONFIRMATION_MODAL](
    state: AppConfirmationModalState,
    options: ConfirmationModalOptions
  ) {
    state.isVisible = true
    state.options = options
  },
  [HIDE_CONFIRMATION_MODAL](state: AppConfirmationModalState) {
    state.isVisible = false
    state.options = {}
  }
}
