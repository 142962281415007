import { parse } from 'date-fns'

export function sleep(time: number) {
  return new Promise<void>(resolve => setTimeout(() => resolve(), time))
}
export function getCurrentTime(plusHours?: number): string {
  const date = new Date()
  if (plusHours) {
    date.setHours(date.getHours() + plusHours)
  }
  let hh: string | number = date.getHours()
  let mm: string | number = date.getMinutes()

  hh = hh < 10 ? '0' + hh : hh
  mm = mm < 10 ? '0' + mm : mm

  const currTime = hh + ':' + mm
  return currTime
}

export function combineDateAndTime(
  date?: Date,
  time?: string
): Date | undefined {
  if (!date || !time) {
    return undefined
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // Jan is 0, dec is 11
  const day = date.getDate()
  const dateString = '' + year + '-' + month + '-' + day
  const combined = parse(
    dateString + ' ' + time + ':00',
    'yyyy-M-d HH:mm:ss',
    new Date()
  )

  return combined
}
