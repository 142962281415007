





import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {},
  setup() {
    return {}
  }
})
