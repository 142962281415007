import { ActionTreeWithRootState } from '~/store/types'
import AuditShopService from '~/services/audits/AuditShopService'
import {
  SET_AUDIT_SHOP,
  SET_AUDIT_SHOP_FIELD_VALUES,
  SET_AUDIT_SHOP_SCHEMA
} from '~/store/modules/shared/account/audits/mutation-types'
import { AccountAuditsState } from '~/store/modules/shared/account/audits/state'

export default {
  async loadAuditShop(
    { commit },
    {
      shopId,
      category,
      schemaOnly = false
    }: { shopId: number; category?: string | number; schemaOnly: boolean }
  ) {
    const auditShopService = this.$dep(AuditShopService)
    const {
      auditShop,
      shopFieldValues,
      schema
    } = await auditShopService.getAuditShop(shopId, category)
    if (!schemaOnly) {
      commit(SET_AUDIT_SHOP, auditShop)
      commit(SET_AUDIT_SHOP_FIELD_VALUES, shopFieldValues)
    }

    commit(SET_AUDIT_SHOP_SCHEMA, schema)
  }
} as ActionTreeWithRootState<AccountAuditsState>
