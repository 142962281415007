import { AuditRequestStatusStatus } from '~/models/audit/request'

export const AUDIT_REQUEST_SUCCESSFUL_STATUSES = Object.freeze([
  AuditRequestStatusStatus.AUDIT_COMPLETED
])

export const AUDIT_REQUEST_FAILED_STATUSES = Object.freeze([
  AuditRequestStatusStatus.CANCELED_BY_AUDITOR,
  AuditRequestStatusStatus.CANCELED_BY_USER,
  AuditRequestStatusStatus.CANCELED_CAUSE_PAYMENT_NOT_RECEIVED,
  AuditRequestStatusStatus.AUDIT_COULD_NOT_COMPLETE,
  AuditRequestStatusStatus.EXPIRED,
  AuditRequestStatusStatus.PAYMENT_EXPIRED,
  AuditRequestStatusStatus.ACCEPTANCE_EXPIRED
])
