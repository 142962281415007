export class ImmutableMap {
  static set<K = any, V = any>(map: Map<K, V>, key: K, value: V): Map<K, V> {
    const newMap = new Map(map)
    newMap.set(key, value)
    return newMap
  }

  static unshift<K = any, V = any>(
    map: Map<K, V>,
    keyOrOtherMap: K | Map<K, V>,
    value?: V
  ): Map<K, V> {
    if (keyOrOtherMap instanceof Map) {
      return new Map([...keyOrOtherMap.entries(), ...map.entries()])
    }
    return new Map([[keyOrOtherMap, value!], ...map.entries()])
  }

  static delete<K = any, V = any>(map: Map<K, V>, key: K): Map<K, V> {
    const newMap = new Map(map)
    newMap.delete(key)
    return newMap
  }
}
