import { Search } from '~/router/routes/car/classifieds/search/components'

export const deletedClassifiedRoutes = [
  {
    name: '__classifieds_search_deleted_classifieds',
    path: '/classifieds/deleted/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
