import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dealers',
    path: '/dealers',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/index.vue' /* webpackChunkName: "pages-dealers-index.vue" */
        )
      )
  }
]
