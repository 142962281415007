import { ClassifiedList } from '~/models/classified-list/types'
import { ParkingTab } from '~/models/parking'
import ClassifiedListsService from '~/services/ClassifiedListsService'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { ADD_SUBSCRIBABLE_SEARCH_IDS } from '~/store/modules/shared/parking/searchSubscriptions/mutation-types'
import { SEARCH_SUBSCRIPTION_NS } from '~/store/modules/shared/parking/searchSubscriptions/state'
import { ParkingState } from '~/store/modules/shared/parking/state'
import { ActionTreeWithRootState } from '~/store/types'

import {
  SET_GROUPS,
  SET_CLASSIFIEDS_LIST,
  SET_SEARCHES,
  SET_ERROR,
  REMOVE_SEARCH,
  ADD_TO_SEARCHES,
  REMOVE_CLASSIFIED,
  SET_LOADING,
  SET_RECENT,
  SET_ACTIVE_TAB,
  SET_ACTIVE_RECENT,
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  CLEAR_COMPARE,
  CLEAR_NOTIFICATION_FOR_INDEX,
  CLEAR_NOTIFICATION_FOR_CLASSIFIED,
  SET_STYLE,
  SET_CLASSIFIED_ORDER,
  SET_CLASSIFIEDS_WITH_NOTIFICATIONS,
  SHOW_PARKING,
  HIDE_PARKING,
  EDIT_CLASSIFIED_LIST,
  FETCH_ALL_CLASSIFIED_LISTS,
  FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID,
  REMOVE_CLASSIFIED_LIST,
  CREATE_CLASSIFIED_LIST,
  COPY_CLASSIFIED_LIST,
  SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING,
  SET_CLASSIFIED_LIST_LOADING,
  DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST,
  CLEAR_PARKING_NOTIFICATIONS,
  REMOVE_LIST_FROM_FAVORITES,
  SET_SWIPE_DIRECTION,
  SET_NOTES_FOR_CLASSIFIED,
  ADD_FAVORITE_CLASSIFIED
} from './mutation-types'
import ClassifiedBucketService from '~/services/classified/buckets/ClassifiedBucketService'
import { ClassifiedBucketType } from '~/models/classified/types'
import { SearchesBucketType } from '~/models/search-bucket/types'
import { ClassifiedsFavoritesService } from '~/services/ClassifiedsFavoritesService'
import { ClassifiedNote } from '~/models/classified/note'

const tabsMap = ['classifieds', 'searches', 'recent', 'lists']
export default {
  async fetchClassifieds(
    { commit },
    {
      silent = false,
      sort,
      category
    }: { silent: boolean; sort: string; category: number }
  ) {
    try {
      if (!silent) {
        commit(SET_LOADING, true)
      }

      const groups = await this.$dep(
        ClassifiedsFavoritesService
      ).getFavoriteClassifieds(category, sort)
      commit(SET_GROUPS, groups)
      if (!silent) {
        commit(SET_LOADING, false)
      }
    } catch (e) {
      commit(SET_ERROR, true)
      if (!silent) {
        commit(SET_LOADING, false)
      }
      this.$logger.captureError(e)
    }
  },
  async fetch_searches({ commit }) {
    try {
      commit(SET_LOADING, true)

      const searches = await this.$dep(SearchBucketService).getBucketSearches(
        SearchesBucketType.FAVORITE
      )

      commit(SET_SEARCHES, searches)
      commit(
        `${SEARCH_SUBSCRIPTION_NS}/${ADD_SUBSCRIBABLE_SEARCH_IDS}`,
        this.$dep(SearchBucketService).filterSubscribableSearchIds(searches),
        { root: true }
      )
      commit(SET_LOADING, false)
    } catch (e) {
      commit(SET_ERROR, true)
      commit(SET_LOADING, false)
      this.$logger.captureError(e)
    }
  },
  async fetch_recent({ commit }) {
    try {
      commit(SET_LOADING, true)

      const [classifieds, searches] = await Promise.all([
        this.$dep(ClassifiedBucketService).getBucketClassifieds(
          ClassifiedBucketType.RECENT
        ),
        this.$dep(SearchBucketService).getBucketSearches(
          SearchesBucketType.RECENT
        )
      ])

      commit(SET_RECENT, {
        classifieds,
        searches
      })
    } catch (e) {
      commit(SET_ERROR, true)
      this.$logger.captureError(e)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async fetch_classifieds_list({ commit }, silent: boolean = false) {
    try {
      if (!silent) {
        commit(SET_LOADING, true)
      }

      const rows = await this.$dep(
        ClassifiedBucketService
      ).getBucketClassifieds(ClassifiedBucketType.FAVORITE)

      const orderArray: number[] = []
      rows.forEach(row => orderArray.push(row.id))

      const withNotifications: number[] = []
      rows.forEach(row => {
        if (row.notifications.length) {
          withNotifications.push(row.id)
        }
        return null
      })

      if (withNotifications.length) {
        commit(SET_CLASSIFIEDS_WITH_NOTIFICATIONS, withNotifications)

        if (process.client) {
          window.parent.postMessage(
            {
              event_id: 'clear_parking_notification'
            },
            '*'
          )
        }
      }

      commit(SET_CLASSIFIED_ORDER, orderArray)
      commit(SET_CLASSIFIEDS_LIST, rows)
      if (!silent) {
        commit(SET_LOADING, false)
      }
    } catch (e) {
      commit(SET_ERROR, true)
      if (!silent) {
        commit(SET_LOADING, false)
      }
      this.$logger.captureError(e)
    }
  },
  async removeFavoriteClassified(
    { commit },
    { id, category, listId }: { id: number; category: number; listId?: string }
  ) {
    try {
      await this.$dep(
        ClassifiedsFavoritesService
      ).removeClassifiedFromFavorites(id)
      commit(REMOVE_CLASSIFIED, { id, category, listId })
    } catch (e) {
      commit(SET_ERROR, true)
      throw e
    }
  },
  async addFavoriteClassified(
    { commit },
    { listId, id }: { listId: number; id: number }
  ) {
    try {
      await this.$dep(ClassifiedsFavoritesService).addClassifiedToFavorites(id)
      commit(ADD_FAVORITE_CLASSIFIED, { listId, id })
    } catch (e) {
      commit(SET_ERROR, true)
      throw e
    }
  },
  async remove_favorite_search({ commit }, id: number | string) {
    try {
      await this.$dep(SearchBucketService).removeSearchFromBucket(
        SearchesBucketType.FAVORITE,
        id
      )
      commit(REMOVE_SEARCH, id)
    } catch (e) {
      commit(SET_ERROR, true)
      this.$logger.captureError(e)
    }
  },
  async add_favorite_search({ commit }, searchId) {
    try {
      commit(SET_LOADING, true)

      const { status } = await this.$dep(
        SearchBucketService
      ).addSearchToFavorites(searchId)

      if (status === 200) {
        commit(ADD_TO_SEARCHES, searchId)
      }

      commit(SET_LOADING, false)
    } catch (e) {
      commit(SET_ERROR, true)
      commit(SET_LOADING, false)
      this.$logger.captureError(e)
    }
  },
  async clear_all_notifications({ state, commit, dispatch }, index) {
    try {
      const classifieds = state.groups?.classifieds?.rows || []
      const ids =
        classifieds.length > 0
          ? classifieds.reduce(
              (acc, classified) =>
                classified?.notifications?.length
                  ? [...acc, classified.id]
                  : acc,
              [] as number[]
            )
          : []

      if (ids.length === 0) {
        return
      }

      await this.$dep(
        ClassifiedsFavoritesService
      ).clearNotificationForClassifieds(ids, true)

      dispatch('clearNotificationsForIndex', index)
    } catch (e) {
      commit(SET_ERROR, true)
      this.$logger.captureError(e)
    }
  },
  clearNotificationsForIndex({ state, commit, dispatch }, index) {
    commit(CLEAR_NOTIFICATION_FOR_INDEX, index)
    if (state.groups?.filters?.categories) {
      const noNotifications = !state.groups.filters.categories.some(
        c => c.notificationsCount && c.notificationsCount > 0
      )

      if (noNotifications) {
        dispatch('clear_parking_notifications')
      }
    }
  },
  async clear_notifications_for_classified({ state, commit, dispatch }, id) {
    try {
      await this.$dep(
        ClassifiedsFavoritesService
      ).clearNotificationForClassifieds([id], true)
      commit(CLEAR_NOTIFICATION_FOR_CLASSIFIED, id)
      // if we cleared all notifications
      if (state.notificationClassifieds.length === 0) {
        dispatch('clear_parking_notifications')
      }
    } catch (e) {
      commit(SET_ERROR, true)
      this.$logger.captureError(e)
    }
  },
  clear_parking_notifications({ commit }) {
    commit(CLEAR_PARKING_NOTIFICATIONS)

    if (typeof window === 'undefined') {
      return
    }

    if (window.parent && window.parent !== window) {
      // remove from iframe parent header
      window.parent.postMessage(
        {
          event_id: 'clear_parking_notification'
        },
        '*'
      )
    } else {
      // remove from car2 header
      const el = document.querySelector(
        '.for-parking.ignore-for-mobile-menu .has-notifications'
      )
      if (el) {
        // @ts-ignore
        el.parentNode.removeChild(el)
      }
    }
  },
  change_active_tab({ state, commit }, tab) {
    const toIndex = tabsMap.findIndex(t => t.startsWith(tab))
    const fromIndex = tabsMap.findIndex(t => t.startsWith(state.activeTab))

    const swipeDirection = fromIndex > toIndex ? 'swipe-right' : 'swipe-left'
    commit(SET_SWIPE_DIRECTION, swipeDirection)

    commit(SET_ACTIVE_TAB, tab)
  },
  change_active_recent({ commit }, recent) {
    commit(SET_ACTIVE_RECENT, recent)
  },
  add_to_compare({ commit }, id) {
    commit(ADD_TO_COMPARE, id)
  },
  remove_from_compare({ commit }, id) {
    commit(REMOVE_FROM_COMPARE, id)
  },
  clear_compare({ commit }) {
    commit(CLEAR_COMPARE)
  },
  set_style({ commit }, style) {
    commit(SET_STYLE, style)
  },
  async set_classified_order({ commit }, order) {
    commit(SET_CLASSIFIED_ORDER, order)
    await this.$dep(ClassifiedBucketService).setClassifiedOrder(order)
  },

  async fetchAllLists({ commit, state }) {
    commit('SET_LOADING', true)
    if (!state.classifiedLists?.length) {
      commit(SET_CLASSIFIED_LIST_LOADING, true)
    }
    let lists: ClassifiedList[] = []
    try {
      lists = await this.$dep(ClassifiedListsService).fetchAllLists()
    } catch (e) {
      commit(SET_CLASSIFIED_LIST_LOADING, false)
      commit('SET_LOADING', false)
      this.$logger.captureError(e)
    }
    let listClassifieds
    const firstListItem = 0
    // fetch the listClassifieds from the first list
    if (lists.length) {
      try {
        listClassifieds = await this.$dep(
          ClassifiedListsService
        ).fetchListClassifiedsById(lists[firstListItem].publicId)
        lists[firstListItem].classifieds = listClassifieds.classifieds
        commit(FETCH_ALL_CLASSIFIED_LISTS, lists)
      } catch (e) {
        this.$logger.captureError(e)
      } finally {
        commit(SET_CLASSIFIED_LIST_LOADING, false)
        commit('SET_LOADING', false)
      }
    } else {
      commit(SET_CLASSIFIED_LIST_LOADING, false)
      commit('SET_LOADING', false)
    }
  },

  async fetchListClassifiedsById({ commit, state }, { listId, page = 1 }) {
    if (!state.loading) {
      commit(SET_CLASSIFIED_LIST_LOADING, true)
    }

    try {
      const res = await this.$dep(
        ClassifiedListsService
      ).fetchListClassifiedsById(listId, page)
      commit(FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID, res)
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_LOADING, false)
    }
  },
  async removeClassifiedFromList(
    { dispatch, commit, state },
    { listId, classifiedId }
  ): Promise<{ status: number; message: string } | undefined> {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      const res: {
        status: number
        message: string
      } = await this.$dep(ClassifiedListsService).removeClassifiedFromList(
        listId,
        classifiedId
      )

      const page = Math.ceil(
        (state.classifiedsPagination.total - 1) /
          state.classifiedsPagination.perPage
      )

      dispatch('fetchListClassifiedsById', { listId, page })
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('classified deleted from list successfully')
      )
      commit(DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST, listId)
      return res
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },

  async editList({ commit }, { listId, listName }) {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      const list = await this.$dep(ClassifiedListsService).editList(
        listId,
        listName
      )
      commit(EDIT_CLASSIFIED_LIST, list)
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('list updated successfully')
      )
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },

  async createList({ commit }, listName) {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      const list = await this.$dep(ClassifiedListsService).createList(listName)
      commit(CREATE_CLASSIFIED_LIST, list)
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('the list was created successfully')
      )
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },

  async deleteList({ dispatch, commit, state }, list: ClassifiedList) {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      await this.$dep(ClassifiedListsService).deleteList(list.publicId)
      const filteredList = state.classifiedLists.filter(
        l => l.publicId !== list.publicId
      )

      await dispatch('fetchClassifiedsForTheFirstListItem', {
        filteredList,
        list
      })
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('list deleted successfully')
      )
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },
  async removeListFromFavorites(
    { dispatch, commit, state },
    list: ClassifiedList
  ) {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      await this.$dep(ClassifiedListsService).removeListFromFavorites(
        list.publicId
      )
      const filteredList = state.classifiedLists.filter(
        l => l.publicId !== list.publicId
      )

      if (state?.selectedList?.publicId === list.publicId) {
        await dispatch('fetchClassifiedsForTheFirstListItem', {
          filteredList,
          list
        })
      } else {
        commit(REMOVE_LIST_FROM_FAVORITES, list.publicId)
        commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
      }

      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('list removed successfully')
      )
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },
  async fetchClassifiedsForTheFirstListItem(
    { commit },
    { filteredList, list }
  ) {
    if (filteredList.length > 0) {
      const firstListItem: ClassifiedList = filteredList[0]
      try {
        const res = await this.$dep(
          ClassifiedListsService
        ).fetchListClassifiedsById(firstListItem.publicId, 1)
        commit(REMOVE_CLASSIFIED_LIST, {
          listIdToRemove: list.publicId,
          classifieds: res.classifieds,
          firstListItem
        })
      } catch (error) {
        this.$logger.captureError(error)
      }
    } else {
      commit(REMOVE_CLASSIFIED_LIST, { listIdToRemove: list.publicId })
    }
  },
  async copyList({ commit }, { listId, listName }) {
    commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, true)
    try {
      const list = await this.$dep(ClassifiedListsService).copyList(
        listId,
        listName
      )
      commit(COPY_CLASSIFIED_LIST, list)
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('the list was copied successfully')
      )
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING, false)
    }
  },
  show_parking({ commit }, tab?: ParkingTab) {
    commit(SHOW_PARKING)
    tab && this.$cookies.set('parking_tab', tab)
    this.$cookies.set('parking_open', 1)

    // @ts-ignore
    window.PARKING_OPEN = true
  },
  hide_parking({ commit, dispatch }) {
    commit(HIDE_PARKING)

    const event = document.createEvent('Event')
    event.initEvent('park-modal-closed', true, true)
    document.dispatchEvent(event)
    dispatch(`${PAGE_NS}/removeBodyClass`, 'modal-open', { root: true })
    document.body.style.marginRight = ''
    this.$cookies.delete('parking_open')
  },
  setNotesForClassified(
    { commit },
    {
      note,
      classifiedId
    }: {
      note: ClassifiedNote | null
      classifiedId: number
    }
  ) {
    commit(SET_NOTES_FOR_CLASSIFIED, { note, clsfdId: classifiedId })
  }
} as ActionTreeWithRootState<ParkingState>
