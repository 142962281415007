import { interopDefault } from '~/router/utils'

export const adminBlacklistRoutes = [
  {
    name: '__admin_blacklist_manage',
    path: '/admin/blacklist/manage/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/blacklist/manage.vue' /* webpackChunkName: "pages-admin-blacklist-remove" */
        )
      )
  }
]
