interface ByUser {
  id: number | string
  username: string
}
interface Reason {
  id: number | string
  title: string
  supportCategory: boolean
}

export enum BlacklistReason {
  SUSPICIOUS = 29,
  FRAUDSTER = 18
}

export interface Blacklist {
  byUser: ByUser
  created: string
  groupId: number
  notes: string
  reason: Reason
  site: string
  reasonId: number
  byUserId: number
}

export interface BlacklistInfo {
  blacklist: Blacklist
  blacklistReasons: Reason[]
  cannotBeBlacklisted: boolean
  idents: string[]
  blacklistCategories: number[]
}

export interface BlacklistActionInfo {
  blacklist: Blacklist
  message: string
}

export interface BlacklistInformUsersInfo {
  notifiedNumber: number
}
