import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__classified_restore_with_code',
    path: '/classifieds/:classifiedId/restore-with-code/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/restore-with-code.vue' /* webpackChunkName: "pages-shared-classifieds-restore-with-code" */
        )
      )
  },
  {
    name: '__classified_touch_with_code',
    path: '/classifieds/:classifiedId/touch-with-code/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/touch-with-code.vue' /* webpackChunkName: "pages-shared-classifieds-touch-with-code" */
        )
      )
  }
]
