import { useContext } from '@nuxtjs/composition-api'
import InjectionToken from 'tsyringe/dist/typings/providers/injection-token'
import { MultiArgDependencyResolverFunction } from '~/models/dependency-container/resolver'

export function useDep<T>(token: InjectionToken<T>): T {
  const { $dep } = useContext()
  return $dep(token)
}

// @ts-ignore
export const useDeps: MultiArgDependencyResolverFunction = (
  ...tokens: InjectionToken[]
) => {
  const { $deps } = useContext()
  // @ts-ignore
  return $deps(...tokens)
}
