import { interopDefault } from '~/router/utils'

export const adminAdvertisingRoutes = [
  {
    name: '__admin_advertising_manage',
    path: '/admin/advertising/manage/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/advertising/manage.vue' /* webpackChunkName: "pages-admin-advertising-manage" */
        )
      )
  }
]
