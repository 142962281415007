import { interopDefault } from '../../utils'

const QnaSeach = () =>
  interopDefault(
    import('~/pages/qna/index.vue' /* webpackChunkName: "pages-qna.vue" */)
  )
const QnaFullQuestion = () =>
  interopDefault(
    import(
      '~/pages/qna/full-question.vue' /* webpackChunkName: "pages-qna-full-question.vue" */
    )
  )
export default [
  {
    name: '__qna_view',
    path: '/questions',
    component: QnaSeach,
    meta: { showOneTap: true }
  },
  {
    name: '__qna_view_full_question',
    path: '/questions/:question',
    component: QnaFullQuestion,
    meta: { showOneTap: true }
  }
]
