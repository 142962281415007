import { QuickSearchState } from './state'
import { ActionTreeWithRootState } from '~/store/types'
import QuickSearchService from '~/services/quick-search/QuickSearchService'
import {
  SET_BREADCRUMBS,
  SET_CATEGORY_MAP,
  SET_FACETS,
  SET_LOADING,
  SET_PARAM,
  SET_QUICK_SEARCHES,
  SET_STATS,
  SET_TARGETS
} from '~/store/modules/shared/quickSearch/mutation-types'
import ClassifiedCategoryService from '~/services/classified/category/ClassifiedCategoryService'
import SeoUrlService from '~/services/seo/url/SeoUrlService'

export default {
  async loadData({ state, commit }) {
    commit(SET_LOADING, true)
    const quickSearchService = this.$dep(QuickSearchService)
    const {
      facets,
      targets,
      stats,
      quickSearches
    } = await quickSearchService.search(state.config.baseUrl, state.params)
    commit(SET_FACETS, facets)
    commit(SET_TARGETS, targets)
    commit(SET_STATS, stats)
    commit(SET_QUICK_SEARCHES, quickSearches)
    commit(SET_LOADING, false)
  },
  async loadCategoriesData({ commit, getters }) {
    const [classifiedCategoryService, seoUrlService] = this.$deps(
      ClassifiedCategoryService,
      SeoUrlService
    )

    const {
      data: categories,
      breadcrumbs
    } = await classifiedCategoryService.getCategoryMap(
      getters.rootCategoryId.toString()
    )
    // Set seo url for categories that have no url.
    const categoriesWithoutUrls = [...categories.values()].filter(
      category => !category.pageUrl
    )
    const searchUrls = await seoUrlService.generateUrls(
      categoriesWithoutUrls.map(c => ({ args: { category: c.id } }))
    )
    categoriesWithoutUrls.forEach((c, i) => {
      c.pageUrl = searchUrls[i].path
    })
    commit(SET_CATEGORY_MAP, categories)
    commit(SET_BREADCRUMBS, breadcrumbs)
  },
  changeParamAndReloadData({ dispatch, commit }, { name, value }) {
    commit(SET_PARAM, { name, value })
    return dispatch('loadData')
  },
  changeParamsAndReloadData(
    { dispatch, commit },
    params: Array<{ name: string; value: any }>
  ) {
    params.forEach(p => commit(SET_PARAM, p))

    return dispatch('loadData')
  }
} as ActionTreeWithRootState<QuickSearchState>
