export function getDealerSiteRouteName(suffix?: string): string {
  const baseDealerSiteRouteName = '__dealers_site'

  return suffix
    ? baseDealerSiteRouteName + '_' + suffix
    : baseDealerSiteRouteName
}

export function getSubDomainRouteName(suffix?: string): string {
  const baseSubdomainRouteName = '__subdomain'

  return suffix ? baseSubdomainRouteName + '_' + suffix : baseSubdomainRouteName
}

export function getRouteMetaItem(
  route: any,
  itemName: any
): string | number | [] | Record<string, any> | null {
  const meta = route?.meta
  if (!meta || !itemName) {
    return null
  }

  if (Array.isArray(meta) && meta.length > 0) {
    const metaItem = meta.find(m => m[itemName])
    return metaItem?.[itemName] || null
  }

  return meta[itemName] || null
}

export function addRouteMeta(route: any, metaItem: Record<string, any>) {
  if (route.meta) {
    return { ...route, meta: { ...route.meta, ...metaItem } }
  }

  return { ...route, meta: metaItem }
}

export function smoothenRoutesScroll(routes: any[]): any[] {
  return routes.map(r => ({
    ...r,
    meta: {
      ...(r.meta || {}),
      ...{
        scrollTopSmooth: true
      }
    }
  }))
}
