import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__delete_rating_form',
    path: '/classifieds/:classifiedId/delete-rating-form/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/delete-rating-form.vue' /* webpackChunkName: "pages-delete-rating-form.vue" */
        )
      )
  }
]
