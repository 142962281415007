import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import {
  NotificationGroup,
  NotificationTopic,
  TopicMap
} from '~/models/notification/types'
import { invalidBodyError } from '~/services/errors'
import { AxiosInstance } from 'axios'

@containerScoped()
export default class NotificationTopicService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async subscribe(topic: string): Promise<void> {
    await this.http.put('/api/user/notifications/push/subscriptions/', {
      topic
    })
  }

  async unsubscribe(topic: string): Promise<void> {
    await this.http.delete('/api/user/notifications/push/subscriptions/', {
      data: { topic }
    })
  }

  async setTopics(topics: string[]): Promise<TopicMap> {
    const response = await this.http.post(
      '/api/user/notifications/push/subscriptions/',
      {
        push_notification_topics: topics
      }
    )

    const { data: body } = response
    if (!Array.isArray(body?.data?.values?.topics)) {
      throw invalidBodyError(body)
    }
    return this.createTopicMap(body.data.values.topics)
  }

  /**
   * @returns Default subscriptions.
   */
  setSubscriptionsToDefault(
    groups: Array<NotificationGroup>
  ): Promise<TopicMap> {
    const allTopics = Object.values(this.getNotificationTopicType(groups))
    return this.setTopics(allTopics)
  }

  async getNotificationsTopicsAndGroups(): Promise<{
    topics: Map<string, NotificationTopic>
    groups: Array<NotificationGroup>
  }> {
    const response = await this.http.get(
      '/api/user/notifications/push/subscriptions/'
    )
    const { data: body } = response

    if (!Array.isArray(body?.data?.topics)) {
      throw invalidBodyError(body)
    }
    if (!Array.isArray(body?.data?.groups)) {
      throw invalidBodyError(body)
    }

    return {
      topics: this.createTopicMap(body.data.topics),
      groups: body?.data?.groups
    }
  }

  private createTopicMap(
    topics: NotificationTopic[]
  ): Map<string, NotificationTopic> {
    return new Map(
      topics.map((topic: NotificationTopic) => [topic.value, topic])
    )
  }

  private getNotificationTopicType(groups: Array<NotificationGroup>) {
    const topicTypes = {} as { [key: string]: string }
    function getGroupTypes(groups: Array<NotificationGroup>) {
      groups.forEach(g => {
        if (g.children) {
          getGroupTypes(g.children)
        } else if (g.value) {
          topicTypes[g.value.toUpperCase()] = g.value
        }
      })
    }
    getGroupTypes(groups)
    return topicTypes
  }
}
