import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'

import { containerScoped } from '~/decorators/dependency-container'
import {
  PopularityType,
  PopularityMap,
  Popularity,
  TrendingTerm,
  FilterMap,
  PopularityResult
} from '~/models/popularity/types'
import { invalidBodyError } from '../errors'
import { toURIBase64 } from '~/utils/object'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { formatPopularityVisualization } from '~/services/popularity/formatters'
import { ActionResult } from '~/models/shared/result'

@containerScoped()
export default class PopularityService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  getPopularityData(
    url: string,
    params: Record<string, any> = {}
  ): Promise<PopularityResult> {
    const q: string = toURIBase64(params)
    return this.requestBuilder
      .request('get', url)
      .params({ q })
      .map(body => {
        return formatPopularityVisualization(body.data)
      })
      .send()
  }

  async getPopularityMap(
    of: PopularityType,
    filters: FilterMap,
    period?: number | null,
    limit?: number
  ): Promise<PopularityMap> {
    filters.request_namespace = 'mobile'
    const { data: body } = await this.http.post('/api/popularity/', {
      filters,
      popularity: of,
      popularity_count: limit,
      period
    })

    if (
      !body ||
      !body.data ||
      !body.data.popularity ||
      !Array.isArray(body.data.popularity.popular)
    ) {
      throw invalidBodyError(body)
    }

    return {
      map: new Map(
        body.data.popularity.popular.map((p: Popularity) => [p.term, p])
      ),
      total: body.data.popularity.total
    }
  }

  async getTrendingTermMap(
    of: PopularityType,
    filters: FilterMap,
    limit?: number,
    dayOffsetRange?: [number, number]
  ): Promise<Map<string, TrendingTerm>> {
    const { data: body } = await this.http.post('/api/popularity/trending/', {
      filters,
      term: of,
      foreground_day_offset: dayOffsetRange && dayOffsetRange[1],
      background_day_offset: dayOffsetRange && dayOffsetRange[0],
      limit
    })

    return new Map(
      body.data.trending_terms.map((t: any) => [
        t.term,
        { ...t, humanName: t.info && t.info.human_name }
      ])
    )
  }

  recordEvent(event: string, params?: any): Promise<ActionResult> {
    if (!event.startsWith('public_')) {
      throw new TypeError("Popularity event names must start with 'public_'")
    }

    return this.requestBuilder
      .request('post', '/api/popularity/record/')
      .data({
        event,
        ...params
      })
      .send()
  }
}
