import { FeedTypes } from '~/models/user/types'
import { GetterTreeWithRootState } from '~/store/types'
import { IndexState } from '~/store/modules/shared/index/state'

export default {
  recentFeed(state) {
    if (state.fetchableFeeds) {
      return state.fetchableFeeds.find(f => {
        return f.type === FeedTypes.RECENT
      })
    }
    return null
  },
  userFeeds(state) {
    if (state.fetchableFeeds) {
      return state.fetchableFeeds.filter(f => {
        return f.type !== FeedTypes.RECENT
      })
    }
    return null
  },
  quickSearches(state) {
    return state.quickSearches
  },
  classifiedsCount(state) {
    return state.count
  }
} as GetterTreeWithRootState<IndexState>
