import { GetterTreeWithRootState } from '~/store/types'
import { AppState } from '~/store/modules/shared/app/state'

export default {
  isCar(state) {
    return state.name === 'car'
  },
  isPlot(state) {
    return state.name === 'plot'
  },
  siteName(state) {
    return state.name
  },
  domain(state) {
    return `${state.name}.gr`
  }
} as GetterTreeWithRootState<AppState>
