export const MAKES_IDS_SEARCH_PARAM = 'makes_ids'
export const MODELS_IDS_SEARCH_PARAM = 'models_ids'
export const MAKE_IDS_SEARCH_PARAM = 'make_ids'
export const MODEL_IDS_SEARCH_PARAM = 'model_ids'
export const MAKES_SEARCH_PARAM = 'makes'
export const MODELS_SEARCH_PARAM = 'models'
export const MAKE_MODELS_REGISTRATION_SEARCH_PARAM = 'registration'
export const MAKE_MODELS_STRICT_SEARCH_PARAM = 'make-models-strict-search'
export const MANUFACTURER_SEARCH_PARAM = 'manufacturer'
export const PRODUCT_MODEL_SEARCH_PARAM = 'product_model'
export const MAKE_SEARCH_PARAM = 'make'
export const MODEL_SEARCH_PARAM = 'model'
export const VARIANT_SEARCH_PARAM = 'variant'
export const SERIES_SEARCH_PARAM = 'series'
