import actions from '~/store/modules/shared/admin/user/actions'
import mutations from '~/store/modules/shared/admin/user/mutations'
import getters from '~/store/modules/shared/admin/user/getters'
import state from '~/store/modules/shared/admin/user/state'
import { createModule } from '~/factories/store'

export default createModule({
  actions,
  mutations,
  getters,
  state
})
