export const FIELD_HALF_WIDTH_COLUMNS = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
  cols: 12
}
export const FIELD_FULL_WIDTH_COLUMNS = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  cols: 12
}
