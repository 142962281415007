import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { invalidBodyError } from '~/services/errors'
import {
  BackgroundJob,
  BackgroundJobResult,
  BackgroundJobsResult
} from '~/models/background-jobs/types'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class BackgroundJobsService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getAllBackgroundJobs(): Promise<BackgroundJob[]> {
    const response = await this.http.get('/api/background-jobs/')
    const body = response.data
    if (!body || !body.data || !body.data.jobs) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.jobs)
  }

  async getBackgroundJobsWithIDs(ids: number[]): Promise<BackgroundJobsResult> {
    const response = await this.http.get('/api/background-jobs/', {
      params: { id: ids }
    })
    const body = response.data
    if (!body || !body.data || !body.data.jobs) {
      throw invalidBodyError(body)
    }

    if (body.data.credits) {
      const credits = {
        free: body.data.credits.free_credits,
        paid: body.data.credits.paid_credits
      }

      return { jobs: toCamelCase(body.data.jobs), credits }
    }
    return { jobs: toCamelCase(body.data.jobs) }
  }

  async getBackgroundJobsByStatus(status: string): Promise<BackgroundJob[]> {
    const response = await this.http.get(`/api/background-jobs/${status}/`)
    const body = response.data
    if (!body || !body.data || !body.data.jobs) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.jobs)
  }

  async getBackgroundJobsByName(
    name: string | string[]
  ): Promise<BackgroundJob[]> {
    const response = await this.http.get('/api/background-jobs/', {
      params: { name }
    })
    const body = response.data
    if (!body || !body.data || !body.data.jobs) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.jobs)
  }

  async getBackgroundJob(jobId: number): Promise<BackgroundJobResult> {
    const response = await this.http.get(`/api/background-jobs/${jobId}/`)
    const body = response.data
    if (!body || !body.data || !body.data.job) {
      throw invalidBodyError(body)
    }

    if (body.data.credits) {
      const credits = {
        free: body.data.credits.free_credits,
        paid: body.data.credits.paid_credits
      }

      return { job: toCamelCase(body.data.job), credits }
    }
    return { job: toCamelCase(body.data.job) }
  }

  async terminateBackgroundJob(jobId: number): Promise<AxiosResponse> {
    const response = await this.http.delete(`/api/background-jobs/${jobId}/`)
    return response
  }
}
