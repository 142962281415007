import { GetterTreeWithRootState } from '~/store/types'
import { AdminPage, AdminPageGroup } from '~/models/user/types'
import { AdminUserState } from '~/store/modules/shared/admin/user/state'

export default {
  getAdminPagesGrouped(state): AdminPageGroup[] | null {
    if (state.adminExtras?.pages) {
      return state.adminExtras.pages
    }
    return null
  },
  getAdminPagesUngrouped(state): AdminPage[] {
    if (state.adminExtras?.pages) {
      const pageArray: AdminPage[] = []
      state.adminExtras.pages.forEach(cat => {
        cat.pages.forEach(page => {
          pageArray.push(page)
        })
      })
      return pageArray
    }
    return []
  },
  isAdminLoggedInAsUser(state): boolean {
    return !!state.adminExtras?.suas
  }
} as GetterTreeWithRootState<AdminUserState>
