import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dealers_list_generic',
    path: '/dealers/list',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/list.vue' /* webpackChunkName: "pages-dealers-list.vue" */
        )
      )
  }
]
