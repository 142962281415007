import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__quick_search_plot',
    path: '/plot/search/:categoryId?/',
    redirect: {
      path: '/quick-search/plot'
    }
  },
  {
    name: '__quick_search_rentals',
    path: '/rentals/search/:categoryId?/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/quick-search/rentals.vue' /* webpackChunkName: "pages-quicksearch-rentals.vue" */
        )
      )
  }
]
