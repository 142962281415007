import { GetterTreeWithRootState } from '~/store/types'
import { ClassifiedsAnalyticsState } from '~/store/modules/shared/classifieds-analytics/state'

export default {
  getSectionFiltersByType(state) {
    return (type: string) =>
      state.sectionFilters.filter(f => f.sectionType === type)
  },
  getCommonSubFilters(state): Record<string, any>[] {
    const sf = []

    if (state.outletsData) {
      const shopSubFilter = state.subFilters[state.outletsData.urlArgName]
      if (shopSubFilter) {
        sf.push(shopSubFilter)
      }
    }

    return sf
  },
  getDateRangePresetByName(state) {
    return (name: string) => state.dateRangePresets.find(p => p.name === name)
  }
} as GetterTreeWithRootState<ClassifiedsAnalyticsState>
