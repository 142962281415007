import {
  AgentsFeed,
  CategoryFeed,
  Feed,
  FetchableFeed,
  ModelsFeed,
  YoutubeFeed,
  CustomLinkFeed
} from '~/models/user/types'
import { toCamelCase } from '~/utils/object'
import { AnalyticsGlimpse } from '~/models/analytics/classifieds'

export function formatFetchableFeed(f: any): FetchableFeed {
  return {
    fetchUrl: f.fetch_url,
    type: f.type,
    refreshInterval: f.refresh_interval,
    feed: f.feed ? formatFeed(f.feed) : undefined,
    id: f.id || undefined,
    searchType: f.search_type || undefined
  }
}

export function formatLandingStatistics(s: any): AnalyticsGlimpse {
  return {
    classifiedViews: {
      fetchUrl: s.classified_views && s.classified_views.fetch_url
    }
  }
}

export function formatFeed(f: any): Feed {
  const feed = {
    classifieds: f.classifieds,
    label: f.label || f.title,
    type: f.type,
    seoUrl: f.seo_url,
    count: f.count,
    categoryIds: f.category_ids || [],
    hasMore: f.has_more
  }
  if (typeof feed.label === 'string') {
    feed.label = { short_title: feed.label }
  }

  if (!Array.isArray(f.category_ids)) {
    feed.categoryIds = []
  }

  return feed
}
export function formatCategoryFeed(f: any): CategoryFeed {
  return {
    categories: toCamelCase(f.categories),
    label: f.title || f.label,
    type: f.type,
    seoUrl: f.seo_url,
    count: f.count,
    icon: f.icon
  }
}

export function formatAgentsFeed(f: any): AgentsFeed {
  return {
    agents: toCamelCase(f.agents),
    label: f.title || f.label,
    type: f.type,
    seoUrl: f.seo_url
  }
}

export function formatModelsFeed(f: any): ModelsFeed {
  return {
    models: toCamelCase(f.models),
    label: f.title || f.label,
    type: f.type,
    seoUrl: f.seo_url,
    categoryIds: f.category_ids || []
  }
}

export function formatYoutubeFeed(f: any): YoutubeFeed {
  return {
    videos: toCamelCase(f.videos),
    label: f.title || f.label,
    type: f.type,
    seoUrl: f.seo_url
  }
}
export function formatCustomLinkFeed(f: any): CustomLinkFeed {
  return {
    linkData: toCamelCase(f.links_data),
    label: f.title || f.label,
    type: f.type,
    seoUrl: f.seo_url,
    icon: f.icon
  }
}
