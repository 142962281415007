export const USER_AGENT_NS = 'userAgent'

export interface UserAgentState {
  isPc?: boolean | null
  isBot?: boolean | null
  isTablet?: boolean | null
}

function getDefaultState(): UserAgentState {
  return {}
}

export default getDefaultState
