export function useCol() {
  const getOffset = (val: string) => {
    switch (val) {
      case '1':
        return 'tw-col-start-1'
      case '2':
        return 'tw-col-start-2'
      case '3':
        return 'tw-col-start-3'
      case '4':
        return 'tw-col-start-4'
      case '5':
        return 'tw-col-start-5'
      case '6':
        return 'tw-col-start-6'
      case '7':
        return 'tw-col-start-7'
      case '8':
        return 'tw-col-start-8'
      case '9':
        return 'tw-col-start-9'
      case '10':
        return 'tw-col-start-10'
      case '11':
        return 'tw-col-start-11'
      case '12':
        return 'tw-col-start-12'
      default:
        return null
    }
  }

  const getSmOffset = (val: string) => {
    switch (val) {
      case '1':
        return 'sm:tw-col-start-1'
      case '2':
        return 'sm:tw-col-start-2'
      case '3':
        return 'sm:tw-col-start-3'
      case '4':
        return 'sm:tw-col-start-4'
      case '5':
        return 'sm:tw-col-start-5'
      case '6':
        return 'sm:tw-col-start-6'
      case '7':
        return 'sm:tw-col-start-7'
      case '8':
        return 'sm:tw-col-start-8'
      case '9':
        return 'sm:tw-col-start-9'
      case '10':
        return 'sm:tw-col-start-10'
      case '11':
        return 'sm:tw-col-start-11'
      case '12':
        return 'sm:tw-col-start-12'
      default:
        return null
    }
  }

  const getMdOffset = (val: string) => {
    switch (val) {
      case '1':
        return 'md:tw-col-start-1'
      case '2':
        return 'md:tw-col-start-2'
      case '3':
        return 'md:tw-col-start-3'
      case '4':
        return 'md:tw-col-start-4'
      case '5':
        return 'md:tw-col-start-5'
      case '6':
        return 'md:tw-col-start-6'
      case '7':
        return 'md:tw-col-start-7'
      case '8':
        return 'md:tw-col-start-8'
      case '9':
        return 'md:tw-col-start-9'
      case '10':
        return 'md:tw-col-start-10'
      case '11':
        return 'md:tw-col-start-11'
      case '12':
        return 'md:tw-col-start-12'
      default:
        return null
    }
  }
  const getLgOffset = (val: string) => {
    switch (val) {
      case '1':
        return 'lg:tw-col-start-1'
      case '2':
        return 'lg:tw-col-start-2'
      case '3':
        return 'lg:tw-col-start-3'
      case '4':
        return 'lg:tw-col-start-4'
      case '5':
        return 'lg:tw-col-start-5'
      case '6':
        return 'lg:tw-col-start-6'
      case '7':
        return 'lg:tw-col-start-7'
      case '8':
        return 'lg:tw-col-start-8'
      case '9':
        return 'lg:tw-col-start-9'
      case '10':
        return 'lg:tw-col-start-10'
      case '11':
        return 'lg:tw-col-start-11'
      case '12':
        return 'lg:tw-col-start-12'
      default:
        return null
    }
  }

  const getXlOffset = (val: string) => {
    switch (val) {
      case '1':
        return 'xl:tw-col-start-1'
      case '2':
        return 'xl:tw-col-start-2'
      case '3':
        return 'xl:tw-col-start-3'
      case '4':
        return 'xl:tw-col-start-4'
      case '5':
        return 'xl:tw-col-start-5'
      case '6':
        return 'xl:tw-col-start-6'
      case '7':
        return 'xl:tw-col-start-7'
      case '8':
        return 'xl:tw-col-start-8'
      case '9':
        return 'xl:tw-col-start-9'
      case '10':
        return 'xl:tw-col-start-10'
      case '11':
        return 'xl:tw-col-start-11'
      case '12':
        return 'xl:tw-col-start-12'
      default:
        return null
    }
  }

  const getColSpanClass = (val: string) => {
    switch (val) {
      case '1':
        return 'tw-col-span-1'
      case '2':
        return 'tw-col-span-2'
      case '3':
        return 'tw-col-span-3'
      case '4':
        return 'tw-col-span-4'
      case '5':
        return 'tw-col-span-5'
      case '6':
        return 'tw-col-span-6'
      case '7':
        return 'tw-col-span-7'
      case '8':
        return 'tw-col-span-8'
      case '9':
        return 'tw-col-span-9'
      case '10':
        return 'tw-col-span-10'
      case '11':
        return 'tw-col-span-11'
      case '12':
        return 'tw-col-span-12'
      default:
        return null
    }
  }
  const getSmColSpanClass = (val: string) => {
    switch (val) {
      case '1':
        return 'sm:tw-col-span-1'
      case '2':
        return 'sm:tw-col-span-2'
      case '3':
        return 'sm:tw-col-span-3'
      case '4':
        return 'sm:tw-col-span-4'
      case '5':
        return 'sm:tw-col-span-5'
      case '6':
        return 'sm:tw-col-span-6'
      case '7':
        return 'sm:tw-col-span-7'
      case '8':
        return 'sm:tw-col-span-8'
      case '9':
        return 'sm:tw-col-span-9'
      case '10':
        return 'sm:tw-col-span-10'
      case '11':
        return 'sm:tw-col-span-11'
      case '12':
        return 'sm:tw-col-span-12'
      default:
        return null
    }
  }
  const getMdColSpanClass = (val: string) => {
    switch (val) {
      case '1':
        return 'md:tw-col-span-1'
      case '2':
        return 'md:tw-col-span-2'
      case '3':
        return 'md:tw-col-span-3'
      case '4':
        return 'md:tw-col-span-4'
      case '5':
        return 'md:tw-col-span-5'
      case '6':
        return 'md:tw-col-span-6'
      case '7':
        return 'md:tw-col-span-7'
      case '8':
        return 'md:tw-col-span-8'
      case '9':
        return 'md:tw-col-span-9'
      case '10':
        return 'md:tw-col-span-10'
      case '11':
        return 'md:tw-col-span-11'
      case '12':
        return 'md:tw-col-span-12'
      default:
        return null
    }
  }
  const getLgColSpanClass = (val: string) => {
    switch (val) {
      case '1':
        return 'lg:tw-col-span-1'
      case '2':
        return 'lg:tw-col-span-2'
      case '3':
        return 'lg:tw-col-span-3'
      case '4':
        return 'lg:tw-col-span-4'
      case '5':
        return 'lg:tw-col-span-5'
      case '6':
        return 'lg:tw-col-span-6'
      case '7':
        return 'lg:tw-col-span-7'
      case '8':
        return 'lg:tw-col-span-8'
      case '9':
        return 'lg:tw-col-span-9'
      case '10':
        return 'lg:tw-col-span-10'
      case '11':
        return 'lg:tw-col-span-11'
      case '12':
        return 'lg:tw-col-span-12'
      default:
        return null
    }
  }
  const getXlColSpanClass = (val: string) => {
    switch (val) {
      case '1':
        return 'xl:tw-col-span-1'
      case '2':
        return 'xl:tw-col-span-2'
      case '3':
        return 'xl:tw-col-span-3'
      case '4':
        return 'xl:tw-col-span-4'
      case '5':
        return 'xl:tw-col-span-5'
      case '6':
        return 'xl:tw-col-span-6'
      case '7':
        return 'xl:tw-col-span-7'
      case '8':
        return 'xl:tw-col-span-8'
      case '9':
        return 'xl:tw-col-span-9'
      case '10':
        return 'xl:tw-col-span-10'
      case '11':
        return 'xl:tw-col-span-11'
      case '12':
        return 'xl:tw-col-span-12'
      default:
        return null
    }
  }
  return {
    getOffset,
    getXlColSpanClass,
    getLgColSpanClass,
    getSmColSpanClass,
    getColSpanClass,
    getSmOffset,
    getMdColSpanClass,
    getMdOffset,
    getXlOffset,
    getLgOffset
  }
}
