export enum KeyCode {
  DOWN = 40,
  UP = 38,
  ENTER = 13,
  ESC = 27,
  RIGHT = 39,
  LEFT = 37,
  SLASH = 191
}

export enum Key {
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  END = 'End',
  HOME = 'Home',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  A = 'a',
  A_CAPS = 'A',
  X = 'x',
  X_CAPS = 'X'
}
