import flow from 'lodash.flow'
import { Merge } from 'type-fest'
import { mutationAlias } from '~/factories/store/action'
import { noopUndoable, undoable } from '~/factories/undoable'
import {
  CategoryMappingPageSettingPayload,
  CategoryMappingFilterTextSettingPayload,
  CategoryMapping,
  MappingSettingPayload,
  TransitInternalCategoryCreationPayload,
  InternalCategoryIdentifier,
  NaReasonNodeId,
  NaReason
} from '~/models/dealer/integration/category/mapping'
import { Undoable } from '~/models/undoable'
import DealerCategoryMapService from '~/services/dealers/integration/DealerCategoryMapService'
import { DealerCategoryMapViewService } from '~/services/dealers/integration/DealerCategoryMapViewService'
import SnackbarService from '~/services/snackbar/SnackbarService'
import {
  SET_MAPPINGS,
  PREPEND_MAPPINGS,
  SET_MAPPING,
  SET_PAGE,
  DELETE_MAPPING,
  SET_MAPPING_FILTER_TEXT,
  SET_FILTERED_MAPPINGS,
  ADD_TRANSIT_INTERNAL_CATEGORY,
  DELETE_TRANSIT_INTERNAL_CATEGORY,
  SET_UNMAPPED_CATEGORY_SCAN_ERRORS,
  SET_ERROR_ALERT_VISIBILITY,
  SET_MARKED_CATEGORY_MAPPING_IDS,
  SET_ALL_CATEGORIES_MAPPED
} from '~/store/modules/shared/dealers/integration/categories/mutation-types'
import { ActionTreeWithRootState } from '~/store/types'
import { DealerIntegrationCategoriesState } from '~/store/modules/shared/dealers/integration/categories/state'
import ScrollService from '~/services/scroll/ScrollService'

export default {
  async loadMappings({ commit }) {
    const mappings = await this.$dep(
      DealerCategoryMapService
    ).getCategoryMappings()
    commit(SET_MAPPINGS, mappings)
    commit(SET_FILTERED_MAPPINGS, mappings)
  },
  async loadUnmappedCategories({ commit, dispatch }) {
    const { mappings, errors } = await this.$dep(
      DealerCategoryMapService
    ).getUnmappedCategoryMappings()

    if (errors.length) {
      commit(SET_UNMAPPED_CATEGORY_SCAN_ERRORS, errors)
      commit(SET_ERROR_ALERT_VISIBILITY, true)
    } else if (!mappings.size) {
      commit(SET_ALL_CATEGORIES_MAPPED, true)
      this.$dep(SnackbarService).success(
        // @ts-ignore
        this.app.i18n.t('all categories already found') as string
      )
    }

    commit(SET_MARKED_CATEGORY_MAPPING_IDS, new Set(mappings.keys()))
    commit(PREPEND_MAPPINGS, mappings)

    mappings.size &&
      (await dispatch('filterMappings', {
        text: '',
        resetPage: true,
        updateUrl: true
      }))
  },
  async updateInternalCategoriesOfMapping(
    { dispatch, getters },
    { id, internalIds }: { id: CategoryMapping['id']; internalIds: number[] }
  ) {
    const m = getters.mappings.get(id)
    if (!m) {
      return
    }

    let mapping: CategoryMapping
    try {
      mapping = await this.$dep(DealerCategoryMapService).upsertCategoryMapping(
        m.externalName,
        m.externalId,
        internalIds
      )
    } catch (e) {
      this.$dep(SnackbarService).error(e)
      return
    }

    const { internalIdentifiers, naReason } = mapping
    await dispatch('setMapping', {
      ...m,
      internalIdentifiers,
      naReason
    })
  },
  async upsertCategoryMapping(
    { getters, dispatch },
    payload: Merge<
      Merge<
        Pick<CategoryMapping, 'externalId' | 'externalName'>,
        Partial<Pick<CategoryMapping, 'id'>>
      >,
      { internalIds: number[]; index?: number }
    >
  ) {
    const { externalId, externalName, internalIds, id, index } = payload

    let mapping: CategoryMapping
    try {
      mapping = await this.$dep(DealerCategoryMapService).upsertCategoryMapping(
        externalName,
        externalId,
        internalIds
      )
    } catch (e) {
      this.$dep(SnackbarService).error(e.response?.data?.error?.generic)
      throw e
    }

    dispatch('setMapping', {
      ...mapping,
      id: id && getters.mappings.has(id) ? id : mapping.id,
      index
    })
  },
  async replaceInternalCategoryOfMapping(
    { getters, dispatch },
    payload: {
      id: CategoryMapping['id']
      internalCategoryId: InternalCategoryIdentifier['id']
      prevInternalCategoryId: InternalCategoryIdentifier['id']
      transit?: boolean
    }
  ) {
    const { id, internalCategoryId, prevInternalCategoryId, transit } = payload
    const m = getters.mappings.get(id)
    if (!m) {
      return
    }

    let internalIds: number[] = m.internalIdentifiers.map(
      (i: InternalCategoryIdentifier) => i.id
    )
    if (transit) {
      internalIds.push(internalCategoryId)
      this.$dep(SnackbarService).success(
        // @ts-ignore
        this.app.i18n.t('saved::verb') as string,
        {
          time: 1500
        }
      )
    } else if (prevInternalCategoryId === NaReasonNodeId.CANNOT_BE_MAPPED) {
      internalIds = [internalCategoryId]
    } else {
      const replacementIndex: number = m.internalIdentifiers.findIndex(
        (i: InternalCategoryIdentifier) => i.id === prevInternalCategoryId
      )
      if (replacementIndex < 0) {
        this.$logger.captureMessage(
          `${id}: Can't replace non-existent internal category ${prevInternalCategoryId}`
        )
        return
      }
      internalIds.splice(replacementIndex, 1, internalCategoryId)
    }

    await dispatch('upsertCategoryMapping', { ...m, internalIds })

    if (transit) {
      await dispatch('deleteTransitInternalCategory', {
        mappingId: id,
        categoryId: prevInternalCategoryId
      })
    }
  },
  async deleteInternalCategoryOfMapping(
    { getters, dispatch },
    payload: { id: CategoryMapping['id']; internalCategoryId: number }
  ) {
    const { id, internalCategoryId } = payload
    const m = getters.mappings.get(id)
    if (!m) {
      return
    }

    const internalIds = new Set(
      m.internalIdentifiers.map((i: InternalCategoryIdentifier) => i.id)
    )
    internalIds.delete(internalCategoryId)
    await dispatch('updateInternalCategoriesOfMapping', {
      id,
      internalIds: [...internalIds]
    })
  },
  async deleteMapping(
    { getters, commit, dispatch },
    id: string
  ): Promise<Undoable> {
    const m = getters.mappings.get(id)
    if (!m) {
      return noopUndoable()
    }
    await this.$dep(DealerCategoryMapService).deleteCategoryMapping(
      m.externalName,
      m.externalId
    )

    const indexOfMapping: number = [...getters.mappings.keys()].findIndex(
      // TODO: time optimization: Get index of mapping through partitioned map data structure
      key => key === id
    )
    commit(DELETE_MAPPING, id)

    return undoable(() => {
      return dispatch('upsertCategoryMapping', {
        ...m,
        internalIds:
          m.naReason === NaReason.CANNOT_BE_MAPPED
            ? [NaReasonNodeId.CANNOT_BE_MAPPED]
            : m.internalIdentifiers.map(
                (i: InternalCategoryIdentifier) => i.id
              ),
        index: indexOfMapping
      })
    })
  },
  setPage(
    { commit, getters },
    {
      pageNumber,
      updateUrl = false,
      scrollToTop = false
    }: CategoryMappingPageSettingPayload
  ) {
    const { rangeOfPages } = getters
    flow(
      n => Math.max(rangeOfPages[0], n),
      n => Math.min(n, rangeOfPages[1]),
      n => {
        commit(SET_PAGE, n)

        if (updateUrl) {
          this.$router.push({ query: { pg: n.toString() } })
        }

        if (process.client && scrollToTop) {
          this.$dep(ScrollService).scrollToTop()
        }
      }
    )(pageNumber)
  },
  async filterMappings(
    { getters, dispatch, commit },
    {
      text,
      resetPage,
      updateUrl
    }: { text: string; resetPage?: boolean; updateUrl?: boolean }
  ) {
    commit(
      SET_FILTERED_MAPPINGS,
      this.$dep(DealerCategoryMapViewService).filterCategoryMappings(
        getters.mappings,
        text
      )
    )
    await dispatch('setMappingFilterText', { text, resetPage, updateUrl })
  },
  setMappingFilterText(
    { commit },
    {
      text,
      resetPage = true,
      updateUrl = true
    }: CategoryMappingFilterTextSettingPayload
  ) {
    commit(SET_MAPPING_FILTER_TEXT, text)
    resetPage && commit(SET_PAGE, 1)

    if (!updateUrl) {
      return
    }

    const queryParams: { q: string; pg?: string } = { q: text }
    if (resetPage) {
      queryParams.pg = '1'
    }

    this.$router.push({ query: queryParams })
  },
  async setMapping(
    { commit, state, dispatch },
    mapping: MappingSettingPayload
  ) {
    commit(SET_MAPPING, mapping)

    await dispatch('filterMappings', {
      text: state.mappingFilterText,
      resetPage: false
    })
  },
  addTransitInternalCategory(
    { commit, state },
    payload: Pick<TransitInternalCategoryCreationPayload, 'mappingId'>
  ) {
    const { mappingId } = payload
    const dealerCategoryMapViewService = this.$dep(DealerCategoryMapViewService)
    if (
      state.transitInternalCategories.get(mappingId)?.size ||
      dealerCategoryMapViewService.mappingReachedInternalCategoryLimit(
        mappingId
      )
    ) {
      return
    }

    const categoryIdentifier = this.$dep(
      DealerCategoryMapViewService
    ).getPresetInternalCategory(mappingId)
    if (!categoryIdentifier) {
      return
    }
    commit(ADD_TRANSIT_INTERNAL_CATEGORY, {
      mappingId,
      categoryIdentifier
    })
  },
  deleteTransitInternalCategory: mutationAlias<
    TransitInternalCategoryCreationPayload
  >(DELETE_TRANSIT_INTERNAL_CATEGORY)
} as ActionTreeWithRootState<DealerIntegrationCategoriesState>
