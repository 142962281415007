import { FloatingCornerElementName, Corner } from '~/models/layout'
import { Breadcrumb, Consent, Metadata } from '~/models/common/types'
import { PageError } from '~/models/error'

export const PAGE_NS = 'page'

export interface PageState {
  breadcrumbs: Breadcrumb[] | null
  metadata?: Metadata[] | null
  seoUrl: string | null
  title?: string | null
  consent: Consent | null
  error?: PageError
  cornerElements: {
    [Corner.TOP_RIGHT]: undefined
    [Corner.BOTTOM_RIGHT]: Set<FloatingCornerElementName>
    [Corner.BOTTOM_LEFT]: undefined
    [Corner.TOP_LEFT]: undefined
  }
  modalOpen: boolean
  bodyClasses: string[]
}

export function getDefaultState(): PageState {
  return {
    breadcrumbs: [],
    metadata: undefined,
    seoUrl: null,
    title: undefined,
    consent: null,
    error: undefined,
    cornerElements: {
      [Corner.TOP_RIGHT]: undefined,
      [Corner.BOTTOM_RIGHT]: new Set(),
      [Corner.BOTTOM_LEFT]: undefined,
      [Corner.TOP_LEFT]: undefined
    },
    modalOpen: false,
    bodyClasses: []
  }
}

export default getDefaultState
