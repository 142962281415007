import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { ClsfdCategory } from '~/models/classified/category/types'
import { Result } from '~/models/shared/result'
import BreadcrumbHandler from '~/services/breadcrumbs/BreadcrumbHandler'
import { formatNumber } from '~/utils/number'
import { invalidBodyError } from '../../errors'
import { CategoryMap } from '~/models/category/types'

@containerScoped()
export default class ClassifiedCategoryService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(BreadcrumbHandler) private breadcrumbHandler: BreadcrumbHandler
  ) {}

  async getCategoryMap(rootId: string): Promise<Result<CategoryMap>> {
    const response = await this.http.get(this.getUrl(rootId))

    const body = response.data
    if (!body.data || !body.data.categories) {
      throw invalidBodyError(body)
    }

    return {
      data: this.formatCategoryMap(body),
      breadcrumbs: this.breadcrumbHandler.getBreadcrumbs(body)
    }
  }

  private getUrl(categoryId: string): string {
    return `/api/classifieds/categories/${categoryId}/`
  }

  private formatCategoryMap(body: any): Map<string, ClsfdCategory> {
    if (!body.data.categories.length) {
      return new Map()
    }

    const map = new Map<string, ClsfdCategory>()
    this.flattenTree(body.data.categories[0], map)
    return map
  }

  private flattenTree(tree: any, map: Map<string, ClsfdCategory>): void {
    const category = this.formatCategory(tree)
    map.set(category.id, category)

    Array.isArray(tree.children) &&
      tree.children.forEach((child: any) => {
        this.flattenTree(child, map)
      })
  }

  private formatCategory(c: any): ClsfdCategory {
    const cat = c.category
    return {
      childrenIds:
        c.children &&
        c.children.map((child: any) => child.category.id.toString()),
      classifiedCount: c.count,
      formattedClassifiedCount: c.count && formatNumber(c.count),
      humanName: cat && cat.human_name,
      humanNamePlural: cat && cat.human_name_plural,
      id: cat && cat.id && cat.id.toString(),
      parentId: cat && cat.parent_id && cat.parent_id.toString(),
      pageUrl: c.next_url,
      allChildrenHaveIcons: c.all_children_have_icons,
      label: c.label
    }
  }
}
