import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { ClassifiedBucketType, Classified } from '~/models/classified/types'
import { ActionResult } from '~/models/shared/result'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { BucketResult } from '~/models/classified/bucket'
import { invalidBodyError } from '~/services/errors'

@containerScoped()
export default class ClassifiedBucketService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  getBucketClassifieds(bucket: ClassifiedBucketType): Promise<Classified[]> {
    return this.requestBuilder
      .request('get', this.getUrl(bucket))
      .validate(body => body && body.data && body.data.classifieds)
      .map(body => {
        return body.data.classifieds
      })
      .send()
  }

  getBucketClassifiedsGrouped(
    bucket: ClassifiedBucketType
  ): Promise<Classified[]> {
    return this.requestBuilder
      .request('get', `/api/classifieds/buckets/${bucket}/grouped/`)
      .validate(body => body && body.data && body.data.groups)
      .map(body => {
        return body.data.groups
      })
      .send()
  }

  removeClassifiedFromBucket(
    bucket: ClassifiedBucketType,
    id: number
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('delete', this.getUrl(bucket))
      .data({ to_delete: id })
      .action()
      .send()
  }

  setClassifiedOrder(order: number[]): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', '/api/classifieds/buckets/favorite/reorder/')
      .data({ classifieds: order })
      .action()
      .send()
  }

  async addClassifiedToFavorites(classifiedId: number): Promise<BucketResult> {
    const url = this.getUrl(ClassifiedBucketType.FAVORITE)
    const response: AxiosResponse = await this.http.put(url, {
      classified: classifiedId
    })
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body.data
  }

  private getUrl(bucket: ClassifiedBucketType): string {
    return `/api/classifieds/buckets/${bucket}/`
  }

  async removeClassifiedFromFavorites(
    classifiedId: number
  ): Promise<BucketResult> {
    const url = this.getUrl(ClassifiedBucketType.FAVORITE)
    const response: AxiosResponse = await this.http.delete(url, {
      data: { classified: classifiedId }
    })
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body.data
  }
}
