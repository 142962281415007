import { containerScoped } from '~/decorators/dependency-container'
import { Breadcrumb } from '../../models/common/types'

@containerScoped()
export default class BreadcrumbHandler {
  getBreadcrumbs(body: any): Breadcrumb[] {
    const breadcrumbs =
      body &&
      body._page &&
      Array.isArray(body._page.breadcrumbs) &&
      body._page.breadcrumbs
    if (!breadcrumbs) {
      return []
    }

    return breadcrumbs.map(this.format)
  }

  private format(b: any): Breadcrumb {
    return {
      text: b.text,
      url: b.url,
      icon: b.icon
    }
  }
}
