import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { containerScoped } from '~/decorators/dependency-container'
import {
  BackendMetaItem,
  Head,
  LinkItem,
  MetaItem,
  LinkPreloadType
} from '~/models/head/types'
import { NamespacedStore } from '~/models/store/types'

import { StoreWithRootState } from '~/store/types'
import { getNamespacedStore } from '~/utils/store'
import {
  IMAGE_PRELOAD_NS,
  ImagePreloadState
} from '~/store/modules/shared/preload/image/state'
import { PAGE_NS, PageState } from '~/store/modules/shared/page/state'
import { APP_NS, AppState } from '~/store/modules/shared/app/state'
import { getStaticUrl } from '~/utils/static'
import { Consent } from '~/models/common/types'

@containerScoped()
export default class HeadService {
  private preloadedImageStore: NamespacedStore<ImagePreloadState>
  private pageStore: NamespacedStore<PageState>
  private appStore: NamespacedStore<AppState>

  constructor(@inject(Store) store: StoreWithRootState) {
    this.preloadedImageStore = getNamespacedStore(store, IMAGE_PRELOAD_NS)
    this.pageStore = getNamespacedStore(store, PAGE_NS)
    this.appStore = getNamespacedStore(store, APP_NS)
  }

  public createHead({
    title,
    meta = [],
    htmlAttrs,
    bodyAttrs,
    link,
    consent,
    noIndex
  }: {
    title?: string
    meta?: MetaItem[]
    htmlAttrs?: object
    bodyAttrs?: Record<string, any>
    link?: LinkItem[]
    consent?: Consent['name']
    noIndex?: boolean
  } = {}): Head {
    const head: Head = {
      title: undefined,
      meta: [],
      link: []
    }
    head.title = title

    if (htmlAttrs) {
      head.htmlAttrs = htmlAttrs
    }

    if (bodyAttrs) {
      head.bodyAttrs = bodyAttrs
    }
    if (link) {
      head.link = link
    }

    this.preloadImages(head.link!)

    if (consent) {
      meta.push(...this.getMetaTagsPerConsentType(consent))
    }
    if (noIndex) {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow'
      })
    }
    head.meta = meta

    return head
  }

  createLayoutHead() {
    const { bodyClasses } = this.pageStore.state
    return {
      bodyAttrs: {
        class: bodyClasses.join(' ')
      }
    }
  }

  private getMetaTagsPerConsentType(consentName: Consent['name']) {
    switch (consentName) {
      case 'over18': {
        return [
          { name: 'rating', content: 'adult' },
          { name: 'rating', content: 'RTA-5042-1996-1400-1577-RTA' }
        ]
      }
      default: {
        return []
      }
    }
  }

  private preloadImages(links: LinkItem[]) {
    const preloadedImageUrls = this.preloadedImageStore.state.urls
    if (preloadedImageUrls) {
      for (const preloadedImageUrl of preloadedImageUrls) {
        links.push({
          rel: 'preload',
          href: preloadedImageUrl,
          as: LinkPreloadType.IMAGE
        })
      }
    }
  }

  public getRouteOgUrlMetaTag(path: string): MetaItem {
    return {
      name: 'og:url',
      content: this.getFullPagePath(path)
    }
  }

  public formatBackendMeta(backendMeta: BackendMetaItem[]): MetaItem[] {
    return (backendMeta || []).map(backendMetaItem => {
      const { property, content, name } = backendMetaItem
      const item: any = {}
      if (name) {
        item.hid = name
        item.name = name
      } else if (property) {
        item.hid = property
        item.property = property
      }
      item.content = content
      return item
    })
  }

  createOgImageMetaItem(
    imagePath: string = '/nuxt-static/img/og/car.jpg'
  ): MetaItem {
    return {
      hid: 'og:image',
      name: 'og:image',
      content: getStaticUrl(imagePath)
    }
  }

  getFullPagePath(path: string) {
    return `https://www.${this.appStore.state.name || 'car'}.gr${path}`
  }
}
