import { injectable } from 'tsyringe'
import { IPartitionedCollection } from '~/models/collection/IPartitionedCollection'

@injectable()
export class PartitionedMap<K, V>
  implements IPartitionedCollection<Map<K, V>, V, K> {
  private partitions_: Map<K, V>[] = []
  private partitionLength_: number = 1000
  private partitionIndicesByKey: Map<K, number> = new Map()

  setPartitionLength(value: number) {
    // TODO: fix collection setting taking into account only default partition length
    this.partitionLength_ = value
    return this
  }

  get partitionLength(): number {
    return this.partitionLength_
  }

  get length(): number {
    return this.partitions_.reduce((n, p) => n + p.size, 0)
  }

  get numberOfPartitions(): number {
    return Math.ceil(this.length / this.partitionLength_)
  }

  private getPartitionIndexByItemIndex(index: number): number {
    return Math.floor(index / this.partitionLength_)
  }

  get partitions(): Map<K, V>[] {
    return this.partitions_
  }

  setCollection(collection: Map<K, V>) {
    this.clear()

    const numberOfPartitions = Math.ceil(
      collection.size / this.partitionLength_
    )
    for (let i = 0; i < numberOfPartitions; i++) {
      this.partitions_[i] = new Map<K, V>()
    }

    let j = 0
    for (const [key, value] of collection.entries()) {
      const pi = this.getPartitionIndexByItemIndex(j)
      const p = this.partitions_[pi]
      p.set(key, value)
      this.partitionIndicesByKey.set(key, pi)
      j++
    }

    return this
  }

  setCollectionFromPartitions(partitions: Map<K, V>[]): this {
    this.clear()

    for (let i = 0; i < partitions.length; i++) {
      this.setPartition(i, partitions[i])
    }

    return this
  }

  /**
   * Appends partitions of argument to partition array, without mutating existing partitions themselves.
   * @param partitionedCollection
   */
  extend(
    partitionedCollection: IPartitionedCollection<Map<K, V>, V, K>
  ): IPartitionedCollection<Map<K, V>, V, K> {
    if (partitionedCollection.partitionLength !== this.partitionLength_) {
      throw new Error('unequal partition lengths')
    }
    const partitions = partitionedCollection.getPartitions()

    const ownPartitionsLength = this.partitions_.length
    for (let i = 0; i < partitions.length; i++) {
      this.setPartition(ownPartitionsLength + i, partitions[i])
    }
    return this
  }

  private setPartition(index: number, partition: Map<K, V> | null | undefined) {
    if (!partition) {
      return this
    }
    this.partitions_[index] = partition

    for (const key of partition.keys()) {
      this.partitionIndicesByKey.set(key, index)
    }

    return this
  }

  getCollection(): Map<K, V> {
    return this.partitions_.reduce((collection, partition) => {
      if (!partition) {
        return collection
      }

      for (const [pk, pv] of partition.entries()) {
        collection.set(pk, pv)
      }
      return collection
    }, new Map())
  }

  clear() {
    this.partitions_ = []
    this.partitionIndicesByKey = new Map()
    return this
  }

  delete(key: K): boolean {
    const i = this.partitionIndicesByKey.get(key)
    if (typeof i === 'undefined') {
      return false
    }
    this.partitions_[i].delete(key)
    this.partitionIndicesByKey.delete(key)
    return true
  }

  get(key: K): V | undefined {
    const partitionIndex = this.partitionIndicesByKey.get(key)
    if (typeof partitionIndex === 'undefined') {
      return undefined
    }
    return this.partitions_[partitionIndex].get(key)
  }

  getPartition(index: number): Map<K, V> {
    return this.partitions_[index]
  }

  getPartitions(): Map<K, V>[] {
    return this.partitions_
  }

  has(key: K): boolean {
    return typeof this.partitionIndicesByKey.get(key) !== 'undefined'
  }

  set(key: K, item: V) {
    const numberOfPartitions = this.partitions_.length
    if (
      numberOfPartitions > 0 &&
      this.partitions_[numberOfPartitions - 1].size < this.partitionLength_
    ) {
      this.partitions_[numberOfPartitions - 1].set(key, item)
      this.partitionIndicesByKey.set(key, numberOfPartitions - 1)
      return this
    }

    this.partitions_[numberOfPartitions] = new Map<K, V>([[key, item]])
    this.partitionIndicesByKey.set(key, numberOfPartitions)
    return this
  }
}
