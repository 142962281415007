import {
  RESET_ROW_RESULTS,
  SET_SEARCH_TYPE,
  CLEAR_ALL,
  SET_CONFIG,
  SET_FACETS_DATA,
  SET_FACETS_SEARCHBAR,
  SET_IS_FAVORITE,
  SET_LOADING_FACETS,
  SET_LOADING_RESULTS,
  SET_PAGE,
  SET_PAGE_DATA,
  SET_PARAM,
  SET_PARAMS,
  SET_PER_PAGE,
  SET_RESULTS_DATA,
  SET_SELLERS_DATA,
  SET_VIEW_TYPE,
  MODIFY_ROW_TOUCHED,
  MODIFY_ROW_DELETED,
  MODIFY_ROW_RESTORED,
  MODIFY_ROW_REMOVED,
  DECREMENT_PAGINATION_TOTAL,
  MODIFY_ROW_HIDDEN,
  MODIFY_ROW_SHOWN,
  MODIFY_ROW_NON_PUBLIC,
  MODIFY_ROW_PUBLIC,
  SET_SHOW_SELLERS,
  SET_SHOW_MOBILE_FACETS,
  SET_ACTIONS_ARE_COLLAPSED,
  MODIFY_ROW_PARKED,
  SET_CURRENT_ROUTE,
  MODIFY_ROW_PROMOTION_ACTIVE,
  SET_PREVIOUS_ROUTE,
  SET_MAP_DATA,
  CLEAR_MAP_DATA,
  SET_MAP_SEARCH_FLAG,
  SET_SELLERS_ONLY_FLAG,
  SET_LOADING_MAP,
  SET_CLUSTER_RESULTS_DATA,
  SET_LOADING_CLUSTER_RESULTS,
  SET_CLASSIFIED_PAID_INFO,
  SET_PAID_SUMMARY,
  SET_MASS_ACTIONS_REMAINING_ACTIONS,
  SET_MASS_ACTIONS_TOTAL_ACTIONS,
  SET_MASS_ACTIONS_RESET_TIMESTAMP,
  SET_AUTO_TOUCH_DATA_IN_SEARCH
} from './mutation-types'
import { SearchRoute, SearchState, SHOW_SELLERS_PARAM } from './state'
import {
  Facet,
  FacetsResult,
  ResultsResult,
  SellersResult
} from '~/models/search/types'
import { CompactPage } from '~/models/common/types'
import { MutationTree } from 'vuex'
import { ViewType } from '~/models/shared/types'
import { PaidClassifiedsInfoSummary } from '~/models/classified/paid/types'
import { AutoTouchSchedule } from '~/models/auto-touch/types'
import { toSnakeCase } from '~/utils/snake-case'
import { SearchConfig } from '~/models/search/config'
import { compatibilityParamsMappings } from '~/constants/classified/search/param'
import { vue3Delete, vue3Set } from '~/utils/nuxt3-migration'

export function preprocessParamValue(value: any) {
  if (Array.isArray(value)) {
    return value
      .filter(v => v !== null && v !== undefined)
      .map(v => v.toString())
  } else if (
    value === null ||
    typeof value === 'undefined' ||
    value === false
  ) {
    return value
  }
  return [value.toString()]
}

// const { setter } = createMutationCreator<SearchState>()
export function preprocessRoute(route: SearchRoute): SearchRoute {
  const { query, path } = route
  // @ts-ignore
  delete query.sp
  return { query, path }
}

export default {
  [RESET_ROW_RESULTS](state: SearchState) {
    state.rows = null
  },
  [SET_SEARCH_TYPE](state: SearchState, payload: boolean) {
    state.isQuickSearch = payload
  },
  [SET_CURRENT_ROUTE](state, currentRoute: SearchRoute) {
    state.currentRoute = preprocessRoute(currentRoute)
  },
  [SET_PREVIOUS_ROUTE](state, previousRoute: SearchRoute) {
    state.previousRoute = preprocessRoute(previousRoute)
  },
  [SET_PAGE_DATA](state: SearchState, pageData: CompactPage) {
    state.page = pageData
  },
  [SET_LOADING_FACETS](state: SearchState, loading: boolean) {
    state.loading.facets = loading
  },
  [SET_LOADING_RESULTS](state: SearchState, loading: boolean) {
    state.loading.results = loading
  },
  [SET_LOADING_MAP](state: SearchState, loading: boolean) {
    state.loading.map = loading
  },
  [SET_LOADING_CLUSTER_RESULTS](state: SearchState, loading: boolean) {
    state.loading.clusterResults = loading
  },
  [SET_RESULTS_DATA](state: SearchState, data: ResultsResult) {
    state.newWantedClassifiedUrl = data.newWantedClassifiedUrl
    state.showInPlotUrl = data.showInPlotUrl
    state.rows = data.results.rows
    state.savable = data.results.savable
    state.shareable = data.results.shareable
    state.pagination = data.results.pagination
    state.sellersInfo = data.sellers
    state.backendAdsConfig = data.results.ads
  },
  [SET_CLUSTER_RESULTS_DATA](state: SearchState, data: ResultsResult) {
    state.clusterRows = data.results.rows
    state.clusterPagination = data.results.pagination
  },
  [SET_FACETS_DATA](state: SearchState, data: FacetsResult) {
    state.facets = data.facets
    state.category = data.category
    state.categoryIds = data.categoryIds
    state.adsTargetings = data.adsTargetings
    state.alternativeSearches = data.alternativeSearches
    state.args = data.args
    state.canBeSaved = data.canBeSaved
    state.changeSearchUrl = data.changeSearchUrl
    state.isFavorite = data.isFavorite
    state.longTitle = data.longTitle
    state.massActions = data.massActions || {}
    state.mySearchLinks = data.mySearchLinks?.searchLinks
    state.newClassifiedUrl = data.newClassifiedUrl
    state.mapSearchUrl = data.mapSearchUrl
    state.previousSearch = data.previousSearch
    state.userProfile = data.userProfile
    state.searchId = data.searchId
    state.shortTitle = data.shortTitle
    state.sortFields = data.sortFields
    vue3Set(state, 'stats', data.stats) // to deal with reactivity issues
    state.title = data.title
    state.textSearch = data.textSearch
    state.notifications = {
      bulkValid: data.searchSubscription?.search?.notifications?.bulkValid,
      instantValid: data.searchSubscription?.search?.notifications?.instantValid
    }
    state.userOwnsSearch = data.userOwnsSearch
    state.notMyClassifiedsUrl = data.notMyClassifiedsUrl
  },
  [SET_VIEW_TYPE](state: SearchState, viewType: ViewType) {
    state.viewType = viewType
  },
  [SET_PER_PAGE](state: SearchState, value: number) {
    state.perPage = value
  },
  [SET_CONFIG](state: SearchState, config: SearchConfig) {
    state.config = config
  },
  [SET_IS_FAVORITE](state: SearchState, value: boolean) {
    state.isFavorite = value
  },
  [SET_PARAMS](state: SearchState, params: any) {
    const formattedParams = Object.keys(params).reduce((acc: any, key) => {
      // comment out if we ever want to transform region/location stuff here
      // const keyToUse = compatibilityParamsMappings[key] || key
      acc[key] = preprocessParamValue(params[key])
      return acc
    }, {})
    state.params = formattedParams
  },
  [SET_PARAM](
    state: SearchState,
    { name, value }: { name: string; value: any }
  ) {
    const nameToUse = compatibilityParamsMappings[name] || name
    if (value === null || value === undefined) {
      vue3Delete(state.params, nameToUse)
      return
    }
    vue3Set(state.params, nameToUse, preprocessParamValue(value))
  },
  [SET_FACETS_SEARCHBAR](
    state: SearchState,
    { q, category }: { q: string; category: any }
  ) {
    const searchbarFacet: Facet | undefined =
      state.facets.find(facet => facet.name === 'mein') ||
      state.facets.find(facet => facet.name === 'admin_mein') ||
      state.facets.find(facet => facet.name === 'long_mein')
    if (!(searchbarFacet && searchbarFacet.values && searchbarFacet.category)) {
      return
    }
    if (q || q === '') {
      searchbarFacet.values.value = q
    }
    if (category) {
      searchbarFacet.category.id = category
    }
  },
  [SET_ACTIONS_ARE_COLLAPSED](state, actionsState) {
    state.actionsAreCollapsed = actionsState
  },
  [CLEAR_ALL](state: SearchState, clearFacets: boolean = false) {
    state.params = {}
    if (clearFacets) {
      state.facets = []
    }
  },
  [SET_CLASSIFIED_PAID_INFO](
    state,
    { classifiedId, paidInfo }: { classifiedId: number; paidInfo: object }
  ) {
    const row = state.rows?.find(row => row.id === classifiedId)
    if (row) {
      row.paid_classified = paidInfo
    }
  },
  [SET_PAGE](state: SearchState, page: number) {
    state.params.pg = preprocessParamValue(page)
    state.pagination.page = page
  },
  [SET_SELLERS_DATA](state, data: SellersResult) {
    const { isFavorite, sellers } = data
    state.isFavorite = isFavorite
    state.pagination = sellers.pagination
    state.sellerRows = sellers.rows
    state.sellersClassifiedsInfo = sellers.classifieds
  },
  [SET_MAP_DATA](state, data: { map: { clusters: []; markers: [] } }) {
    const { clusters, markers } = data.map
    state.map.clusters = clusters
    state.map.markers = markers
  },
  [CLEAR_MAP_DATA](state) {
    state.map.clusters = []
    state.map.markers = []
  },
  [MODIFY_ROW_PARKED](state, { classifiedId, parked }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (state.clusterRows?.length) {
      const clusterRow = state.clusterRows.find(row => row.id === classifiedId)
      if (clusterRow) {
        clusterRow.is_parked = parked
      }
    }
    if (row) {
      row.is_parked = parked
    }
  },
  [MODIFY_ROW_TOUCHED](state, { classifiedId, modified }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.modified = modified || Date.now().toString()
      vue3Set(row, 'touched', true)
    }
  },
  [MODIFY_ROW_PROMOTION_ACTIVE](state, { classifiedId, promo }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row && row.promotion) {
      row.promotion.activePromotion = promo
    }
  },
  [MODIFY_ROW_DELETED](state, { classifiedId, deletedDate }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.is_deleted = true
      row.states.is_deleted = true
      row.deleted_date = deletedDate || Date.now().toString()
    }
  },
  [MODIFY_ROW_RESTORED](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.is_deleted = false
      row.states.is_deleted = false
      row.states.is_removed = false
      row.deleted_date = undefined
    }
  },
  [MODIFY_ROW_REMOVED](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.states.is_removed = true
    }
  },
  [DECREMENT_PAGINATION_TOTAL](state) {
    state.pagination.total--
  },
  [MODIFY_ROW_HIDDEN](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.states.is_hidden = true
    }
  },
  [MODIFY_ROW_SHOWN](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.states.is_hidden = false
    }
  },
  [MODIFY_ROW_NON_PUBLIC](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.states.is_non_public = true
    }
  },
  [MODIFY_ROW_PUBLIC](state, { classifiedId }) {
    const row = state.rows && state.rows.find(row => row.id === classifiedId)
    if (row) {
      row.states.is_non_public = false
    }
  },
  [SET_SHOW_SELLERS](state, showSellersState: boolean) {
    vue3Set(state.params, SHOW_SELLERS_PARAM, showSellersState ? true : null)
  },
  [SET_SHOW_MOBILE_FACETS](state, mobileFacetsState: boolean) {
    state.showMobileFacets = mobileFacetsState
  },
  [SET_MAP_SEARCH_FLAG](state, value: boolean) {
    state.flags.isMapSearch = value
  },
  [SET_SELLERS_ONLY_FLAG](state, value: boolean) {
    state.flags.isSellersOnlySearch = value
  },
  [SET_PAID_SUMMARY](state, value: PaidClassifiedsInfoSummary) {
    state.paidSummary = value
  },
  [SET_MASS_ACTIONS_REMAINING_ACTIONS](state, value: number) {
    if (state.massActions) {
      state.massActions.remainingActions = value
    }
  },
  [SET_MASS_ACTIONS_TOTAL_ACTIONS](state, value: number) {
    if (state.massActions) {
      state.massActions.totalActions = value
    }
  },
  [SET_MASS_ACTIONS_RESET_TIMESTAMP](state, value: string) {
    if (state.massActions) {
      state.massActions.resetTimestamp = value
    }
  },
  [SET_AUTO_TOUCH_DATA_IN_SEARCH](
    state,
    {
      classifiedId,
      autoTouchData
    }: { classifiedId: number; autoTouchData: AutoTouchSchedule | null }
  ) {
    const classified = state.rows?.find(r => r.id === classifiedId)
    if (classified) {
      if (!autoTouchData) {
        classified.auto_touch = null
      } else {
        classified.auto_touch = {
          ...classified.auto_touch,
          ...toSnakeCase(autoTouchData)
        }
      }
    }
  }
} as MutationTree<SearchState>
