import { QnaComment, QnaQuestion } from '~/models/qna/types'
import { Pagination } from '~/models/search/types'
import { DEFAULT_NUMBER_OF_RESULTS_PER_PAGE } from '~/constants/search/defaults'
import { MainCategory } from '~/models/main-category'
import { Make } from '~/models/make-model'

export interface QnaState {
  questions: Array<QnaQuestion>
  comments: Array<QnaComment>
  pagination: Pagination
  dropdownCategories: []
  categories: Array<MainCategory>
  tags: { [key: string]: {} }
  fuelTypes: { [key: string]: {} | null }
  makesWithModels: Array<Make>
  makeAndModelsByCategory: { [key: string]: any }
  isRecaptchaVisible: boolean
  fullQuestion: QnaQuestion
  loading: {
    questions: boolean
    fullQuestion: boolean
    makesAndModels: boolean
  }
}
export const QNA_NS = 'qna'
function getDefaultState(): QnaState {
  return {
    questions: [],
    comments: [],
    pagination: {
      page: 1,
      pageParam: '',
      perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
      total: 0
    },

    dropdownCategories: [],
    categories: [],
    tags: {},
    fuelTypes: {},
    makesWithModels: [],
    makeAndModelsByCategory: {},
    isRecaptchaVisible: false,
    fullQuestion: {} as QnaQuestion,
    loading: {
      questions: false,
      fullQuestion: false,
      makesAndModels: false
    }
  }
}
export default getDefaultState
