import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__audits_login',
    path: '/audits/login/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/login.vue' /* webpackChunkName: "pages-shared-login_register-login.vue" */
        )
      )
  },
  {
    name: '__audits_password_reset',
    path: '/audits/password-reset/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/password-reset.vue' /* webpackChunkName: "pages-shared-login_register-guest-password-reset.vue" */
        )
      )
  },
  {
    name: '__audits_password_set',
    path: '/audits/password-set/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/password-set.vue' /* webpackChunkName: "pages-shared-login_register-guest-password-set.vue" */
        )
      )
  }
]
