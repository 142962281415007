import { Store } from 'vuex'
import {
  SET_LOADED_PAGE_ID,
  SET_LOADING_PAGE_ID
} from '~/store/modules/shared/dealers/site/mutation-types'
import {
  DEALER_SITE_NS,
  DealerSiteState
} from '~/store/modules/shared/dealers/site/state'
import { getNamespacedStore } from '~/utils/store'
import { PageType, SearchArgRecord } from '../types'
import { PageSnippet } from './types'

export default abstract class BasePageSnippet implements PageSnippet {
  id: string
  name?: string
  pagePath: string
  type: PageType
  index: boolean
  searchArgs?: SearchArgRecord
  visible: boolean

  constructor({
    id,
    index,
    name,
    pagePath,
    searchArgs,
    type,
    visible
  }: PageSnippet) {
    this.id = id
    this.index = index
    this.name = name
    this.pagePath = pagePath
    this.searchArgs = searchArgs
    this.type = type
    this.visible = visible
  }

  async loadPage(store: Store<any>): Promise<void> {
    const dsStore = getNamespacedStore<DealerSiteState>(store, DEALER_SITE_NS)
    dsStore.commit(SET_LOADING_PAGE_ID, this.id)
    await this.onPageLoad(store)

    const { loadingPageId } = dsStore.state
    /** Only sets loaded state for the page that has started to load most
     *  recently, since that is the page the user wants to load.
     */
    if (loadingPageId === this.id) {
      dsStore.commit(SET_LOADING_PAGE_ID, undefined)
      dsStore.commit(SET_LOADED_PAGE_ID, this.id)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onPageLoad(store: Store<any>): Promise<void> {
    return Promise.resolve()
  }
}
