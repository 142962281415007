











import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { CIcon } from '~/icons/types'

export default defineComponent({
  props: {
    bgClass: {
      type: String,
      default: null
    },
    textClass: {
      type: String,
      default: null
    },
    variant: {
      type: String as PropType<
        'primary' | 'light' | 'success' | 'danger' | 'warning' | 'info'
      >,
      default: 'info'
    },
    size: {
      type: String as PropType<'md' | 'lg'>,
      default: 'md'
    },
    softRounded: {
      type: Boolean,
      default: false
    },
    icon: {
      type: CIcon,
      default: null
    }
  },
  setup(props) {
    const badgesClasses = computed(() => {
      const classes = []

      classes.push(props.softRounded ? 'tw-rounded-md' : 'tw-rounded-full')

      if (props.bgClass && props.textClass) {
        classes.push(props.bgClass, props.textClass)
      } else if (props.variant === 'primary') {
        classes.push('tw-bg-primary-100', 'tw-text-primary-500')
      } else if (props.variant === 'light') {
        classes.push('tw-bg-grey-300', 'tw-text-grey-700')
      } else if (props.variant === 'success') {
        classes.push('tw-bg-green-100', 'tw-text-green-800')
      } else if (props.variant === 'danger') {
        classes.push('tw-bg-red-100', 'tw-text-red-800')
      } else if (props.variant === 'warning') {
        classes.push('tw-bg-yellow-100', 'tw-text-yellow-900')
      } else {
        classes.push('tw-bg-blue-100', 'tw-text-blue-500')
      }

      classes.push(
        props.size === 'lg'
          ? 'tw-px-3 tw-py-0.5 tw-text-base'
          : 'tw-py-0.5 tw-px-2.5 tw-text-sm'
      )

      return classes
    })

    return { badgesClasses }
  }
})
