import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { invalidBodyError } from '~/services/errors'
import {
  Leaderboard,
  QnaComment,
  QnaFormData,
  QnaQueryParams,
  QnaQuestion,
  QuestionType
} from '~/models/qna/types'
import { Pagination } from '~/models/search/types'
import { toCamelCase } from '~/utils/object'
import { MainCategory } from '~/models/main-category'
import qs from 'qs'
import VueI18n from 'vue-i18n'
import { toSnakeCase } from '~/utils/snake-case'
import { ReactionEnum } from '~/models/classified-list/types'
import { CategoryId } from '~/models/category/types'
import SnackbarService from '~/services/snackbar/SnackbarService'
import LoggerService from '~/services/LoggerService'

import { HttpStatus } from '~/constants/http'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export default class QnaService {
  // private url: string

  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(LoggerService) public logger: LoggerService,
    @inject(VueI18n) private i18n: VueI18n,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  async fetchAllCategories(): Promise<{
    dropdownCategories: []
    categories: Array<MainCategory>
    tags: { [key: string]: {} }
    fuelTypes: { [key: string]: {} | null }
  }> {
    const response: AxiosResponse = await this.http.get('/api/qna/categories/')
    const body = toCamelCase(response.data)
    if (!body?.data?.categories) {
      throw invalidBodyError(body)
    }
    const categories: Array<MainCategory> = body.data.categories
    const tags: { [key: string]: {} } = {}
    const fuelTypes: { [key: string]: {} | null } = {}
    const nomalizedData = body.data.categories.map((t: MainCategory) =>
      t.children.map(c => {
        tags[c.category.id] = this.transformToNameId(c.tags)
        fuelTypes[c.category.id] = c.fuelTypes
          ? this.transformToNameId(c.fuelTypes)
          : null

        return {
          name: `${c.label}`,
          id: c.category.id,
          label: c.label,
          count: c.count
        }
      })
    )
    return {
      dropdownCategories: nomalizedData.flat(),
      categories,
      fuelTypes,
      tags
    }
  }

  async fetchQuestionDataById(
    questionId: string
  ): Promise<{ comments: Array<QnaComment>; question: QnaQuestion }> {
    const response: AxiosResponse = await this.http.get(
      `/api/question/${questionId}/`
    )

    const body = response.data
    if (!body?.data?.question || !body?.data?.comments) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data)
  }

  getQuestionType(categoryId: number) {
    if (categoryId === CategoryId.XYMA) {
      return QuestionType.XYMA_QUESTION
    } else if (categoryId === CategoryId.PLOT) {
      return QuestionType.PLOT_QUESTION
    } else {
      return QuestionType.MODEL_QUESTION
    }
  }

  isVehicle(id: number): boolean {
    if (!id) {
      return false
    }
    return ![CategoryId.XYMA, CategoryId.PLOT, CategoryId.JOBS].includes(id)
  }

  async createNewQuestion(data: QnaFormData): Promise<QnaQuestion> {
    const questionType = this.getQuestionType(data.categoryId)
    const response: AxiosResponse = await this.http.post(
      `/api/question/category/${data.categoryId}/`,
      {
        question_text: data.text,
        question_title: data.title,
        fuel_type: data.fuelId,
        makemodel_year: data.year,
        makemodel:
          data.modelId && data.modelId?.length > 0
            ? data.modelId && data.modelId[0]
            : data.makeId && data.makeId[0],
        question_type: questionType,
        question_tag: data.tagId,
        recaptcha_token: data.recaptchaToken
      }
    )

    const body = response.data
    if (!body.data?.values) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.values)
  }

  async updateQuestion(data: QnaFormData): Promise<QnaQuestion> {
    const questionType = this.getQuestionType(data.categoryId)
    const response: AxiosResponse = await this.http.put(
      `/api/question/${data.id}/category/${data.categoryId}/`,
      {
        question_text: data.text,
        question_title: data.title,
        fuel_type: data.fuelId,
        makemodel_year: data.year,
        makemodel: data.modelId?.length
          ? data.modelId[0]
          : data.makeId && data.makeId[0],
        question_type: questionType,
        question_tag: data.tagId,
        recaptcha_token: data.recaptchaToken
      }
    )

    const body = response.data
    if (!body.data?.values) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.values)
  }

  async deleteQuestion(questionId: string) {
    const response: AxiosResponse = await this.http.delete(
      `/api/question/${questionId}/`
    )
    const body = response.data
    if (!body.message) {
      throw invalidBodyError(body)
    }
  }

  async changeQuestionVisibility(
    questionId: string,
    visibilityStatus: boolean
  ): Promise<QnaQuestion> {
    const requestType = visibilityStatus ? 'post' : 'delete'
    const response: AxiosResponse = await this.http[requestType](
      `/api/question/${questionId}/visibility/`
    )
    const body = response.data
    if (!body.message || !body?.data?.question) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.question)
  }

  async updateComment(comment: string, commentId: number): Promise<QnaComment> {
    const response: AxiosResponse = await this.http.put(
      `/api/question/comment/${commentId}/`,
      {
        comment
      }
    )

    const body = response.data
    if (!body.data?.values) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.values)
  }

  async fetchCommentById(commentId: number) {
    const response: AxiosResponse = await this.http.get(
      `/api/question/comment/${commentId}/`
    )

    const body = response.data
    if (!body.data?.comment) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.comment)
  }

  async makeCommentVisible(commentId: number): Promise<QnaQuestion> {
    const response: AxiosResponse = await this.http.post(
      `/api/question/comments/${commentId}/visibility/`
    )

    const body = response.data
    if (!body.data?.comment || !body.message) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.comment)
  }

  async deleteComment(commentId: number) {
    const response: AxiosResponse = await this.http.delete(
      `/api/question/comments/${commentId}/visibility/`
    )

    const body = response.data
    if (!body?.message) {
      throw invalidBodyError(body)
    }
  }

  async makeCommentInvisible(commentId: number): Promise<QnaQuestion> {
    const response: AxiosResponse = await this.http.delete(
      `/api/question/comments/${commentId}/visibility/`
    )

    const body = response.data
    if (!body.data?.comment || !body.message) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.comment)
  }

  // First level comment
  async createQuestionComment(
    questionId: string,
    comment: string
  ): Promise<any> {
    const response: AxiosResponse = await this.http.post(
      `/api/question/comments/${questionId}/`,
      { comment }
    )

    const body = response.data
    if (!body.data?.values) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.values)
  }

  async createCommentReply(
    questionId: string,
    commentId: number,
    comment: string
  ): Promise<any> {
    const response: AxiosResponse = await this.http.post(
      `/api/question/${questionId}/comments/${commentId}/reply/`,
      { comment }
    )
    const body = response.data
    if (!body.data?.values) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.values)
  }

  // @TODO set reaction
  async createCommentReaction(
    commentId: number,
    reaction: ReactionEnum
  ): Promise<boolean> {
    const response: AxiosResponse = await this.http.post(
      `/api/questions/comments/${commentId}/react/`,
      { reaction }
    )
    const body = response.data
    if (!body.data?.values?.reaction && !body?.message) {
      throw invalidBodyError(body)
    }

    return !!body.data?.values?.reaction
  }

  async createQuestionReaction(
    questionId: string,
    reaction: ReactionEnum
  ): Promise<boolean> {
    const response: AxiosResponse = await this.http.post(
      `/api/question/${questionId}/react/`,
      { reaction }
    )
    const body = response.data
    if (!body.data?.values?.reaction && !body?.message) {
      throw invalidBodyError(body)
    }

    return !!body.data?.values?.reaction
  }

  async questionSearch(searchParams: {}): Promise<{
    pagination: Pagination
    rows: Array<QnaQuestion>
  }> {
    const filteredSearchParams = this.filterQueryParams(searchParams)
    const qParams = qs.stringify(toSnakeCase(filteredSearchParams), {
      addQueryPrefix: true,
      arrayFormat: 'repeat'
    })

    const response: AxiosResponse = await this.http.get(
      `/api/qna/search/${qParams}`
    )

    const body = response.data
    if (!body?.data?.questions) {
      throw invalidBodyError(body)
    }

    return toCamelCase(body.data.questions)
  }

  fetchLeaderboard(): Promise<Array<Leaderboard>> {
    return this.requestBuilder
      .request('get', '/api/qna/leaderboard/')
      .map(body => toCamelCase(body.data.leaderboard))
      .send()
  }

  filterQueryParams(queryParams: { [key: string]: any }): QnaQueryParams {
    const {
      category,
      tag,
      fuelType,
      fromYear,
      toYear,
      q,
      make,
      model,
      pg,
      userId,
      deleted
    } = toCamelCase(queryParams)
    return {
      category,
      tag,
      fuelType,
      fromYear,
      toYear,
      q,
      make,
      model,
      pg,
      userId,
      deleted
    }
  }

  /**
   * Tranform object to array of object
   */
  transformToNameId(obj: {
    [key: string]: string
  }): Array<{ name: string; id: number }> {
    return Object.keys(obj).map(k => ({
      name: obj[k],
      id: parseInt(k, 10)
    }))
  }

  handleError(error: AxiosError) {
    const response = error.response
    if (response) {
      switch (response.status) {
        case 400: {
          this.snackbar.error(response.data.error)
          break
        }
        case HttpStatus.UNPROCESSABLE_ENTITY: {
          const errors: Array<string> = Object.values(response.data.data.errors)
          this.snackbar.error(errors.join('\n'))
          break
        }
        case HttpStatus.GONE: {
          this.snackbar.error(response.data.error)
          break
        }
        default: {
          this.snackbar.error(response.data.error)
          this.logger.captureError(error)
          break
        }
      }
    } else {
      this.snackbar.error(
        this.i18n.t('an error occurred please try again later')
      )
      this.logger.captureError(error)
    }
  }
}
