import { getAccountRoutes } from '~/router/routes/shared/account/common'
import { accountsAuditsRoutes } from '~/router/routes/car/account/audits'
import { accountOrdersRoutes } from '~/router/routes/car/account/orders'
import { accountEshopRoutes } from '~/router/routes/car/account/eshop'

export default getAccountRoutes('/account', 'parking', [
  ...accountsAuditsRoutes,
  ...accountEshopRoutes,
  ...accountOrdersRoutes
])
