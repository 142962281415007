import { interopDefault } from '~/router/utils'

const DealerFaq = () =>
  interopDefault(
    import(
      '~/pages/car/faq/dealer.vue' /* webpackChunkName: "pages-faq-dealer.vue" */
    )
  )
export default [
  {
    name: '__dealer_registration_info',
    path: '/dealers/registration-info',
    component: DealerFaq
  }
]
