import { BackendFlashMessage, ClientFlashMessage } from '~/models/flash/types'

export const FLASH_NS = 'flash'

export interface FlashState {
  clientFlashMessage: ClientFlashMessage | null // TODO: maybe allow more than one here if needed later
  backendFlashMessage: BackendFlashMessage | null
}

export function getDefaultState(): FlashState {
  return {
    clientFlashMessage: null,
    backendFlashMessage: null
  }
}

export default getDefaultState
