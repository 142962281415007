import { interopDefault } from '~/router/utils'
import { smoothenRoutesScroll } from '~/utils/router'

export const auditsPackagesRoutes = smoothenRoutesScroll([
  {
    path: 'audits/packages',
    name: '__account_audits_packages',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/packages/index.vue' /* webpackChunkName: "pages-shared-account-audits-packages-index.vue" */
        )
      )
  },
  {
    path: 'audits/packages/new',
    name: '__account_audits_packages_new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/packages/new.vue' /* webpackChunkName: "pages-shared-account-audits-packages-new.vue" */
        )
      )
  },
  {
    path: 'audits/packages/:packageId',
    name: '__account_audits_packages_edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/packages/edit.vue' /* webpackChunkName: "pages-shared-account-audits-packages-edit.vue" */
        )
      )
  }
])
