export type PopularityType = 'categories' | string

export type PopularityFilterType = 'type' | 'categories' | string

/**
 * Popularity of term.
 */
export interface Popularity {
  term: string
  count: number
  percentage: number
}

export interface TrendingTerm {
  term: string
  diff: number
  humanName?: string
  percentage: number
}

export type FilterMap = Record<
  Partial<PopularityFilterType>,
  string[] | string | number | number[] | boolean | null
>

export interface PopularityMap {
  map: Map<string, Popularity>
  total: number
}
export interface PopularityPlotXAxis {
  label: string
  values: {
    label: string
  }[]
}
export interface PopularityPlotYAxis {
  label: string
  total: number
  values: number[]
}

export interface PopularityPlot {
  yAxis: PopularityPlotYAxis
  xAxis: PopularityPlotXAxis
  yAxles: PopularityPlotYAxis[]
}

// Record key typed as string as it is fully dynamic
export type PopularityTotals = Record<string, number>

export enum PopularityVisualizationType {
  PLOT = 'plot',
  TABLE = 'table',
  PIE = 'pie',
  HORIZONTAL_BAR = 'horizontal_bar',
  BAR = 'bar'
}

export interface PopularityResult {
  totals?: PopularityTotals
  type: PopularityVisualizationType
  data: PopularityPlot | PopularityTable | PopularityPie
}

export type PopularityTableColumnTargetValueFilter = Record<string, string>
export interface PopularityTableColumnTargetValue {
  label: string
  filter: PopularityTableColumnTargetValueFilter
}

export interface PopularityTableColumnTarget {
  href?: string
  label: string
  tags?: string[]
  dataSource?: string
  value?: PopularityTableColumnTargetValue
}

export interface PopularityTableColumn {
  isBaseDimension?: boolean
  label: string
  targets?: PopularityTableColumnTarget[]
  total: number
  value?: string | number
  data?: string | number
  dataSource?: string
}

export interface PopularityTableRow {
  columns: PopularityTableColumn[]
}

export interface PopularityTable {
  columns: PopularityTableColumn[]
  rows: PopularityTableRow[]
}

export interface PopularityPie {
  columns: PopularityPieColumn[]
  rows: PopularityPieRow[]
}

export interface PopularityHorizontalBar {
  columns: PopularityBarColumn[]
  rows: PopularityBarRow[]
}

export interface PopularityBar extends PopularityPlot {}
// Pie uses the same data structure as tables for now
export interface PopularityPieRow extends PopularityTableRow {}
export interface PopularityBarRow extends PopularityTableRow {}
export interface PopularityPieColumn extends PopularityTableColumn {}
export interface PopularityBarColumn extends PopularityTableColumn {}

export type PopularityVisualization =
  | PopularityPie
  | PopularityTable
  | PopularityPlot
