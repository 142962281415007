import {
  Path,
  Mapping,
  Field
} from '~/models/dealer/integration/classified/mapping'

export const DEALER_CLASSIFIED_FIELD_NS =
  'dealers/integration/classifieds/field'

export interface DealerClassifiedFieldState {
  paths: Set<Path>
  associatedPaths: Set<Path>
  pathsLoading: boolean
  mappings: Map<Field, Mapping>
  mappingsLoading: boolean
  dealerId?: string
  outletName?: string
}

export default function getDefaultState(): DealerClassifiedFieldState {
  return {
    paths: new Set(),
    associatedPaths: new Set(),
    pathsLoading: false,
    mappings: new Map(),
    mappingsLoading: false,
    dealerId: undefined,
    outletName: undefined
  }
}
