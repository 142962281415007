import {
  MapSearch,
  Search
} from '~/router/routes/car/classifieds/search/components'
import { ClassifiedViewPage } from '~/router/routes/car/classifieds/view'

export const plotRoutes = [
  {
    name: '__classifieds_search_plot',
    path: '/plot/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_plot_seo',
    path: '/plot/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]

export const plotMapRoutes = [
  {
    name: '__classifieds_search_plot_map',
    path: '/plot/map',
    component: MapSearch,
    meta: {
      noFooter: true,
      disableNotificationButton: true,
      isSearchRoute: true,
      wideContainer: true,
      inMap: true
    },
    children: [
      {
        name: '__classifieds_search_plot_map_classified',
        path: '/plot/map/:slugOrId',
        component: ClassifiedViewPage,
        meta: {
          noFooter: true,
          noBreadcrumbs: true,
          disableNotificationButton: true,
          inMap: true
        }
      }
    ]
  }
]
