import { MutationTree } from 'vuex'
import { AdvertiseFormState } from './state'
import {
  SET_CAMPAIGN_SCHEMA,
  SET_LOADING,
  SET_CAMPAIGN_VALUE,
  SET_NEW_AD_SCHEMA,
  SET_AD_VALUE,
  SET_CAMPAIGN_VALUES,
  SET_CAMPAIGN_ERRORS,
  SET_NEW_AD_VALUES,
  SET_NEW_AD_ERRORS,
  SET_CAMPAIGN_ADS,
  UPDATE_CAMPAIGN_AD,
  UPDATE_CAMPAIGN_AD_VALUE,
  SET_CAMPAIGN_AD_ERRORS,
  SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA,
  SET_NEW_ITEM_VALUE,
  UPDATE_CAMPAIGN_AD_ITEM_VALUE,
  UPDATE_CAMPAIGN_AD_ITEM,
  ADD_NEW_CAMPAIGN_AD,
  DELETE_CAMPAIGN_AD,
  ADD_NEW_CAMPAIGN_AD_ITEM,
  SET_NEW_ITEM_VALUES,
  SET_NEW_ITEM_ERRORS,
  SET_CAMPAIGN_AD_ITEM_ERRORS,
  CLEAR_FORMS,
  DELETE_CAMPAIGN_AD_ITEM
} from './mutation-types'

import { createMutationCreator } from '~/factories/store/mutation'
import {
  AdCampaignFormValue,
  AdFormValue,
  AdItemFormValuesWithId,
  AdItemFormValueWithId,
  CampaignAd,
  CampaignAdErrors,
  CampaignAdFormValue,
  CampaignAdItemErrors,
  CampaignAdItemForAdId,
  CampaignAdItemFormValue,
  SchemaValue
} from '~/models/advertising/types'
import { vue3Set } from '~/utils/nuxt3-migration'

const { setter } = createMutationCreator<AdvertiseFormState>()

export default {
  ...setter(SET_LOADING, 'loading'),
  ...setter(SET_CAMPAIGN_SCHEMA, 'campaignSchema'),
  ...setter(SET_CAMPAIGN_VALUES, 'campaignValues'),
  ...setter(SET_CAMPAIGN_ERRORS, 'campaignErrors'),
  ...setter(SET_NEW_AD_VALUES, 'newAdValues'),
  ...setter(SET_NEW_AD_SCHEMA, 'newAdSchema'),
  ...setter(SET_NEW_AD_ERRORS, 'newAdErrors'),
  ...setter(SET_CAMPAIGN_ADS, 'campaignAds'),
  [SET_CAMPAIGN_VALUE](state, val: AdCampaignFormValue) {
    vue3Set(state.campaignValues, val.name, val.value)
  },
  [SET_AD_VALUE](state, val: AdFormValue) {
    vue3Set(state.newAdValues, val.name, val.value)
  },
  [UPDATE_CAMPAIGN_AD](state, campaignAd: CampaignAd) {
    const index = state.campaignAds.findIndex(a => a.ad.id === campaignAd.ad.id)
    if (index >= 0) {
      vue3Set(state.campaignAds, index, {
        ...state.campaignAds[index],
        ...campaignAd
      })
    }
  },
  [UPDATE_CAMPAIGN_AD_VALUE](state, campaignVal: CampaignAdFormValue) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === campaignVal.id.toString()
    )

    if (index >= 0) {
      const currentValues = { ...state.campaignAds[index].values }
      currentValues[campaignVal.adFormValue.name] =
        campaignVal.adFormValue.value
      vue3Set(state.campaignAds[index], 'values', currentValues)
    }
  },
  [UPDATE_CAMPAIGN_AD_ITEM](state, campaignAdItem: CampaignAdItemForAdId) {
    const adIndex = state.campaignAds.findIndex(
      a => a.ad.id.toString() === campaignAdItem.id.toString()
    )
    if (adIndex >= 0) {
      const itemIndex = state.campaignAds[adIndex].items?.findIndex(
        i =>
          i.item.id.toString() ===
          campaignAdItem.campaignAdItem.item.id.toString()
      )
      // @ts-ignore
      if (itemIndex >= 0) {
        if (!state.campaignAds[adIndex].items) {
          vue3Set(state.campaignAds[adIndex], 'items', [])
        }
        let currentItem = {}
        if (state.campaignAds[adIndex].items) {
          // @ts-ignore
          currentItem = state.campaignAds[adIndex].items[itemIndex]
        }
        // @ts-ignore
        vue3Set(state.campaignAds[adIndex].items, itemIndex, {
          ...currentItem,
          ...campaignAdItem.campaignAdItem
        })
      }
    }
  },
  [UPDATE_CAMPAIGN_AD_ITEM_VALUE](
    state,
    campaignItemVal: CampaignAdItemFormValue
  ) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === campaignItemVal.adId.toString()
    )

    if (index >= 0) {
      const itemIndex = state.campaignAds[index].items?.findIndex(
        i => i.item.id.toString() === campaignItemVal.itemId.toString()
      )
      // @ts-ignore
      if (itemIndex >= 0) {
        // @ts-ignore
        if (!state.campaignAds[index].items[itemIndex].values) {
          vue3Set(
            // @ts-ignore
            state.campaignAds[index].items[itemIndex],
            'values',
            {
              [campaignItemVal.adItemFormValue.name]:
                campaignItemVal.adItemFormValue.value
            }
          )
          return
        }

        vue3Set(
          // @ts-ignore
          state.campaignAds[index].items[itemIndex].values,
          campaignItemVal.adItemFormValue.name,
          campaignItemVal.adItemFormValue.value
        )
      }
    }
  },
  [SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA](state, schemaVal: SchemaValue) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === schemaVal.id.toString()
    )

    if (index >= 0) {
      vue3Set(state.campaignAds[index], 'newItemSchema', schemaVal.schema)
    }
  },
  [SET_CAMPAIGN_AD_ITEM_ERRORS](state, errorsVal: CampaignAdItemErrors) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === errorsVal.adId.toString()
    )

    if (index >= 0) {
      const itemIndex = state.campaignAds[index].items?.findIndex(
        i => i.item.id.toString() === errorsVal.itemId.toString()
      )
      // @ts-ignore
      if (itemIndex >= 0) {
        vue3Set(
          // @ts-ignore
          state.campaignAds[index].items[itemIndex],
          'errors',
          errorsVal.errors
        )
      }
    }
  },
  [SET_CAMPAIGN_AD_ERRORS](state, errorsVal: CampaignAdErrors) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === errorsVal.id.toString()
    )

    if (index >= 0) {
      vue3Set(state.campaignAds[index], 'errors', errorsVal.errors)
    }
  },
  [SET_NEW_ITEM_VALUE](state, adItemVal: AdItemFormValueWithId) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === adItemVal.id.toString()
    )

    if (index < 0) {
      return
    }

    if (!state.campaignAds[index].newItemValues) {
      vue3Set(state.campaignAds[index], 'newItemValues', {})
    }

    vue3Set(
      // @ts-ignore
      state.campaignAds[index].newItemValues,
      adItemVal.adItemFormValue.name,
      adItemVal.adItemFormValue.value
    )
  },
  [SET_NEW_ITEM_VALUES](state, adItemVals: AdItemFormValuesWithId) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === adItemVals.id.toString()
    )

    if (index < 0) {
      return
    }

    vue3Set(state.campaignAds[index], 'newItemValues', adItemVals.adItemForm)
  },
  [SET_NEW_ITEM_ERRORS](state, adItemVals: AdItemFormValuesWithId) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === adItemVals.id.toString()
    )

    if (index < 0) {
      return
    }

    vue3Set(state.campaignAds[index], 'newItemErrors', adItemVals.adItemForm)
  },
  [ADD_NEW_CAMPAIGN_AD_ITEM](state, campaignAdItemVal: CampaignAdItemForAdId) {
    const index = state.campaignAds.findIndex(
      a => a.ad?.id.toString() === campaignAdItemVal.id.toString()
    )

    if (!state.campaignAds[index].items) {
      state.campaignAds[index].items = []
    }
    // @ts-ignore
    state.campaignAds[index].items.push(campaignAdItemVal.campaignAdItem)
  },
  [ADD_NEW_CAMPAIGN_AD](state, campaignAd: CampaignAd) {
    state.campaignAds.push(campaignAd)
  },
  [DELETE_CAMPAIGN_AD](state, adId: number | string) {
    const index = state.campaignAds.findIndex(
      c => c.ad.id.toString() === adId.toString()
    )
    state.campaignAds.splice(index, 1)
  },
  [DELETE_CAMPAIGN_AD_ITEM](
    state,
    { adId, itemId }: { adId: number | string; itemId: number | string }
  ) {
    const adIndex = state.campaignAds.findIndex(c => c.ad.id === adId)
    if (adIndex > -1) {
      const itemIndex = state.campaignAds[adIndex].items?.findIndex(
        i => i.item.id.toString() === itemId.toString()
      )
      if (itemIndex && itemIndex > -1) {
        // @ts-ignore
        state.campaignAds[adIndex].items.splice(itemIndex, 1)
      }
    }
  },
  [CLEAR_FORMS](state) {
    state.loading = false
    state.campaignValues = {}
    state.campaignErrors = {}
    state.campaignSchema = []
    state.campaignAds = []
    state.newAdValues = {}
    state.newAdErrors = {}
    state.newAdSchema = []
    state.name = ''
  }
} as MutationTree<AdvertiseFormState>
