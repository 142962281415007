import { MutationTree } from 'vuex'
import { createMutationCreator } from '~/factories/store/mutation'
import { AccountAuditsState } from '~/store/modules/shared/account/audits/state'
import {
  RESET_STATE,
  SET_AUDIT_SHOP,
  SET_AUDIT_SHOP_FIELD_VALUES,
  SET_AUDIT_SHOP_SCHEMA
} from '~/store/modules/shared/account/audits/mutation-types'
import getDefaultState from './state'
const { setter } = createMutationCreator<AccountAuditsState>()

export default {
  ...setter(SET_AUDIT_SHOP, 'auditShop'),
  ...setter(SET_AUDIT_SHOP_FIELD_VALUES, 'auditShopFieldValues'),
  ...setter(SET_AUDIT_SHOP_SCHEMA, 'auditShopSchema'),
  [RESET_STATE](state: AccountAuditsState) {
    Object.assign(state, getDefaultState())
  }
} as MutationTree<AccountAuditsState>
