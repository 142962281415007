import { interopDefault } from '~/router/utils'
const TradesIndex = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/index.vue' /* webpackChunkName: "pages-shared-trades-index.vue" */
    )
  )
const TradesPage = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/trades.vue' /* webpackChunkName: "pages-shared-trades-trades.vue" */
    )
  )
const TradesSwipe = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/swipe.vue' /* webpackChunkName: "pages-shared-trades-swipe.vue" */
    )
  )
const TradesMatches = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/matches.vue' /* webpackChunkName: "pages-shared-trades-matches.vue" */
    )
  )
const TradesLikes = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/likes.vue' /* webpackChunkName: "pages-shared-trades-likes.vue" */
    )
  )
const TradesMessages = () =>
  interopDefault(
    import(
      '~/pages/shared/trades/messages.vue' /* webpackChunkName: "pages-shared-trades-messages.vue" */
    )
  )
export default [
  {
    name: '__trades',
    path: '/trades/',
    meta: { noFooter: false, inTrades: true },
    component: TradesIndex
  },
  {
    name: '__trades_page',
    path: '/trades/:cid',
    meta: { noFooter: true, inTrades: true },
    component: TradesPage,
    redirect: { name: '__trades_swipe' },
    children: [
      {
        name: '__trades_swipe',
        path: '/trades/:cid/swipe',
        meta: { noFooter: true, inTrades: true },
        component: TradesSwipe
      },
      {
        name: '__trades_matches',
        path: '/trades/:cid/matches',
        meta: { noFooter: true, inTrades: true },
        component: TradesMatches
      },
      {
        name: '__trades_likes',
        path: '/trades/:cid/likes',
        meta: { noFooter: true, inTrades: true },
        component: TradesLikes
      },
      {
        name: '__trades_liked',
        path: '/trades/:cid/liked',
        meta: { noFooter: true, inTrades: true },
        redirect: {
          name: '__trades_likes'
        }
      },
      {
        name: '__trades_liked-by',
        path: '/trades/:cid/liked-by',
        meta: { noFooter: true, inTrades: true },
        redirect: {
          name: '__trades_likes',
          query: { 'sub-tab': 'likedBy' }
        }
      },
      {
        name: '__trades_messages',
        path: '/trades/:cid/thread/:thread',
        meta: { noFooter: true, inTrades: true },
        component: TradesMessages
      }
    ]
  }
]
