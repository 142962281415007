import { interopDefault } from '~/router/utils'

export const adminSearchLogsRoutes = [
  {
    name: '__admin_search_logs',
    path: '/admin/search/logs/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/logs/search.vue' /* webpackChunkName: "pages-admin-search-logs" */
        )
      ),
    meta: { noFooter: true }
  }
]
