import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__filters_search',
    path: '/classifieds/:category/search/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/search/filters.vue' /* webpackChunkName: "pages-car-classifieds-filters.vue" */
        )
      ),
    meta: { noFooter: true }
  }
]
