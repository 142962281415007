












import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    tag: { type: String, default: 'div' },
    fluid: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false }
  },
  setup() {
    return {}
  }
})
