import { CIcon } from '~/icons/types'
export const ciPlus: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 3a1.5 1.5 0 0 1 1.5 1.5v6h6a1.5 1.5 0 0 1 0 3h-6v6a1.5 1.5 0 0 1-3 0v-6h-6a1.5 1.5 0 0 1 0-3h6v-6A1.5 1.5 0 0 1 12 3Z'
    }
  ],
  name: 'plus',
  type: 'solid'
}
