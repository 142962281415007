import { ValueOf } from 'type-fest'
import { MutationTree } from 'vuex'
import { MutationCreator, OneToOneSetterCreator } from '~/models/store/factory'

function createOneToOneSetter<S, K extends keyof S>(
  type: string,
  stateKey: K
): MutationTree<S> {
  return {
    [type](state: S, payload: ValueOf<S, K>) {
      state[stateKey] = payload
    }
  }
}

function createOneToOneSetterCreator<S>(): OneToOneSetterCreator<S> {
  return <K extends keyof S>(type: string, stateKey: K) =>
    createOneToOneSetter<S, K>(type, stateKey)
}

export function createMutationCreator<S>(): MutationCreator<S> {
  return {
    setter: createOneToOneSetterCreator<S>()
  }
}

export const setterCreator = createOneToOneSetterCreator
