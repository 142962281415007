import { Field, Path } from '~/models/dealer/integration/classified/mapping'
import {
  SET_PATHS,
  SET_PATHS_LOADING,
  SET_MAPPINGS_LOADING,
  SET_MAPPINGS,
  DELETE_MAPPING,
  ADD_MAPPING,
  SET_OUTLET_META
} from '~/store/modules/shared/dealers/integration/classifieds/field/mutation-types'
import { DealerClassifiedFieldState } from '~/store/modules/shared/dealers/integration/classifieds/field/state'
import { ActionTreeWithRootState } from '~/store/types'
import DealerClassifiedFieldService from '~/services/dealers/integration/DealerClassifiedFieldService'

export default {
  async initialize({ dispatch, state }, dealerId: number) {
    await dispatch('loadPaths', dealerId)
    await dispatch('loadMappings', dealerId || state.dealerId)
  },
  /**
   * Loads outlet meta too.
   */
  async loadPaths({ commit }, dealerId: number) {
    commit(SET_PATHS_LOADING, true)
    try {
      const [paths, outletMeta] = await this.$dep(
        DealerClassifiedFieldService
      ).getPaths(dealerId)

      commit(SET_PATHS, paths)
      commit(SET_OUTLET_META, outletMeta)
    } catch (e) {
      this.$logger.captureError(e)
      this.$error({
        message: e.response?.data?.error || e.message,
        statusCode: e.response?.status
      })
    } finally {
      commit(SET_PATHS_LOADING, false)
    }
  },

  async loadMappings({ commit }, dealerId: number) {
    commit(SET_MAPPINGS_LOADING, true)
    try {
      const mappings = await this.$dep(
        DealerClassifiedFieldService
      ).getMappings(dealerId)
      commit(SET_MAPPINGS, mappings)
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_MAPPINGS_LOADING, false)
    }
  },

  async createOrUpdateMapping(
    { dispatch, commit, state },
    { field, path }: { field: Field; path: Path }
  ) {
    if (typeof state.dealerId === 'undefined') {
      this.$logger.captureError(new TypeError('State dealerId undefined'))
      return
    }
    const classifiedFieldService = this.$dep(DealerClassifiedFieldService)
    try {
      await classifiedFieldService.createOrUpdateMapping(
        state.dealerId,
        field,
        path
      )
      commit(ADD_MAPPING, { field, path })
      if (
        [...state.mappings.values()].some(
          // if updated field is dependency, request all mappings since some may have changed from backend.
          m => {
            return (
              m.field !== field &&
              classifiedFieldService.getMappingDependencies(m.field).has(field)
            )
          }
        )
      ) {
        await dispatch('loadMappings', state.dealerId)
      }
    } catch (e) {
      this.$logger.captureError(e)
    }
  },

  async deleteMapping({ commit, state: { mappings, dealerId } }, field: Field) {
    if (typeof dealerId === 'undefined') {
      this.$logger.captureError(new TypeError('State outletId undefined'))
      return
    } else if (!mappings.has(field)) {
      return
    }

    try {
      await this.$dep(DealerClassifiedFieldService).deleteMapping(
        dealerId,
        field
      )
      commit(DELETE_MAPPING, field)
    } catch (e) {
      this.$logger.captureError(e)
    }
  }
} as ActionTreeWithRootState<DealerClassifiedFieldState>
