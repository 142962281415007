import { injectable } from '~/decorators/dependency-container'

@injectable()
export default class FunctionCache {
  private functionMap = new Map<string | symbol, Function>()

  cache<T extends Function = Function>(
    key: string | symbol,
    fnCreator: () => T
  ): T {
    if (this.functionMap.has(key)) {
      return this.functionMap.get(key) as T
    }

    const fn = fnCreator()
    this.functionMap.set(key, fn)
    return fn
  }
}
