import { CIcon } from '~/icons/types'
export const ciTimesCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6Zm3.2 11.2c.5.5.5 1.2 0 1.7s-1.2.5-1.7 0L12 13.7l-1.6 1.6c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l1.6-1.6-1.6-1.6c-.5-.5-.5-1.2 0-1.7s1.2-.5 1.7 0l1.6 1.6 1.6-1.6c.5-.5 1.2-.5 1.7 0s.5 1.2 0 1.7L13.7 12l1.5 1.6Z'
    }
  ],
  name: 'times-circle',
  type: 'solid'
}
