import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const adminSearchConfig: SearchConfig = Object.freeze({
  settings: {
    isAdminSearch: true,
    galleryLgPerRow: 4,
    viewType: {
      availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
      defaultViewTypeDesktop: ViewType.LIST,
      defaultViewTypeMobile: ViewType.LIST
    },
    showAdminMassActions: true,
    searchbar: {
      autocompleteUrl: '/api/admin/classifieds/query/'
    },
    hideSubtitle: true,
    hasSearchStats: true,
    rowsTarget: 'admin-classified-view',
    disableShowSellers: true
  },
  rootParams: {
    admin: ['1']
  }
})
