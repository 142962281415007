import { MutationTree } from 'vuex'
import { SearchActionsState } from './state'
import { SET_NO_CREDITS_FOR_CLASSIFIED, SET_PENDING } from './mutation-types'

export default {
  [SET_PENDING](state, pendingValue: boolean) {
    state.pending = pendingValue
  },
  [SET_NO_CREDITS_FOR_CLASSIFIED](state, classifiedId: number) {
    state.noCreditsForClassified = classifiedId
  }
} as MutationTree<SearchActionsState>
