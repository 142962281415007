import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const myClassifiedsSearchConfig: SearchConfig = Object.freeze({
  settings: {
    viewType: {
      availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
      defaultViewTypeDesktop: ViewType.LIST,
      defaultViewTypeMobile: ViewType.LIST
    },
    searchName: 'list',
    showAdminMassActions: false,
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/my/query/',
      usesNuxtLinks: false
    },
    displayAds: true
  },
  rootParams: {
    sort: ['dm'],
    myclassifieds: ['1'],
    category: [20001]
  }
})
