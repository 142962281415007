import qs from 'qs'
import { ActionTreeWithRootState } from '~/store/types'
import {
  ADD_DEALERS,
  INCREMENT_PAGE,
  RESET_PARAMS,
  SET_DEALER_DATA,
  SET_HAS_REACHED_LAST_PAGE,
  SET_LOADING
} from './mutation-types'
import { DealersMapState } from './state'
import DealersMapService from '~/services/dealers/map/DealersMapService'

export default {
  // TODO: This is dead code and should be either omitted or used
  clearAll({ dispatch, commit }) {
    commit(RESET_PARAMS, {})
    dispatch('search')
  },
  async changePage({ state, commit }) {
    commit(INCREMENT_PAGE)
    commit(SET_HAS_REACHED_LAST_PAGE, false)
    const { data } = await this.$dep(DealersMapService).getDealersMapInfo({
      ...state.params
    })
    const { rows } = data.results
    if (rows.length > 0) {
      commit(ADD_DEALERS, rows)
    } else {
      commit(SET_HAS_REACHED_LAST_PAGE, true)
    }
  },
  async search({ state, commit }) {
    commit(SET_LOADING, true)
    const { data, page } = await this.$dep(DealersMapService).getDealersMapInfo(
      {
        ...state.params
      }
    )
    if (process.client) {
      const query = qs.parse(page.query)
      delete query.pg
      // @ts-ignore
      this.$router.replace({ query }).catch((_: any) => {})
    }
    commit(SET_DEALER_DATA, data)
    commit(SET_LOADING, false)
  }
} as ActionTreeWithRootState<DealersMapState>
