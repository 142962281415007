import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__account_wanted_parts',
    path: '/account/wanted-parts/',
    redirect: { name: '__account_wanted_parts_my_classifieds' },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/wanted-parts/index.vue' /* webpackChunkName: "pages-account-wanted-parts-messages.vue" */
        )
      ),
    children: [
      {
        name: '__account_wanted_parts_my_classifieds',
        path: '/account/wanted-parts/my-classifieds/',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/wanted-parts/my-classifieds.vue' /* webpackChunkName: "pages-account-wanted-parts-my-classifieds.vue" */
            )
          )
      },
      {
        name: '__account_wanted_parts_messages',
        path: '/account/wanted-parts/messages/',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/wanted-parts/messages.vue' /* webpackChunkName: "pages-account-wanted-parts-messages.vue" */
            )
          )
      },
      {
        name: '__account_wanted_parts_favorites_search',
        path: '/account/wanted-parts/favorites-search/',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/wanted-parts/favorite-search/list.vue' /* webpackChunkName: "pages-account-wanted-parts-favorites-search.vue" */
            )
          )
      },
      {
        name: '__account_wanted_parts_favorites_search_new',
        path: '/account/wanted-parts/favorites-search/new',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/wanted-parts/favorite-search/new.vue' /* webpackChunkName: "pages-account-wanted-parts-favorites-search-new.vue" */
            )
          )
      }
    ]
  }
]
