import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dispatched_fieldset',
    path: '/dispatched-form/fieldset',
    meta: {
      isIframe: true,
      noFooter: true
    },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/form/dispatched/dispatched-fieldset.vue' /* webpackChunkName: "pages/classifieds/form/dispatched/dispatched-fieldset.vue" */
        )
      )
  }
]
