import { container } from 'tsyringe'
import { indexSuffixKeyGetter } from '~/builders/store/collection/internal/PartitionedCollectionMutationBuilder'
import { PartitionedCollectionStoreBuilder } from '~/builders/store/collection/PartitionedCollectionStoreBuilder'
import { PartitionedMap } from '~/models/collection/PartitionedMap'
import {
  CategoryMappingId,
  CategoryMapping
} from '~/models/dealer/integration/category/mapping'
import { DealerIntegrationCategoriesState } from '~/store/modules/shared/dealers/integration/categories/state'

const partitionLength = 800

export const partitionedMap = <K, V>() =>
  container
    .resolve<PartitionedMap<K, V>>(PartitionedMap)
    .setPartitionLength(partitionLength)

const partitionedCollectionStoreBuilder = () =>
  container
    .resolve<
      PartitionedCollectionStoreBuilder<
        DealerIntegrationCategoriesState,
        Map<CategoryMappingId, CategoryMapping>,
        CategoryMapping,
        CategoryMappingId
      >
    >(PartitionedCollectionStoreBuilder)
    .setNumberOfPartitions(16)
    .setPartitionLength(partitionLength)

export const partitionedMapMutation = (key: string, stateBaseKey: string) =>
  partitionedCollectionStoreBuilder()
    .mutation()
    .setMutationKey(key)
    .setPartitionStateKeyGetter(
      indexSuffixKeyGetter(
        stateBaseKey as keyof DealerIntegrationCategoriesState
      )
    )
    .build()

export const partitionedMapInitialState = (baseKey: string) =>
  partitionedCollectionStoreBuilder()
    .initialState()
    .setPartitionStateKeyGetter(indexSuffixKeyGetter(baseKey))
    .build()

export const storePartitionedMapBuilder = (stateBaseKey: string) =>
  partitionedCollectionStoreBuilder()
    .partitionedCollection()
    .setPartitionStateKeyGetter(indexSuffixKeyGetter(stateBaseKey))
    .setCollectionConstructor(PartitionedMap)
