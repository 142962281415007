import { APP_NS } from '~/store/modules/shared/app/state'
import { getRouteMetaItem } from '~/utils/router'
import { USER_NS } from '~/store/modules/shared/user/state'

export default ({ store, route }: any) => {
  const isIframe = Boolean(getRouteMetaItem(route, 'isIframe'))
  const isProduction = process.env.NODE_ENV === 'production'
  const isDevClarityEnabled = process.env.DEV_CLARITY_RECORDING === '1'
  const isClarityEnabled = !isIframe && (isProduction || isDevClarityEnabled)

  if (!isClarityEnabled) {
    return
  }

  const isCar = store.getters[`${APP_NS}/isCar`]
  ;(function(c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function() {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    // @ts-ignore
    t = l.createElement(r)
    // @ts-ignore
    t.async = 1
    // @ts-ignore
    t.src = 'https://www.clarity.ms/tag/' + i
    // @ts-ignore
    y = l.getElementsByTagName(r)[0]
    // @ts-ignore
    y.parentNode.insertBefore(t, y)
  })(window, document, 'clarity', 'script', isCar ? 'jjn6e1b6fw' : 'jjnac9wrp8')

  if (window.clarity && typeof window.clarity === 'function') {
    window.clarity('set', 'usertype', store.state.user.type)

    const userIsAnon = store.getters[`${USER_NS}/isAnon`]
    if (!userIsAnon) {
      window.clarity('set', 'username', store.state.user.username)
      window.clarity('set', 'userid', store.state.user.id)
    }
  }
}
