import { interopDefault } from '~/router/utils'
import { smoothenRoutesScroll } from '~/utils/router'

export const auditsAuditorsRoutes = smoothenRoutesScroll([
  {
    path: 'audits/auditors',
    name: '__account_audits_auditors',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/auditors/index.vue' /* webpackChunkName: "pages-shared-account-audits-auditors-index.vue" */
        )
      )
  },
  {
    path: 'audits/auditors/new',
    name: '__account_audits_auditors_new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/auditors/new.vue' /* webpackChunkName: "pages-shared-account-audits-auditors-new.vue" */
        )
      )
  },
  {
    path: 'audits/auditors/:auditorId/edit',
    name: '__account_audits_auditors_edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/auditors/edit.vue' /* webpackChunkName: "pages-shared-account-audits-auditors-edit.vue" */
        )
      )
  }
])
