import { container } from 'tsyringe'
import { constructor } from 'tsyringe/dist/typings/types'
import { injectable } from '~/decorators/dependency-container'
import { IPartitionedCollection } from '~/models/collection/IPartitionedCollection'
import { IndexedKeyGetter } from '~/models/store/collection'

@injectable()
export class PartitionedCollectionBuilder<T, I, K> {
  private partitionStateKeyGetter: IndexedKeyGetter | undefined
  private numberOfPartitions: number | undefined
  private partitionLength: number | undefined
  private collectionConstructor:
    | constructor<IPartitionedCollection<T, I, K>>
    | undefined

  setNumberOfPartitions(value: number) {
    this.numberOfPartitions = value
    return this
  }

  setPartitionLength(value: number) {
    this.partitionLength = value
    return this
  }

  setPartitionStateKeyGetter(value: IndexedKeyGetter | undefined) {
    this.partitionStateKeyGetter = value
    return this
  }

  setCollectionConstructor(
    value: constructor<IPartitionedCollection<T, I, K>> | undefined
  ) {
    this.collectionConstructor = value
    return this
  }

  buildFromState<S>(state: S): IPartitionedCollection<T, I, K> {
    const {
      partitionStateKeyGetter,
      numberOfPartitions,
      partitionLength,
      collectionConstructor
    } = this

    const partitions: T[] = []

    for (let i = 0; i < numberOfPartitions!; i++) {
      // @ts-ignore
      partitions[i] = state[partitionStateKeyGetter(i)]
    }

    return container
      .resolve(collectionConstructor!)
      .setPartitionLength(partitionLength!)
      .setCollectionFromPartitions(partitions)
  }
}
