import { Analytics } from '~/models/dealer/site/types'
import { DEALER_SITE_NS } from '../state'

export interface DealerSiteAnalyticsState {
  google: Partial<Analytics['google']>
}

export const DEALER_SITE_ANALYTICS_NS = `${DEALER_SITE_NS}/analytics`

export default function getDefaultState(): DealerSiteAnalyticsState {
  return {
    google: {
      code: undefined,
      codeV4: undefined
    }
  }
}
