import { LatLng } from 'leaflet'

// TODO: Move this to service
export function loadLeaflet() {
  return new Promise(resolve => {
    if (window.L) {
      return resolve(window.L)
    }
    import('leaflet').then(Leaflet => {
      // @ts-ignore
      delete Leaflet.Icon.Default.prototype._getIconUrl
      Leaflet.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
      return resolve(Leaflet)
    })
  })
}
export function addButton({ L, map }: any, button: any) {
  L.Control.AddBtn = L.Control.extend({
    options: {
      position: button.position
    },
    onAdd() {
      const controlUI = L.DomUtil.create(
        'a',
        'leaflet-draw-toolbar leaflet-bar'
      )
      L.DomEvent.disableClickPropagation(controlUI)

      controlUI.onclick = function(e: Event) {
        e.preventDefault()
        button.callback()
      }

      controlUI.title = button.title
      controlUI.href = '#'
      controlUI.innerHTML = button.html
      Object.entries(button.styles).forEach(entry => {
        const styleKey = entry[0]
        const styleValue = entry[1]
        controlUI.style[styleKey] = styleValue
      })
      L.DomEvent.disableClickPropagation(controlUI)

      return controlUI
    }
  })

  const btn = new L.Control.AddBtn()
  map.addControl(btn)
  return btn
}

export function bounceToString(northEast: LatLng, southWest: LatLng) {
  return `${Object.values(northEast).join('x')},${Object.values(southWest).join(
    'x'
  )}`
}
export function stringToBounce(geolocation: string): Array<Array<number>> {
  const bounds: Array<Array<number>> = []
  geolocation.split(',').forEach(b => {
    const tmp = b.split('x')
    bounds.push([parseFloat(tmp[0]), parseFloat(tmp[1])])
  })

  return bounds
}

export function getBounceFromPositions(positions: []) {
  const latLngs = positions.map(position => {
    return window.L.latLng(position[0], position[1])
  })
  return window.L.latLngBounds(latLngs)
}
