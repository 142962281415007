import {
  ADD_DEALERS,
  INCREMENT_PAGE,
  RESET_PARAMS,
  SET_ACTIVE_DEALER,
  SET_ACTIVE_GEOLOCATION,
  SET_DEALER_DATA,
  SET_HAS_REACHED_LAST_PAGE,
  SET_HOVERED_DEALER,
  SET_LOADING,
  SET_MOBILE_LIST_VISIBLE,
  SET_PARAM,
  SET_PARAMS,
  TOGGLE_MOBILE_FACETS_VISIBLE,
  TOGGLE_MOBILE_LIST_VISIBLE
} from './mutation-types'
import { DealersMapState } from './state'
import { Params, DealersMapResult } from '~/models/dealer/map/types'
import { Dealer } from '~/models/dealer/types'

export default {
  [SET_DEALER_DATA](state: DealersMapState, data: DealersMapResult) {
    state.sortOptions = data.sorting
    state.categoriesCounts = data.facets.categories
    state.pagination = data.results.pagination
    state.rows = data.results.rows
    state.markers = data.markers
    state.totalDealers = data.results.pagination.total
  },
  [SET_PARAMS](state: DealersMapState, params: Params) {
    state.params = params
  },
  [SET_PARAM](
    state: DealersMapState,
    { name, value }: { name: string; value: any }
  ) {
    ;(state.params as any)[name] = value
  },
  [INCREMENT_PAGE](state: DealersMapState) {
    state.params.pg = Number(state.params.pg) + 1
  },
  [SET_LOADING](state: DealersMapState, value: boolean) {
    state.loading = value
  },
  [ADD_DEALERS](state: DealersMapState, dealers: Dealer[]) {
    state.rows.push(...dealers)
  },
  [SET_HAS_REACHED_LAST_PAGE](state: DealersMapState, value: boolean) {
    state.hasReachedLastPage = value
  },
  [SET_MOBILE_LIST_VISIBLE](state: DealersMapState, value: boolean) {
    state.mobile.listVisible = value
  },
  [SET_HOVERED_DEALER](state: DealersMapState, dealer: Dealer) {
    state.hoveredDealer = dealer
  },
  [SET_ACTIVE_DEALER](state: DealersMapState, dealer: Dealer) {
    state.activeDealer = dealer
  },
  [SET_ACTIVE_GEOLOCATION](state: DealersMapState, geolocation: any) {
    state.activeGeolocation = geolocation
  },
  [TOGGLE_MOBILE_FACETS_VISIBLE](state: DealersMapState) {
    state.mobile.facetsVisible = !state.mobile.facetsVisible
  },
  [TOGGLE_MOBILE_LIST_VISIBLE](state: DealersMapState) {
    state.mobile.listVisible = !state.mobile.listVisible
  },
  [RESET_PARAMS](state: DealersMapState) {
    state.params.ca = []
    state.params.pg = 1
    state.params.q = null
    state.params.postcode = null
    state.params.distance = null
  }
}
