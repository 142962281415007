import { Search } from '~/router/routes/car/classifieds/search/components'

export const wantedPartsRoutes = [
  {
    name: '__classifieds_search_wanted_parts',
    path: '/wanted-parts/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_wanted_parts_seo',
    path: '/wanted-parts/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  }
]
