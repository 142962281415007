import {
  FIVE_MINUTES_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
  TEN_MINUTES_MS
} from '~/constants/duration'
import { CacheableUrl } from './types'

export const cacheableUrls: readonly CacheableUrl[] = Object.freeze([
  {
    regex: /\/api\/clsfds\/search\//,
    maxAge: ONE_HOUR_MS
  },
  // {
  //   regex: /\/api\/seo\/parse-url\//,
  //   maxAge: ONE_HOUR_MS
  // },
  {
    regex: /\/api\/(\d+)\/$/,
    maxAge: ONE_MINUTE_MS
  },
  {
    regex: /\/api\/qna\/categories\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/qna\/search\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/discounts\/available\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/classifieds\/text-search\//,
    maxAge: FIVE_MINUTES_MS
  }
])
