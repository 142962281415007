import { createModule } from '~/factories/store'
import actions from '~/store/modules/shared/storage/actions'
import getters from '~/store/modules/shared/storage/getters'
import mutations from '~/store/modules/shared/storage/mutations'
import state from '~/store/modules/shared/storage/state'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
