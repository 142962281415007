import once from 'lodash.once'
import { Merge } from 'type-fest'
import LoggerService from '~/services/LoggerService'

type ObjectWithLogger = Merge<any, { logger: LoggerService }>

export function logError(getMessage?: (error: Readonly<Error>) => string) {
  // eslint-disable-next-line no-console
  const warnOnce = once((message: string) => console.warn(message))

  return (
    target: ObjectWithLogger,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    const originalFunction = descriptor.value

    descriptor.value = function(...args: any[]) {
      const callOriginalFunction = () => originalFunction.apply(this, args)

      try {
        return callOriginalFunction()
      } catch (e) {
        const obj = this as ObjectWithLogger
        if (!obj.logger) {
          warnOnce(
            `${target.constructor.name}.${propertyKey}: Undefined logger`
          )
          throw e
        }

        if (getMessage) {
          e.message = getMessage(e)
        }
        obj.logger.captureError(e)
      }
    }
  }
}
