import { Merge } from 'type-fest'
import { MutationTree } from 'vuex'
import { ImmutableMap } from '~/factories/mutation/map'
import {
  CategoryMapping,
  CategoryMappingId,
  TransitInternalCategoryCreationPayload,
  InternalCategoryIdentifier,
  TransitInternalCategoryDeletionPayload
} from '~/models/dealer/integration/category/mapping'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  partitionedMapMutation,
  storePartitionedMapBuilder,
  partitionedMap
} from '~/store/modules/shared/dealers/integration/categories/factories'
import {
  SET_MAPPINGS,
  PREPEND_MAPPINGS,
  SET_MAPPING,
  SET_PAGE,
  DELETE_MAPPING,
  SET_MAPPING_FILTER_TEXT,
  SET_FILTERED_MAPPINGS,
  ADD_TRANSIT_INTERNAL_CATEGORY,
  DELETE_TRANSIT_INTERNAL_CATEGORY,
  SET_LAST_PICKER_SELECTED_CATEGORY,
  SET_UNMAPPED_CATEGORY_SCAN_ERRORS,
  SET_ERROR_ALERT_VISIBILITY,
  SET_MARKED_CATEGORY_MAPPING_IDS,
  SET_ALL_CATEGORIES_MAPPED
} from '~/store/modules/shared/dealers/integration/categories/mutation-types'
import { DealerIntegrationCategoriesState } from '~/store/modules/shared/dealers/integration/categories/state'

const { setter } = createMutationCreator<DealerIntegrationCategoriesState>()

const mappingsSetterMutationTree = partitionedMapMutation(
  SET_MAPPINGS,
  'mappings'
)
const setMappings = mappingsSetterMutationTree[SET_MAPPINGS]
const filteredMappingsSetterMutationTree = partitionedMapMutation(
  SET_FILTERED_MAPPINGS,
  'filteredMappings'
)
const setFilteredMappings =
  filteredMappingsSetterMutationTree[SET_FILTERED_MAPPINGS]

export default {
  ...mappingsSetterMutationTree,
  [SET_MAPPING](state, mapping: Merge<CategoryMapping, { index?: number }>) {
    const { index } = mapping
    const stateMappings = storePartitionedMapBuilder('mappings').buildFromState(
      state
    )
    if (typeof index === 'undefined') {
      setMappings(state, stateMappings.set(mapping.id, mapping).getCollection())
      return
    }

    const mappings = partitionedMap<CategoryMappingId, CategoryMapping>()
    const stateMappingEntries: Array<[CategoryMappingId, CategoryMapping]> = [
      ...stateMappings.getCollection().entries()
    ]
    const size = stateMappingEntries.length
    for (let i = 0; i < size; i++) {
      const [id, mapping_] = stateMappingEntries[i]
      index === i && mappings.set(mapping.id, mapping)

      mappings.set(id, mapping_)
    }

    index === size && mappings.set(mapping.id, mapping) // overflowing index implies item append

    setMappings(state, mappings.getCollection())
  },
  [DELETE_MAPPING](state, id: string) {
    const mappings = storePartitionedMapBuilder('mappings').buildFromState(
      state
    )
    mappings.delete(id)
    setMappings(state, mappings.getCollection())

    const filteredMappings = storePartitionedMapBuilder(
      'filteredMappings'
    ).buildFromState(state)

    if (filteredMappings.has(id)) {
      filteredMappings.delete(id)
      setFilteredMappings(state, filteredMappings.getCollection())
    }
  },
  [PREPEND_MAPPINGS](state, mappings: Map<CategoryMappingId, CategoryMapping>) {
    const stateMappings = storePartitionedMapBuilder('mappings').buildFromState(
      state
    )
    for (const m of mappings.values()) {
      for (const sm of stateMappings.getCollection().values()) {
        m.externalName === sm.externalName &&
          m.externalId === sm.externalId &&
          stateMappings.delete(sm.id)
      }
    }

    setMappings(
      state,
      partitionedMap<CategoryMappingId, CategoryMapping>()
        .setCollection(mappings)
        .extend(stateMappings)
        .getCollection()
    )
  },
  ...setter(SET_PAGE, 'pageNumber'),
  ...setter(SET_MAPPING_FILTER_TEXT, 'mappingFilterText'),
  ...filteredMappingsSetterMutationTree,
  [ADD_TRANSIT_INTERNAL_CATEGORY](
    state,
    { mappingId, categoryIdentifier }: TransitInternalCategoryCreationPayload
  ) {
    const internalCategoriesOfMapping =
      state.transitInternalCategories.get(mappingId) ||
      new Map<number, InternalCategoryIdentifier>()

    state.transitInternalCategories = ImmutableMap.set(
      state.transitInternalCategories,
      mappingId,
      internalCategoriesOfMapping.set(categoryIdentifier.id, categoryIdentifier)
    )
  },
  [DELETE_TRANSIT_INTERNAL_CATEGORY](
    state,
    { mappingId, categoryId }: TransitInternalCategoryDeletionPayload
  ) {
    const internalCategoriesOfMapping = state.transitInternalCategories.get(
      mappingId
    )
    if (!internalCategoriesOfMapping) {
      return
    }

    internalCategoriesOfMapping.delete(categoryId)
    state.transitInternalCategories = ImmutableMap.set(
      state.transitInternalCategories,
      mappingId,
      internalCategoriesOfMapping
    )
  },
  ...setter(SET_LAST_PICKER_SELECTED_CATEGORY, 'lastPickerSelectedCategory'),
  ...setter(SET_UNMAPPED_CATEGORY_SCAN_ERRORS, 'unmappedCategoryScanErrors'),
  ...setter(SET_ERROR_ALERT_VISIBILITY, 'showErrorAlert'),
  ...setter(SET_ALL_CATEGORIES_MAPPED, 'allCategoriesMapped'),
  ...setter(SET_MARKED_CATEGORY_MAPPING_IDS, 'markedCategoryMappingIds')
} as MutationTree<DealerIntegrationCategoriesState>
