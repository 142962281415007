import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import {
  Path,
  Mapping,
  Field,
  ResponseMapping
} from '~/models/dealer/integration/classified/mapping'
import { OutletMeta } from '~/models/dealer/integration/classified/outlet'
import { StringMap } from '~/models/shared/types'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export default class DealerClassifiedFieldService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  getPaths(dealerId: number): Promise<[Path[], OutletMeta]> {
    return this.requestBuilder
      .request('get', `/api/xml/analyze/${dealerId}`)
      .validate(body => body?.data)
      .map(body => [body.data.paths, this.formatOutletMeta(body.data.meta)])
      .send()
  }

  private formatOutletMeta(meta: any): OutletMeta {
    return {
      // eslint-disable-next-line camelcase
      id: meta?.new_id?.toString(),
      legacyId: meta?.id?.toString(),
      name: meta?.name
    }
  }

  getMappings(dealerId: number): Promise<StringMap<Mapping>> {
    return this.requestBuilder
      .request('get', `/api/panel/xml/${dealerId}/field-map/`)
      .validate(body => body?.data)
      .map(
        body =>
          new Map(
            body.data.mappings
              .filter((m: ResponseMapping) => m.path)
              .map((m: Mapping) => [m.field, m])
          )
      )

      .send()
  }

  createOrUpdateMapping(
    dealerId: string,
    field: Field,
    path: Path
  ): Promise<Mapping> {
    return this.requestBuilder
      .request('post', `/api/panel/xml/${dealerId}/field-map/`)
      .data({ field, path })
      .validate(body => body?.data)
      .send()
  }

  deleteMapping(dealerId: string, field: Field): Promise<void> {
    return this.requestBuilder
      .request('post', `/api/panel/xml/${dealerId}/field-map/`)
      .data({ field, path: null })
      .validate(body => body?.data)
      .send()
  }

  getMappingDependencies(field: Field): Set<Field> {
    const deps = new Set<Field>()
    if (![Field.product, Field.lastUpdate].includes(field)) {
      deps.add(Field.product)
    }
    return deps
  }

  private readonly requiredFields = new Set<Field>([
    Field.product,
    Field.uniqueId,
    Field.categoryName,
    Field.title,
    Field.price
  ])

  fieldIsRequired(field: Field): boolean {
    return this.requiredFields.has(field)
  }
}
