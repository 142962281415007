import { LoginModalState } from './state'

import {
  SET_LOGIN_MODAL_VISIBILITY,
  SET_UPDATE_LOGIN_STATUS,
  REDIRECT_AFTER_LOGIN
} from './mutation-types'

export default {
  [SET_LOGIN_MODAL_VISIBILITY](state: LoginModalState, isVisible: boolean) {
    state.isLoginModalVisible = isVisible
  },
  [SET_UPDATE_LOGIN_STATUS](state: LoginModalState, isLoginSuccess: boolean) {
    state.isLoginSuccess = isLoginSuccess
  },
  [REDIRECT_AFTER_LOGIN](state: LoginModalState, redirectAfterLogin: boolean) {
    state.redirectAfterLogin = redirectAfterLogin
  }
}
