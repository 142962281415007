import { interopDefault } from '~/router/utils'

const ExternalCertificatesDocs = () =>
  interopDefault(
    import(
      '~/pages/car/external-certificates/external-certificates-docs.vue' /* webpackChunkName: "pages/external-certificates/external-certificates-docs.vue" */
    )
  )

const ExternalCertificatesForm = () =>
  interopDefault(
    import(
      '~/pages/car/external-certificates/external-certificates-form.vue' /* webpackChunkName: "pages/external-certificates/external-certificates-form.vue" */
    )
  )

const ExternalCertificatesPdf = () =>
  interopDefault(
    import(
      '~/pages/car/external-certificates/external-certificates-pdf.vue' /* webpackChunkName: "pages/external-certificates/external-certificates-pdf.vue" */
    )
  )

const ExternalCertificatesDummy = () =>
  interopDefault(
    import(
      '~/pages/car/external-certificates/external-certificates-dummy.vue' /* webpackChunkName: "pages/external-certificates/external-certificates-dummy.vue" */
    )
  )

export default [
  {
    name: '__external_certificates_docs',
    path: '/vehicles/certificates/external/docs',
    component: ExternalCertificatesDocs
  },
  {
    name: '__external_certificates_add',
    path: '/vehicles/certificates/external/add',
    component: ExternalCertificatesForm
  },
  {
    name: '__external_certificates_edit',
    path: '/vehicles/certificates/external/:reportId/edit',
    component: ExternalCertificatesForm
  },
  {
    name: '__external_certificates_pdf',
    path: '/audits/external-certificates/public/:pid/',
    component: ExternalCertificatesPdf,
    meta: {
      noHeader: true,
      noFooter: true,
      isIframe: true
    }
  },
  {
    name: '__external_certificates_dummy',
    path: '/audits/external-certificates/dummy/',
    component: ExternalCertificatesDummy,
    meta: {
      noHeader: true,
      noFooter: true,
      isIframe: true
    }
  }
]
