import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'
import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { Geolocation } from '~/models/common/types'
import { removeWhiteSpaces } from '~/utils/string'
import { geolocationToGoogleGeolocation } from '~/utils/geolocation'
import { GeocodingProperties } from '~/models/location/geocoding'
import { defaultGoogleMapCircleOptions } from '~/constants/google/map'
import { Loader } from '@googlemaps/js-api-loader'
import { v4 as uuidV4 } from 'uuid'

@ClientOnly
@containerScoped()
export default class GoogleMapsService {
  constructor(@inject(VueI18n) private i18n: VueI18n) {}

  private geocoder!: google.maps.Geocoder
  private loaded = false

  public async loadGoogleMaps() {
    if (this.loaded) {
      return
    }

    const loader = new Loader({
      apiKey: process.env.GOOGLE_API_KEY as string,
      version: 'weekly',
      language: this.i18n.locale
    })

    await Promise.all([
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
      loader.importLibrary('places'),
      loader.importLibrary('geometry')
    ])

    this.geocoder = new window.google.maps.Geocoder()
    this.loaded = true
  }

  public async createAutocompleteInput(
    inputElement: HTMLInputElement,
    countryCodes: string[] = ['gr', 'cy', 'bg', 'al', 'tr', 'mk']
  ): Promise<google.maps.places.Autocomplete> {
    await this.loadGoogleMaps()
    return new google.maps.places.Autocomplete(inputElement, {
      componentRestrictions: {
        country: countryCodes
      }
    })
  }

  public async geocode(
    request: google.maps.GeocoderRequest
  ): Promise<google.maps.GeocoderResult[]> {
    await this.loadGoogleMaps()
    return new Promise((resolve, reject) => {
      this.geocoder.geocode(request, (results, status) => {
        if (
          status === google.maps.GeocoderStatus.ZERO_RESULTS ||
          !results ||
          results.length === 0
        ) {
          resolve([])
        } else if (status !== google.maps.GeocoderStatus.OK) {
          reject(new Error(`Geocoder returned an error status code: ${status}`))
        }
        resolve(results || [])
      })
    })
  }

  public extractAddress(
    geocoderResults: google.maps.GeocoderResult[] = []
  ): string {
    if (geocoderResults.length > 1) {
      return geocoderResults[0].formatted_address
    }
    return ''
  }

  public extractCountry(
    geocoderResults: google.maps.GeocoderResult[] = []
  ): string {
    const countryTypes = ['country']
    if (geocoderResults.length) {
      for (const result of geocoderResults) {
        const country = result.address_components.find(address =>
          this.addressHasOneOfTypes(address.types, countryTypes)
        )
        if (country) {
          return country.short_name
        }
      }
    }

    return ''
  }

  public extractCity(
    geocoderResults: google.maps.GeocoderResult[] = []
  ): string {
    const cityTypes = ['political', 'administrative_area_level_3']
    for (const result of geocoderResults) {
      const city = result.address_components.find(address =>
        this.addressHasOneOfTypes(address.types, cityTypes)
      )
      if (city) {
        return city.long_name
      }
    }
    return ''
  }

  public extractPostalCode(
    geocoderResults: google.maps.GeocoderResult[] = []
  ): string {
    const postalCodeTypes = ['postal_code']
    for (const result of geocoderResults) {
      const postalCode = result.address_components.find(address =>
        this.addressHasOneOfTypes(address.types, postalCodeTypes)
      )
      if (postalCode) {
        return removeWhiteSpaces(postalCode.long_name)
      }
    }
    return ''
  }

  public extractGeolocation(
    geocoderResults: google.maps.GeocoderResult[] = []
  ): Geolocation | undefined {
    for (const result of geocoderResults) {
      const geolocation: google.maps.GeocoderGeometry['location'] =
        result.geometry && result.geometry.location
      if (geolocation) {
        return { lat: geolocation.lat(), lon: geolocation.lng() }
      }
    }
  }

  private addressHasOneOfTypes(
    addressTypes: string[],
    types: string[]
  ): boolean {
    const typesIntersection = addressTypes.filter(x => types.includes(x))
    return typesIntersection.length > 0
  }

  public getCurrentGpsGeolocation(): Promise<Geolocation> {
    return new Promise((resolve, reject) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          })
        }, reject)
      } else reject(new Error('Can not find current gps lat and lng'))
    })
  }

  async createGoogleMap(
    element: HTMLElement,
    options?: google.maps.MapOptions
  ): Promise<google.maps.Map> {
    await this.loadGoogleMaps()
    return new google.maps.Map(element, {
      mapId: uuidV4(),
      ...options
    })
  }

  async addMarkerToGoogleMap(
    options?: google.maps.marker.AdvancedMarkerElementOptions
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    await this.loadGoogleMaps()
    return new google.maps.marker.AdvancedMarkerElement(options)
  }

  async addCircleToGoogleMap(
    map: google.maps.Map,
    radius: number,
    circleOptions: google.maps.CircleOptions = defaultGoogleMapCircleOptions
  ): Promise<google.maps.Circle> {
    await this.loadGoogleMaps()
    const circle = new google.maps.Circle({
      strokeColor:
        circleOptions.strokeColor ?? defaultGoogleMapCircleOptions.strokeColor,
      strokeOpacity:
        circleOptions.strokeOpacity ??
        defaultGoogleMapCircleOptions.strokeOpacity,
      strokeWeight:
        circleOptions.strokeWeight ??
        defaultGoogleMapCircleOptions.strokeWeight,
      fillColor:
        circleOptions.fillColor ?? defaultGoogleMapCircleOptions.fillColor,
      fillOpacity:
        circleOptions.fillOpacity ?? defaultGoogleMapCircleOptions.fillOpacity,
      map,
      radius,
      center: circleOptions.center
    })
    circle.setEditable(circleOptions.editable ?? false)
    return circle
  }

  async addListener(
    instance: any,
    eventName: string,
    handler: any = () => {}
  ): Promise<google.maps.MapsEventListener> {
    await this.loadGoogleMaps()
    return google.maps.event.addListener(instance, eventName, handler)
  }

  async removeListener(listener: google.maps.MapsEventListener): Promise<void> {
    await this.loadGoogleMaps()
    google.maps.event.removeListener(listener)
  }

  async getGeocodingProperties(
    geolocation: Geolocation
  ): Promise<GeocodingProperties> {
    const results = await this.geocode({
      location: geolocationToGoogleGeolocation(geolocation)
    })
    return {
      address: this.extractAddress(results),
      postcode: this.extractPostalCode(results),
      city: this.extractCity(results),
      country: this.extractCountry(results)
    }
  }
}
