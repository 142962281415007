/* eslint-disable no-undef */
import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import { DependencyContainer } from 'tsyringe'
import PageViewRecorder from '~/services/telemetry/PageViewRecorder'
import { getRouteMetaItem } from '~/utils/router'
import { Context } from '@nuxt/types'
import AnalyticsService from '~/services/AnalyticsService'

export default ({ route, $requestContainer }: any) => {
  initializeGoogleAnalytics(route, $requestContainer)
}

export function initializeGoogleAnalytics(
  route: Context['route'],
  container: DependencyContainer
) {
  const isIframeRoute = getRouteMetaItem(route, 'isIframe')
  if (isIframeRoute) {
    return
  }

  const analytics = container.resolve(AnalyticsService)
  analytics.initialize()

  const pageViewRecorder = container.resolve(PageViewRecorder)
  pageViewRecorder.recordPluginPageView()
}
