import { MutationTree } from 'vuex'
import {
  ADD_COMMENT,
  ADD_QUESTION,
  ADD_QUESTION_REACTION,
  DELETE_QUESTION,
  SET_CATEGORY_DATA,
  SET_LOADING_STATE,
  SET_MAKES_WITH_MODELS_DATA,
  SET_MAKE_DATA,
  SET_QUESTION_DATA,
  UPDATE_QUESTION,
  SET_MORE_COMMENT_REPLIES,
  SET_COMMENT_VISIBILITY,
  DELETE_COMMENT,
  SET_COMMENT_REACTION,
  ADD_COMMENT_REPLY,
  SET_FULL_QUESTION_DATA,
  UPDATE_COMMENT
} from './mutation-types'
import { QnaComment, QnaQuestion } from '~/models/qna/types'
import { Pagination } from '~/models/search/types'
import { QnaState } from './state'
import { MainCategory } from '~/models/main-category'
import { Make } from '~/models/make-model'
import { ReactionEnum } from '~/models/classified-list/types'
import { vue3Set } from '~/utils/nuxt3-migration'

export default {
  [SET_LOADING_STATE](
    state,
    {
      loadingVar,
      status
    }: {
      loadingVar: 'questions' | 'fullQuestion' | 'makesAndModels'
      status: boolean
    }
  ) {
    state.loading[loadingVar] = status
  },
  [SET_QUESTION_DATA](
    state,
    {
      questions,
      pagination
    }: { questions: Array<QnaQuestion>; pagination: Pagination }
  ) {
    state.questions = questions
    state.pagination = pagination
  },

  [SET_CATEGORY_DATA](
    state,
    data: {
      dropdownCategories: []
      categories: MainCategory[]
      tags: {}
      fuelTypes: {}
    }
  ) {
    state.dropdownCategories = data.dropdownCategories
    state.categories = data.categories
    state.tags = data.tags
    state.fuelTypes = data.fuelTypes
  },
  [SET_MAKES_WITH_MODELS_DATA](
    state,
    { categoryId, makesWithModels }: { categoryId: number; makesWithModels: [] }
  ) {
    const categoryMakeAndModels = state.makeAndModelsByCategory[categoryId]

    if (!categoryMakeAndModels) {
      return
    }

    const tempMakeAndModelsByCategory = updateMakes(
      categoryMakeAndModels.makes.slice(),
      makesWithModels
    )
    vue3Set(categoryMakeAndModels, 'makes', tempMakeAndModelsByCategory)
  },
  [SET_MAKE_DATA](
    state,
    { categoryId, makes }: { categoryId: number; makes: [] }
  ) {
    vue3Set(state.makeAndModelsByCategory, categoryId, makes)
  },

  [ADD_QUESTION](state, question: QnaQuestion) {
    state.questions = [question, ...state.questions]
    state.pagination.total = state.pagination.total + 1
  },

  [UPDATE_QUESTION](state, question: QnaQuestion) {
    const index = state.questions.findIndex(q => q.id === question?.id)
    if (state.fullQuestion.id === question?.id) {
      vue3Set(state, 'fullQuestion', question)
    }
    vue3Set(state.questions, index, question)
  },

  [DELETE_QUESTION](
    state,
    { questionId, isAdmin }: { questionId: string; isAdmin: boolean }
  ) {
    if (isAdmin) {
      const index = state.questions.findIndex(q => q.id === questionId)
      if (state.fullQuestion.id === questionId) {
        vue3Set(state.fullQuestion, 'isDeleted', !state.fullQuestion.isDeleted)
      } else {
        vue3Set(
          state.questions[index],
          'isDeleted',
          !state.questions[index].isDeleted
        )
      }
    } else {
      const questions = state.questions.filter(q => q.id !== questionId)
      state.questions = questions
      if (state.fullQuestion.id === questionId) {
        state.fullQuestion = { isDeleted: true } as QnaQuestion
      }
      state.pagination.total = state.pagination.total - 1
    }
  },

  [ADD_COMMENT](
    state,
    {
      questionId,
      comment,
      isFirstComment
    }: { questionId: string; comment: QnaComment; isFirstComment: boolean }
  ) {
    if (isFirstComment) {
      const index = state.questions.findIndex(q => q.id === questionId)
      vue3Set(state.questions[index], 'questionFirstComment', comment)
      vue3Set(
        state.questions[index],
        'questionReplies',
        state.questions[index].questionReplies + 1
      )
    } else {
      state.comments = state.comments.concat(comment)
    }
    if (state.fullQuestion?.id) {
      vue3Set(
        state.fullQuestion,
        'questionReplies',
        state.fullQuestion.questionReplies + 1
      )
    }
  },
  [ADD_QUESTION_REACTION](
    state,
    {
      questionId,
      reactionStatus,
      reaction
    }: { questionId: string; reactionStatus: boolean; reaction: ReactionEnum }
  ) {
    let question
    if (state.questions.length) {
      question = state.questions.find(q => q.id === questionId)
    } else if (state.fullQuestion?.id === questionId) {
      question = state.fullQuestion
    }

    if (reactionStatus && question) {
      const myReaction =
        question?.myReactions?.length > 0 ? question.myReactions[0] : null
      vue3Set(question, 'myReactions', [reaction])

      const reactions = question?.reactions
      if (myReaction && myReaction !== reaction) {
        reactions[myReaction]--
      }

      vue3Set(question, 'reactions', {
        ...reactions,
        [reaction]: reactions[reaction] ? reactions[reaction] + 1 : 1
      })
    } else if (!reactionStatus && question) {
      vue3Set(question, 'myReactions', [])

      const reactions = question.reactions
      vue3Set(question, 'reactions', {
        ...reactions,
        [reaction]: reactions[reaction] ? reactions[reaction] - 1 : 0
      })
    }

    if (state.fullQuestion && question) {
      vue3Set(state, 'fullQuestion', question)
      state.fullQuestion = question as QnaQuestion
    }
  },

  [SET_COMMENT_VISIBILITY](
    state,
    {
      commentId,
      visibilityStatus,
      isFirstComment,
      questionId
    }: {
      commentId: number
      visibilityStatus: boolean
      isFirstComment: boolean
      questionId: string
    }
  ) {
    let comment = {}
    if (isFirstComment && questionId) {
      const question = state.questions.find(q => q.id === questionId)
      if (question) {
        comment = question.questionFirstComment
      }
    } else {
      comment = findComment(state.comments, commentId) as QnaComment
    }
    vue3Set(comment, 'isDeleted', visibilityStatus)
  },

  [SET_MORE_COMMENT_REPLIES](
    state,
    { commentId, replies }: { commentId: number; replies: QnaComment }
  ) {
    const comment = findComment(state.comments, commentId) as QnaComment
    vue3Set(comment, 'replies', replies.replies)
    vue3Set(comment, 'repliesHasMore', false)
  },
  [UPDATE_COMMENT](
    state,
    {
      comment,
      isFirstComment,
      questionId
    }: { comment: QnaComment; isFirstComment: boolean; questionId: string }
  ) {
    if (isFirstComment && questionId) {
      const question = state.questions.find(q => q.id === questionId)
      if (question) {
        vue3Set(question, 'questionFirstComment', comment)
      }
    } else {
      const foundComment = findComment(state.comments, comment.id) as QnaComment
      vue3Set(foundComment, 'comment', comment.comment)
      vue3Set(foundComment, 'lastΕdit', comment.lastΕdit)
    }
  },
  [DELETE_COMMENT](
    state,
    {
      comment,
      isFirstComment,
      questionId
    }: { comment: QnaComment; isFirstComment: boolean; questionId: string }
  ) {
    const commentToDelete = isFirstComment
      ? comment
      : findComment(state.comments, comment.id)

    if (isFirstComment && questionId) {
      const question = state.questions.find(q => q.id === questionId)
      if (question) {
        question.questionFirstComment = {} as QnaComment
        question.questionReplies--
      }
    } else if (commentToDelete?.parentId) {
      const parentComment = findComment(
        state.comments,
        comment.parentId
      ) as QnaComment
      vue3Set(
        parentComment,
        'replies',
        parentComment.replies.filter((cd: QnaComment) => cd.id !== comment.id)
      )
    } else {
      state.comments = state.comments.filter(cd => cd.id !== comment.id)
    }
    if (state.fullQuestion?.id) {
      vue3Set(
        state.fullQuestion,
        'questionReplies',
        state.fullQuestion.questionReplies - 1
      )
    }
  },
  [SET_COMMENT_REACTION](
    state,
    {
      commentId,
      reaction,
      firstComment
    }: {
      commentId: number
      reaction: ReactionEnum
      firstComment?: QnaComment
    }
  ) {
    const comment =
      firstComment || (findComment(state.comments, commentId) as QnaComment)
    const isReactionExist = comment.myReactions.find(
      (r: string) => r === reaction
    )
    const myReactionsLength = comment.myReactions.length as number
    if (isReactionExist && myReactionsLength === 1) {
      vue3Set(comment.reactions, reaction, comment.reactions[reaction] - 1)
      vue3Set(
        comment,
        'myReactions',
        comment.myReactions.filter((mr: string) => mr !== reaction)
      )
    } else if (!isReactionExist && myReactionsLength === 0) {
      const r = comment.reactions[reaction] || 0
      vue3Set(comment, 'myReactions', comment.myReactions.concat(reaction))
      vue3Set(comment.reactions, reaction, r + 1)
    } else if (!isReactionExist && comment.myReactions.length > 0) {
      vue3Set(comment, 'reactions', {
        [comment.myReactions[0]]: comment.reactions[comment.myReactions[0]] - 1,
        [reaction]: comment.reactions[reaction]
          ? comment.reactions[reaction] + 1
          : 1
      })
      vue3Set(comment, 'myReactions', [reaction])
    }
  },
  [ADD_COMMENT_REPLY](state, { newComment, questionId, isFirstComment }) {
    let parentComment: QnaComment = {} as QnaComment

    if (questionId && isFirstComment) {
      const question = state.questions.find(q => q.id === questionId)
      if (question) {
        parentComment = findComment(
          [question.questionFirstComment],
          newComment.parentId
        ) as QnaComment
      }
    } else {
      parentComment = findComment(
        state.comments,
        newComment.parentId
      ) as QnaComment
    }
    vue3Set(parentComment, 'replies', parentComment.replies.concat(newComment))
  },
  [SET_FULL_QUESTION_DATA](
    state,
    {
      comments,
      question
    }: { comments: Array<QnaComment>; question: QnaQuestion }
  ) {
    state.comments = comments
    state.fullQuestion = question
  }
} as MutationTree<QnaState>

function updateMakes(
  makes: Array<Make>,
  makesWithModels: Array<Make>
): Array<Make> {
  return makes.map(m => {
    const make = makesWithModels.find(mm => mm.id === m.id)
    return make || m
  })
}
function findComment(
  comments: Array<QnaComment>,
  commentId: number
): QnaComment | null {
  let result: QnaComment | null = null
  function find(comments: Array<QnaComment>, commentId: number) {
    let i = comments.length
    while (i--) {
      if (!commentId) {
        break
      }
      if (commentId === comments[i].id) {
        result = comments[i]
        break
      }
      comments[i].replies?.length &&
        !result &&
        find(comments[i].replies, commentId)
    }
  }
  find(comments, commentId)

  return result
}
