import { MapSearch } from '~/router/routes/car/classifieds/search/components'
import { ClassifiedViewPage } from '~/router/routes/car/classifieds/view'

export const carMapRoutes = [
  ...generateRoute('classifieds/:vType', 'classifieds_vehicle'),
  ...generateRoute(':category', 'general')
]

function generateRoute(category: string, nameParam: string) {
  return [
    {
      name: `__classifieds_search_${nameParam}_map`,
      path: `/${category}/map`,
      component: MapSearch,
      meta: {
        noFooter: true,
        disableNotificationButton: true,
        isSearchRoute: true,
        wideContainer: true,
        inMap: true,
        showOneTap: true
      },
      children: [
        {
          name: `__classifieds_search_${nameParam}_map_classified`,
          path: `/${category}/map/:slugOrId`,
          component: ClassifiedViewPage,
          meta: {
            noFooter: true,
            noBreadcrumbs: true,
            disableNotificationButton: true,
            inMap: true
          }
        }
      ]
    }
  ]
}
