import { Store, CommitOptions, DispatchOptions } from 'vuex'
import { NamespacedStore } from '../models/store/types'

export function getNamespacedStore<S extends any = any, R extends any = any>(
  store: Store<R>,
  namespace: string
): NamespacedStore<S> {
  let ns = namespace
  if (ns[ns.length - 1] === '/') {
    ns = ns.substr(0, ns.length)
  }
  const state = getNamespacedState<S, R>(store, ns)
  if (state === undefined) {
    throw new Error(`Namespace ${ns} doesn't match a store.`)
  }

  return {
    commit: (type: string, payload: any, options?: CommitOptions) => {
      store.commit(`${ns}/${type}`, payload, options)
    },
    dispatch: (type: string, payload?: any, options?: DispatchOptions) => {
      return store.dispatch(`${ns}/${type}`, payload, options)
    },
    getters: (name: string) => store.getters[`${ns}/${name}`],
    get state(): S {
      return getNamespacedState<S, R>(store, ns)!
    }
  }
}

function getNamespacedState<S extends any = any, R extends any = any>(
  store: Store<R>,
  ns: string
): S | undefined {
  const nsParts = ns.split('/')
  let state: any = store.state
  for (const nsPart of nsParts) {
    state = state[nsPart]
    if (state === undefined) {
      return undefined
    }
  }
  return state
}
