import { MutationTree } from 'vuex'
import {
  Mapping,
  Path,
  Field
} from '~/models/dealer/integration/classified/mapping'
import { OutletMeta } from '~/models/dealer/integration/classified/outlet'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  ADD_MAPPING,
  SET_MAPPINGS,
  SET_PATHS,
  DEFAULT_STATE,
  SET_PATHS_LOADING,
  SET_MAPPINGS_LOADING,
  DELETE_MAPPING,
  SET_OUTLET_META
} from '~/store/modules/shared/dealers/integration/classifieds/field/mutation-types'
import getDefaultState, {
  DealerClassifiedFieldState
} from '~/store/modules/shared/dealers/integration/classifieds/field/state'

const { setter } = createMutationCreator<DealerClassifiedFieldState>()

const countAssociatedFieldsOfPath = (
  state: DealerClassifiedFieldState,
  path: Path
): number =>
  [...state.mappings.values()].reduce(
    (sum, m) => sum + Number(m.path === path),
    0
  )

export default {
  [SET_MAPPINGS](state, mappings: Map<Field, Mapping>) {
    state.mappings = new Map(mappings)

    const associatedPaths = new Set<Path>()
    for (const m of state.mappings.values()) {
      m.path && associatedPaths.add(m.path)
    }
    state.associatedPaths = associatedPaths
  },
  [ADD_MAPPING](state, mapping: Mapping) {
    const mappings = new Map<Field, Mapping>(state.mappings)
    const previousMapping = mappings.get(mapping.field)
    mappings.set(mapping.field, mapping)

    const associatedPaths = new Set<Path>(state.associatedPaths)
    if (
      previousMapping?.path &&
      countAssociatedFieldsOfPath(state, previousMapping.path) === 1
    ) {
      associatedPaths.delete(previousMapping.path)
    }
    associatedPaths.add(mapping.path)
    state.mappings = mappings
    state.associatedPaths = associatedPaths
  },
  [DELETE_MAPPING](state, field: Field) {
    const mappings = new Map<Field, Mapping>(state.mappings)
    const mapping = mappings.get(field)
    mappings.delete(field)

    if (mapping && countAssociatedFieldsOfPath(state, mapping.path) === 1) {
      const associatedPaths = new Set(state.associatedPaths)
      associatedPaths.delete(mapping.path)
      state.associatedPaths = associatedPaths
    }

    state.mappings = mappings
  },
  ...setter(SET_MAPPINGS_LOADING, 'mappingsLoading'),
  [SET_PATHS](state, paths: Set<Path> | Path[]) {
    state.paths = new Set(paths)
  },
  ...setter(SET_PATHS_LOADING, 'pathsLoading'),
  [SET_OUTLET_META](state, meta: OutletMeta) {
    state.outletName = meta.name
    state.dealerId = meta.legacyId
  },
  [DEFAULT_STATE](state) {
    const {
      paths,
      associatedPaths,
      pathsLoading,
      mappings,
      mappingsLoading
    } = getDefaultState()
    state.paths = paths
    state.pathsLoading = pathsLoading
    state.mappings = mappings
    state.associatedPaths = associatedPaths
    state.mappingsLoading = mappingsLoading
  }
} as MutationTree<DealerClassifiedFieldState>
