export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const SET_QUESTION_DATA = 'SET_QUESTION_DATA'
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'
export const SET_MAKE_DATA = 'SET_MAKE_DATA'
export const SET_MAKES_WITH_MODELS_DATA = 'SET_MAKES_WITH_MODELS_DATA'
export const ADD_QUESTION = 'ADD_QUESTION'
export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_QUESTION_REACTION = 'ADD_QUESTION_REACTION'
export const SET_MORE_COMMENT_REPLIES = 'SET_MORE_COMMENT_REPLIES'
export const SET_COMMENT_VISIBILITY = 'SET_COMMENT_VISIBILITY'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const SET_COMMENT_REACTION = 'SET_COMMENT_REACTION'
export const ADD_COMMENT_REPLY = 'ADD_COMMENT_REPLY'
export const SET_FULL_QUESTION_DATA = 'SET_FULL_QUESTION_DATA'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
