import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__vehicle_service_services',
    path: '/vehicle-service/services',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/vehicle-service/services.vue' /* webpackChunkName: "pages-car-vehicle-service-services" */
        )
      ),
    meta: {
      noFooter: true
    }
  },
  {
    name: '__vehicle_service_workshops',
    path: '/vehicle-service/workshops',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/vehicle-service/workshops.vue' /* webpackChunkName: "pages-car-vehicle-service-workshops" */
        )
      )
  },
  {
    name: '__vehicle_service_workshop',
    path: '/vehicle-service/workshops/:id',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/vehicle-service/workshop.vue' /* webpackChunkName: "pages-car-vehicle-service-workshop" */
        )
      )
  },
  {
    name: '__vehicle_service_workshop_complete_profile_and_book',
    path: '/vehicle-service/workshops/:id/complete-profile-and-book',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/vehicle-service/complete-profile-and-book.vue' /* webpackChunkName: "pages-car-vehicle-service-workshop-complete-profile-and-book" */
        )
      )
  },
  {
    name: '__vehicle_service_appointment_success',
    path: '/vehicle-service/appointment-success',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/vehicle-service/appointment-success.vue' /* webpackChunkName: "pages-car-vehicle-service-appointment-success" */
        )
      )
  }
]
