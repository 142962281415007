import { MutationTree } from 'vuex'
import { FlashState } from '~/store/modules/shared/flash/state'
import {
  SET_BACKEND_FLASH_MESSAGE,
  SET_CLIENT_FLASH_MESSAGE
} from '~/store/modules/shared/flash/mutation-types'
import { BackendFlashMessage, ClientFlashMessage } from '~/models/flash/types'

export default {
  [SET_CLIENT_FLASH_MESSAGE](state, clientFlashMessage: ClientFlashMessage) {
    state.clientFlashMessage = clientFlashMessage
  },
  [SET_BACKEND_FLASH_MESSAGE](state, backendFlashMessage: BackendFlashMessage) {
    state.backendFlashMessage = backendFlashMessage
  }
} as MutationTree<FlashState>
