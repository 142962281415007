export function getScopedStyleData(parent: any) {
  const data = { attrs: {} }

  if (parent.$options._scopeId) {
    // @ts-ignore
    data.attrs[`${parent.$options._scopeId}`] = ''
  }

  return data
}
