import { containerScoped } from '~/decorators/dependency-container'
import { CancelTokenSource } from 'axios'

@containerScoped()
export default class CancelableRequestMap {
  private map: Map<string, CancelTokenSource> = new Map()

  get(url: string) {
    return this.map.get(url)
  }

  set(url: string, source: CancelTokenSource) {
    this.map.set(url, source)
  }

  delete(url: string) {
    this.map.delete(url)
  }
}
