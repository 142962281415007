import { createModule } from '~/factories/store'
import mutations from '~/store/modules/shared/account/audits/mutations'
import getters from '~/store/modules/shared/account/audits/getters'
import state from '~/store/modules/shared/account/audits/state'
import actions from '~/store/modules/shared/account/audits/actions'

export default createModule({
  actions,
  mutations,
  getters,
  state
})
