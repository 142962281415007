import { container } from 'tsyringe'
import { MutationTree } from 'vuex'
import { IBuilder } from '~/builders/internal/IBuilder'
import { injectable } from '~/decorators/dependency-container'
import {
  IPartitionedCollection,
  DefaultPartitionedCollectionKey
} from '~/models/collection/IPartitionedCollection'
import { PartitionedMap } from '~/models/collection/PartitionedMap'
import { IndexedKeyGetter } from '~/models/store/collection'

@injectable()
export class PartitionedCollectionMutationBuilder<
  S,
  T extends any,
  I,
  K extends any = DefaultPartitionedCollectionKey
> implements IBuilder<MutationTree<S>> {
  private partitionLength: number | undefined
  private numberOfPartitions: number | undefined
  private mutationKey: string | undefined
  private partitionStateKeyGetter: IndexedKeyGetter | undefined
  private partitionedCollectionGetter: () => IPartitionedCollection<
    T,
    I,
    K
  > = () =>
    // @ts-ignore
    container.resolve(PartitionedMap) as IPartitionedCollection<Map<K, I>, I, K>

  setMutationKey(value: string) {
    this.mutationKey = value
    return this
  }

  setPartitionStateKeyGetter(value: IndexedKeyGetter | undefined) {
    this.partitionStateKeyGetter = value
    return this
  }

  setPartitionLength(value: number) {
    this.partitionLength = value
    return this
  }

  setNumberOfPartitions(value: number) {
    this.numberOfPartitions = value
    return this
  }

  setPartitionedCollectionGetter(value: () => IPartitionedCollection<T, I, K>) {
    this.partitionedCollectionGetter = value
    return this
  }

  build(): MutationTree<S> {
    const {
      partitionLength,
      partitionStateKeyGetter,
      numberOfPartitions,
      mutationKey
    } = this
    const partitionedCollectionGetter = () => this.partitionedCollectionGetter()

    return {
      [mutationKey!](state, collection: T) {
        const partitionedCollection = partitionedCollectionGetter()
        partitionedCollection
          .setPartitionLength(partitionLength!)
          .setCollection(collection)

        // Assign partitions
        for (let i = 0; i < numberOfPartitions!; i++) {
          // @ts-ignore TODO: Make state string-indexable
          state[partitionStateKeyGetter!(i)] =
            partitionedCollection.getPartition(i) || null
        }
      }
    }
  }
}

export const indexSuffixKeyGetter = (
  baseIdentifier: string
): IndexedKeyGetter => index => {
  return baseIdentifier + (index < 10 ? '0' : '') + index
}
