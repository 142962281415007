import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { invalidBodyError } from '~/services/errors'
import {
  MassActionResponse,
  MassPriceChangeOptions
} from '~/models/search/types'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class MassActionsService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async massTouch(
    searchId: string,
    ids?: number[]
  ): Promise<MassActionResponse> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/mass-actions/touch/',
      {
        search_id: searchId,
        ids
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body)
  }

  async massPriceChange(
    searchId: string,
    options: MassPriceChangeOptions,
    ids?: number[]
  ): Promise<MassActionResponse> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/mass-actions/price-change/',
      {
        search_id: searchId,
        ids,
        direction: options.direction,
        change_type: options.changeType,
        change: options.change
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body)
  }

  async massShow(
    searchId: string,
    ids?: number[]
  ): Promise<MassActionResponse> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/mass-actions/show/',
      {
        search_id: searchId,
        ids
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body)
  }

  async massHide(
    searchId: string,
    ids?: number[]
  ): Promise<MassActionResponse> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/mass-actions/hide/',
      {
        search_id: searchId,
        ids
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body)
  }

  async massCustomPage(
    searchId: string,
    customSearchIds: number[],
    ids?: number[]
  ): Promise<MassActionResponse> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/mass-actions/custom-page/',
      {
        search_id: searchId,
        ids,
        custom_search_ids: customSearchIds
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body)
  }
}
