import { Snackbar } from '~/models/app/snackbar'
import { Timeout } from '~/models/timing/timeout'

export interface SnackbarState {
  snackbar: Snackbar | null
  timeout: Timeout | null
}

export const SNACKBAR_NS = 'snackbar'

function getDefaultState(): SnackbarState {
  return {
    snackbar: null,
    timeout: null
  }
}

export default getDefaultState
