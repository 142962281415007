import { Corner, FloatingCornerElementName } from '~/models/layout'
import { GetterTreeWithRootState } from '~/store/types'
import { PageState } from './state'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'

export default {
  pageIsExplicitAndRequiresConsent(state, getters): boolean {
    const consent = state.consent
    if (consent) {
      return !getters.consentIsConfirmed(consent.name)
    }
    return false
  },
  consentIsConfirmed: (_state, _getters, _rootState, rootGetters) => (
    consentName: string
  ) => {
    return Boolean(
      rootGetters[`${STORAGE_NS}/getItem`](`consent.${consentName}`)
    )
  },
  backToTopButtonShown: state => {
    const s = state.cornerElements[Corner.BOTTOM_RIGHT]
    const n = FloatingCornerElementName
    return s.has(n.BACK_TO_TOP_BUTTON) && !s.has(n.NOTIFICATION_ENABLING_BUTTON)
  },
  bottomRightCornerElements: state => state.cornerElements[Corner.BOTTOM_RIGHT]
} as GetterTreeWithRootState<PageState>
