import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'

import { containerScoped } from '~/decorators/dependency-container'
import { invalidBodyError } from '~/services/errors'
import { Make, Model } from '~/models/make-model'
import { toCamelCase } from '~/utils/object'
import qs from 'qs'

@containerScoped()
export default class MakeModelService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async fetchModelsByMake(
    categoryId: number,
    makeIds: number | number[]
  ): Promise<Array<Make>> {
    const normalizedMakeIds = [makeIds].flat()
    const params = qs.stringify(
      { make: normalizedMakeIds },
      {
        arrayFormat: 'repeat',
        encode: false,
        skipNulls: true,
        addQueryPrefix: true
      }
    )
    const response: AxiosResponse = await this.http.get(
      `/api/models/category/${categoryId}/make/${params}`
    )

    const body = toCamelCase(response.data)
    if (!body?.data) {
      throw invalidBodyError(body)
    }
    return this.normalizeModels(body.data)
  }

  normalizeModels(makes: Array<{ models: [] }>): Array<Make> {
    return makes.map(m => {
      const models = m.models.map(
        (d: {
          count: number
          name: string
          id: number
          hasSeries: boolean
          seriesModels: Array<any>
        }) => {
          const { count, name: text, id: value } = d
          if (!d.hasSeries) {
            return {
              count,
              value,
              text,
              disabled: false
            }
          } else {
            const series: Array<Model> = d.seriesModels.map(s => ({
              count: s.count || 0,
              value: s.id,
              text: s.name,
              disabled: false
            }))

            return [
              {
                count,
                hasSeries: true,
                value,
                text,
                disabled: false
              },
              ...series
            ]
          }
        }
      )

      return { ...m, models: models.flat() } as Make
    })
  }

  async fetchMakesByCategoryId(
    categoryId: number
  ): Promise<{ makes: Array<Make>; topMakes: Array<Make> }> {
    const response: AxiosResponse = await this.http.get(
      `/api/makes/category/${categoryId}/`
    )
    const body = response.data
    if (!body?.data?.makes) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data)
  }
}
