import { CategoryId } from '~/models/category/types'

export const SEARCH_PARTIAL_SEARCH_CONFIG = {
  create(name: string) {
    return {
      settings: {
        searchName: name,
        isFiltersPage: true,
        updateUrl: false,
        isVehiclesSearch: false
      },
      rootParams: {
        category: [CategoryId[name.toUpperCase() as keyof typeof CategoryId]],
        context: 'filters-page'
      }
    }
  }
}

export const NEW_SEARCH_PARTIAL_SEARCH_CONFIG = {
  create(name: string) {
    return {
      settings: {
        searchName: name,
        isFiltersPage: true,
        updateUrl: false,
        replaceUrl: true,
        isVehiclesSearch: false
      },
      rootParams: {
        category: name,
        context: 'quick-search'
      }
    }
  }
}
