import { interopDefault } from '~/router/utils'
import { playgroundComponentsRoutes } from '~/router/routes/shared/playground/components'
import { playgroundToolsRoutes } from '~/router/routes/shared/playground/tools'
import { addRouteMeta } from '~/utils/router'

export default [
  {
    name: '__playground',
    path: '/playground',
    component: () =>
      interopDefault(import('~/pages/shared/playground/index.vue')),
    children: [...playgroundComponentsRoutes, ...playgroundToolsRoutes].map(r =>
      addRouteMeta(r, { noFooter: true })
    ),
    meta: {
      noFooter: true
    }
  }
]
