import { Store } from 'vuex'
import {
  DEALER_SITE_NS,
  DealerSiteState
} from '~/store/modules/shared/dealers/site/state'
import { getNamespacedStore } from '~/utils/store'
import BasePageSnippet from '../BasePageSnippet'

export default class CustomPageSnippet extends BasePageSnippet {
  async onPageLoad(store: Store<any>): Promise<void> {
    const dsStore = getNamespacedStore<DealerSiteState>(store, DEALER_SITE_NS)
    await dsStore.dispatch('loadCustomPage', this.id)
  }
}
