import { UserId } from '~/models/user/types'
import { Ip } from '~/models/ip'

export interface DisallowReasonPreset {
  description: string
  value: DisallowReasonPresetValue
}

export interface AccessToggle {
  disallowReason: string | null
  disallowReasonDescription: AccessToggleName
  allow: boolean
  value: AccessToggleName
  disallowReasonPresets?: DisallowReasonPreset[]
}

export interface AuthUserAccessToggle {
  disallowDescription: string | null
  allow: boolean
}

export interface AccessTogglesResult {
  accessToggles: AccessToggle[]
}

export enum AccessToggleName {
  QNA_POST_COMMENT = 'qna_post_comment',
  QNA_POST_QUESTION = 'qna_post_question',
  AUTO_TOUCH = 'auto_touch',
  USER_ANALYTICS = 'user_analytics',
  DISPATCHED_ENGINE_DETAILS_FIELDSET = 'dispatched_engine_details_fieldset',
  DISPATCHED_NOTES_FIELDSET = 'dispatched_notes_fieldset',
  ADMIN_ISSUE_STRIPE_INVOICE = 'admin_issue_stripe_invoice',
  REPORT_CLASSIFIEDS = 'report_classifieds',
  PLOT_AGENT_PAYMENTS = 'plot_agent_payments',
  GUEST_PAYMENTS = 'guest_payments',
  TRADES = 'trades',
  CLASSIFIED_OFFERS = 'classified_offers',
  EXTERNAL_CERTIFICATES_CREATE = 'EXTERNAL_CERTIFICATES_CREATE',
  AUDIT_PANEL_SHOPS = 'audit_panel_shops',
  AUDIT_PANEL_AUDITORS = 'audit_panel_auditors',
  AUDIT_PANEL_AUDIT_PACKAGES = 'audit_panel_audit_packages',
  AUDIT_PANEL_AUDIT_REQUESTS = 'audit_panel_audit_requests',
  AUDIT_PANEL_ACCEPT_PAYMENTS = 'audit_panel_accept_payments',
  CREATE_CLASSIFIEDS = 'create_classifieds',
  HAVE_CLASSIFIEDS = 'have_classifieds',
  PARKING = 'parking',
  CLASSIFIEDS_PROMOTIONS = 'classifieds_promotions',
  RECEIVE_MESSAGES = 'receive_messages',
  NOTIFICATIONS = 'notifications',
  GUEST_CAR_STRIPE_CREDITS = 'guest_car_stripe_credits',
  ONE_CLICK_AUTO_TOUCH = 'one_click_auto_touch',
  NEW_CATEGORIES_SEARCH_PAGE = 'new_categories_search_page',
  IN_HOUSE_ADS_ACCESS = 'in_house_ads_access',
  SALE_REQUESTS_BUY = 'sale_requests_buy',
  SALE_REQUESTS_SELL = 'sale_requests_sell',
  SALE_REQUESTS_ADMIN = 'sale_requests_admin',
  WANTED_PARTS_ACCESS = 'wanted_parts_access',
  PERK_VEHICLE_MODELS_PRICE_PAGE = 'perk_vehicle_models_price_page',
  PERK_VEHICLE_MODEL_PRICE_HISTORY = 'perk_vehicle_model_price_history',
  MARKETPLACE_SELLER = 'marketplace_seller',
  MARKETPLACE_BUYER = 'marketplace_buyer',
  MARKETPLACE_CAN_SELLER_ACCEPT_NEW_ORDER = 'marketplace_can_seller_accept_new_order',
  MARKETPLACE_CAN_BUYER_PLACE_NEW_ORDERS = 'marketplace_can_buyer_do_new_order',
  MARKETPLACE_ADMIN_MODERATE = 'marketplace_admin_moderate',
  ADMIN_MANAGE_CMT_ACTIONS = 'admin_manage_cmt_actions'
}

export enum DisallowReasonPresetValue {
  BROKE_THE_RULES = 'broke_the_rules'
}

export type AccessToggleIdentifier = UserId | Ip
