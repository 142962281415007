import { ClassifiedBucketType } from '~/models/classified/types'
import { ParkingTab, RecentParkingTab, ParkingRecent } from '~/models/parking'
import { SearchOfBucket, Pagination } from '~/models/search/types'
import { ClassifiedList } from '~/models/classified-list/types'
import { ParkingClassifieds } from '~/models/classified/parking/types'

export const PARKING_NS = 'parking'

type ClassifiedId = number

export interface ParkingState {
  groups: ParkingClassifieds
  bucket: ClassifiedBucketType | null
  error: boolean
  searches: SearchOfBucket[] | null
  recent: ParkingRecent | null
  classifiedLists: ClassifiedList[]
  classifiedsPagination: Pagination
  loading: boolean
  classifiedListLoading: boolean
  classifiedListActionButtonsLoading: boolean
  activeTab: ParkingTab
  activeRecent: RecentParkingTab
  classifiedsList: any[] | null // TODO: Type this array, it's assigned in the fetch_classifieds_list parking action.
  compareList: ClassifiedId[] | null
  style: string
  classifiedOrder: ClassifiedId[]
  notificationClassifieds: ClassifiedId[]
  selectedList: ClassifiedList | null
  showParking: boolean
  parkUrlBefore: string | null
  parkingNotifications: number
  dispatched: boolean
  swipeDirection: 'swipe-left' | 'swipe-right' | null
  selectedCategory?: number
}

function getDefaultState(): ParkingState {
  return {
    groups: {
      classifieds: {
        pagination: { page: 1, total: 0, pageParam: 'pg', perPage: 24 },
        rows: []
      },
      filters: { categories: [] },
      sortOptions: []
    },
    bucket: null,
    error: false,
    searches: null,
    recent: null,
    classifiedLists: [],
    classifiedsPagination: { page: 1, total: 0, pageParam: 'pg', perPage: 24 },
    loading: true,
    classifiedListLoading: false,
    classifiedListActionButtonsLoading: false,
    activeTab: 'classifieds',
    activeRecent: 'recent-classifieds',
    classifiedsList: null,
    compareList: [],
    style: 'full',
    classifiedOrder: [],
    notificationClassifieds: [],
    selectedList: null,
    showParking: false,
    parkUrlBefore: null,
    parkingNotifications: 0,
    dispatched: false,
    swipeDirection: null,
    selectedCategory: 0
  }
}
export default getDefaultState
