import { Seller } from '~/models/classified/types'
import { BlacklistReason } from '~/models/blacklist/types'
import { UserType } from '~/models/user/types'
import { Context } from '@nuxt/types'

export function sellerIsBlacklistedAsSuspiciousOrFraudster(seller: Seller) {
  return (
    seller.bl &&
    (seller.bl === BlacklistReason.SUSPICIOUS ||
      seller.bl === BlacklistReason.FRAUDSTER)
  )
}

export function sellerIsOfTypeDealer(seller: Seller) {
  return (
    seller.type === UserType.DEALER ||
    seller.type === UserType.MANAGER ||
    seller.type === UserType.AGENT
  )
}
export function sellerIsOfTypeAgent(seller: Seller) {
  return seller.type === UserType.MANAGER || seller.type === UserType.AGENT
}

export function userIsBot(req: Context['req']) {
  const botRegex = /bot|googlebot|crawler|spider|robot|crawling/i
  let userAgent
  if (typeof req !== 'undefined') {
    userAgent = req.headers!['user-agent'] as string
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent
  }
  return userAgent && botRegex.test(userAgent)
}
