export function ClientOnly(target: Function) {
  const className = target.prototype.constructor.name
  for (const propertyName of Object.getOwnPropertyNames(target.prototype)) {
    const descriptor = Object.getOwnPropertyDescriptor(
      target.prototype,
      propertyName
    )
    if (descriptor) {
      const isMethod = descriptor.value instanceof Function
      if (!isMethod) continue
      const originalMethod = descriptor.value
      descriptor.value = function(...args: any[]) {
        if (process.server) {
          throw new TypeError(
            `Cannot call method ${propertyName} of class ${className} on the server-side`
          )
        }
        return originalMethod.apply(this, args)
      }

      Object.defineProperty(target.prototype, propertyName, descriptor)
    }
  }
}

/**
 * Decorator that throws when method is not being called in browser.
 *
 * Example usage:
 * ```ts
 * class GoogleMapsService {
 *   @clientOnly
 *   getMap() {
 *     return this.map
 *   }
 * }
 * ```
 * @param _target
 * @param propertyKey
 * @param descriptor
 */
export function clientOnly(
  _target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value

  descriptor.value = function(...args: any[]) {
    if (process.server) {
      throw new TypeError(
        `Cannot call function ${propertyKey} on the server-side`
      )
    }

    return originalMethod.apply(this, args)
  }

  return descriptor
}

export function serverOnly(
  _target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value

  descriptor.value = function(...args: any[]) {
    if (process.client) {
      throw new Error(`Cannot call function ${propertyKey} on the client-side`)
    }

    return originalMethod.apply(this, args)
  }

  return descriptor
}
