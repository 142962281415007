import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import VueRouter, { RouteConfig } from 'vue-router'

import { dealerSiteRouteNames } from '~/constants/dealer-site-route-names'
import { httpToken } from '~/constants/dependency-injection/tokens'

import { containerScoped } from '~/decorators/dependency-container'
import { CategoryId } from '~/models/category/types'
import DealerSiteConfigService from '~/services/dealers/site/DealerSiteConfigService'
import { adminSearchRoutes } from '~/router/routes/car/admin/search'
import { dealsRoutes } from '~/router/routes/car/classifieds/search/deals'
import { deletedClassifiedRoutes } from '~/router/routes/car/classifieds/search/deleted'
import { jobsRoutes } from '~/router/routes/car/classifieds/search/jobs'
import { rentalsRoutes } from '~/router/routes/car/classifieds/search/rentals'
import { myClassifiedRoutes } from '~/router/routes/car/classifieds/search/my-classifieds'
import { partsRoutes } from '~/router/routes/car/classifieds/search/parts'
import {
  plotMapRoutes as carPlotMapRoutes,
  plotRoutes as carPlotRoutes
} from '~/router/routes/car/classifieds/search/plot'
import { userProfileRoutes } from '~/router/routes/shared/classifieds/user-profile'
import { vehiclesRoutes } from '~/router/routes/car/classifieds/search/vehicles'
import { xymaRoutes } from '~/router/routes/car/classifieds/search/xyma'
import { mapRoutes as plotMapRoutes } from '~/router/routes/plot/classifieds/search/map'
import { listRoutes as plotListRoutes } from '~/router/routes/plot/classifieds/search/list'
import { blackFridayRoutes } from '~/router/routes/car/classifieds/search/black-friday'
import { SearchConfig } from '~/models/search/config'
import { dealsSearchConfig } from '~/constants/search/config/deals'
import { adminSearchConfig } from '~/constants/search/config/admin'
import {
  SEARCH_PARTIAL_SEARCH_CONFIG,
  NEW_SEARCH_PARTIAL_SEARCH_CONFIG
} from '~/constants/search/config/search-partial'
import {
  plotListSearchConfig,
  plotMapSearchConfig,
  plotSearchConfig
} from '~/constants/search/config/plot'
import { xymaSearchConfig } from '~/constants/search/config/xyma'
import { partsSearchConfig } from '~/constants/search/config/parts'
import { deletedSearchConfig } from '~/constants/search/config/deleted'
import { jobsSearchConfig } from '~/constants/search/config/jobs'
import { rentalsSearchConfig } from '~/constants/search/config/rentals'
import { userClassifiedsSearchConfig } from '~/constants/search/config/user-classifieds'
import { myClassifiedsSearchConfig } from '~/constants/search/config/my-classifieds'
import { vehiclesSearchConfig } from '~/constants/search/config/vehicles'
import { carMapRoutes } from '~/router/routes/car/classifieds/search/car-map'
import filterRoutes from '~/router/routes/car/filters'
import quickSearchRoutes from '~/router/routes/car/classifieds/search/quick-search'
import { allVTypesRegex } from '~/constants/classified/vehicle'
import { wantedPartsRoutes } from '~/router/routes/car/classifieds/search/wanted-parts'
import { wantedPartsSearchConfig } from '~/constants/search/config/wantedPartsSearchConfig'

@containerScoped()
export default class SearchConfigService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(DealerSiteConfigService)
    private dealerSiteConfigService: DealerSiteConfigService,
    @inject(VueRouter) private router: VueRouter
  ) {}

  public getConfig(route: any): SearchConfig {
    const routeBelongsToRoutes = (routes: any) => {
      return routes.some(
        (r: RouteConfig) =>
          r.name === route.name ||
          (r.children &&
            r.children.some((c: RouteConfig) => c.name === route.name))
      )
    }
    if (routeBelongsToRoutes(vehiclesRoutes)) {
      const { vType } = route.params
      return vehiclesSearchConfig.create(vType)
    } else if (
      routeBelongsToRoutes(carPlotMapRoutes) ||
      routeBelongsToRoutes(plotMapRoutes)
    ) {
      return plotMapSearchConfig
    } else if (routeBelongsToRoutes(plotListRoutes)) {
      return plotListSearchConfig
    } else if (routeBelongsToRoutes(carPlotRoutes)) {
      return plotSearchConfig
    } else if (routeBelongsToRoutes(carMapRoutes)) {
      return this.getCarMapConfig(route)
    } else if (routeBelongsToRoutes(xymaRoutes)) {
      return xymaSearchConfig
    } else if (routeBelongsToRoutes(partsRoutes)) {
      return partsSearchConfig
    } else if (routeBelongsToRoutes(deletedClassifiedRoutes)) {
      return deletedSearchConfig
    } else if (routeBelongsToRoutes(blackFridayRoutes)) {
      // return blackFridaySearchConfig // uncomment this for black friday 2024
      return dealsSearchConfig
    } else if (routeBelongsToRoutes(dealsRoutes)) {
      return dealsSearchConfig
    } else if (routeBelongsToRoutes(jobsRoutes)) {
      return jobsSearchConfig
    } else if (routeBelongsToRoutes(rentalsRoutes)) {
      return rentalsSearchConfig
    } else if (routeBelongsToRoutes(userProfileRoutes)) {
      const { userId } = route.params
      return userClassifiedsSearchConfig.create(userId)
    } else if (routeBelongsToRoutes(adminSearchRoutes)) {
      return adminSearchConfig
    } else if (routeBelongsToRoutes(myClassifiedRoutes)) {
      return this.getMyClassifiedsSearchConfig(route)
    } else if (dealerSiteRouteNames.includes(route.name!)) {
      return this.dealerSiteConfigService.createSearchConfig()
    } else if (routeBelongsToRoutes(filterRoutes)) {
      const { category } = route.params
      const config = SEARCH_PARTIAL_SEARCH_CONFIG.create(category)
      config.settings.isVehiclesSearch = !!category?.match(allVTypesRegex)
      return config
    } else if (routeBelongsToRoutes(quickSearchRoutes)) {
      const { category } = route.query
      const categoryToUse =
        Array.isArray(category) && category.length > 1 ? category[0] : category
      const config = NEW_SEARCH_PARTIAL_SEARCH_CONFIG.create(categoryToUse)
      return config
    } else if (routeBelongsToRoutes(wantedPartsRoutes)) {
      return wantedPartsSearchConfig
    }
    return this.defaultConfig()
  }

  getMyClassifiedsSearchConfig(route: any) {
    if (route.query.category === CategoryId.WANTED_PARTS.toString()) {
      return {
        ...myClassifiedsSearchConfig,
        settings: {
          ...wantedPartsSearchConfig.settings,
          ...myClassifiedsSearchConfig.settings
        }
      }
    }

    return myClassifiedsSearchConfig
  }

  getCarMapConfig(route: any): SearchConfig {
    const { category } = route.params

    const doesCategoryExist =
      CategoryId[category.toUpperCase() as keyof typeof CategoryId]

    if (category === 'xyma') {
      return xymaSearchConfig
    } else if (category === 'parts') {
      return partsSearchConfig
    } else if (category === 'jobs') {
      return jobsSearchConfig
    } else if (category === 'rentals') {
      return rentalsSearchConfig
    } else if (category && doesCategoryExist) {
      return vehiclesSearchConfig.create(category)
    } else {
      return this.defaultConfig()
    }
  }

  defaultConfig(): SearchConfig {
    return {
      settings: {},
      rootParams: {
        category: [CategoryId.CLASSIFIEDS],
        sort: ['md']
      }
    }
  }
}
