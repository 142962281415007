import { interopDefault } from '~/router/utils'
import { RouteConfig } from 'vue-router'

export function getParkingRoutes(namePrefix?: string): RouteConfig[] {
  const prefix = namePrefix ? '_' + namePrefix : ''
  return [
    {
      path: 'favorite/',
      name: `_${prefix}_parking_classifieds_favorite`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/favorite.vue' /* webpackChunkName: "pages-shared-account-classifieds-favorite.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'classifieds'
      }
    },
    {
      path: 'searches/',
      name: `_${prefix}_parking_searches_favorite`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/searches.vue' /* webpackChunkName: "pages-shared-account-classifieds-searches.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'searches'
      }
    },
    {
      path: 'recent/',
      name: `_${prefix}_parking_recent`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/recent/index.vue' /* webpackChunkName: "pages-shared-account-classifieds-recent-index.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'recent'
      },
      redirect: { name: `_${prefix}_parking_recent_classifieds` },
      children: [
        {
          path: 'classifieds/',
          name: `_${prefix}_parking_recent_classifieds`,
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/parking/recent/classifieds.vue' /* webpackChunkName: "pages-shared-account-recent-classifieds.vue" */
              )
            ),
          meta: {
            ignoreScrollBehavior: true,
            showOneTap: true,
            tabName: 'recent-classifieds'
          }
        },
        {
          path: 'searches/',
          name: `_${prefix}_parking_recent_searches`,
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/parking/recent/searches.vue' /* webpackChunkName: "pages-shared-account-recent-searches.vue" */
              )
            ),
          meta: {
            ignoreScrollBehavior: true,
            showOneTap: true,
            tabName: 'recent-searches'
          }
        }
      ]
    },
    {
      path: 'lists/',
      name: `_${prefix}_parking_classifieds_lists`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/lists.vue' /* webpackChunkName: "pages-shared-account-classifieds-lists.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'lists'
      }
    }
  ]
}
