import { Telephone } from '~/models/shared/types'
import { Pagination } from '~/models/search/types'
import { UserType } from '~/models/user/types'
import { SchemaField } from '~/models/form/types'

export const INHOUSE_DASHBOARD_VISITED = 'inh-dash-visited'

export interface CampaignSchemaResult {
  inHouseAdCampaign: AdCampaign
  schema: SchemaField[]
  values?: AdCampaignForm
}

export interface CampaignsRow {
  id: number
  created: string
  name: string
  ads: Ad[]
  totalImpressions: number
  totalClicks: number
  viewableImpressions: number
}
export interface Campaigns {
  pagination: Pagination
  rows: CampaignsRow[]
}
export interface CampaignsResult {
  campaigns: Campaigns
}

export interface Ads {
  pagination: Pagination
  rows: Ad[]
}
export interface AdsResult {
  ads: Ads
}

export interface AdItems {
  pagination: Pagination
  rows: AdItem[]
}
export interface AdItemsResult {
  items: AdItems
}

export interface NewAdSchema {
  schema: SchemaField[]
}

export interface NewAdItemSchema {
  schema: SchemaField[]
}

export interface AdCampaignForm {
  name?: string
  enabled?: boolean
}

export interface AdActionResult {
  message: string
  status: number
}

export type AdCampaignKeys = keyof AdCampaignForm
export type AdCampaignErrors = Partial<Record<AdCampaignKeys, string>>

export interface AdForm {
  name?: string
  startDate?: Date
  finishDate?: Date
  enabled?: boolean
  budget?: number
  budgetType?: string
  deliveryStrategy?: string
  impressionsPerPeriodPeriod?: string
  impressionsPerPeriodPerUser?: number
}

export type AdFormKeys = keyof AdForm
export type AdFormErrors = Partial<Record<AdFormKeys, string>>

export interface AdItemForm {
  title?: string
  name?: string
  subtitle?: string
  targetLink?: string
  squareBannerUrl?: string
  rectangleBannerUrl?: string
  squareImageUrl?: string
  designType: string
  placementContext: string
}

export type AdItemFormKeys = keyof AdItemForm
export type AdItemFormErrors = Partial<Record<AdItemFormKeys, string>>

export interface AdCampaignFormValue {
  name: keyof AdCampaignForm
  value: any
}

export interface AdFormValue {
  name: keyof AdForm
  value: any
}

export interface CampaignAdFormValue {
  id: number | string
  adFormValue: AdFormValue
}

export interface AdItemFormValue {
  name: keyof AdItemForm
  value: any
}

export interface AdItemFormValuesWithId {
  id: number | string
  adItemForm: AdItemForm
}

export interface AdItemFormValueWithId {
  id: number | string
  adItemFormValue: AdItemFormValue
}
export interface CampaignAdItemFormValue {
  adId: number | string
  itemId: number | string
  adItemFormValue: AdItemFormValue
}

export interface SchemaValue {
  id: number | string
  schema: SchemaField[]
}
export interface CampaignAdErrors {
  id: number | string
  errors: AdFormErrors
}
export interface CampaignAdItemErrors {
  adId: number | string
  itemId: number | string
  errors: AdItemFormErrors
}

export interface AdCampaign {
  name: string
  id: number
  created: string
  enabled: boolean
}
export enum AdStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export enum AdItemStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
  REPLACED = 'replaced',
  EXPIRED = 'expired'
}

export interface CampaignResult {
  campaign: AdCampaign
  values: AdCampaignForm
}
export interface AdUser {
  avatar: string
  displayName: string
  id: number
  adAccountId: number
  name: string
  telephone: Telephone
  type: UserType
  username: string
}
export interface Ad {
  status: AdStatus
  currentStatus: AdItemStatus | null
  budget: number
  budgetType: string
  campaignId: number
  name: string
  finishDate: string
  startDate: string
  totalImpressions: number
  totalClicks: number
  viewableImpressions: number
  isDelete: boolean
  items: AdItem[]
  user: AdUser
  isDeleted: boolean
  rejectedItem?: AdItem
  created: string | null
  currentSpend: number
  enabled: boolean
  id: number
}

export interface AdResult {
  ad: Ad
  values: AdForm
}
export interface AdItemResult {
  item: AdItem
  values: AdItemForm
}

export interface CampaignAdResult {
  schema: SchemaField[]
  values: AdForm
  inHouseAd: Ad
}

export interface CampaignAdItemResult {
  schema: SchemaField[]
  values: AdItemForm
  item: AdItem
}

export interface CampaignAdItemPublishResult {
  item: AdItem
}

export interface AdActor {
  displayName: string
  id: number
}

export interface AdItemContextDescription {
  category: string
  makes: string
  locations: string
}
export interface AdItem {
  id: number
  campaignId: number
  approvedBy?: AdActor
  approvedTimestamp?: string
  rejectedBy?: AdActor
  rejectedTimestamp?: string
  contextDescription?: AdItemContextDescription
  adId: number
  name: string
  title: string
  status: string
  subtitle: string
  created: Date
  totalImpressions: number
  image: string | null
  squareBannerUrl: string | null
  totalClicks: number
  targetLink: string
  rectangleBannerUrl: string | null
  designType: string
  rejectionReason: string | null
  squareImageUrl: string
  // eslint-disable-next-line camelcase
  banner_300_250_url?: string
  // eslint-disable-next-line camelcase
  banner_320_100_url?: string
  // eslint-disable-next-line camelcase
  banner_728_90_url?: string
  // eslint-disable-next-line camelcase
  banner_970_250_url?: string
  // eslint-disable-next-line camelcase
  square_image_url?: string
}

export interface CampaignAdItem {
  item: AdItem
  schema: SchemaField[]
  values: AdItemForm
  errors?: AdItemFormErrors
}

export interface CampaignAdItemForAdId {
  id: number | string
  campaignAdItem: CampaignAdItem
}
export interface CampaignAd {
  schema?: SchemaField[]
  newItemSchema?: SchemaField[]
  newItemValues?: AdItemForm
  newItemErrors: AdItemFormErrors
  items?: CampaignAdItem[]
  values?: AdForm
  ad: Ad
  errors?: AdFormErrors
}

export interface CampaignAdsResult {
  ads: CampaignAds
}

export interface CampaignAds {
  pagination: Pagination
  rows: Ad[]
}

export interface AdAsset {
  url: string
  absoluteUrl: string
  lastUsed: string | Date
  type: string
}

export interface AdAssetsResult {
  assets: AdAsset[]
}

export interface AdBalanceResult {
  balance: number
  totalCampaigns: number
}

export interface AdAssetUploadResult {
  url: string
  absoluteUrl: string
  name?: string
}

export interface AdItemUnitEntry {
  label: string
  value: string
  banners: string[]
}

export interface InHouseEntry {
  item: AdItem
}

export interface InHouseUnitDetails {
  sizes: number[]
  sizesMappings?: number[][][]
  lazy?: boolean
}

export interface PlacementUnitsResult {
  schema: {
    placementUnits: SchemaField
  }
}

export interface ImpressionForecast {
  impressions: number
  day: string
}
export interface AdsForecastingResult {
  reach: ForecastingReach
}

export interface ForecastingBudget {
  min: number
  max: number
}
export interface ForecastingReach {
  impressionsForcast: ImpressionForecast[]
  uniqueUsersPerDay: number
  totalImpressions: number
  impressionsPerDay: number
  budgetTotalImpressions: number
  totalUniqueUsers: number
  budgetFillPercentage: number
  dailySuggestedBudget: ForecastingBudget
  lifetimeSuggestedBudget: ForecastingBudget
}

export interface ForcastingInputData {
  fromDate: string
  toDate: string
  units: AdItemUnitEntry[]
  budget: number
  categories?: number[]
  locations?: string[]
  makes?: number[]
}
