import { GetterTreeWithRootState } from '~/store/types'
import { FlashState } from '~/store/modules/shared/flash/state'
import { APP_NS } from '~/store/modules/shared/app/state'

export default {
  flashShown(state, _, __, rootGetters): Boolean {
    if (rootGetters[`${APP_NS}/isPlot`]) {
      // plot has no backend flash messages for now
      return Boolean(state.clientFlashMessage)
    }
    return Boolean(state.clientFlashMessage || state.backendFlashMessage)
  }
} as GetterTreeWithRootState<FlashState>
