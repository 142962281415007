export const SET_USERS = 'SET_USERS'
export const SET_PAGE = 'SET_PAGE'
export const RESET_PAGE_NUMBER = 'RESET_PAGE_NUMBER'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT'
export const SET_FILTERS = 'SET_FILTERS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const SET_SORTING = 'SET_SORTING'
export const SET_SCHEMA = 'SET_SCHEMA'
export const SET_USER_TYPE = 'SET_USER_TYPE'
export const SET_SITE = 'SET_SITE'
