
import { CIcon } from '~/icons/types'
import { getScopedStyleData } from '~/utils/vue'
import { mergeData } from 'vue-functional-data-merge'
import { PropType, defineComponent } from '@nuxtjs/composition-api'
import { CreateElement, RenderContext, VNode } from 'vue'

const DEFAULT_FILL = 'currentColor'
const DEFAULT_OPACITY = '1.00'

export default defineComponent({
  name: 'CIcon',
  functional: true,
  props: {
    icon: {
      type: Object as PropType<CIcon>,
      required: true
    },
    noColors: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: [String, Number],
      default: null
    }
  },
  render(
    createElement: CreateElement,
    { data, parent, props }: RenderContext
  ): VNode {
    if (!props.icon) {
      return
    }

    const icon: CIcon = props.icon

    function getPathFill(path: any) {
      if (
        path.fill &&
        (icon.name === 'vehicle-rentals' || icon.type === 'duotone')
      ) {
        // force path.fill even if noColors is true
        return path.fill
      } else if (!path.fill || props.noColors) {
        return DEFAULT_FILL
      }
      return path.fill
    }

    return createElement(
      'svg',
      mergeData(
        data,
        {
          attrs: {
            'aria-hidden': 'true',
            focusable: 'false',
            'data-prefix': 'ci',
            role: 'img',
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: `${icon.minX || 0} ${icon.minY || 0} ${icon.width} ${
              icon.height
            }`,
            fill: icon.fill
          },
          staticClass: `ci tw-inline-block ci-${icon.name} ci-${icon.type}${
            !props.noColors && icon.type !== 'duotone' ? ' colorize' : ''
          }`,
          class: { 'fix-size': props.size },
          style: {
            width: props.size ? `${props.size}px` : undefined,
            height: props.size ? `${props.size}px` : undefined
          }
        },
        getScopedStyleData(parent)
      ),
      [
        ...icon.paths.map(path =>
          createElement('path', {
            attrs: {
              fill: getPathFill(path),
              opacity: path.opacity || DEFAULT_OPACITY,
              d: path.d,
              'fill-rule': path.fillRule,
              'clip-rule': path.clipRule
            }
          })
        ),
        ...(icon.ellipses || []).map(ellipse =>
          createElement('ellipse', {
            attrs: {
              fill: DEFAULT_FILL,
              cx: ellipse.cx,
              cy: ellipse.cy,
              rx: ellipse.rx,
              ry: ellipse.ry
            }
          })
        ),
        ...(icon.circles || []).map(circle =>
          createElement('circle', {
            attrs: {
              fill: circle.fill || 'none',
              stroke: DEFAULT_FILL,
              cx: circle.cx,
              cy: circle.cy,
              r: circle.r
            }
          })
        ),
        ...(icon.rectangles || []).map(rect =>
          createElement('rect', {
            attrs: {
              fill: DEFAULT_FILL,
              x: rect.x,
              y: rect.y,
              width: rect.width,
              height: rect.height,
              rx: rect.rx,
              ry: rect.ry
            }
          })
        )
      ]
    )
  }
})
