/* eslint-disable no-console */
import { inject } from 'tsyringe'
import * as SentryTypes from '@sentry/minimal'
import { sentryToken } from '~/constants/dependency-injection/tokens'
import { User, UserType } from '~/models/user/types'

import { containerScoped } from '~/decorators/dependency-container'

@containerScoped()
export default class LoggerService {
  constructor(@inject(sentryToken) private sentry: typeof SentryTypes) {}

  public captureError(error: string | Error) {
    if (typeof error === 'object') {
      try {
        JSON.stringify(error)
      } catch (jsonConversionError) {
        jsonConversionError.message = `Error while json-stringifying error: ${
          jsonConversionError.message
        } (${error!.toString()})`
        this.sentry.captureException(jsonConversionError)
        return
      }
    }

    this.sentry.captureException(error)
    console.error('Logging error:', error)
  }

  captureMessage(message: string) {
    this.sentry.captureMessage(message)
  }

  setUser({
    id,
    username,
    type,
    name
  }: Pick<User, 'id' | 'username' | 'type' | 'name'>) {
    let id_ = typeof id === 'undefined' ? id : id.toString()
    if (type === UserType.ANON) {
      id_ = `${UserType.ANON}:${id}`
    }

    this.sentry.setUser({
      id: id_,
      username,
      name,
      type
    })
  }
}
