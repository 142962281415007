import Vue from 'vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import CButton from '~/components/shared/configurable/button/CButton.vue'
import CContainer from '~/components/shared/configurable/grid/CContainer.vue'
import CAlert from '~/components/shared/configurable/alert/CAlert.vue'
import CSpinner from '~/components/shared/configurable/CSpinner.vue'
import COverlay from '~/components/shared/configurable/overlay/COverlay.vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import CRow from '~/components/shared/configurable/grid/CRow.vue'
import CCol from '~/components/shared/configurable/grid/CCol.vue'
import CFormSelect from '~/components/shared/configurable/form/select/CFormSelect.vue'
import CFormSelectOption from '~/components/shared/configurable/form/select/CFormSelectOption.vue'
import CCheckbox from '~/components/shared/configurable/form/checkbox/CCheckbox.vue'
import CRadio from '~/components/shared/configurable/form/radio/CRadio.vue'
import CModal from '~/components/shared/configurable/modal/CModal.vue'
import CForm from '~/components/shared/configurable/form/CForm.vue'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import CCheckboxGroup from '~/components/shared/configurable/form/checkbox/CCheckboxGroup.vue'
import CTable from '~/components/shared/configurable/table/CTable.vue'
import CTableHead from '~/components/shared/configurable/table/CTableHead.vue'
import CTableHeadCell from '~/components/shared/configurable/table/CTableHeadCell.vue'
import CTableRow from '~/components/shared/configurable/table/CTableRow.vue'
import CTableDataCell from '~/components/shared/configurable/table/CTableDataCell.vue'
import CTableBody from '~/components/shared/configurable/table/CTableBody.vue'
import CRadioGroup from '~/components/shared/configurable/form/radio/CRadioGroup.vue'
import CInput from '~/components/shared/configurable/form/input/CInput.vue'
import CInputGroup from '~/components/shared/configurable/form/input/CInputGroup.vue'
import CInputGroupText from '~/components/shared/configurable/form/input/CInputGroupText.vue'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'
import CFormGroup from '~/components/shared/configurable/form/CFormGroup.vue'

export default () => {
  Vue.component('CIcon', CIcon)
  Vue.component('CButton', CButton)
  Vue.component('CContainer', CContainer)
  Vue.component('CAlert', CAlert)
  Vue.component('CSpinner', CSpinner)
  Vue.component('COverlay', COverlay)
  Vue.component('CLink', CLink)
  Vue.component('CRow', CRow)
  Vue.component('CCol', CCol)
  Vue.component('CFormSelect', CFormSelect)
  Vue.component('CFormSelectOption', CFormSelectOption)
  Vue.component('CFormGroup', CFormGroup)
  Vue.component('CInput', CInput)
  Vue.component('CInputGroup', CInputGroup)
  Vue.component('CInputGroupText', CInputGroupText)
  Vue.component('CCheckbox', CCheckbox)
  Vue.component('CCheckboxGroup', CCheckboxGroup)
  Vue.component('CModal', CModal)
  Vue.component('CForm', CForm)
  Vue.component('CDropdown', CDropdown)
  Vue.component('CTable', CTable)
  Vue.component('CTableHead', CTableHead)
  Vue.component('CTableHeadCell', CTableHeadCell)
  Vue.component('CTableDataCell', CTableDataCell)
  Vue.component('CTableRow', CTableRow)
  Vue.component('CTableBody', CTableBody)
  Vue.component('CRadio', CRadio)
  Vue.component('CRadioGroup', CRadioGroup)
  Vue.component('CDynamicIcon', CDynamicIcon)
}
