import { RawLocation } from 'vue-router'
import HierarchicalCategoryService from '~/services/category/HierarchicalCategoryService'
import { AdminCategoriesState } from '~/store/modules/shared/admin/categories/state'
import { ActionTreeWithRootState, LoadStatus } from '~/store/types'
import { CategoryId, HierarchicalCategory } from '~/models/category/types'
import {
  ADD_HIERARCHICAL_CATEGORIES,
  SET_LOAD_STATUS,
  COLLAPSE_CATEGORY,
  EXPAND_CATEGORY,
  FOCUS_CATEGORY,
  SET_CATEGORY_FOR_EDIT,
  SET_POPULARITY_MAP
} from './mutation-types'
import PopularityService from '~/services/popularity/PopularityService'

export default {
  async loadHierarchicalCategories({ commit }, ids: string[]) {
    const { UNLOADED, LOADED, LOADING } = LoadStatus

    commit(SET_LOAD_STATUS, LOADING)
    try {
      commit(
        ADD_HIERARCHICAL_CATEGORIES,
        await this.$dep(HierarchicalCategoryService).getCategoryMap(ids)
      )
      commit(SET_LOAD_STATUS, LOADED)
    } catch (error) {
      this.$logger.captureError(error)
      commit(SET_LOAD_STATUS, UNLOADED)
    }
  },
  async toggleCategory(
    { commit, dispatch, state: { categories, expandedIds } },
    id: string
  ) {
    if (!categories.has(id)) {
      return
    }

    if (expandedIds.has(id)) {
      commit(COLLAPSE_CATEGORY, id)
      return
    }

    await dispatch('loadHierarchicalCategories', [id])
    commit(EXPAND_CATEGORY, id)
  },
  /**
   * Loads category, expands its ancestors and sets it for edit.
   * @param commit
   * @param dispatch
   * @param state
   * @param id
   */
  async loadCategoryForEdit({ commit, dispatch, state }, id: string) {
    await dispatch('loadHierarchicalCategories', [id])
    commit(FOCUS_CATEGORY, id)

    const c: HierarchicalCategory | undefined = state.categories.get(id)
    if (c && c.parentId) {
      commit(EXPAND_CATEGORY, c.parentId)
    }

    commit(SET_CATEGORY_FOR_EDIT, id)
  },
  selectCategoryForEdit(
    { commit, state },
    { id, nextLocation }: { id: string; nextLocation?: RawLocation }
  ) {
    const c: HierarchicalCategory | undefined = state.categories.get(id)
    if (!c) {
      return
    } else if (c.parentId) {
      commit(EXPAND_CATEGORY, c.parentId)
    }

    commit(SET_CATEGORY_FOR_EDIT, id)
    if (nextLocation) {
      this.$router.push(nextLocation)
    }
  },
  async loadPopularityMap({ commit }) {
    try {
      const popularityService = this.$dep(PopularityService)
      commit(
        SET_POPULARITY_MAP,
        await popularityService.getPopularityMap('categories', {
          type: 'classified_view',
          categories: CategoryId.CLASSIFIEDS
        })
      )

      commit(
        SET_POPULARITY_MAP,
        await popularityService.getPopularityMap(
          'categories',
          {
            type: 'classified_view',
            categories: CategoryId.CLASSIFIEDS
          },
          null,
          100000
        )
      )
    } catch (error) {
      const { $logger: logger } = this
      logger.captureError(error)
    }
  }
} as ActionTreeWithRootState<AdminCategoriesState>
