import { UserSearchBooleanFilter } from '~/models/user/search'
import { UserSearchState } from '~/store/modules/shared/admin/users/search/state'
import { GetterTreeWithRootState } from '~/store/types'

export default {
  getBooleanFilter: state => (filter: UserSearchBooleanFilter) =>
    state.booleanFilters.has(filter),
  hasSelectedFilters: ({ filters, booleanFilters }): boolean =>
    Boolean(
      filters['user-type']?.length ||
        filters['last-activity'] ||
        filters.site ||
        booleanFilters.size
    )
} as GetterTreeWithRootState<UserSearchState>
