import { FloatingCornerElementName, Corner } from '~/models/layout'
import { PageError } from '~/models/error'
import { PageState } from './state'
import { CompactPage, Consent, Breadcrumb } from '~/models/common/types'
import {
  SET_CONSENT,
  SET_PAGE_DATA,
  SET_BREADCRUMBS,
  SET_ERROR,
  ADD_CORNER_ELEMENT,
  REMOVE_CORNER_ELEMENT,
  SET_MODAL_OPEN,
  ADD_BODY_CLASS,
  REMOVE_BODY_CLASS
} from './mutation-types'
import { MutationTree } from 'vuex'

export default {
  [SET_PAGE_DATA](state, pageData: CompactPage) {
    state.breadcrumbs = pageData.breadcrumbs
    state.seoUrl = pageData.seoUrl
    state.title = pageData.title
    state.metadata = pageData.metadata
  },
  [SET_CONSENT](state, consent: Consent) {
    state.consent = consent
  },
  [SET_BREADCRUMBS](state, breadcrumbs: Breadcrumb[]) {
    state.breadcrumbs = breadcrumbs
  },
  [SET_ERROR](state, error: PageError) {
    state.error = error
  },
  [ADD_CORNER_ELEMENT](
    state,
    {
      name,
      corner = Corner.BOTTOM_RIGHT
    }: { name: FloatingCornerElementName; corner: Corner.BOTTOM_RIGHT }
  ) {
    const s = state.cornerElements[corner]
    s.add(name)
    state.cornerElements[corner] = new Set(s)
  },
  [REMOVE_CORNER_ELEMENT](
    state,
    {
      name,
      corner
    }: { name: FloatingCornerElementName; corner: Corner.BOTTOM_RIGHT }
  ) {
    const s = state.cornerElements[corner]
    s.delete(name)
    state.cornerElements[corner] = new Set(s)
  },
  [SET_MODAL_OPEN](state, modalIsOpen: boolean) {
    state.modalOpen = modalIsOpen
  },
  [ADD_BODY_CLASS](state, className: string) {
    if (!state.bodyClasses.includes(className)) {
      state.bodyClasses.push(className)
    }
  },
  [REMOVE_BODY_CLASS](state, className: string) {
    if (state.bodyClasses.includes(className)) {
      state.bodyClasses.splice(state.bodyClasses.indexOf(className), 1)
    }
  }
} as MutationTree<PageState>
