import { ActionTreeWithRootState } from '~/store/types'
import { AccountState } from '~/store/modules/shared/account/state'
import {
  SET_ACCOUNT_LOADING,
  SET_ACCOUNT_OVERVIEW,
  SET_ACCOUNT_OVERVIEW_STRIPE_SELLER,
  SET_AGENT_QUICK_STATS_PANELS
} from '~/store/modules/shared/account/mutation-types'
import StripeService from '~/services/logistics/StripeService'
import AccountService from '~/services/account/AccountService'
import { AccountOverview } from '~/models/account'
import { HttpStatus } from '~/constants/http'
import ClassifiedsAnalyticsService from '~/services/analytics/classifieds/ClassifiedsAnalyticsService'
import { StripeSeller } from '~/models/payments/stripe'

export default {
  async cancelSubscriptionPermanently({ commit }) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      await this.$dep(StripeService).cancelSubscriptionPermanently()
      window.location.reload()
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async cancelSubscriptionAtPeriodEnd({ commit }) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      await this.$dep(StripeService).cancelSubscriptionAtPeriodEnd(true)
      window.location.reload()
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async reactivateSubscription({ commit }) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      await this.$dep(StripeService).cancelSubscriptionAtPeriodEnd(false)
      window.location.reload()
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async fetchAgentOrDealerOverview({ commit }) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      const overview: AccountOverview = await this.$dep(
        AccountService
      ).getAgentOrDealerOverview()
      commit(SET_ACCOUNT_OVERVIEW, overview)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async fetchGuestOverview({ commit }) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      const overview: AccountOverview = await this.$dep(
        AccountService
      ).getGuestOverview()
      commit(SET_ACCOUNT_OVERVIEW, overview)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async fetchAuditsUserOverview(
    { commit },
    fetchStripeSellerData: boolean = false,
    fetchOverviewData: boolean = true
  ) {
    commit(SET_ACCOUNT_LOADING, true)
    try {
      if (fetchOverviewData) {
        const overview: AccountOverview = await this.$dep(
          AccountService
        ).getAuditsUserOverview()
        commit(SET_ACCOUNT_OVERVIEW, overview)
      }

      if (fetchStripeSellerData) {
        const stripeSeller: StripeSeller = await this.$dep(
          StripeService
        ).getStripeSellerData()
        commit(SET_ACCOUNT_OVERVIEW_STRIPE_SELLER, stripeSeller)
      }
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_ACCOUNT_LOADING, false)
    }
  },
  async getAgentQuickStatsPanels({ commit, getters }) {
    commit(SET_ACCOUNT_LOADING, true)
    const fetchUrl = getters.getQuickStatsFetchUrl

    try {
      if (fetchUrl) {
        const panelData = await this.$dep(
          ClassifiedsAnalyticsService
        ).getPanelData(fetchUrl)
        const { subPanels } = panelData

        commit(SET_AGENT_QUICK_STATS_PANELS, subPanels)
      }
    } catch (error) {
      if (
        !error.response ||
        error.response.status !== HttpStatus.UNAUTHORIZED
      ) {
        error.message = `Failed to get panel data for url ${fetchUrl}. ${error}`
        this.$logger.captureError(error)
      }
    } finally {
      commit(SET_ACCOUNT_LOADING, false)
    }
  }
} as ActionTreeWithRootState<AccountState>
