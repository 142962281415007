import { interopDefault } from '~/router/utils'

const Compare = () =>
  interopDefault(
    import(
      '~/pages/shared/compare/compare.vue' /* webpackChunkName: "pages-shared-compare-compare.vue" */
    )
  )
export default [
  {
    name: '__compare',
    path: '/classifieds/compare',
    component: Compare
  }
]
