import { interopDefault } from '~/router/utils'

export const adminAnnouncementsRoutes = [
  {
    name: '__admin_announcements_list',
    path: '/admin/announcements/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/announcements/list.vue' /* webpackChunkName: "pages-shared-admin-announcements-list" */
        )
      )
  },
  {
    name: '__admin_announcements_new',
    path: '/admin/announcements/new/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/announcements/new.vue' /* webpackChunkName: "pages-shared-admin-announcements-new" */
        )
      )
  },
  {
    name: '__admin_announcements_edit',
    path: '/admin/announcements/:id/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/announcements/edit.vue' /* webpackChunkName: "pages-shared-admin-announcements-edit" */
        )
      )
  }
]
