import { AuditShop } from '~/models/audit/shop'
import { SchemaField } from '~/models/form/types'

export const ACCOUNT_AUDITS_NS = 'account/audits'

export interface AccountAuditsState {
  auditShop: AuditShop | null
  auditShopFieldValues: Record<string, any>
  auditShopSchema: SchemaField[] | null
}

function getDefaultState(): AccountAuditsState {
  return {
    auditShop: null,
    auditShopFieldValues: {},
    auditShopSchema: null
  }
}

export default getDefaultState
