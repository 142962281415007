import { Pagination } from '~/models/search/types'
import { Classified } from '~/models/classified/types'

export interface NewClassifiedOfferForm {
  amount: number
  message?: string
}

export interface ClassifiedOfferBid {
  amount: number
  state: OfferState
  id: number
  created: string
  user: OfferUser
}

export interface NewClassifiedOfferPostResult {
  offer: ClassifiedOfferEntry
}

export interface ClassifiedOfferCountResult {
  offerCount: number
  hasOfferForClassified?: boolean
  offer: ClassifiedOfferEntry | null
}

export enum OfferState {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  WITHDRAWN = 'withdrawn',
  EXPIRED = 'expired'
}

export enum OfferActions {
  REJECT = 'reject',
  ACCEPT = 'accept',
  CANCEL = 'cancel',
  COUNTER = 'counter',
  NEW = 'new'
}

export interface OfferUser {
  avatar: string | null
  displayName: string
  id: number
  username: string
}
export interface OfferClassified extends Classified {
  rawPrice: number
  priceParked?: string
}
export interface ClassifiedOfferEntry {
  lastAmount: number
  created: string
  modified: string
  classified: OfferClassified
  id: number
  lastStatus: OfferState
  latestBid: ClassifiedOfferBid
  thread: ClassifiedOfferThread
  bidCount: number
  user: OfferUser
}
export interface ClassifiedOfferThread {
  id: number
  preview: string
  recipientRead: boolean
  threadRead: boolean
  showRecipient: boolean
  showSender: boolean
}

export interface ClassifiedOffersList {
  pagination: Pagination
  rows: ClassifiedOfferEntry[]
}
export interface ClassifiedOffersListResult {
  classifiedOffersList: ClassifiedOffersList
}
export interface ClassifiedOffersSetStateResult {
  offer: ClassifiedOfferEntry
}
