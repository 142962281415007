import { container } from 'tsyringe'
import { PartitionedCollectionBuilder } from '~/builders/store/collection/internal/PartitionedCollectionBuilder'
import { PartitionedCollectionInitialStateBuilder } from '~/builders/store/collection/internal/PartitionedCollectionInitialStateBuilder'
import { PartitionedCollectionMutationBuilder } from '~/builders/store/collection/internal/PartitionedCollectionMutationBuilder'
import { injectable } from '~/decorators/dependency-container'
import { DefaultPartitionedCollectionKey } from '~/models/collection/IPartitionedCollection'
import { IndexedKeyGetter } from '~/models/store/collection'

@injectable()
export class PartitionedCollectionStoreBuilder<
  S,
  T,
  I,
  K extends any = DefaultPartitionedCollectionKey
> {
  private partitionLength: number = 800
  private numberOfPartitions: number = 4
  private partitionStateKeyGetter: IndexedKeyGetter | undefined

  setPartitionLength(value: number) {
    this.partitionLength = value
    return this
  }

  setNumberOfPartitions(value: number) {
    this.numberOfPartitions = value
    return this
  }

  setPartitionStateKeyGetter(value: IndexedKeyGetter) {
    this.partitionStateKeyGetter = value
    return this
  }

  mutation(): PartitionedCollectionMutationBuilder<S, T, I, K> {
    return container
      .resolve<PartitionedCollectionMutationBuilder<S, T, I, K>>(
        PartitionedCollectionMutationBuilder
      )
      .setPartitionLength(this.partitionLength)
      .setNumberOfPartitions(this.numberOfPartitions)
      .setPartitionStateKeyGetter(this.partitionStateKeyGetter)
  }

  initialState(): PartitionedCollectionInitialStateBuilder {
    return container
      .resolve(PartitionedCollectionInitialStateBuilder)
      .setNumberOfPartitions(this.numberOfPartitions)
      .setPartitionStateKeyGetter(this.partitionStateKeyGetter)
  }

  partitionedCollection(): PartitionedCollectionBuilder<T, I, K> {
    return container
      .resolve<PartitionedCollectionBuilder<T, I, K>>(
        PartitionedCollectionBuilder
      )
      .setPartitionLength(this.partitionLength)
      .setNumberOfPartitions(this.numberOfPartitions)
      .setPartitionStateKeyGetter(this.partitionStateKeyGetter)
  }
}
