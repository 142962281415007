export interface StorageState {
  state: Record<string, any>
}
export const STORAGE_NS = 'storage'
function getDefaultState(): StorageState {
  return {
    state: {}
  }
}
export default getDefaultState
