export function getLiveStaticUrl(path: string): string {
  return `https://static.car.gr${path}`
}

export function getStaticUrl(path: string): string {
  if (
    process.env.NODE_ENV === 'production' &&
    !process.env.SERVE_LOCAL_STATIC_IN_PROD
  ) {
    return `https://static-cz.car.gr${path}`
  }
  return path
}
