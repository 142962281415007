import { TreeNode } from '~/models/shared/tree/types'
import { ClsfdCategory } from '~/models/classified/category/types'
import { Schema } from '~/models/form/types'

export type CategoryMap = Map<string, ClsfdCategory>

export interface Category extends TreeNode {
  humanName: string
  humanPath?: string
  classifiedsCount?: number
}

export interface HierarchicalCategory extends Category {
  ancestorIds: string[]
  selected: boolean
  childrenCanBeRetrieved: boolean
  unselectable: boolean
  original: boolean
}

export interface ClassifiedBucketGroupCategory {
  /* eslint-disable camelcase */
  human_path: string
  plural_name: string
  name: string
  parent_ids: number[]
  parent_id: number
  human_name_plural: string
  moved_to_category_id: number | null
  human_name: string
  id: number
}
export enum CategoryId {
  PLOT = 20002,
  JOBS = 30002,
  RENTALS = 40002,
  RENTALS_CARS = 41000,
  RENTALS_BIKES = 42000,
  RENTALS_BICYCLES = 47000,
  RENTALS_TRUCKS = 46000,
  RENTALS_TAXIS = 44006,
  RENTALS_CAMPERS = 45000,
  RENTALS_BOATS = 43000,
  JOBS_WANTED = 30003,
  JOBS_OFFERED = 30004,
  XYMA = 50,
  PARTS = 30,
  PARTS_CAR = 77,
  PARTS_CARS = 77,
  PARTS_TRACTORS = 1187,
  PARTS_BUILDERS = 1188,
  PARTS_TRUCKS = 394,
  PARTS_TOOLS = 7,
  PARTS_FORKLIFTS = 1189,
  PARTS_BUSES = 1190,
  PARTS_BIKES = 711,
  PARTS_BICYCLES = 3028,
  PARTS_BOATS = 34,
  PARTS_CAMPERS = 1042,
  VEHICLES = 15000,
  CARS = 15001,
  BIKES = 15002,
  BOATS = 15003,
  BICYCLES = 15004,
  CAMPERS = 15102,
  COMMERCIAL = 15101,
  TRUCKS = 15006,
  HOBBIES = 15100,
  TRAILERS = 15013,
  TAXIS = 15014,
  CARAVANS = 15015,
  MOTORHOMES = 15016,
  RADIOCONTROLS = 15017,
  GOKARTS = 15018,
  WATERSPORTS = 15019,
  SNOWSPORTS = 15020,
  AIRSPORTS = 15021,
  VANS = 15005,
  BUSES = 15007,
  BUILDERS = 15008,
  SEMITRUCKS = 15009,
  SEMITRAILERS = 15010,
  TRACTORS = 15011,
  FORKLIFTS = 15012,
  CLASSIFIEDS = 20001,
  HOBBY = 15100,
  XYMA_JOBS = 8165,
  XYMA_SERVICES = 4851,
  JEEPSUV = 15218,
  VANMINIBUS = 15864,
  FARMERPICKUP = 15214,
  SPORTSCAR = 15685,
  COMMERCIALCAR = 15865,
  CABRIO = 15212,
  COUPESPORT = 15215,
  COMPACT = 15211,
  COMBICARAVAN = 15210,
  LIMO = 15213,
  TRAILER = 15207,
  TOOLS = 30,
  CARAVANHOUSE = 15430,
  CONTAINER = 15658,
  WANTED_PARTS = 50002,
  // TODO these are hacky, remove later if possible
  DEALS = 'deals',
  TRADES = 'trades',
  SERVICE = 'service'
}

export interface CategoryGroup {
  label: string
  rootCategory: number
  suggestions: []
}

export interface CategoriesSuggestion {
  category?: {
    id: string | number
    path: Array<CategoryPath>
    humanName: string
    hasChildren: boolean
  }
  id: string
  index?: string
  path?: string
  rootCategory?: number
  score?: number
  targetUrl?: string
  class?: string
  selectable?: boolean
}

export interface CategoryHeader {
  id?: string
  header: string
  rootCategory: number
  category?: { id: string; path: [] }
  class?: string
  selectable?: boolean
}

export interface CategorySearchOptions {
  headers: boolean
}

export interface CategoryPath {
  humanName: string
  id: number
  name: string
}

export interface TreeLevelCategory {
  humanPath: string
  pluralName: string
  name: string
  hasChildren: boolean
  parentId?: number
  humanNamePlural: string
  humanName: string
  id: number
  movedToCategoryUrl: string | null
  movedToCategoryId: number | null
  isActive: boolean
  disabled?: boolean
  isRecent?: boolean
}

export type TreeLevelNode = Pick<
  TreeLevelCategory,
  | 'id'
  | 'humanName'
  | 'parentId'
  | 'hasChildren'
  | 'disabled'
  | 'isActive'
  | 'humanPath'
>

export interface TreeLevelCategoryResult {
  levels: TreeLevelCategory[][]
  hasRecentCategories: boolean
}

export interface CategoryMapEntry {
  selectedCategory?: TreeLevelCategory
  editCollapse: boolean
}

export interface TreeLevelGetterOptions {
  rootUrlParam: boolean
}

export interface CmtAction {
  humanName: string
  actionDate: null | string
  actionType: string
  actionUser: null | string
  blockedAction: null | boolean
  created: string
  id: number
  nextValue: Record<string, any>
  previousValue: Record<string, any>
  relatedInfo: {
    backendClass: string
    id: number
  }
  backendClass: string
  scheduleInfo: Record<string, any>
  scheduled: boolean
  status: CmtActionStatus
  user: {
    displayName: string
    id: number
  }
}

export interface ContentManagementToolsCategoryResponse {
  category: Category
  classifiedsCount: number
  values: {
    name: string
    translations: { [key: string]: string }
  }
  schema: Schema
  pendingActions: CmtAction[]
}

export interface TranslationValues {
  translations: { [key: string]: string }
}

export interface ContentManagementToolsFieldResponse {
  category: Category
  field: any
  values: ContentManagementToolsFieldValues
  schema: Schema
}

export interface ContentManagementToolsExtraResponse {
  extra: ContentManagementToolsExtraValues
  categories: Category[]
  values: {
    translations: { [key: string]: string }
  }
  schema: Schema
}

export interface ContentManagementToolsExtraValues {
  group: string
  humanName: string
  id: number
  name: string
  value: number
  translations: { [key: string]: string }
}

export interface ContentManagementToolsFieldValues {
  name?: string
  isMultiple: boolean
  isFacetable: boolean
  editable?: boolean
  fieldType?: string
  fieldsetId?: number | null
  fieldsetOrder?: number
  formOrder: { [key: string]: string }[]
  facetOrder: { [key: string]: string }[]
  excludeForCategories: []
  translations: { [key: string]: string }
  lookupValues?: {
    lookupKey: string
    translations: { [key: string]: string }
  }[]
  min?: number
  max?: number
  step?: number
  valueType?: string
  valueMetric?: string
}

export interface CategoryField {
  humanName: string
  categoryId: number
  defaults: {
    lookupGroup: string
    form: {
      changeable: boolean
    }
  }
  active: boolean
  editable: boolean
  fieldSetId: number
  fieldType: string
  fieldsetOrder: null
  id: number
  info: string
  name: string
  version: number
}

export type CmtActionStatus =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'failed'
  | 'completed'
