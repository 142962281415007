import { DEBOUNCE_DEFAULT } from '~/constants/debounce'

/**
 *
 * @deprecated Avoid using this
 */
export function executeSyncOnServerAsyncOnClient(fn: any) {
  if (process.server) {
    return fn()
  } else {
    fn()
  }
}

export function debounce<T extends Function = Function>(
  fn: T,
  time: number = DEBOUNCE_DEFAULT
): Function {
  let timeout: number

  function cancel() {
    clearTimeout(timeout)
  }

  function debounced() {
    // @ts-ignore
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    // @ts-ignore
    timeout = setTimeout(() => functionCall(), time)
  }

  debounced.cancel = cancel

  return debounced
}

/**
 * No-operation. It saves a little bit of space to reference a single function across usage occurrences. Also, provides
 * more control.
 */
export function noop(): void {}
