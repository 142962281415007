import { interopDefault } from '~/router/utils'

export const adminCategoriesRoutes = [
  {
    name: '__admin_categories_edit',
    path: '/admin/categories/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/categories/edit.vue' /* webpackChunkName: "pages-admin-categories-edit" */
        )
      )
  }
]
