var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative focus-within:tw-z-10"},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"tw-z-10",class:_vm.labelClasses,attrs:{"for":_vm.internalId,"aria-required":_vm.required}},[(_vm.$slots.label)?_vm._t("label"):[_vm._v(" "+_vm._s(_vm.label))]],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-relative"},[(_vm.$slots.before)?_c('div',{staticClass:"tw-left-0 tw-pl-3",class:_vm.beforeClasses},[_vm._t("before")],2):_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({ref:"inputTemplateRef",staticClass:"c-input tw-form-input tw-py-0 tw-block tw-w-full tw-transition-colors disabled:tw-bg-grey-300 disabled:tw-text-grey-600 disabled:tw-border-grey-300 disabled:tw-cursor-not-allowed read-only:tw-bg-grey-300 read-only:tw-text-grey-600 read-only:tw-border-grey-300 read-only:tw-cursor-not-allowed tw-border-solid placeholder:tw-text-grey-600",class:[
        _vm.controlClasses,
        _vm.inputSizeClasses,
        _vm.roundnessToUse,
        _vm.inputBeforeAndAfterClasses,
        _vm.inputClass,
        { 'no-arrows': _vm.noArrows }
      ],attrs:{"id":_vm.internalId,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"step":_vm.type === 'number' ? _vm.step : undefined,"required":_vm.required,"name":_vm.name},domProps:{"value":_vm.internalValue},on:{"wheel":_vm.onWheel}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
        {input: _vm.handleInput,
        keydown: _vm.handleKeydown,
        change: _vm.handleChange}))),_vm._v(" "),(_vm.$slots.after)?_c('div',{staticClass:"tw-right-0 tw-pr-3",class:_vm.afterClasses},[_vm._t("after")],2):_vm._e()]),_vm._v(" "),(_vm.$slots.helperText || _vm.helperText)?_c('div',{class:_vm.helperTextClasses},[(_vm.$slots.helperText)?_vm._t("helperText"):_c('span',{staticClass:"tw-inline-flex tw-items-center tw-gap-1"},[(_vm.helperTextIcon)?_c('CIcon',{attrs:{"icon":_vm.helperTextIcon,"size":16}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.helperText))])],1)],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }