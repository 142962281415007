import { onBeforeUnmount, ref } from '@nuxtjs/composition-api'
import { useDep } from '~/compositions/dependency-container'
import GoogleMapsService from '~/services/GoogleMapsService'
import { Geolocation } from '~/models/common/types'
import { geolocationToGoogleGeolocation } from '~/utils/geolocation'
import { noop } from '~/utils/function'
import { FullLocation } from '~/models/google'
import { CountryCode } from '~/models/location/country'

export function useGoogleAutocomplete() {
  const autocompleteInput = ref<google.maps.places.Autocomplete | null>(null)
  const googleMapsService = useDep(GoogleMapsService)
  const placeChangeListener = ref<google.maps.MapsEventListener | null>(null)

  async function createAutocompleteInput(
    templateRef: HTMLInputElement,
    countryCodes: string[] = ['gr']
  ) {
    autocompleteInput.value = await googleMapsService.createAutocompleteInput(
      templateRef,
      countryCodes
    )
  }

  async function createListeners(
    onPlaceChange: (data: FullLocation) => any = noop,
    onCustomInput: () => any = noop
  ) {
    placeChangeListener.value = await googleMapsService.addListener(
      autocompleteInput.value,
      'place_changed',
      async () => {
        if (!autocompleteInput.value) {
          return
        }
        const place = await getPlace()
        if (!place.geometry?.location) {
          return onCustomInput()
        }
        const geolocation: Geolocation = {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
        }
        const postcode = await getPostcode(place, geolocation)
        const city = getCity(place)
        const address = getAddress(place)
        const country = getCountry(place)
        onPlaceChange({ geolocation, postcode, address, city, country })
      }
    )
  }

  async function getPostcode(
    place: any,
    geolocation: Geolocation
  ): Promise<string | null> {
    const postcode = googleMapsService.extractPostalCode([place])
    if (postcode) {
      return postcode
    }
    // attempt to infer the postalcode from geolocation
    const geocoderResults = await googleMapsService.geocode({
      location: geolocationToGoogleGeolocation(geolocation)
    })
    return (
      (geocoderResults.length > 0 &&
        googleMapsService.extractPostalCode(geocoderResults)) ||
      null
    )
  }

  async function getPlace() {
    let place = autocompleteInput.value!.getPlace()
    if (place?.geometry?.location) {
      return place
    }
    const nameGeocodeResults: google.maps.GeocoderResult[] = await googleMapsService.geocode(
      { address: place.name }
    )
    if (nameGeocodeResults?.length > 0) {
      place = { name: place.name, ...nameGeocodeResults[0] }
    }
    return place
  }

  function getAddress(place: any) {
    return place.formatted_address
  }

  function getCity(place: any) {
    return googleMapsService.extractCity([place])
  }

  function getCountry(place: any) {
    return googleMapsService.extractCountry([place]) as CountryCode
  }

  onBeforeUnmount(() => {
    placeChangeListener.value &&
      googleMapsService.removeListener(placeChangeListener.value)
  })

  return { createAutocompleteInput, createListeners }
}
