import { interopDefault } from '~/router/utils'

export const accountOrdersRoutes = [
  {
    path: 'orders/',
    name: '__account_orders',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/account/orders/index.vue' /* webpackChunkName: "pages-shared-account-orders-index.vue" */
        )
      ),
    meta: {
      scrollTopSmooth: true
    },
    redirect: { name: '__account_orders_list' },
    children: [
      {
        path: 'list/',
        name: '__account_orders_list',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/list.vue' /* webpackChunkName: "pages-shared-account-orders-list.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'details/:orderId/',
        name: '__account_orders_order_details',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/order.vue' /* webpackChunkName: "pages-shared-account-orders-order.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'shipping-addresses/',
        name: '__account_orders_shipping_addresses',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/shipping-addresses/index.vue' /* webpackChunkName: "pages-shared-account-orders-shipping-addresses-index.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'shipping-addresses/new/',
        name: '__account_orders_shipping_addresses_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/shipping-addresses/new.vue' /* webpackChunkName: "pages-shared-account-orders-shipping-addresses-new.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'shipping-addresses/:shippingAddressId/edit/',
        name: '__account_orders_shipping_addresses_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/shipping-addresses/edit.vue' /* webpackChunkName: "pages-shared-account-orders-shipping-addresses-edit.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'invoice-info/',
        name: '__account_orders_invoice_info',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/invoice-info/index.vue' /* webpackChunkName: "pages-shared-account-orders-invoice-info-index.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'invoice-info/new/',
        name: '__account_orders_invoice_info_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/invoice-info/new.vue' /* webpackChunkName: "pages-shared-account-orders-invoice-info-new.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'invoice-info/:invoiceInfoId/edit/',
        name: '__account_orders_invoice_info_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/invoice-info/edit.vue' /* webpackChunkName: "pages-shared-account-orders-invoice-info-edit.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'reviews/',
        name: '__account_orders_reviews',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/reviews/list.vue' /* webpackChunkName: "pages-shared-account-eshop-reviews-list.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'reviews/new/:sellerId',
        name: '__account_orders_reviews_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/reviews/form.vue' /* webpackChunkName: "pages-shared-account-eshop-reviews-form.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'reviews/edit/:reviewId',
        name: '__account_orders_reviews_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/reviews/form.vue' /* webpackChunkName: "pages-shared-account-eshop-reviews-form.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      }
    ]
  }
]
