import { interopDefault } from '~/router/utils'

export const adminPopularityRoutes = [
  {
    name: '__admin_popularity_query',
    path: '/admin/popularity/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/popularity/query.vue' /* webpackChunkName: "pages-admin-popularity-query" */
        )
      ),
    meta: { noFooter: true }
  }
]
