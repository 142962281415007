export type Path = string

export interface Mapping {
  field: Field
  path: Path
}

export interface ResponseMapping {
  field: Field
  path: Path | null
}

export enum Field {
  additionalImage = 'additional_image',
  availability = 'availability',
  categoryId = 'category_id',
  categoryName = 'category_name',
  color = 'color',
  condition = 'condition',
  description = 'description',
  externalLink = 'external_link',
  image = 'image',
  inStock = 'in_stock',
  lastUpdate = 'last_update',
  manufacturer = 'manufacturer',
  mpn = 'mpn',
  price = 'price',
  product = 'product',
  size = 'size',
  title = 'title',
  uniqueId = 'unique_id',
  weight = 'weight'
}
