
import { PropType } from 'vue'
import { invalidBodyError } from '~/services/errors'
import { formatFeed } from '~/services/user/formatters'
import { mapGetters, mapState } from 'vuex'
import { Classified } from '~/models/classified/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { debounce } from '~/utils/function'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { Feed } from '~/models/user/types'
import { arraysEqual } from '~/utils/array'
import { APP_NS } from '~/store/modules/shared/app/state'
import { CategoryId } from '~/models/category/types'
import { defineComponent } from '@nuxtjs/composition-api'
import { DEBOUNCE_DEFAULT } from '~/constants/debounce'

interface Data {
  classifieds: Classified[]
  showLink: boolean
  enableTransitions: boolean
  activeRecents: string[]
  testCounter: number
  transitionName: string
  recentLocation: object
  queue: object[]
  queueInterval: object
  fetchInterval: object
  hiddenClassifieds: Set<string>
  forcePause: boolean
  firstFetchTry: boolean
  firstFeedSet: boolean
}

export default defineComponent({
  props: {
    refreshInterval: {
      type: Number || null,
      required: false,
      default: 12 // in seconds
    },
    isIndexMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    loadedFeed: {
      type: Object as PropType<Feed>,
      required: false,
      default: undefined
    },
    isLatest: {
      type: Boolean,
      required: false,
      default: false
    },
    maxClassifieds: {
      type: Number,
      required: false,
      default: 50
    },
    lazyLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    showOnlyPlotRecents: {
      type: Boolean,
      default: false
    }
  } as any,
  fetch() {
    const landing = this.getItem('landing')
    let storedLocation = null
    let storedRecents = null

    if (landing) {
      storedLocation = landing.location
      if (!this.isPlot && !this.showOnlyPlotRecents) {
        // active recents are only used in car (for now)
        storedRecents = landing.recents
      }
    }

    if (storedLocation) {
      this.setRecentLocation(storedLocation)
    } else if (this.userLocation) {
      this.setRecentLocation(this.userLocation)
    } else {
      this.setRecentLocation({})
    }

    if (storedRecents) {
      this.activeRecents = storedRecents
    }

    // if (!this.lazyLoad) {
    //   await this.fetchFeed()
    // }
  },
  data(): Data {
    return {
      classifieds: [],
      showLink: true,
      enableTransitions: false,
      activeRecents: [],
      testCounter: 0,
      transitionName: 'entry',
      recentLocation: {
        radius: null,
        city: '',
        coordinates: null,
        postcode: null
      },
      queue: [],
      queueInterval: null,
      fetchInterval: null,
      hiddenClassifieds: new Set(),
      forcePause: false,
      firstFetchTry: false,
      firstFeedSet: false
    }
  },
  computed: {
    ...mapState(USER_NS, {
      userLocation: 'location'
    }),
    ...mapGetters(STORAGE_NS, {
      getItem: 'getItem'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    liveFeedActive() {
      if (this.fetchInterval) {
        return true
      }
      return false
    },
    isLatestDesktop() {
      return this.isLatest && this.isPc
    },
    defaultRecents() {
      return this.isPlot
        ? [CategoryId.PLOT]
        : [15001, 15002, 15003, 15100, 15101, 15102, 30, 20002]
    },
    params() {
      const params = {}
      if (
        !this.isPlot &&
        this.activeRecents.length &&
        !arraysEqual(this.activeRecents, this.defaultRecents) &&
        !this.isIndexMobile
      ) {
        params.category = this.activeRecents
      }
      if (this.showOnlyPlotRecents) {
        params._site = 'plot'
      }

      if (
        this.recentLocation &&
        this.recentLocation.postcode &&
        this.recentLocation.radius &&
        this.recentLocation.radius < 800000 // 800km is virtually all Greece so for caching reasons only add these params if it's lower
      ) {
        params.postcode = this.recentLocation.postcode
        params.distance = this.recentLocation.radius
      }

      if (Object.keys(params).length) {
        return params
      }
      return null
    }
  },
  beforeDestroy(): void {
    clearInterval(this.fetchInterval)
    clearInterval(this.queueInterval)
    if (window) {
      window.removeEventListener('focus', this.onFocusCheck)
    }
  },
  async mounted() {
    if (!this.lazyLoad) {
      // initially request after 5secs and then fetchinterval takes control
      await this.fetchFeed()
      // setTimeout(this.fetchFeed, 5000)
      this.startIntervals()
      if (window && !this.isLatest) {
        window.addEventListener('focus', this.onFocusCheck)
      }
    }
  },
  methods: {
    onFocusCheck: debounce(function() {
      if (this.queue.length < 6 && !this.forcePause) {
        this.fetchFeed()
      }
    }, 2000),
    startIntervals() {
      this.queueInterval = setInterval(this.checkQueue, 2000)
      if (this.fetchInterval === null) {
        this.setFetchInterval()
      }
    },
    async startFetching() {
      await this.fetchFeed()
      this.setFetchInterval()
    },
    setFetchInterval() {
      if (process.client) {
        // only set interval on client (else we got weird server intervals)
        this.fetchInterval = setInterval(
          this.fetchFeed,
          this.refreshInterval * 1000
        )
      }
    },
    feedControlClick() {
      if (this.fetchInterval) {
        // pause clicked
        this.$analytics.recordEvent({
          namespace: 'n_index_recent_feed_control_clicked',
          action: 'click',
          label: 'pause clicked'
        })

        this.clearFetchInterval()
        if (this.$refs.scroller && this.$refs.scroller.scrollX >= 0) {
          this.forcePause = true
        }
      } else {
        // play clicked

        this.$analytics.recordEvent({
          namespace: 'n_index_recent_feed_control_clicked',
          action: 'click',
          label: 'play clicked'
        })

        if (this.$refs.scroller && this.$refs.scroller.scrollX < 0) {
          this.$refs.scroller.moveToStart()
        }
        this.forcePause = false

        if (this.fetchInterval === null) {
          this.startFetching()
        }

        if (this.queueInterval === null) {
          this.queueInterval = setInterval(this.checkQueue, 2000)
        }
      }
    },
    clearFetchInterval() {
      clearInterval(this.fetchInterval)
      this.fetchInterval = null
    },
    checkQueue() {
      // only add stuff if we are scrolled at the beginning
      let startCheck = false
      if (!this.isLatestDesktop && !this.isIndexMobile && this.$refs.scroller) {
        if (this.$refs.scroller.scrollX >= 0 && !this.forcePause) {
          startCheck = true
          if (this.fetchInterval === null) {
            this.startFetching()
          }
        } else {
          this.clearFetchInterval()
          return
        }
      } else {
        // we are in _latest or index mobile
        startCheck = true
      }

      if (this.queue.length && startCheck) {
        this.transitionName = 'entry'
        // this.classifieds.unshift(...testClassifieds)

        if (!this.isLatestDesktop) {
          this.classifieds.unshift(this.queue[0])
        } else {
          this.classifieds.push(this.queue[0])
        }

        this.$nextTick(() => {
          if (this.classifieds.length > this.maxClassifieds) {
            if (!this.isLatestDesktop) {
              // remove the last one and add it to hidden
              const deletedClsfd = this.classifieds.pop()
              this.hiddenClassifieds.add(deletedClsfd.id)
            } else {
              this.maxClassifiedsReached()
            }
          }
        })

        // remove first entry
        this.queue.shift()
      }
    },
    showLinkStatus(state) {
      this.showLink = state
    },
    setRecentLocation(loc) {
      this.recentLocation = {
        radius: loc.radius || 800000,
        city: loc.city || '',
        coordinates: loc.coordinates || null,
        postcode: loc.postcode || null
      }
    },
    addTestEntries() {
      const testClassifieds = [
        {
          id: 'test' + this.testCounter + 1,
          category_ids: [20001, 20002, 20003, 20006, 20016],
          title: 'TEST' + (this.testCounter + 1),
          price: '45.222 €',
          thumb_pattern: 'https://static.plot.gr/38123271_0_{size}.jpg',
          seo_url: '/plot/view/38123271-mezoneta-3235-tm-pros-enoikiasi',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: 'test' + this.testCounter + 2,
          category_ids: [20001, 15000, 15001, 15218],
          title: 'TEST' + (this.testCounter + 2),
          price: '3.445 €',
          thumb_pattern: 'https://static-cz.car.gr/40022717_1_{size}.jpeg',
          seo_url: '/xyma/view/12073974-the-best-laptop-in-the-universe',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: 'test' + this.testCounter + 3,
          category_ids: [20001, 20002, 20003, 20006, 20015],
          title: 'TEST' + (this.testCounter + 3),
          price: '3.445 €',
          thumb_pattern: 'https://static-cz.car.gr/40022718_2h_{size}.jpeg',
          seo_url: '/xyma/view/12073974-the-best-laptop-in-the-universe',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: 'test' + this.testCounter + 4,
          category_ids: [20001, 50, 4112, 4640, 5381],
          title: 'TEST' + (this.testCounter + 4),
          price: '45.222 €',
          thumb_pattern: 'https://static.plot.gr/38123271_0_{size}.jpg',
          seo_url: '/plot/view/38123271-mezoneta-3235-tm-pros-enoikiasi',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          modified: new Date().getTime(),
          id: 'test' + this.testCounter + 5,
          category_ids: [20001, 50, 2915, 5905, 3931, 7888],
          title: 'TEST' + (this.testCounter + 5),
          price: '2.596 €',
          seo_url: '/classifieds/cars/view/1644603-nissan-x-trail',
          thumb_pattern: 'https://static.car.gr/1644603_0_{size}.jpg',
          has_photos: true
        }
      ]

      this.testCounter += testClassifieds.length

      this.queue.push(...testClassifieds)
    },
    recentsChanged: debounce(function(ac) {
      this.activeRecents = ac
      this.enableTransitions = true
      this.transitionName = 'empty'
      this.firstFeedSet = false
      this.classifieds = []
      this.queue = []
      this.refetch()
    }, DEBOUNCE_DEFAULT),
    locationChanged(location) {
      // save to storage
      this.$store.dispatch('storage/set', {
        landing: {
          location: { ...location }
        }
      })

      this.recentLocation = location
      this.enableTransitions = true
      this.transitionName = 'empty'
      this.queue = []
      this.refetch()
    },
    refetch() {
      this.classifieds = []
      this.fetchFeed()
    },
    async fetchFeed() {
      try {
        if (!this.firstFetchTry && this.loadedFeed && !this.params) {
          // only set if not params are present
          this.firstFetchTry = true
          this.setFeedData(this.loadedFeed)
          this.firstFeedSet = true
          return
        }
        this.firstFetchTry = true

        const params =
          !this.params || (this.isIndexMobile && !this.showOnlyPlotRecents)
            ? {}
            : this.params
        const response = await this.$axios.get('/api/user/feeds/recent/', {
          params
        })
        const body = response.data
        if (!(body && body.data && body.data.feed)) {
          throw invalidBodyError(body)
        }

        this.setFeedData(formatFeed(body.data.feed))
        this.firstFeedSet = true
      } catch (error) {
        error.message = `Failed to fetch feed with error: ${error}`
        this.$logger.captureError(error)
      }
    },
    maxClassifiedsReached() {
      // populate in component
    },
    setFeedData(feed: Feed) {
      const { classifieds } = feed

      if (this.classifieds.length) {
        // we already have some classifieds so queue the new ones

        const newClassifieds = classifieds.filter(clsfd => {
          return !(
            this.classifieds.some(c => c.id === clsfd.id) ||
            this.hiddenClassifieds.has(clsfd.id) ||
            this.queue.some(c => c.id === clsfd.id)
          )
        })

        if (newClassifieds.length) {
          this.queue.push(...newClassifieds)
        }
      } else {
        this.enableTransitions = true
        this.transitionName = 'empty'
        this.$nextTick(() => {
          this.classifieds = classifieds
        })
      }
    }
  }
})
