import { Path, Field } from '~/models/dealer/integration/classified/mapping'
import { Option } from '~/models/shared/types'
import { DealerClassifiedFieldState } from '~/store/modules/shared/dealers/integration/classifieds/field/state'
import { GetterTreeWithRootState } from '~/store/types'

export default {
  getPathOptions: ({ paths, mappings }) => (field: Field): Option[] => {
    const getOption = (p: Path) => ({
      text: p.split('/').join(' / '), // TODO: use single loop
      value: p
    })
    const options: Option[] = [...paths].map(getOption)

    const path = mappings.get(field)?.path
    if (path && !paths.has(path)) {
      options.push(getOption(path))
    }
    return options
  },
  pathIsValid: ({ paths }) => (path: Path): boolean => paths.has(path)
} as GetterTreeWithRootState<DealerClassifiedFieldState>
