export interface LoginModalState {
  isLoginModalVisible: boolean
  isLoginSuccess: boolean
  redirectAfterLogin: boolean
}
export const LOGIN_MODAL_NS = 'loginModal'
function getDefaultState(): LoginModalState {
  return {
    isLoginModalVisible: false,
    isLoginSuccess: false,
    redirectAfterLogin: true
  }
}
export default getDefaultState
