import { IBuilder } from '~/builders/internal/IBuilder'
import { injectable } from '~/decorators/dependency-container'
import { IndexedKeyGetter } from '~/models/store/collection'

@injectable()
export class PartitionedCollectionInitialStateBuilder
  implements IBuilder<Record<string, null>> {
  private partitionStateKeyGetter: IndexedKeyGetter | undefined
  private numberOfPartitions: number | undefined

  setNumberOfPartitions(value: number) {
    this.numberOfPartitions = value
    return this
  }

  setPartitionStateKeyGetter(value: IndexedKeyGetter | undefined) {
    this.partitionStateKeyGetter = value
    return this
  }

  build(): Record<string, null> {
    const { partitionStateKeyGetter, numberOfPartitions } = this

    const state: Record<string, null> = {}
    for (let i = 0; i < numberOfPartitions!; i++) {
      state[partitionStateKeyGetter!(i)] = null
    }
    return state
  }
}
