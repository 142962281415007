import { Route } from 'vue-router/types/router'
import { Search } from '~/router/routes/car/classifieds/search/components'

export function redirectDeleted(to: Route) {
  if (to.query.deleted) {
    // transform to states
    delete to.query.deleted
    to.query.states = 'deleted'
  }

  return {
    name: '__classifieds_search_list',
    query: { category: 20002, myclassifieds: 1, ...to.query }
  }
}
export const listRoutes = [
  {
    name: '__classifieds_search_list',
    path: '/list/search',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_list_seo',
    path: '/list/search/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_my_account',
    path: '/account/search/',
    redirect: (to: Route) => redirectDeleted(to)
  },
  {
    name: '__classifieds_my',
    path: '/classifieds/my/',
    redirect: (to: Route) => redirectDeleted(to)
  }
]
