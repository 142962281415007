import NotificationTopicService from '~/services/notification/NotificationTopicService'
import {
  SET_SUBSCRIPTIONS,
  SET_LOADING_STATE,
  ENABLE_SUBSCRIPTION,
  DISABLE_SUBSCRIPTION,
  ENABLE_SUBSCRIPTIONS,
  SET_GROUPS
} from '~/store/modules/shared/notification/topic/subscription/mutation-types'
import { NotificationTopicSubscriptionState } from '~/store/modules/shared/notification/topic/subscription/state'
import { ActionTreeWithRootState } from '~/store/types'

export default {
  async loadSubscriptions({ commit }) {
    commit(SET_LOADING_STATE, true)
    try {
      const { topics, groups } = await this.$dep(
        NotificationTopicService
      ).getNotificationsTopicsAndGroups()
      commit(SET_SUBSCRIPTIONS, topics)
      commit(SET_GROUPS, groups)
    } catch (e) {
      this.$logger.captureError(e)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async setSubscriptionsToDefault({ commit, state }) {
    commit(
      SET_SUBSCRIPTIONS,
      await this.$dep(NotificationTopicService).setSubscriptionsToDefault(
        state.groups
      )
    )
  },
  async subscribe({ commit }, topic: string) {
    await this.$dep(NotificationTopicService).subscribe(topic)
    commit(ENABLE_SUBSCRIPTION, topic)
  },
  async unsubscribe({ commit }, topic: string) {
    await this.$dep(NotificationTopicService).unsubscribe(topic)
    commit(DISABLE_SUBSCRIPTION, topic)
  },
  async setSubscription(
    { dispatch },
    { topic, subscribe }: { topic: string; subscribe: boolean }
  ) {
    await dispatch(subscribe ? 'subscribe' : 'unsubscribe', topic)
  },
  async groupSubscribe(
    { commit, getters: { subscribedTopics, getDescendantTopics } },
    group: string
  ) {
    const topics: string[] = [
      ...new Set<string>([...subscribedTopics, ...getDescendantTopics(group)])
    ]

    commit(ENABLE_SUBSCRIPTIONS, topics)
    commit(
      SET_SUBSCRIPTIONS,
      await this.$dep(NotificationTopicService).setTopics(topics)
    )
  },
  async groupUnsubscribe(
    { commit, getters: { subscribedTopics, getDescendantTopics } },
    group: string
  ) {
    const descendantTopics: Set<string> = new Set(getDescendantTopics(group))
    const topics: string[] = subscribedTopics.filter(
      (t: string) => !descendantTopics.has(t)
    )
    commit(
      SET_SUBSCRIPTIONS,
      await this.$dep(NotificationTopicService).setTopics(topics)
    )
  },
  async setGroupSubscription(
    { dispatch },
    { group, subscribe }: { group: string; subscribe: boolean }
  ) {
    await dispatch(subscribe ? 'groupSubscribe' : 'groupUnsubscribe', group)
  }
} as ActionTreeWithRootState<NotificationTopicSubscriptionState>
