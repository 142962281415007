import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { Params, DealersMapResult } from '~/models/dealer/map/types'
import { formatDealer } from '~/services/dealers/formatters'
import { formatPagination } from '~/services/formatters'
import { invalidBodyError } from '../../errors'

interface DealersMapPage {
  path: string
  endpoint: string
  query: string
}

interface CustomResult<T> {
  data: T
  page: DealersMapPage
}

@containerScoped()
export default class DealersMapService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getDealersMapInfo(
    params: Params
  ): Promise<CustomResult<DealersMapResult>> {
    const response: AxiosResponse = await this.http.get(
      '/api/catalogue/dealers/',
      {
        params
      }
    )
    const { data: body } = response
    if (!body || !body.data) {
      throw invalidBodyError(body)
    }
    return {
      data: this.formatDealersMapDataResponse(body.data),
      page: this.formatDealersMapPageResponse(body._page)
    }
  }

  private formatDealersMapPageResponse(p: any): DealersMapPage {
    return {
      path: p.path,
      endpoint: p.endpoint,
      query: p.query
    }
  }

  private formatDealersMapDataResponse(r: any): DealersMapResult {
    return {
      facets: r.facets,
      sorting: r.sorting,
      results: {
        pagination: formatPagination(r.results.pagination),
        rows: r.results.rows.map(formatDealer)
      },
      markers: r.markers
    }
  }
}
