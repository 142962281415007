import { MutationTree } from 'vuex'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  SET_SUBSCRIPTIONS,
  SET_LOADING_STATE,
  ENABLE_SUBSCRIPTION,
  DISABLE_SUBSCRIPTION,
  ENABLE_SUBSCRIPTIONS,
  SET_GROUPS
} from '~/store/modules/shared/notification/topic/subscription/mutation-types'
import { NotificationTopicSubscriptionState } from '~/store/modules/shared/notification/topic/subscription/state'

const { setter } = createMutationCreator<NotificationTopicSubscriptionState>()

export default {
  ...setter(SET_LOADING_STATE, 'loading'),
  ...setter(SET_SUBSCRIPTIONS, 'subscriptions'),
  ...setter(SET_GROUPS, 'groups'),
  [ENABLE_SUBSCRIPTION](state, topic: string) {
    const subs = new Map(state.subscriptions)
    subs.set(topic, { ...subs.get(topic)!, enabled: true })
    state.subscriptions = subs
  },
  [ENABLE_SUBSCRIPTIONS](state, topics: string[]) {
    const subs = new Map(state.subscriptions)
    for (const topic of topics) {
      subs.set(topic, { ...subs.get(topic)!, enabled: true })
    }
    state.subscriptions = subs
  },
  [DISABLE_SUBSCRIPTION](state, topic: string) {
    const subs = new Map(state.subscriptions)
    subs.set(topic, { ...subs.get(topic)!, enabled: false })
    state.subscriptions = subs
  }
} as MutationTree<NotificationTopicSubscriptionState>
