import { Merge } from 'type-fest'
import { MutationTree } from 'vuex'
import {
  UserSearchBooleanFilter,
  UserSearchFilters
} from '~/models/user/search'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  SET_USERS,
  SET_PAGE,
  SET_LOADING_STATE,
  SET_SEARCH_TEXT,
  SET_FILTERS,
  SET_SCHEMA,
  TOGGLE_FILTER,
  SET_USER_TYPE,
  CLEAR_FILTERS,
  RESET_PAGE_NUMBER,
  SET_SITE,
  SET_SORTING
} from '~/store/modules/shared/admin/users/search/mutation-types'
import {
  UserSearchState,
  getEmptyFilters
} from '~/store/modules/shared/admin/users/search/state'

const { setter } = createMutationCreator<UserSearchState>()

export default {
  ...setter(SET_USERS, 'users'),
  ...setter(SET_SEARCH_TEXT, 'searchText'),
  [SET_FILTERS](state, filters: UserSearchFilters) {
    const fs: Merge<
      UserSearchFilters,
      Partial<Pick<UserSearchFilters, 'boolean'>>
    > = { ...filters }
    const booleanFilters = fs.boolean
    delete fs.boolean
    state.filters = fs

    if (booleanFilters) {
      state.booleanFilters = booleanFilters
    }
  },
  [CLEAR_FILTERS](state) {
    state.filters = getEmptyFilters()
    state.booleanFilters = new Set()
  },
  [TOGGLE_FILTER](state, filter: UserSearchBooleanFilter) {
    const booleanFilters = new Set(state.booleanFilters)
    booleanFilters.has(filter)
      ? booleanFilters.delete(filter)
      : booleanFilters.add(filter)
    state.booleanFilters = booleanFilters
  },
  [SET_USER_TYPE](state, userType: UserSearchState['filters']['user-type']) {
    state.filters = {
      ...state.filters,
      'user-type': userType
    }
  },
  [SET_SORTING](state, sorting: string) {
    state.sorting = sorting
  },
  [SET_SITE](state, site: UserSearchState['filters']['site']) {
    state.filters = {
      ...state.filters,
      site
    }
  },
  ...setter(SET_PAGE, 'page'),
  [RESET_PAGE_NUMBER](state) {
    state.page = { ...state.page, number: 1 }
  },
  ...setter(SET_LOADING_STATE, 'loading'),
  ...setter(SET_SCHEMA, 'schema')
} as MutationTree<UserSearchState>
