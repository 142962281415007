import Tree from '~/models/shared/tree/Tree'
import { TreeNode } from '~/models/shared/tree/types'
import { NotificationTopicSubscriptionState } from '~/store/modules/shared/notification/topic/subscription/state'
import { GetterTreeWithRootState } from '~/store/types'
import { NotificationGroup } from '~/models/notification/types'
import { treeFromTuples } from '~/models/shared/tree'

const isGroup = (value: string, groups: Array<NotificationGroup>) =>
  Object.values(getNotificationTopicGroup(groups)).includes(value)
const descendantIsSubscribed = (state: NotificationTopicSubscriptionState) => (
  node: TreeNode
) => {
  const id = node.id
  return state.subscriptions.has(id) && state.subscriptions.get(id)!.enabled
}

function getNotificationTopicGroup(groups: Array<NotificationGroup>) {
  const groupTypes = {} as { [key: string]: string }
  groupTypes[groups[0].value.toUpperCase()] = groups[0].value
  groups[0].children.forEach((g: NotificationGroup) => {
    groupTypes[g.value.toUpperCase()] = g.value
  })
  return groupTypes
}

function notificationTopicTree(
  groups: Array<NotificationGroup>
): Readonly<Tree> {
  const tree = [] as any

  groups[0].children.forEach((c: NotificationGroup) => {
    tree.push([c.value, c.children.map((cc: NotificationGroup) => cc.value)])
  })
  return treeFromTuples([[groups[0].value, tree]])
}

export default {
  topicIsSubscribed: state => (topic: string): boolean =>
    state.subscriptions.has(topic) && state.subscriptions.get(topic)!.enabled,
  subscribedTopics: (state): string[] =>
    [...state.subscriptions.values()]
      .filter(topic => topic.enabled)
      .map(topic => topic.value),

  getDescendantTopics: state => (group: string): string[] => {
    return notificationTopicTree(state.groups)
      .getDescendants(group, desc => {
        return !isGroup(desc.id, state.groups)
      })
      .map(node => node.id as string)
  },

  everyDescendantIsSubscribed: state => (group: string) => {
    const descendants = notificationTopicTree(state.groups).getDescendants(
      group,
      desc => !isGroup(desc.id, state.groups)
    )
    return descendants.every(descendantIsSubscribed(state))
  },
  someDescendantIsSubscribed: state => (group: string) => {
    const descendants = notificationTopicTree(state.groups).getDescendants(
      group,
      desc => !isGroup(desc.id, state.groups)
    )
    return descendants.some(descendantIsSubscribed(state))
  }
} as GetterTreeWithRootState<NotificationTopicSubscriptionState>
