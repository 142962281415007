import { UserCredits } from '~/models/user/types'

export interface BackgroundJob {
  completed: boolean | null
  completedPercentage: number
  description: string
  estimatedFinishTime: string | null
  failure: number
  id: number
  lastUpdated: string | null
  name: string
  pending: number
  size: number
  started: string
  status: string
  success: number
  terminated: boolean
  totalWorkDone: number
  translatedDescription: string
  workCompleted: boolean
}

export enum BackgroundJobStatusTypes {
  PENDING = 'pending',
  COMPLETED = 'completed',
  TERMINATED = 'terminated',
  RUNNING = 'running'
}

export enum BackgroundJobActionTypes {
  MASS_TOUCH = 'mass_actions.touch',
  PRICE_CHANGE = 'mass_actions.price_change',
  SHOW = 'mass_actions.show',
  HIDE = 'mass_actions.hide',
  CUSTOM_PAGE = 'mass_actions.custom_page'
}

export interface BackgroundJobResult {
  job: BackgroundJob
  credits?: UserCredits
}

export interface BackgroundJobsResult {
  jobs: BackgroundJob[]
  credits?: UserCredits
}

export interface ClassifiedBackgroundJob {
  description: string
  id: number
  lastUpdated: string
  name: string
  status: string
}

export interface ClassifiedBackgroundJobs {
  failed: ClassifiedBackgroundJob[]
  succeed: ClassifiedBackgroundJob[]
}
