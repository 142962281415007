import { inject, singleton } from 'tsyringe'
import { ClientOnly } from '~/decorators'
import { SCROLL_DEPTH_THRESHOLDS } from '~/constants/scroll/scroll-depth'
import AnalyticsService from '~/services/AnalyticsService'

@singleton()
@ClientOnly
export default class ScrollDepthService {
  constructor(@inject(AnalyticsService) private analytics: AnalyticsService) {}
  private currentThreshold: number = 0

  initialize() {
    window.addEventListener(
      'scroll',
      _event => {
        const maxScrollHeight = this.getCurrentPageMaxScrollHeight()
        const scrollVal = window.scrollY
        const scrollPercentage = Math.round((scrollVal / maxScrollHeight) * 100)
        if (
          SCROLL_DEPTH_THRESHOLDS.includes(scrollPercentage) &&
          this.currentThreshold !== scrollPercentage
        ) {
          this.currentThreshold = scrollPercentage
          this.recordEvent(scrollPercentage)
        }
      },
      { passive: true }
    )
  }

  resetCurrentThreshold() {
    this.currentThreshold = 0
  }

  recordEvent(threshold: number) {
    this.analytics.recordEvent({
      namespace: 'scroll_depth',
      action: 'scroll',
      label: threshold.toString()
    })
  }

  getCurrentPageMaxScrollHeight(): number {
    return (
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    )
  }
}
