import { VNode } from 'vue'
import {
  vue3DirectiveBind,
  vue3DirectiveComponentUpdated,
  vue3DirectiveUnbind
} from '~/utils/nuxt3-migration'

const OBSERVER_PROP_NAME = 'c_visibility_observer'

export interface CVisibleOptions {
  handler: (isVisible: boolean) => {}
  once: boolean
  offset: number
  threshold: number
}

class VisibilityObserver {
  el: HTMLElement
  callback: (isIntersecting: boolean) => {}
  offset: number
  threshold: number
  once: boolean
  doneOnce: boolean
  visible: any
  observer: any

  constructor(el: HTMLElement, options: any, node: any) {
    this.el = el
    this.callback = options.callback
    this.offset = options.offset || 0
    this.threshold = options.threshold || 0
    this.once = options.once || false
    this.observer = null
    this.visible = undefined
    this.doneOnce = false
    // Create the observer instance (if possible)
    this.createObserver(node)
  }

  createObserver(node: VNode) {
    if (!node || !node.context) {
      return
    }
    // Remove any previous observer
    if (this.observer) {
      this.destroyObserver()
    }

    // Should only be called once and `callback` prop should be a function
    if (this.doneOnce || typeof this.callback !== 'function') {
      return
    }

    // Create the observer instance
    try {
      this.observer = new IntersectionObserver(this.handler.bind(this), {
        root: null,
        rootMargin: `${this.offset}px`,
        threshold: this.threshold
      })
    } catch {
      // No IntersectionObserver support, so just stop trying to observe
      this.doneOnce = true
      this.observer = undefined
      this.callback(true)
      return
    }

    // Start observing in a `$nextTick()` (to allow DOM to complete rendering)
    node.context.$nextTick(() => {
      requestAnimationFrame(() => {
        // Placed in an `if` just in case we were destroyed before
        // this `requestAnimationFrame` runs
        if (this.observer) {
          this.observer.observe(this.el)
        }
      })
    })
  }

  handler(entries: any[]) {
    const entry = entries ? entries[0] : {}
    const isIntersecting = Boolean(
      entry.isIntersecting || entry.intersectionRatio > 0.0
    )
    if (isIntersecting !== this.visible) {
      this.visible = isIntersecting
      this.callback(isIntersecting)
      if (this.once && this.visible) {
        this.doneOnce = true
        this.destroyObserver()
      }
    }
  }

  destroyObserver() {
    this.observer && this.observer.disconnect()
    this.observer = null
  }
}

const destroy = (el: any) => {
  const observer = el[OBSERVER_PROP_NAME]
  if (observer && observer.stop) {
    observer.stop()
  }
  delete el[OBSERVER_PROP_NAME]
}

const bind = (el: any, { value }: { value: CVisibleOptions }, node: VNode) => {
  const { handler, offset, threshold, once } = value
  const options = {
    offset: offset || 0,
    threshold: threshold || 0,
    once: once || false,
    callback: handler
  }
  // Destroy any previous observer
  destroy(el)
  // Create new observer
  el[OBSERVER_PROP_NAME] = new VisibilityObserver(el, options, node)
}

// When the directive options may have been updated (or element)
const componentUpdated = (
  el: any,
  { value, oldValue }: { value: CVisibleOptions; oldValue: CVisibleOptions },
  node: VNode
) => {
  const optionsChanged = () => {
    // does not support different handler currently
    return (
      value.offset !== oldValue.offset ||
      value.once !== oldValue.once ||
      value.threshold !== oldValue.threshold
    )
  }
  if (el && (optionsChanged() || !el[OBSERVER_PROP_NAME])) {
    // Re-bind on element
    bind(el, { value }, node)
  }
}

// When directive un-binds from element
const unbind = (el: HTMLElement) => {
  // Remove the observer
  destroy(el)
}

export default {
  [vue3DirectiveBind]: bind,
  [vue3DirectiveComponentUpdated]: componentUpdated,
  [vue3DirectiveUnbind]: unbind
}
