import { CIcon } from '~/icons/types'
export const ciExclamationTriangle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M22 15.8 14.6 3c-1.2-2-4-2-5.2 0L2 15.8c-1.2 2 .3 4.5 2.6 4.5h14.7c2.4 0 3.8-2.5 2.7-4.5ZM10.5 7.7c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5V11c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5V7.7Zm2.6 8.9c-.3.2-.7.4-1.1.4-.8 0-1.5-.7-1.5-1.5S11.2 14 12 14s1.5.7 1.5 1.5c0 .4-.2.8-.4 1.1Z'
    }
  ],
  name: 'exclamation-triangle',
  type: 'solid'
}
