import { arraysEqual } from './array'

export function findDeep({
  object,
  key,
  value
}: {
  object: object
  key: any
  value: any
}): any {
  let result = null
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      result = findDeep({ object: object[i], key, value })
      if (result) {
        break
      }
    }
  } else {
    for (const prop in object) {
      if (prop === key) {
        // @ts-ignore
        if (object[prop] === value) {
          return object
        }
      }
      // @ts-ignore
      if (object[prop] instanceof Object || Array.isArray(object[prop])) {
        // @ts-ignore
        result = findDeep({
          object: (object as any)[prop],
          key,
          value
        })
        if (result) {
          break
        }
      }
    }
  }
  return result
}
export function deepJsonCopy(object: any) {
  return JSON.parse(JSON.stringify(object))
}

export function serialize(obj: any) {
  const str = []
  for (const p in obj)
    if (Array.isArray(obj[p])) {
      for (let i = 0; i < obj[p].length; i++) {
        if (!obj[p][i]) continue
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][i]))
      }
    } else if (Object.prototype.hasOwnProperty.call(obj, p)) {
      if (!obj[p]) continue
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

export function removeNullValues(obj: any) {
  obj = JSON.parse(JSON.stringify(obj))
  Object.entries(obj).forEach(([key, value]) => {
    // @ts-ignore
    if (value === null || arraysEqual([null], value)) {
      delete obj[key]
    }
  })
  return obj
}

export function getValueByPath(o: any, s: any) {
  s = s.replace(/\[(\w+)\]/g, '.$1')
  s = s.replace(/^\./, '')
  const a = s.split('.')
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

export function isObject(item: any) {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    typeof item !== 'function'
  )
}

export function deepMerge(target: any, source: any) {
  const output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      // todo: this array merging might need more thorough testing
      if (Array.isArray(source[key]) && target[key]) {
        output[key] = [...source[key], ...target[key]]
      } else if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = deepMerge(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export function multiDeepMerge(objects: object[]): object {
  if (!objects || objects.length === 0) {
    return {}
  }
  if (objects.length === 1) {
    return objects[0]
  }

  let merged = {}
  for (const o of objects) {
    merged = deepMerge(merged, o)
  }
  return merged
}
/**
 * Remove object keys width undef and null
 * @param obj
 * @returns
 */
export function removeKeysWithNoValues(
  obj: { [key: string]: any },
  nullForEmptyObj: boolean = false
): { [key: string]: any } | null {
  Object.keys(obj).forEach(
    key =>
      (obj[key] === undefined || obj[key] === null || obj[key] === '') &&
      delete obj[key]
  )
  if (nullForEmptyObj && Object.keys(obj).length === 0) {
    return null
  }
  return obj
}
/**
 * Convert Kebab and Snake to camelCase
 * new_var -> newVar
 * new-var -> newVar
 */

export function toCamelCase(o: any, ignoreKey?: string | string[]): any {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach(k => {
      if (ignoreKey) {
        const shouldIgnore = Array.isArray(ignoreKey)
          ? ignoreKey.includes(k)
          : ignoreKey === k
        n[shouldIgnore ? k : toCamel(k)] = toCamelCase(o[k], ignoreKey)
      } else {
        n[toCamel(k)] = toCamelCase(o[k])
      }
    })

    return n
  } else if (Array.isArray(o)) {
    return o.map(i => {
      return toCamelCase(i, ignoreKey)
    })
  }

  return o
}

export function toCamel(s: string) {
  return s.replace(/([-_][a-zA-Z0-9])/g, ($1: string) =>
    $1.charAt(1).toUpperCase()
  )
}

export function objectsEqual(o1: object, o2: object) {
  return JSON.stringify(o1) === JSON.stringify(o2)
}

export function toBase64(obj: object) {
  return Buffer.from(JSON.stringify(obj)).toString('base64')
}

export function toURIBase64(obj: object) {
  return encodeURIComponent(toBase64(obj))
}

export function fromBase64(str: string) {
  return JSON.parse(Buffer.from(str, 'base64').toString())
}

export function sortObject(obj: { [key: string]: any }) {
  return Object.keys(obj)
    .sort()
    .reduce((accumulator: { [key: string]: any }, key) => {
      accumulator[key] = obj[key]
      return accumulator
    }, {})
}
