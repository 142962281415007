import { AxiosResponse } from 'axios'
import CacheService from '~/services/CacheService'
import { deepJsonCopy } from '~/utils/object'
import { cacheableUrls } from './misc'
// @ts-ignore
import hash from 'object-hash'
import { appendToResponseHeader, getHashableFromConfig } from '~/utils/http'
import { ServerResponse } from 'http'

export default function onResponse(
  axiosResponse: AxiosResponse,
  cache: CacheService,
  nodeResponse: ServerResponse
) {
  if (process.client) {
    attemptToSetCache(axiosResponse, cache)
  } else if (process.server) {
    injectBackendCookieHeaders(axiosResponse, nodeResponse)
  }
  return axiosResponse
}

function injectBackendCookieHeaders(
  axiosResponse: AxiosResponse,
  nodeResponse: ServerResponse
) {
  if (
    // Only inject /api/ requests headers, exclude external services
    axiosResponse.config.url?.startsWith('/api/') &&
    axiosResponse.headers['set-cookie']
  ) {
    appendToResponseHeader(
      nodeResponse,
      'set-cookie',
      axiosResponse.headers['set-cookie']
    )
  }
}

function attemptToSetCache(response: AxiosResponse, cache: CacheService): void {
  const cacheableUrl = cacheableUrls.find(
    url => response.config.url && url.regex.test(response.config.url)
  )
  if (cacheableUrl) {
    const hashable = getHashableFromConfig(response.config)
    const key = hash(hashable)
    cache.set(
      key,
      deepJsonCopy({
        data: response.data
      }),
      cacheableUrl.maxAge
    )
  }
}
