import { interopDefault } from '~/router/utils'
export const adminTranslationsRoutes = [
  {
    name: '__admin_translations',
    path: '/admin/translations-new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/translations.vue' /* webpackChunkName: "pages-admin-translations" */
        )
      ),
    meta: { noFooter: true }
  }
]
