






import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    width: {
      type: String,
      required: false,
      default() {
        return '100%'
      }
    },
    height: {
      type: String,
      required: false,
      default: null
    }
  }
})
