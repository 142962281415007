import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__shorts_edit',
    path: '/shorts/:cid/edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/shorts/shorts-edit.vue' /* webpackChunkName: "pages-shorts-videos.vue" */
        )
      )
  },
  {
    name: '__shorts_classified',
    path: '/shorts/classified/:cid',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/shorts/index.vue' /* webpackChunkName: "pages-shorts-classified-index.vue" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__shorts',
    path: '/shorts/:vid',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/shorts/index.vue' /* webpackChunkName: "pages-shorts-index.vue" */
        )
      ),
    meta: { noFooter: true }
  }
]
