
























import { ErrorPageError } from '~/models/error'
import { HttpStatus } from '~/constants/http'
import error404Image from '~/assets/img/errors/404.png'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  },
  computed: {
    errorImage() {
      const { statusCode } = this.error
      switch (statusCode) {
        case HttpStatus.NOT_FOUND: {
          return error404Image
        }
        default: {
          return null
        }
      }
    },
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    })
  }
})
