export interface Head {
  title?: string | null
  meta?: MetaItem[]
  htmlAttrs?: object
  bodyAttrs?: Record<string, any>
  link?: LinkItem[]
  titleTemplate?: string
}

export enum LinkPreloadType {
  IMAGE = 'image'
}

export interface LinkItem {
  rel: string
  href: string
  as?: LinkPreloadType
}

export interface MetaItem {
  hid?: string
  content: string
  name?: string
  property?: string
}

export interface BackendMetaItem {
  content: string
  property?: string
  name?: string
}
