import { interopDefault } from '~/router/utils'

export const Search: any = () =>
  interopDefault(
    import(
      '~/pages/car/classifieds/search/search.vue' /* webpackChunkName: "pages-classifieds-search-search" */
    )
  )

export const MapSearch = () =>
  interopDefault(
    import(
      '~/pages/shared/classifieds/search/map-search.vue' /* webpackChunkName: "pages-classifieds-search-map-search" */
    )
  )
