import { Geolocation } from '~/models/common/types'
import { CountryCode } from '~/models/location/country'

export interface FullLocation {
  geolocation: Geolocation
  city: string
  address: string
  postcode: string | null
  country: CountryCode
}

export enum GoogleAutocompleteInputMode {
  ADDRESS = 'address',
  CITY = 'city',
  POSTCODE = 'postcode',
  COUNTRY = 'country'
}
