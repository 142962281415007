import { Classified } from '~/models/classified/types'
import { containerScoped } from '~/decorators/dependency-container'

@containerScoped()
export default class ClassifiedSearchPageUrlService {
  getUrl(path: string): string {
    // Append path to base url
    return location.origin + path
  }

  getClassifiedUrl(classified: Classified): string {
    return classified.seo_url || '/' + classified.id
  }
}
