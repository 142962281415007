import { interopDefault } from '~/router/utils'

export const adminIpsRoutes = [
  {
    name: '__admin_ips_access_toggles',
    path: '/admin/ips/access-toggles',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/ips/access-toggles.vue' /* webpackChunkName: "pages-shared-admin-ips-access-toggles" */
        )
      )
  }
]
