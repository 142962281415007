


















import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { TableVerticalAlign } from '~/models/table'

export default defineComponent({
  props: {
    maxHeight: {
      type: Number,
      default: null
    },
    tableClass: {
      type: [String, Array],
      default: () => []
    },
    striped: {
      type: Boolean,
      default: false
    },
    stickyColumn: {
      // Currently only sets the first column as sticky
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    verticalAlign: {
      type: String as PropType<TableVerticalAlign>,
      default: TableVerticalAlign.MIDDLE
    },
    withPagination: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const {
      maxHeight,
      tableClass,
      striped,
      stickyColumn,
      condensed,
      hover,
      verticalAlign
    } = toRefs(props)

    const style = computed(() => {
      function maxHeightStyle() {
        if (maxHeight.value) {
          return {
            maxHeight: `${maxHeight.value}px`
          }
        }
        return {}
      }
      return { ...maxHeightStyle() }
    })

    const tableClasses = computed(() => {
      function stripedClasses() {
        return striped.value ? ['striped'] : []
      }
      function stickyColumnClasses() {
        return stickyColumn.value ? ['sticky-column'] : []
      }
      function condensedClasses() {
        return condensed.value ? ['condensed'] : []
      }
      function hoverClasses() {
        return hover.value ? ['hover'] : []
      }
      function verticalAlignClasses() {
        switch (verticalAlign.value) {
          case TableVerticalAlign.TOP: {
            return ['vertical-align', 'top']
          }
          case TableVerticalAlign.MIDDLE: {
            return ['vertical-align', 'middle']
          }
          case TableVerticalAlign.BOTTOM: {
            return ['vertical-align', 'bottom']
          }
          default: {
            return []
          }
        }
      }

      return [
        ...verticalAlignClasses(),
        ...hoverClasses(),
        ...stripedClasses(),
        ...stickyColumnClasses(),
        ...condensedClasses(),
        tableClass.value
      ]
    })

    return { style, tableClasses }
  }
})
