import { GetterTreeWithRootState } from '~/store/types'
import { EditState } from './state'
import { USER_NS } from '~/store/modules/shared/user/state'

export default {
  classifiedEditingIsDisabled(state, _getters, _rootState, rootGetters) {
    const userIsAdmin = rootGetters[`${USER_NS}/isAdmin`]

    if (userIsAdmin || !state.info) {
      return false
    }

    const activeSaleRequestStatus =
      state.info.saleRequests?.activeSaleRequestStatus
    const classifiedIsHiddenDueToSaleRequest =
      activeSaleRequestStatus &&
      ['completed', 'pending'].includes(activeSaleRequestStatus)

    return state.info.deleted || classifiedIsHiddenDueToSaleRequest
  }
} as GetterTreeWithRootState<EditState>
