






































import {
  computed,
  defineComponent,
  ref,
  Ref,
  toRefs
} from '@nuxtjs/composition-api'
import { useUrl } from '~/compositions/url'
import { useDep } from '~/compositions/dependency-container'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import CExternalLinkMediatorModal from '~/components/shared/configurable/modal/CExternalLinkMediatorModal.vue'

export default defineComponent({
  components: { CExternalLinkMediatorModal },
  props: {
    href: {
      type: String,
      default: null
    },
    to: {
      type: [Object, String],
      default: null
    },
    target: {
      type: String,
      default: '_self'
    },
    prefetch: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    useExternalLinkMediator: {
      type: Boolean,
      default: false
    },
    strictExternal: {
      type: Boolean,
      default: false
    },
    externalLinkMediatorDisplayUrl: {
      type: String,
      default: null
    },
    role: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { href, disabled, useExternalLinkMediator, strictExternal } = toRefs(
      props
    )

    const showExternalLinkMediatorModal = ref(false)
    const { urlIsExternal, urlIsGatewayLink } = useUrl()

    const dealerSiteService = useDep(DealerSiteService)

    const onAnchorClick = (e: Event): void => {
      if (disabled.value) {
        e.preventDefault()
        return
      }
      emit('click', e)

      if (!href.value) {
        return
      }

      if (useExternalLinkMediator.value) {
        if (
          !dealerSiteService.routeIsOfDealerSite() &&
          (isExternalLink.value || isGatewayLink.value)
        ) {
          e.preventDefault()
          showExternalLinkMediatorModal.value = true
        }
      }
    }

    const onNuxtLinkAnchorClick = (e: Event, navigate: any) => {
      if (disabled.value) {
        e.preventDefault()
      }
      emit('click', e)

      navigate(e)
    }

    const isExternalLink = computed(() => {
      if (!href.value) {
        return false
      }
      return urlIsExternal(href.value, strictExternal.value)
    })

    const isGatewayLink = computed(() => {
      if (!href.value) {
        return false
      }
      return urlIsGatewayLink(href.value)
    })

    const rel: Ref<string | null> = computed(() => {
      if (isGatewayLink.value || isExternalLink.value) {
        return 'nofollow'
      }
      return null
    })

    return {
      rel,
      showExternalLinkMediatorModal,
      onAnchorClick,
      onNuxtLinkAnchorClick
    }
  }
})
