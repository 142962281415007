





import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props) {
    const { direction } = toRefs(props)

    const classes = computed(() => {
      switch (direction.value) {
        case 'vertical':
        default: {
          return ['vertical-group']
        }
        case 'list': {
          return ['list-group']
        }
        case 'horizontal': {
          return ['horizontal-group']
        }
      }
    })

    return { classes }
  }
})
