import { TreeLevelCategory } from '~/models/category/types'
import { CategoryChooserResult } from '~/models/category/chooser'
import { toCamelCase } from '~/utils/object'
import { Category } from '~/models/search/types'

export function formatCategory(c: any): Category {
  return {
    humanName: c.human_name,
    humanNamePlural: c.human_name_plural,
    humanPath: c.human_path,
    id: c.id,
    name: c.name,
    parentId: c.parent_id,
    pluralName: c.plural_name
  }
}

export function formatTreeLevelCategory(c: any): TreeLevelCategory {
  return {
    humanPath: c.human_path,
    hasChildren: c.hasChildren,
    humanName: c.human_name,
    humanNamePlural: c.human_name_plural,
    movedToCategoryUrl: c.moved_to_category_url,
    movedToCategoryId: c.moved_to_category_id,
    id: c.id,
    isActive: c.isActive,
    name: c.name,
    parentId: c.parent_id,
    pluralName: c.plural_name
  }
}

export function formatCategoryChooserResult(body: any): CategoryChooserResult {
  return {
    sections: toCamelCase(body.data.categories),
    breadcrumbs: body._page.breadcrumbs
  }
}
