import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { CompactResult } from '~/models/shared/result'
import { invalidBodyError } from '~/services/errors'
import {
  formatFormCategory,
  formatFormClassified
} from '~/services/form/formatters'
import { formatCompactPage } from '~/services/formatters'
import { EditInfoResult } from '~/models/classified/edit'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class ClassifiedEditService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getEditInfo(
    classifiedId: number
  ): Promise<CompactResult<EditInfoResult>> {
    const response = await this.http.get(
      `/api/classifieds/${classifiedId}/edit/`
    )
    const { data: body } = response
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }
    return {
      data: this.formatEditInfoResponse(body.data),
      page: formatCompactPage(body._page, body?.data?.change_search_url)
    }
  }

  getCopyInfo(classifiedId: number) {
    // Currently uses the edit endpoint
    return this.getEditInfo(classifiedId)
  }

  private formatEditInfoResponse(r: any): EditInfoResult {
    return {
      allCategories: toCamelCase(r.all_categories),
      category: formatFormCategory(r.category),
      classified: formatFormClassified(r.classified),
      registerReferenceCode: r.register_reference_code
    }
  }
}
