import { Dealer } from '~/models/dealer/types'
import { Telephone } from '~/models/shared/types'
import { formatCategory } from '~/services/category/formatters'

export function formatDealer(d: any): Dealer {
  return {
    geolocation: d.geolocation,
    managerId: d.manager_id,
    googleRating: d.google_rating,
    id: d.id,
    categories: d.categories && d.categories.map(formatDealerMapDealerCategory),
    displayName: d.display_name,
    displayPhoto: d.display_photo,
    name: d.name,
    location: d.location,
    telephone1: d.telephone1 && formatDealerTelephone(d.telephone1),
    telephone2: d.telephone2 && formatDealerTelephone(d.telephone2),
    pageUrl: d.page_url,
    searchStats: d.search_stats && {
      count: d.search_stats.count,
      maxPrice: d.search_stats.max_price,
      minPrice: d.search_stats.min_price
    }
  }
}

export function formatDealerMapDealerCategory(c: any) {
  return {
    category: formatCategory(c.category),
    count: c.count,
    selected: c.selected,
    pageUrl: c.page_url
  }
}

export function formatDealerTelephone(t: any): Telephone {
  return {
    code: t.code,
    dontShowToPublic: t.dont_show_to_public,
    telephone: t.telephone,
    social: t.social
  }
}
