import { GetterTreeWithRootState } from '~/store/types'
import { I18nLocale } from '~/models/shared/types'
import { I18nState } from './state'

export default {
  localeIsActive: state => (locale: I18nLocale) => locale === state.locale,
  locale: state => {
    return state.locale
  }
} as GetterTreeWithRootState<I18nState>
