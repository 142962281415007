import { createModule } from '~/factories/store'
import mutations from '~/store/modules/shared/snackbar/mutations'
import state from '~/store/modules/shared/snackbar/state'
import actions from '~/store/modules/shared/snackbar/actions'

export default createModule({
  mutations,
  actions,
  state
})
