import { CIcon } from '~/icons/types'
export const ciExternalLink: CIcon = {
  name: 'external-link',
  type: 'regular',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M464.4 0H336.1c-17.7 0-32 14.3-32 32s14.3 32 32 32h66.7L168.1 298.7c-12.5 12.5-12.5 32.8 0 45.3 6.2 6.2 14.4 9.4 22.6 9.4 8.2 0 16.4-3.1 22.6-9.4L448 109.3V176c0 17.7 14.3 32 32 32s32-14.3 32-32V47.6C512 21.3 490.7 0 464.4 0z',
      fill: 'currentColor'
    },
    {
      d:
        'M480 274c-17.7 0-32 14.3-32 32v86.1c0 30.8-25 55.8-55.8 55.8H119.8C89 448 64 423 64 392.2V119.8C64 89 89 64 119.8 64H206c17.7 0 32-14.3 32-32S223.6 0 206 0h-86.1C53.8 0 0 53.8 0 119.8v272.4C0 458.2 53.8 512 119.8 512h272.4c66.1 0 119.8-53.8 119.8-119.8V306c0-17.6-14.3-32-32-32z',
      fill: 'currentColor'
    }
  ]
}
