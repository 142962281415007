import { Search } from '~/router/routes/car/classifieds/search/components'

export const xymaRoutes = [
  {
    name: '__classifieds_search_xyma',
    path: '/xyma/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_xyma_seo',
    path: '/xyma/*.html',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_mikres_aggelies',
    path: '/mikres-aggelies/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_mikres_aggelies_seo',
    path: '/mikres-aggelies/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  }
]
