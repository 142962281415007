export const ADS_NS = 'ads'
export interface AdsState {
  showAdmanAds: boolean
}

function getDefaultState(): AdsState {
  return {
    showAdmanAds: false
  }
}

export default getDefaultState
