import { containerScoped } from '~/decorators/dependency-container'
import {
  SubscriptionInfo,
  Subscription
} from '~/models/search/subscriptions/types'

@containerScoped()
export default class SearchSubscriptionFormatter {
  formatSubscriptionInfo(subscriptionInfo: any): SubscriptionInfo {
    const i = subscriptionInfo
    const search = i?.search
    const n = search && search.notifications
    const s = search && search.stats
    const subs = i?.subscriptions
    return {
      searchId: search && search.search_id,
      notifications: {
        instantValid: n && n.instant_valid,
        instantAverageLimit: n && n.instant_average_limit,
        bulkValid: n && n.bulk_valid,
        bulkAverageLimit: n && n.bulk_average_limit
      },
      stats: {
        average: s && s.average,
        days: s && s.days
      },
      totalSubscriptions: subs && subs.total,
      maxSubscriptions: subs && subs.max
    }
  }

  formatSubscription(subscription: any): Subscription {
    const s = subscription
    return {
      id: s.id?.toString(),
      searchId: s.search_id,
      creationDate: s.created,
      lastSearchDate: s.last_search,
      sendOptions: {
        type: s?.send_options?.type
      },
      unseenCount: s.unseen_count
    }
  }
}
