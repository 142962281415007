import { interopDefault } from '~/router/utils'

const PartsCategorySearch = () =>
  interopDefault(
    import(
      '~/pages/shared/classifieds/form/parts-category-search.vue' /* webpackChunkName: "pages-classifieds-form-parts-category-search.vue" */
    )
  )
export default [
  {
    name: '__parts_new',
    path: '/parts/new',
    component: PartsCategorySearch
  }
]
