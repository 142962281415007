import { Seller } from '~/models/classified/types'
import { LeasingOption } from '~/models/classified/leasing/types'

export function formatSeller(s: any): Seller {
  return {
    avatar: s.avatar,
    website: s.website,
    geolocation: s.geolocation,
    fax: s.fax,
    mobile: s.mobile,
    telephone1: s.telephone,
    telephone2: s.telephone2,
    extra_telephone: s.extra_telephone,
    name: s.name,
    tags: s.tags,
    address: s.address,
    analytics: s.analytics,
    analytics_v4: s.analytics_v4,
    bl: s.bl,
    email: s.email,
    extraName: s.extra_name,
    id: s.id,
    location: s.location,
    managerId: s.manager_id,
    type: s.type,
    userSince: s.user_since,
    canReceiveMessages: s.can_receive_messages,
    internal_page: s.internal_page,
    google_rating: s.google_rating
  }
}

export function formatLeasingOption(leasingOption: any): LeasingOption {
  return {
    mileage: {
      value: leasingOption.mileage.value,
      suffix: leasingOption.mileage.suffix
    },
    duration: {
      value: leasingOption.duration.value,
      suffix: leasingOption.duration.suffix
    },
    lease: {
      valueWithVat: leasingOption.lease.value_with_vat,
      valueWithoutVat: leasingOption.lease.value_without_vat,
      suffix: leasingOption.lease.suffix
    },
    downpayment: {
      valueWithVat: leasingOption.downpayment.value_with_vat,
      valueWithoutVat: leasingOption.downpayment.value_without_vat,
      suffix: leasingOption.downpayment.suffix
    },
    purchasePrice: leasingOption.purchase_price && {
      valueWithVat: leasingOption.purchase_price.value_with_vat,
      valueWithoutVat: leasingOption.purchase_price.value_without_vat,
      suffix: leasingOption.purchase_price.suffix
    }
  }
}
