import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { ParseSeoUrlResult, SeoUrlInput, Url } from '~/models/seo/url/types'
import { invalidBodyError } from '../../errors'

@containerScoped()
export default class SeoUrlService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async parseUrl(url: string): Promise<ParseSeoUrlResult> {
    const response = await this.http.post('/api/seo/parse-url/', {
      url: decodeURIComponent(url)
    })
    const body = response.data
    if (!(body.data && body.data.args)) {
      throw invalidBodyError(body)
    }

    return {
      args: body.data.args,
      category: body.data.category
    }
  }

  async generateUrls(urlInputGroups: SeoUrlInput[]): Promise<Url[]> {
    const response = await this.http.post('/api/seo/generate-url/', {
      urls: urlInputGroups
    })
    const body = response.data
    if (!body.data || !Array.isArray(body.data.urls)) {
      throw invalidBodyError(body)
    }

    return body.data.urls.map(
      (url: any): Url => ({
        path: url.url && url.url.toString()
      })
    )
  }

  async generateUrl(urlInput: SeoUrlInput): Promise<Url> {
    const urls = await this.generateUrls([urlInput])
    return urls[0]
  }
}
