import { inject } from 'tsyringe'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { containerScoped } from '~/decorators/dependency-container'
import { DealerSiteConfigFactoryParams } from '~/models/classified/types'
import { ViewType } from '~/models/shared/types'
import { StoreWithRootState } from '~/store/types'
import { SearchConfig } from '~/models/search/config'

@containerScoped()
export default class DealerSiteConfigService {
  constructor(
    @inject(Store) private store: StoreWithRootState,
    @inject(VueRouter) private router: VueRouter
  ) {}

  createSearchConfig(): SearchConfig {
    const { store } = this
    const { outlets, websiteId } = store.state.dealers.site
    const { searchArgs } = store.getters['dealers/site/activePage']
    const getTemplateOption = store.getters['dealers/site/getTemplateOption']
    const defaultViewType = getTemplateOption('classifieds_default_layout')
    const currentRoute = { ...this.router.currentRoute }
    if (process.server && currentRoute.query) {
      delete currentRoute.query.lang
    }

    return DealerSiteConfigService.createSearchConfigFromParams({
      searchArgs,
      defaultViewType,
      websiteId,
      outlets
    })
  }

  private static createSearchConfigFromParams({
    searchArgs,
    defaultViewType,
    websiteId,
    outlets
  }: DealerSiteConfigFactoryParams): SearchConfig {
    return {
      rootParams: searchArgs,
      settings: {
        searchName: 'dsite-search',
        modifier: 'mobile-desktop',
        searchbar: {
          autocompleteEndpoint: '/api/classifieds/dsite/query/',
          usesNuxtLinks: true
        },
        forceAwait: true,
        showAddress: outlets.size > 0,
        showModificationDate: outlets.size > 0,
        viewType: {
          availableViewTypes: [ViewType.GALLERY, ViewType.LIST],
          defaultViewTypeDesktop: defaultViewType,
          defaultViewTypeMobile: defaultViewType
        },
        isCompareButtonVisible: false,
        sellerWebsiteId: websiteId,
        omitEmptyMakes: true,
        disableShowSellers: true,
        displayAds: false,
        isDealerSiteSearch: true
      }
    }
  }
}
