import { ValueOf } from 'type-fest'
import { MutationTree } from 'vuex'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { Layout, Outlet, WebsiteMeta, Conf } from '~/models/dealer/site/types'
import { StringMap } from '~/models/shared/types'
import {
  SET_SITE_CONTENT,
  SET_WEBSITE_META,
  SET_LOADED_PAGE_ID,
  ADD_CUSTOM_PAGE_BODY,
  SET_LOADING_STATE,
  SET_OUTLETS,
  SET_ACTIVE_OUTLET_ID,
  SET_SITE_LOADED,
  REGISTER_NAVIGATION_TO_OTHER_PAGE,
  ADD_PAGE_SNIPPET,
  SET_LOADING_PAGE_ID,
  SET_TEMPLATE_CONF
} from './mutation-types'
import getDefaultState, { DealerSiteState } from './state'

export default {
  [SET_SITE_CONTENT](state, layout: Layout) {
    state.title = layout.title
    state.pageSnippets = layout.pageSnippets
    state.indexPageId = layout.indexPageId
    const { id, displayName } = layout.owner
    state.owner = { id, displayName }
  },
  [SET_TEMPLATE_CONF](state, conf: Conf) {
    const proxyConf: Conf = { ...conf }
    Object.entries<ValueOf<Conf>>(proxyConf).forEach(([k, v]) => {
      if (
        (k.includes('color') || k.includes('colour')) &&
        typeof v === 'string' &&
        v.indexOf('rgb') !== 0 &&
        v[0] !== '#' &&
        // @ts-ignore
        // Hexadecimal check. NOTE: If a color code which is composed of 'abcdef' letters is given, it will compile
        //  to its hexadecimal equivalent
        [...v].every(c => '1234567890abcdef'.includes(c.toLowerCase()))
      ) {
        // @ts-ignore
        proxyConf[k] = '#' + v
      }
    })

    const eligibleMeasurementUnits = ['px', 'rem', 'em']
    if (
      proxyConf.header_height &&
      !eligibleMeasurementUnits.some(u => {
        const h = proxyConf.header_height!.toString()
        return h.substr(h.length - u.length, u.length) === u
      })
    ) {
      // Default to pixels if no unit is specified or if unit is not eligible.
      proxyConf.header_height = proxyConf.header_height + 'px'
    }

    // todo add header photo stretch
    const headerPhotoStretch = proxyConf.header_photo_stretch
    if (typeof headerPhotoStretch === 'boolean') {
      proxyConf.header_photo_stretch = headerPhotoStretch
        ? '100% 100%'
        : 'contain'
    }

    state.template = {
      ...(state.template || getDefaultState().template || {}),
      conf: proxyConf
    }
  },
  [SET_WEBSITE_META](state, meta: WebsiteMeta) {
    const { indexPage, websiteId } = meta
    state.websiteId = websiteId

    if (indexPage) {
      state.indexPageId = indexPage.id
      state.indexPageType = indexPage.type
    }
  },
  [SET_LOADED_PAGE_ID](state, loadedPageId: string) {
    state.loadedPageId = loadedPageId
  },
  [SET_LOADING_PAGE_ID](state, loadingPageId: string | undefined) {
    state.loadingPageId = loadingPageId
  },
  [ADD_CUSTOM_PAGE_BODY](
    state,
    { pageId, body }: { pageId: string; body: string | null }
  ) {
    const bodies = new Map(state.customPageBodies)
    bodies.set(pageId, body || '')
    state.customPageBodies = bodies
  },
  [SET_LOADING_STATE](state, loading: boolean) {
    state.loading = loading
  },
  [SET_OUTLETS](state, outlets: StringMap<Outlet>) {
    state.outlets = outlets
  },
  [SET_ACTIVE_OUTLET_ID](state, activeOutletId: string) {
    state.activeOutletId = activeOutletId
  },
  [SET_SITE_LOADED](state) {
    state.siteLoaded = true
  },
  [REGISTER_NAVIGATION_TO_OTHER_PAGE](state) {
    state.navigatedToOtherPage = true
  },
  [ADD_PAGE_SNIPPET](state, pageSnippet: PageSnippet) {
    const pageSnippets = new Map(state.pageSnippets)
    pageSnippets.set(pageSnippet.id, pageSnippet)
    state.pageSnippets = pageSnippets
  }
} as MutationTree<DealerSiteState>
