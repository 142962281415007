import { interopDefault } from '~/router/utils'

export default [
  {
    path: '/email-change-activation/',
    name: '__email_change_activation',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/email-change-activation.vue' /* webpackChunkName: "pages-shared-email-change-activation.vue" */
        )
      ),
    meta: { noFooter: true, noHeader: true }
  }
]
