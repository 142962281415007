import { interopDefault } from '~/router/utils'

const XymaCategorySearch = () =>
  interopDefault(
    import(
      '~/pages/shared/classifieds/form/xyma-category-search.vue' /* webpackChunkName: "pages-classifieds-form-xyma-category-search.vue" */
    )
  )
export default [
  {
    name: '__xyma_new',
    path: '/xyma/new',
    component: XymaCategorySearch
  }
]
