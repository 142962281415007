import { arraysEqual } from '~/utils/array'
import axios, { AxiosError } from 'axios'
import { ServerResponse } from 'http'
import SnackbarService from '~/services/snackbar/SnackbarService'
import { HttpStatus } from '~/constants/http'
import LoggerService from '~/services/LoggerService'
import { PageError } from '~/models/error'

export function parseHttpError({ error }: { error: AxiosError }): PageError {
  return {
    message:
      error.response &&
      (error.response.data.message || error.response.data.error),
    statusCode:
      (error.response && error.response.data && error.response.data.status) ||
      500
  }
}

export function parseError({ error }: { error: Error }): PageError {
  return {
    message: error.toString(),
    statusCode: 0 // TODO: add app errors here
  }
}

export function setUrlParam(url: any, paramName: any, paramValue: any) {
  try {
    // remove the hash part before operating on the url
    const i = url.indexOf('#')
    const hash = i === -1 ? '' : url.substr(i)
    url = i === -1 ? url : url.substr(0, i)

    const re = new RegExp('([?&])' + paramName + '=.*?(&|$)', 'i')
    const separator = url.includes('?') ? '&' : '?'
    if (url.match(re)) {
      url = url.replace(re, '$1' + paramName + '=' + paramValue + '$2')
    } else {
      url = url + separator + paramName + '=' + paramValue
    }
    return url + hash // finally append the hash as well
  } catch (e) {
    return null
  }
}

export function removeHttpFromUrl(string: string) {
  const regex = /^(https?:)\/\//
  return string.replace(regex, '')
}

export function preprocessParams(params: Record<string, any>) {
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === null || value === false) {
      delete params[key]
    } else if (typeof value === 'number') {
      params[key] = String(value)
    } else if (Array.isArray(value)) {
      if (arraysEqual(value, [null]) || arraysEqual(value, [undefined])) {
        delete params[key]
      } else if (arraysEqual(value, ['true'])) {
        params[key] = 1
      } else {
        params[key] = value.map(paramValue => String(paramValue))
      }
    } else if (value === true) {
      params[key] = 1
    } else if (
      Object.keys(value).length === 0 &&
      value.constructor === Object
    ) {
      delete params[key]
    }
  })
  return params
}
export function paramsValuesAsArrays(params: any) {
  Object.entries(params).forEach(([key, value]) => {
    if (!Array.isArray(value)) {
      params[key] = [value]
    }
  })
  return params
}

export function getHashableFromConfig(config: any) {
  const method = config.method
  if (method === 'get') {
    return {
      url: config.url,
      params: config.params || {}
    }
  } else {
    let params = {}
    if (config.data) {
      params =
        typeof config.data === 'object' ? config.data : JSON.parse(config.data)
    }
    return {
      url: config.url,
      params
    }
  }
}

export function isCancelError(error: Error) {
  return axios.isCancel(error)
}

export function appendToResponseHeader(
  res: ServerResponse,
  name: string,
  value: string[],
  forceReplace: boolean = false
): void {
  if (!value || process.client) {
    return
  }
  const preExistingHeaderValues = (): string[] => {
    const header = res.getHeader(name)

    if (!header) {
      return []
    } else if (Array.isArray(header)) {
      return header
    } else return [header.toString()]
  }

  let setToSet = new Set([...preExistingHeaderValues(), ...value])
  if (forceReplace) {
    setToSet = new Set([...value])
  }
  res.setHeader(name, Array.from(new Set(setToSet)))
}

/**
 * Handle Request Errors
 * @deprecated Use composable useRequestError and avoid importing
 * unnecessary dependecies.
 */
export function handleError(
  error: AxiosError,
  snackbar: SnackbarService,
  logger: LoggerService,
  t: Function
) {
  const response = error.response
  if (isCancelError(error)) {
    return
  }

  if (response) {
    const { data } = response

    switch (response.status) {
      case HttpStatus.BAD_REQUEST: {
        snackbar.error(data.error || data.message || joinErrors(data.errors))
        break
      }
      case HttpStatus.UNPROCESSABLE_ENTITY: {
        if (data.error) {
          snackbar.error(data.error || data.message)
          break
        }
        const errors: Array<string> = Object.values(
          data?.data?.errors || data?.errors
        )
        snackbar.error(errors?.join('\n'))
        break
      }
      case HttpStatus.GONE: {
        snackbar.error(data.error || data.message)
        break
      }
      case HttpStatus.INTERNAL_SERVER_ERROR: {
        snackbar.error(t('an error occurred please try again later'))
        break
      }
      default: {
        snackbar.error(data.error || data.message || data?.data?.error?.message)
        break
      }
    }
  } else if (error instanceof Error) {
    snackbar.error(error.message)
  } else {
    logger.captureError(error)
    snackbar.error(t('an error occurred please try again later'))
  }
}

export function joinErrors(data: any) {
  const errors = data?.data?.errors || data?.errors
  if (!errors) {
    return
  }
  return Object.values(errors).join('\n')
}
