import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__landing_pages_trades',
    path: '/landing/trades/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/trades/index.vue' /* webpackChunkName: "landing-pages-trades.vue" */
        )
      )
  }
]
