import { containerScoped } from '~/decorators/dependency-container'
import { subtractDate } from '~/utils/date'
import {
  LAST_DAY_DATE_PRESET_NAME,
  LAST_MONTH_DATE_PRESET_NAME,
  LAST_SIX_MONTHS_DATE_PRESET_NAME,
  LAST_THREE_MONTHS_DATE_PRESET_NAME,
  LAST_TWO_MONTHS_DATE_PRESET_NAME,
  LAST_WEEK_DATE_PRESET_NAME,
  LAST_YEAR_DATE_PRESET_NAME
} from '~/constants/analytics/classifieds'
import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'
import { Store } from 'vuex'
import { StoreWithRootState } from '~/store/types'

@containerScoped()
export default class ClassifiedsAnalyticsDateRangeService {
  constructor(
    @inject(VueI18n) private i18n: VueI18n,
    @inject(Store) private store: StoreWithRootState
  ) {}

  getSingleAnalyticsDateRangePresets() {
    const today = new Date()
    const { i18n } = this
    return [
      {
        from: subtractDate(today, { days: 1 }),
        to: today,
        label: i18n.t('last day'),
        name: LAST_DAY_DATE_PRESET_NAME
      },
      {
        from: subtractDate(today, { days: 7 }),
        to: today,
        label: i18n.t('last week'),
        name: LAST_WEEK_DATE_PRESET_NAME
      },
      {
        from: subtractDate(today, { months: 1 }),
        to: today,
        label: i18n.t('last month'),
        name: LAST_MONTH_DATE_PRESET_NAME
      },
      {
        from: subtractDate(today, { months: 2 }),
        to: today,
        label: i18n.t('last {count} months', { count: 2 }),
        name: LAST_TWO_MONTHS_DATE_PRESET_NAME
      },
      {
        from: subtractDate(today, { months: 6 }),
        to: today,
        label: i18n.t('last {count} months', { count: 6 }),
        name: LAST_SIX_MONTHS_DATE_PRESET_NAME
      }
    ]
  }

  public getDateRangePresets(monthsAvailable: number) {
    const { i18n } = this

    const maxDataDate = this.getMaxDataDate()
    const presets = [
      {
        from: subtractDate(maxDataDate, { days: 1 }),
        to: maxDataDate,
        label: i18n.t('last day'),
        name: LAST_DAY_DATE_PRESET_NAME
      },
      {
        from: subtractDate(maxDataDate, { days: 7 }),
        to: maxDataDate,
        label: i18n.t('last week'),
        name: LAST_WEEK_DATE_PRESET_NAME
      },
      {
        from: subtractDate(maxDataDate, { months: 1 }),
        to: maxDataDate,
        label: i18n.t('last month'),
        name: LAST_MONTH_DATE_PRESET_NAME
      }
    ]
    if (monthsAvailable >= 2) {
      presets.push({
        from: subtractDate(maxDataDate, { months: 2 }),
        to: maxDataDate,
        label: i18n.t('last {count} months', { count: 2 }),
        name: LAST_TWO_MONTHS_DATE_PRESET_NAME
      })
    }
    if (monthsAvailable >= 3) {
      presets.push({
        from: subtractDate(maxDataDate, { months: 3 }),
        to: maxDataDate,
        label: i18n.t('last {count} months', { count: 3 }),
        name: LAST_THREE_MONTHS_DATE_PRESET_NAME
      })
    }
    if (monthsAvailable >= 6) {
      presets.push({
        from: subtractDate(maxDataDate, { months: 6 }),
        to: maxDataDate,
        label: i18n.t('last {count} months', { count: 6 }),
        name: LAST_SIX_MONTHS_DATE_PRESET_NAME
      })
    }
    if (monthsAvailable >= 12) {
      presets.push({
        from: subtractDate(maxDataDate, { years: 1 }),
        to: maxDataDate,
        label: i18n.t('last year'),
        name: LAST_YEAR_DATE_PRESET_NAME
      })
    }
    return presets
  }

  public getMaxDataDate(): Date {
    const today = new Date(Date.now())

    return subtractDate(today, { days: 1 })
  }

  public getMinDataDate(): Date {
    return new Date(this.store.state.classifiedsAnalytics.dateAvailableFrom)
  }
}
