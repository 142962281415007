import { AdminExtras, UserType } from '~/models/user/types'

export interface AdminUserState {
  editingUserType: UserType | null
  adminExtras: AdminExtras
}

export const ADMINUSER_NS = 'admin/user'
function getDefaultState(): AdminUserState {
  return {
    editingUserType: null,
    adminExtras: {
      ticketsCount: 0,
      reportCount: 0,
      performance: null,
      pages: [],
      pendingAds: 0
    }
  }
}
export default getDefaultState
