import { MutationTree } from 'vuex'
import { AppState } from './state'
import { createMutationCreator } from '~/factories/store/mutation'
import { SET_APP_NAME } from '~/store/modules/shared/app/mutation-types'

const { setter } = createMutationCreator<AppState>()

export default {
  ...setter(SET_APP_NAME, 'name')
} as MutationTree<AppState>
