import RouteGuardService from '~/services/RouteGuardService'
import { Context } from '@nuxt/types'

export default function({ $dep, redirect }: Context) {
  const routeGuardService = $dep(RouteGuardService)
  if (routeGuardService.userIsGuestWithIncompleteProfile()) {
    return redirect({
      name: '__account_settings_details',
      query: { 'from-activate': '1' }
    })
  }
}
