import { interopDefault } from '~/router/utils'

export const playgroundToolsRoutes = [
  {
    name: '__playground_tools_theme',
    path: '/playground/tools/theme',
    component: () =>
      interopDefault(import('~/pages/shared/playground/tools/theme.vue'))
  },

  {
    name: '__playground_tools_category_id_to_text',
    path: '/playground/tools/category-id-to-text',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/tools/category-id-to-text.vue' /* webpackChunkName: "pages-playground-tools-category-id-to-text" */
        )
      )
  },
  {
    name: '__playground_tools_drag_scroll',
    path: '/playground/tools/drag-scroll',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/tools/drag-scroll.vue' /* webpackChunkName: "pages-playground-tools-drag-scroll" */
        )
      )
  },
  {
    name: '__playground_tools_driver',
    path: '/playground/tools/driver',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/tools/driver.vue' /* webpackChunkName: "pages-playground-tools-driver" */
        )
      )
  },
  {
    name: '__playground_tools_web_socket',
    path: '/playground/tools/web-socket',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/tools/web-socket.vue' /* webpackChunkName: "pages-playground-tools-web-socket" */
        )
      )
  },
  {
    name: '__playground_tools_misc',
    path: '/playground/tools/misc',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/tools/misc.vue' /* webpackChunkName: "pages-playground-tools-misc" */
        )
      )
  }
]
