export function getRandomNumberBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function formatNumber(
  number: number | string,
  thousandsSeparator: string = '.',
  decimalSeparator: string = ','
): string {
  if (number === undefined || number === null) {
    return ''
  }
  const negativeSign = number < 0 ? '-' : ''
  const n = Math.abs(Number(number))

  // Integer part
  const int = Math.floor(n).toString()

  // Format decimal part
  const parts = number.toString().split('.')
  const d = parts.length === 2 ? decimalSeparator + parts[1] : ''

  if (n < 1000) {
    return negativeSign + int + d
  }

  let formattedInt = ''
  let idx = int.length
  while (idx >= 0) {
    if (idx <= 3) {
      formattedInt = int.substr(0, idx) + formattedInt
      break
    }
    formattedInt = thousandsSeparator + int.substr(idx - 3, 3) + formattedInt
    idx = idx - 3
  }
  return negativeSign + formattedInt + d
}

export function formatPercentage(
  n: number,
  fractionDigits: number = 1,
  delimiter: string = '.'
): string {
  const formatted = (n * 100).toFixed(fractionDigits)
  return delimiter === '.' ? formatted : formatted.replace('.', delimiter)
}

export function formatPrice(price: number) {
  return formatNumber(price.toFixed(2))
}
