import { Geolocation } from '~/models/common/types'
import { CountryCode, CountryId } from '~/models/location/country'
import { GeocodingSearchLocation } from '~/models/location/geocoding'
import { PostcodeWithCountry } from '~/models/location/postcode'

export function geolocationToGoogleGeolocation(
  geolocation: Geolocation
): google.maps.LatLngLiteral {
  return {
    lat: geolocation.lat,
    lng: geolocation.lon
  }
}

export function geolocationsEqual(
  geoA: Geolocation,
  geoB: Geolocation
): boolean {
  return geoA.lat === geoB.lat && geoA.lon === geoB.lon
}

export function getCountryIdFromCountryCode(countryCode: CountryCode): number {
  switch (countryCode) {
    case CountryCode.GR: {
      return CountryId.GREECE
    }
    case CountryCode.CY: {
      return CountryId.CYPRUS
    }
    case CountryCode.BG: {
      return CountryId.BULGARIA
    }
    default: {
      return CountryId.GREECE
    }
  }
}

export function createPostcodeWithCountry(
  countryCode: CountryCode,
  postcode: string | null
): PostcodeWithCountry {
  return {
    country: { id: getCountryIdFromCountryCode(countryCode) },
    postcode: { name: postcode || '' }
  }
}

export function createSearchGeolocationString(
  geolocation: GeocodingSearchLocation
): string {
  const {
    geolocation: { lat, lon },
    radius,
    name,
    id
  } = geolocation
  return `${lat}-${lon}-${radius}-${name}-${id}`
}
