import { v1 as uuidv1 } from 'uuid'
import { containerScoped } from '~/decorators/dependency-container'
import { CategoryMapping } from '~/models/dealer/integration/category/mapping'

@containerScoped()
export class DealerIntegrationCategoryMappingFactory {
  categoryMappingFromObject({
    internalIdentifiers,
    externalId,
    externalName,
    naReason,
    naReasonMessage,
    classifiedTitles = []
  }: Omit<CategoryMapping, 'id'>): CategoryMapping {
    return {
      id: uuidv1(),
      externalId: externalId || '',
      externalName: externalName || '',
      naReason,
      naReasonMessage,
      internalIdentifiers,
      classifiedTitles
    }
  }
}
