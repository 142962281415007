import { inject, registry } from 'tsyringe'
import { ValueOf } from 'type-fest'
import VueRouter from 'vue-router'
import { containerScoped } from '~/decorators/dependency-container'
import { PAGE_PARAM_NAME } from '~/constants/pagination'
import { Result } from '~/models/shared/result'
import { StringMap } from '~/models/shared/types'
import {
  SearchableUser,
  UserSearchFilters,
  UserSearchSchema,
  UserSearchBooleanFilter,
  StaticNameSearchFilters
} from '~/models/user/search'
import LoggerService from '~/services/LoggerService'
import { toCamelCase } from '~/utils/object'
import { UserType } from '~/models/user/types'
import RequestBuilder from '~/builders/http/RequestBuilder'
import RouterService from '~/services/RouterService'

export const userSearchUrlToken = Symbol('userSearchUrlInjectionToken')

/**
 * Should be redacted from public.
 */
@containerScoped()
@registry([
  {
    token: userSearchUrlToken,
    useValue: '/api/admin/users/search/'
  }
])
export default class UserSearchService {
  constructor(
    @inject(userSearchUrlToken) private userSearchUrl: string,
    @inject(LoggerService) private logger: LoggerService,
    @inject(VueRouter) private router: VueRouter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(RouterService) private routerService: RouterService
  ) {}

  async search(
    text: string,
    filters: UserSearchFilters = { boolean: new Set() },
    sorting: string,
    page: number,
    updateQueryParamsOfPage: boolean = false
  ): Promise<
    Result<{ users: StringMap<SearchableUser>; schema: UserSearchSchema }>
  > {
    const queryParams = this.createSearchRequestQueryParams(
      text,
      filters,
      page,
      sorting
    )

    const data = await this.requestBuilder
      .request('get', this.userSearchUrl)
      .params(queryParams)
      .validate(
        body => body?.data?.users?.rows && body?.data?.users?.pagination
      )
      .previousRequestCancelable()
      .send()
    const {
      users: { rows, pagination }
    } = data
    const userMap = new Map<string, SearchableUser>(
      rows.map((user: any) => {
        if (typeof user.id === 'undefined') {
          this.logger.captureError(
            new TypeError(`User id is undefined: ${JSON.stringify(user)}`)
          )
        }

        return [user.id?.toString(), toCamelCase(user)]
      })
    )
    delete data.users

    if (updateQueryParamsOfPage) {
      this.routerService.updateQueryParams(queryParams)
    }

    return {
      data: { users: userMap, schema: data },
      page: {
        number: pagination.page,
        paramName: pagination.pageParam,
        perPage: pagination.perPage,
        totalCount: pagination.total
      }
    }
  }

  private createSearchRequestQueryParams(
    searchText: string,
    filters: UserSearchFilters,
    pageNumber: number = 1,
    sorting: string
  ): Record<string, string> {
    const getFilterIfExistent = this.createExistentFilterGetter(filters)
    return {
      ...(searchText ? { q: searchText } : {}),
      ...getFilterIfExistent('user-type'),
      ...getFilterIfExistent('site'),
      ...getFilterIfExistent('last-activity'),
      ...[...filters.boolean.values()].reduce(
        (obj, b) => ({ ...obj, [b]: 1 }),
        {}
      ),
      ...(sorting ? { sort: sorting } : {}),
      [PAGE_PARAM_NAME]: pageNumber.toString()
    }
  }

  createFiltersFromSearchRequestQueryParams(): [
    string | null,
    UserSearchFilters,
    number | null,
    string | null
  ] {
    const queryParams = this.router.currentRoute.query

    const getFilterIfExistent = this.createExistentFilterGetter(queryParams)

    if (!queryParams['user-type']) {
      delete queryParams['user-type']
    }

    const searchText = queryParams.q as string | null
    const sorting = queryParams.sort as string | null

    const userType = getFilterIfExistent(
      'user-type',
      (type: UserType | UserType[]) =>
        typeof type === 'string' ? [type] : type
    )
    const site = getFilterIfExistent('site')
    const lastActivity = getFilterIfExistent('last-activity')
    const pageNumber = queryParams.pg

    delete queryParams['user-type']
    delete queryParams.site
    delete queryParams['last-activity']
    delete queryParams.q
    delete queryParams.pg

    const filters: UserSearchFilters = {
      ...userType,
      ...site,
      ...lastActivity,
      boolean: new Set(Object.keys(queryParams)) as Set<UserSearchBooleanFilter>
    }

    return [searchText, filters, Number(pageNumber), sorting]
  }

  private createExistentFilterGetter(filters: StaticNameSearchFilters) {
    return <K extends keyof StaticNameSearchFilters>(
      key: K,
      mapValue: (value: any) => ValueOf<StaticNameSearchFilters, K> = value =>
        value
    ) => {
      return key in filters ? { [key]: mapValue(filters[key]) } : {}
    }
  }

  userHasIssues(user: SearchableUser): boolean {
    return user.xmlFeed?.hasIssues
  }

  userIsDisabled(user: SearchableUser): boolean {
    return (user.userTags && user.userTags.includes('disabled')) || false
  }
}
