import { MutationTree } from 'vuex'
import { UserAgentState } from './state'
import { SET_IS_BOT, SET_IS_PC, SET_IS_TABLET } from './mutation-types'

export default {
  [SET_IS_PC](state, isPc: boolean) {
    state.isPc = isPc
  },
  [SET_IS_BOT](state, isBot: boolean) {
    state.isBot = isBot
  },
  [SET_IS_TABLET](state, isTablet: boolean) {
    state.isTablet = isTablet
  }
} as MutationTree<UserAgentState>
