import TradesService from '~/services/trades/Trades'
import {
  addToHistory,
  changeActionsVisibility,
  changeNavbarVisibility,
  checkForRefetch,
  closeDetails,
  fetchCategories,
  fetchClassifiedDetails,
  fetchLiked,
  fetchLikedBy,
  fetchMatches,
  fetchNotifications,
  fetchRecommendations,
  fetchSettings,
  fetchTradableClassifieds,
  likeClassified,
  matchedClassified,
  matchScreenVisibilityChange,
  passClassified,
  readNotifications,
  removeLikedBy,
  removeTradableClassified,
  rewind,
  setDetailsClassifiedId,
  setPage,
  seveSettings,
  unmatched,
  updateSeetingsCategories
} from './actions-types'
import {
  ADD_LIKED,
  ADD_MATCH,
  ADD_ROW,
  ADD_TO_HISTORY,
  REMOVE_LIKED_BY,
  REMOVE_ROW,
  REMOVE_TRADABLE_CLASSIFIED,
  RESET_RECOMMENDATIONS_DATA,
  SET_ACTIONS_VISIBILITY,
  SET_ALLOWED_TRADES_MAX,
  SET_CATEGORIES,
  SET_CLASSIFIED_DETAILS,
  SET_CURRENT_PAGE,
  SET_DETAILS_CLASSIFIED_ID,
  SET_LIKED,
  SET_LIKED_BY,
  SET_LOADING_STATUS,
  SET_MATCH_SCREEN_VISIBILITY,
  SET_MATCHED_CLASSIFIED,
  SET_MATCHES,
  SET_NAVBAR_VISIBILITY,
  SET_NOTIFICATIONS,
  SET_RECOMMENDATIONS_DATA,
  SET_SETTINGS,
  SET_TRADABLE_CLASSIFIEDS,
  UNMATCHED
} from './mutation-types'
import {
  TradesCategory,
  TradesNotificationType,
  TradesRecommendations,
  TradesRow,
  TradesRowClassified
} from '~/models/trades/types'
import { ActionTreeWithRootState } from '~/store/types'

import getDefaultState, { TradesState } from './state'
import { handleError } from '~/utils/http'
import { AxiosError } from 'axios'

export default {
  async [fetchRecommendations](
    { commit },
    {
      classified,
      reset = true
    }: { classified: string; pg: number; reset: boolean }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    commit(SET_LOADING_STATUS, true)
    try {
      const data = (await tradesService.fetchRecommendations(
        classified
      )) as TradesRecommendations
      if (reset) {
        commit(RESET_RECOMMENDATIONS_DATA)
      }
      commit(SET_RECOMMENDATIONS_DATA, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    } finally {
      commit(SET_LOADING_STATUS, false)
    }
  },
  async [fetchCategories]({ commit }, qParams: string) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchCategories(qParams)

      commit(SET_CATEGORIES, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },
  async [fetchTradableClassifieds]({ commit }) {
    const tradesService = this.$dep(TradesService) as TradesService
    commit(SET_LOADING_STATUS, true)
    try {
      const {
        classifieds,
        allowedTradesMax
      } = await tradesService.fetchTradableClassifieds()

      commit(SET_TRADABLE_CLASSIFIEDS, classifieds)
      commit(SET_ALLOWED_TRADES_MAX, allowedTradesMax)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    } finally {
      commit(SET_LOADING_STATUS, false)
    }
  },

  async [fetchSettings]({ commit }, classified: string) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchSettings(classified)
      commit(SET_SETTINGS, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [fetchClassifiedDetails](
    { commit },
    {
      targetClassifiedId,
      myClassifiedId
    }: {
      targetClassifiedId: string
      myClassifiedId: string
    }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      commit(SET_CLASSIFIED_DETAILS, null)

      const data = await tradesService.fetchClassifiedDetails(
        targetClassifiedId,
        myClassifiedId
      )
      commit(SET_CLASSIFIED_DETAILS, {
        ...data.compactClassified,
        id: parseInt(targetClassifiedId, 10),
        match: data.match
      })
    } catch (error) {
      commit(SET_CLASSIFIED_DETAILS, {
        error
      })
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [fetchMatches](
    { commit },
    { classified, page = 1 }: { classified: string; page: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchMatches(classified, page)
      commit(SET_MATCHES, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [fetchLiked](
    { commit },
    { classified, page = 1 }: { classified: string; page: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchLiked(classified, page)
      commit(SET_LIKED, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [fetchLikedBy](
    { commit },
    { classified, page = 1 }: { classified: string; page: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchLikedBy(classified, page)
      commit(SET_LIKED_BY, data)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [fetchNotifications](
    { commit },
    {
      classified,
      errorCb = () => {}
    }: { classified: string; errorCb: Function }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      const data = await tradesService.fetchNotifications(classified)
      commit(SET_NOTIFICATIONS, data)
    } catch (error) {
      errorCb()
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [readNotifications](
    { commit, dispatch },
    { classified, type }: { classified: string; type: TradesNotificationType }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    let params = ''
    if (type) {
      let typeToUse = type.toString()
      if (
        type === TradesNotificationType.MESSAGE ||
        type === TradesNotificationType.MATCHED
      ) {
        // they are virtually the same so serialize them (from a ux perspective)
        typeToUse = `${TradesNotificationType.MESSAGE}&type=${TradesNotificationType.MATCHED}`
      }

      params = `?type=${typeToUse}&read=1`
    }
    try {
      const data = await tradesService.fetchNotifications(classified, params)
      commit(SET_NOTIFICATIONS, data)
      if (
        type === TradesNotificationType.MATCHED ||
        type === TradesNotificationType.MESSAGE
      ) {
        await dispatch(fetchMatches, { classified })
      } else if (type === TradesNotificationType.LIKED_BY) {
        await dispatch(fetchLikedBy, { classified })
      }
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [seveSettings](
    { commit, dispatch },
    {
      classified,
      categories
    }: { classified: string; categories: Array<TradesCategory> }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      await tradesService.seveSettings(classified, categories)
      if (categories?.length) {
        commit(RESET_RECOMMENDATIONS_DATA)
        commit(SET_RECOMMENDATIONS_DATA, getDefaultState().recommendations)
        dispatch('fetchRecommendations', { classified })
      }
      commit(SET_SETTINGS, { categories })
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [likeClassified](
    { commit, dispatch },
    {
      classified,
      likedClassified,
      removeLikedByRow = false
    }: {
      classified: string
      likedClassified: TradesRowClassified
      removeLikedByRow: boolean
    }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    commit(REMOVE_LIKED_BY, likedClassified.id)
    commit(REMOVE_ROW, likedClassified.id)
    try {
      const res = await tradesService.likeClassified(
        classified,
        likedClassified.id
      )
      if (!removeLikedByRow) {
        commit(ADD_LIKED, tradesService.setThumbSize(likedClassified, 'k'))
      }
      if (res.match) {
        dispatch('matchScreenVisibilityChange', true)
        dispatch('changeNavbarVisibility', false)
        dispatch('matchedClassified', likedClassified)
        commit(ADD_MATCH, {
          message: res.message,
          classified: tradesService.setThumbSize(likedClassified, 'k')
        })
      }

      dispatch('checkForRefetch', classified)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [passClassified](
    { commit, dispatch },
    { classified, passClsfd }: { classified: string; passClsfd: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    commit(REMOVE_ROW, passClsfd)
    commit(REMOVE_LIKED_BY, passClsfd)
    try {
      await tradesService.passClassified(classified, passClsfd)
      dispatch('checkForRefetch', classified)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [unmatched](
    { commit },
    { classifiedA, classifiedB }: { classifiedA: number; classifiedB: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      await tradesService.unmatched(classifiedA, classifiedB)
      commit(UNMATCHED, classifiedB)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [removeLikedBy](
    { commit, dispatch },
    { classifiedA, classifiedB }: { classifiedA: string; classifiedB: number }
  ) {
    const tradesService = this.$dep(TradesService) as TradesService
    try {
      await tradesService.removeLikedBy(classifiedA, classifiedB)
      commit(REMOVE_LIKED_BY, classifiedB)
      commit(REMOVE_ROW, classifiedB)
      dispatch('checkForRefetch', classifiedA)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    }
  },

  async [removeTradableClassified]({ commit }, classifiedId: number | string) {
    const tradesService = this.$dep(TradesService) as TradesService
    commit(SET_LOADING_STATUS, true)
    try {
      const { message } = await tradesService.removeTradableClassified(
        classifiedId
      )
      commit(REMOVE_TRADABLE_CLASSIFIED, classifiedId)
      this.$snackbar.success(message)
    } catch (error) {
      handleError(
        error as AxiosError,
        this.$snackbar,
        this.$logger,
        this.app.$t
      )
    } finally {
      commit(SET_LOADING_STATUS, false)
    }
  },

  async [checkForRefetch]({ state, dispatch }, classified: string | number) {
    if (
      state.recommendations.rows.length === 4 ||
      state.recommendations.rows.length === 0
    ) {
      await dispatch('fetchRecommendations', {
        classified,
        reset: false
      })
    }
  },
  [closeDetails]({ state, dispatch }, withPush: boolean) {
    dispatch('setDetailsClassifiedId', null)
    dispatch('changeNavbarVisibility', true)
    dispatch('changeActionsVisibility', true)

    if (withPush) {
      let routeToGo = '__trades_swipe'
      if (state.currentPage === 3) {
        routeToGo = '__trades_likes'
      } else if (state.currentPage === 2) {
        routeToGo = '__trades_matches'
      }

      this.$router.push({
        name: routeToGo,
        query: this.$router.currentRoute.query,
        hash: ''
      })
    }
  },

  [setPage]({ commit }, routerName: string) {
    switch (routerName) {
      case '__trades_page':
        commit(SET_CURRENT_PAGE, 1)
        break
      case '__trades_matches':
        commit(SET_CURRENT_PAGE, 2)
        break
      case '__trades_likes':
        commit(SET_CURRENT_PAGE, 3)
        break
      default:
        commit(SET_CURRENT_PAGE, 1)
        break
    }
  },

  [matchScreenVisibilityChange]({ commit }, status: boolean) {
    commit(SET_MATCH_SCREEN_VISIBILITY, status)
  },

  [matchedClassified]({ commit }, classified: TradesRow) {
    commit(SET_MATCHED_CLASSIFIED, classified)
  },

  [rewind]({ commit }, classified: [TradesRow]) {
    commit(ADD_ROW, classified)
  },

  [setDetailsClassifiedId]({ commit }, classifiedId: number) {
    commit(SET_DETAILS_CLASSIFIED_ID, classifiedId)
  },

  [changeNavbarVisibility]({ commit }, isVisible: boolean) {
    commit(SET_NAVBAR_VISIBILITY, isVisible)
  },

  [changeActionsVisibility]({ commit }, isVisible: boolean) {
    commit(SET_ACTIONS_VISIBILITY, isVisible)
  },
  [updateSeetingsCategories]({ state, commit }, categories: number[]) {
    commit(SET_SETTINGS, { ...state.settings, categories })
  },
  [addToHistory]({ commit }, item: TradesRowClassified) {
    commit(ADD_TO_HISTORY, item)
  }
} as ActionTreeWithRootState<TradesState>
