import actions from '~/store/modules/shared/advertising/dashboard/actions'
import getters from '~/store/modules/shared/advertising/dashboard/getters'
import mutations from '~/store/modules/shared/advertising/dashboard/mutations'
import state from '~/store/modules/shared/advertising/dashboard/state'
import { createModule } from '~/factories/store'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
