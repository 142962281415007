import { useContext } from '@nuxtjs/composition-api'
import VueI18n from 'vue-i18n'
import { getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'

export function useI18n(): VueI18n {
  const { app } = useContext()
  const { i18n } = app

  const instance = getCurrentInstance()
  const vm = instance?.proxy || new Vue({})

  // @ts-ignore
  return {
    ...i18n,
    // @ts-ignore
    locale: i18n.locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm)
  }
}
