import { Search } from '~/router/routes/car/classifieds/search/components'

export const dealsRoutes = [
  {
    name: '__classifieds_search_deals',
    path: '/deals/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  },
  {
    name: '__classifieds_search_deals_seo',
    path: '/deals/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true, showOneTap: true }
  }
]
