import InMobiService from '~/services/InMobiService'
import { USER_NS } from '~/store/modules/shared/user/state'
import { getRouteMetaItem } from '~/utils/router'

const osIsOldWindowsVersion = (osVersion: string) => {
  const osIsWindows = osVersion.startsWith('NT')
  if (!osIsWindows) {
    return false
  }
  try {
    const windowsNtVersion = parseInt(osVersion.split(' ')[1])
    // Windows Vista is NT 6.0 (https://en.wikipedia.org/wiki/Windows_NT)
    return windowsNtVersion <= 6
  } catch (_error) {
    // ignore possible os version parsing errors
    return false
  }
}

const osIsOldMacOSX = (osName: string, osVersion: string) => {
  if (osName === 'Mac OSX') {
    try {
      const [majorOsVersion, minorOsVersion] = osVersion.split('.')

      // Disabling all versions below Sierra (2016)
      // https://en.wikipedia.org/wiki/MacOS_version_history#Version_10.12:_%22Sierra%22
      if (parseInt(majorOsVersion) <= 10 && parseInt(minorOsVersion) <= 12) {
        return true
      }
    } catch (_error) {
      return false
    }
  }
  return false
}

export default ({ $dep, $ua, route, store, $cookies }: any) => {
  if (process.server) {
    throw new Error('InMobi should not be initialized server-side')
  }

  const userIsOfTypeDealer = store.getters[`${USER_NS}/isOfTypeDealer`]
  const osVersion = $ua.osVersion()
  const osName = $ua.os()
  const disableInMobiCookie = $cookies.get('disable-cookie-banner')
  const isIframeRoute = Boolean(getRouteMetaItem(route, 'isIframe'))

  const isIframe = Boolean(
    isIframeRoute || (process.client && window.isIframe())
  )

  const shouldLoadInMobi =
    !disableInMobiCookie &&
    !userIsOfTypeDealer &&
    !isIframe &&
    !process.env.CONSENT_MODAL_DISABLED &&
    !osIsOldWindowsVersion(osVersion) &&
    !osIsOldMacOSX(osName, osVersion)

  const disableInMobi = false

  if (shouldLoadInMobi && !disableInMobi) {
    const inmobiService = $dep(InMobiService)
    inmobiService.loadInMobi()
  }
}
