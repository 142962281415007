import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dealers_map',
    path: '/dealers/map',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/map.vue' /* webpackChunkName: "pages-dealers-map.vue" */
        )
      ),
    meta: { noFooter: true }
  }
]
