import DependencyContainer from 'tsyringe/dist/typings/types/dependency-container'
import LoggerService from '~/services/LoggerService'
import { StoreWithRootState } from '~/store/types'

export default ({
  app: { $requestContainer },
  store: { state }
}: {
  app: { $requestContainer: DependencyContainer }
  store: StoreWithRootState
}) => {
  if (typeof state.user.id === 'undefined') {
    return
  }
  const logger = $requestContainer.resolve(LoggerService)
  logger.setUser(state.user)
}
