import flow from 'lodash.flow'
import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { DealerIntegrationCategoryMappingFactory } from '~/factories/dealer/integration/category'
import { keyValueFormattedValidationError } from '~/factories/http/error'
import {
  CategoryMapping,
  CategoryMappingId,
  InternalCategoryIdentifier,
  NaReason,
  NaReasonNodeId,
  CategoryUpsertEndpointInput,
  UnmappedCategoryScanError
} from '~/models/dealer/integration/category/mapping'
import InternalRequestBuilder from '~/builders/http/InternalRequestBuilder'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toCamelCase } from '~/utils/object'

const categoryMapUrl = '/api/panel/xml/category-map/'
const unmappedCategoriesUrl = '/api/xml/unmapped-categories/'

@containerScoped()
export default class DealerCategoryMapService {
  private mutationRequestBuilder: () => InternalRequestBuilder

  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(DealerIntegrationCategoryMappingFactory)
    private categoryMappingFactory: DealerIntegrationCategoryMappingFactory
  ) {
    this.mutationRequestBuilder = () =>
      requestBuilder.new().validate(() => true)
  }

  getCategoryMappings(): Promise<Map<CategoryMappingId, CategoryMapping>> {
    return this.requestBuilder
      .request('get', '/api/panel/xml/category-map/')
      .validate(body => Array.isArray(body.data?.mappings))
      .map((body: any) => this.mapResponseMappings(body.data?.mappings))
      .send()
  }

  async upsertCategoryMapping(
    externalName: string,
    externalId: string,
    internalIds: (number | NaReasonNodeId)[]
  ): Promise<CategoryMapping> {
    // todo
    //  * cannot be mapped overwrites all options when performed with existing persisted mappings and displays confirmation modal if so.
    const mapping: CategoryMapping = await this.mutationRequestBuilder()
      .request('post', categoryMapUrl)
      .data({
        external_name: externalName,
        external_id: externalId,
        ...this.getInternalIdInputOfUpsertEndpoint(internalIds)
      } as CategoryUpsertEndpointInput)
      .validate(
        body => !body.data?.errors,
        keyValueFormattedValidationError(body => body.data?.errors)
      )
      .map(body => this.mapResponseMapping(body.data?.values))
      .send()

    return this.categoryMappingFactory.categoryMappingFromObject({
      classifiedTitles: [],
      externalId,
      externalName,
      internalIdentifiers: mapping.internalIdentifiers,
      naReason: mapping.naReason,
      naReasonMessage: mapping.naReasonMessage
    })
  }

  private getInternalIdInputOfUpsertEndpoint(
    internalIds: (number | NaReasonNodeId)[]
  ): Pick<CategoryUpsertEndpointInput, 'car_id' | 'car_id2' | 'na_reason'> {
    if (internalIds.some(id => id === NaReasonNodeId.CANNOT_BE_MAPPED)) {
      return {
        car_id: undefined,
        car_id2: undefined,
        na_reason: NaReason.CANNOT_BE_MAPPED
      }
    }

    return {
      car_id: internalIds.length ? internalIds[0] : undefined,
      car_id2: internalIds.length > 1 ? internalIds[1] : undefined,
      na_reason: internalIds.length ? undefined : NaReason.NOT_MAPPED_YET
    }
  }

  async deleteCategoryMapping(
    externalName: string,
    externalId: string
  ): Promise<void> {
    await this.mutationRequestBuilder()
      .request('delete', categoryMapUrl)
      .data({
        external_name: externalName,
        external_id: externalId
      })
      .send()
  }

  getUnmappedCategoryMappings(): Promise<{
    mappings: Map<CategoryMappingId, CategoryMapping>
    errors: UnmappedCategoryScanError[]
  }> {
    return this.requestBuilder
      .request('get', unmappedCategoriesUrl)
      .validate(body => Array.isArray(body?.mappings))
      .map((body: any) => ({
        ...body,
        mappings: this.mapResponseMappings(body.mappings)
      }))
      .send()
  }

  private mapResponseMappings(
    mappings: any[]
  ): Map<CategoryMappingId, CategoryMapping> {
    return flow(
      (mappings: any[]) => mappings.map((m: any) => this.mapResponseMapping(m)),
      (mappings: CategoryMapping[]) =>
        new Map<CategoryMappingId, CategoryMapping>(
          mappings.map(m => [m.id, m])
        )
    )(mappings)
  }

  private mapResponseMapping(m: any): CategoryMapping {
    const internalIdentifiers: InternalCategoryIdentifier[] = []
    ;(m.car_id || m.car_name) &&
      internalIdentifiers.push({
        id: m.car_id || '',
        name: m.car_name || ''
      }) &&
      delete m.car_id &&
      delete m.car_name
    ;(m.car_id2 || m.car_name2) &&
      internalIdentifiers.push({
        id: m.car_id2 || '',
        name: m.car_name2 || ''
      }) &&
      delete m.car_id2 &&
      delete m.car_name2
    m.internalIdentifiers = internalIdentifiers

    m.classifiedTitles = m.titles || []
    delete m.titles

    return flow(toCamelCase, (m: CategoryMapping) =>
      this.categoryMappingFactory.categoryMappingFromObject(m)
    )(m)
  }
}
