import { interopDefault } from '~/router/utils'

export default [
  {
    path: '/market/cart',
    name: '__market_cart',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/marketplace/cart.vue' /* webpackChunkName: "pages-car-market-cart.vue" */
        )
      )
  },
  {
    path: '/market/cart/checkout/:userId',
    name: '__market_cart_checkout_seller',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/marketplace/checkout-seller.vue' /* webpackChunkName: "pages-car-market-cart-checkout-seller.vue" */
        )
      )
  },
  {
    path: '/market/orders/:orderId/success',
    name: '__market_order_success',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/marketplace/order-success.vue' /* webpackChunkName: "pages-car-market-order-success.vue" */
        )
      )
  },
  {
    path: '/market/terms/',
    name: '__market_terms',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/marketplace/terms.vue' /* webpackChunkName: "pages-car-market-terms.vue" */
        )
      )
  }
]
