import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import GoogleAdsService from '~/services/ads/GoogleAdsService'
import { clientOnly } from '~/decorators'
import { getStaticUrl } from '~/utils/static'
import CookiesService from '~/services/CookiesService'
import { CONCENT_COOKIE_NAME } from '~/constants/cookies'
import LoggerService from '~/services/LoggerService'

@containerScoped()
export default class InMobiService {
  constructor(
    @inject(GoogleAdsService) private googleAdsService: GoogleAdsService,
    @inject(CookiesService) private cookies: CookiesService,
    @inject(LoggerService) private logger: LoggerService
  ) {}

  @clientOnly
  loadInMobi() {
    const qScript = document.createElement('script')
    qScript.defer = true
    qScript.src = getStaticUrl('/nuxt-static/js/inmobi-choice.js?v=3')
    qScript.type = 'text/javascript'
    qScript.addEventListener('load', () => {
      let url = ''

      if (process.env.DOMAIN) {
        url =
          process?.env?.DOMAIN.startsWith('www') ||
          process?.env?.DOMAIN.startsWith('http')
            ? process.env.DOMAIN
            : `www.${process.env.DOMAIN}`
      }

      ;(window.initializeInMobi || function() {})(url)
      this.registerInMobiListener()
    })

    document.head.appendChild(qScript)
  }

  registerInMobiListener(): void {
    const { googleAdsService } = this
    ;(window.__tcfapi || function() {})(
      'addEventListener',
      2,
      (tcData: any, success: boolean) => {
        if (!success) {
          return
        }
        if (['useractioncomplete', 'tcloaded'].includes(tcData.eventStatus)) {
          if (tcData.eventStatus === 'useractioncomplete') {
            // only refresh the ads if user just made a choice
            // because they are probably disabled from the google-publisher-tag plugin
            googleAdsService.refreshAllSlots()
          } else if (
            tcData.eventStatus === 'tcloaded' &&
            this.cookies.get(CONCENT_COOKIE_NAME) === null
          ) {
            // this means we have a loaded quatncast choice but there is no cookie found
            // that has the CONSENT_COOKIE name. Maybe they changed it so
            // throw a sentry error message to inform developers about this change
            this.logger.captureError(
              `DEVELOPER ALERT: InMobi choice cookie name (${CONCENT_COOKIE_NAME}) has probably changed. Please update the CONCENT_COOKIE_NAME constant to avoid the disruption of ads`
            )

            // at this point we better refresh the ads to avoid them not being shown at all
            googleAdsService.refreshAllSlots()
          }
        }
        // else it means either consent was not given or the CMP popup is still showing
      }
    )
  }
}
