import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import {
  PaidClassifiedsInfoSummary,
  PaidNewClassifiedCategoryInfo,
  PaymentsInfoResult
} from '~/models/classified/paid/types'
import { ActionResult, Result } from '~/models/shared/result'
import { invalidBodyError } from '~/services/errors'
import { toCamelCase } from '~/utils/object'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  MODIFY_ROW_PUBLIC,
  SET_CLASSIFIED_PAID_INFO
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { RootState } from '~/store/types'
import { Store } from 'vuex'

@containerScoped()
export default class PaidClassifiedService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(Store) private store: Store<RootState>
  ) {}

  async getNewPaidClassifiedCategory(
    categoryId: number
  ): Promise<Result<PaidNewClassifiedCategoryInfo>> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/paid/new/${categoryId}/`
    )
    const { data: body } = response
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }

    const bodyCamelCase = toCamelCase(body)
    return bodyCamelCase.data
  }

  async getSummary(): Promise<PaidClassifiedsInfoSummary> {
    const response: AxiosResponse = await this.http.get(
      '/api/users/paid-classifieds/'
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }

    const bodyCamelCase = toCamelCase(body)
    return bodyCamelCase.data
  }

  async getPaymentsInfo(
    classifiedId: number,
    inCamelCase = true
  ): Promise<PaymentsInfoResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/paid/${classifiedId}/info/`
    )
    const { data: body } = response
    if (!body && !body.data) {
      throw invalidBodyError(body)
    }
    let result = body.data
    if (inCamelCase) {
      // in search we don't do camel case so this needs to happen for the calls not from search
      result = toCamelCase(body.data)
    }
    return result
  }

  async pay(classifiedId: number): Promise<ActionResult> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/paid/${classifiedId}/pay/`
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body
  }

  public async loadSearchClassifiedPaidInfo(
    classifiedId: number
  ): Promise<any> {
    const paidInfo = await this.getPaymentsInfo(classifiedId, false)

    if (!paidInfo) {
      return
    }

    this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_CLASSIFIED_PAID_INFO}`, {
      classifiedId,
      paidInfo
    })

    this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_PUBLIC}`, {
      classifiedId
    })
    return Promise.all([
      this.store.dispatch(`${USER_NS}/loadUser`),
      this.store.dispatch(`${CLASSIFIED_SEARCH_NS}/loadPaidSummary`)
    ])
  }
}
