import {
  scoped,
  singleton as tsyringeSingleton,
  injectable as tsyringeInjectable,
  Lifecycle
} from 'tsyringe'
import { InjectionToken } from 'tsyringe/dist/typings/providers'

export const containerScoped = <T>(token?: InjectionToken<T>) =>
  scoped(Lifecycle.ContainerScoped, token)

export const singleton = <T>() => tsyringeSingleton<T>()

export const injectable = <T>() => tsyringeInjectable<T>()
