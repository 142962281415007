export const allVTypes = Object.freeze([
  'cars',
  'bikes',
  'boats',
  'bicycles',
  'vans',
  'trucks',
  'buses',
  'builders',
  'semitrucks',
  'semitrailers',
  'tractors',
  'forklifts',
  'trailers',
  'taxis',
  'caravans',
  'motorhomes',
  'radiocontrols',
  'gokarts',
  'watersports',
  'snowsports',
  'airsports',
  'campers',
  'hobby',
  'commercials'
])

export const allVTypesRegex = allVTypes.join('|') // produces 'cars|bikes|boats...'
