export enum CountryCode {
  GR = 'GR',
  CY = 'CY',
  BG = 'BG'
}

export enum CountryId {
  GREECE = 1,
  CYPRUS = 17,
  BULGARIA = 3742
}
