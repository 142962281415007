import { CIcon } from '~/icons/types'
export const ciPlus: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 3.5a1 1 0 0 1 1 1V11h6.5a1 1 0 1 1 0 2H13v6.5a1 1 0 1 1-2 0V13H4.5a1 1 0 1 1 0-2H11V4.5a1 1 0 0 1 1-1Z'
    }
  ],
  name: 'plus',
  type: 'regular'
}
