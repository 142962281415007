import { MutationTree } from 'vuex'
import { OPEN_NAV, CLOSE_NAV } from './mutation-types'
import { DealerSiteLayoutState } from './state'

export default {
  [OPEN_NAV](state) {
    state.navOpen = true
  },
  [CLOSE_NAV](state) {
    state.navOpen = false
  }
} as MutationTree<DealerSiteLayoutState>
