import { Inject, Context } from '@nuxt/types/app'
import { DependencyContainer } from 'tsyringe'
import AnalyticsService from '~/services/AnalyticsService'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import LoggerService from '~/services/LoggerService'
import SnackbarService from '~/services/snackbar/SnackbarService'

export default ({ app: { $requestContainer } }: Context, inject: Inject) => {
  const globalDeps = getGlobalDependencies($requestContainer)
  for (const [key, value] of Object.entries(globalDeps)) {
    if (typeof value !== 'undefined') {
      inject(key, value)
    }
  }
}

function getGlobalDependencies(c: DependencyContainer) {
  return {
    cache: c.resolve(CacheService),
    logger: c.resolve(LoggerService),
    cookies: c.resolve(CookiesService),
    analytics: c.resolve(AnalyticsService),
    snackbar: c.resolve(SnackbarService)
  }
}
