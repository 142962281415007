import ScrollDepthService from '~/services/scroll/ScrollDepthService'

export default function({ $dep }: any) {
  if (process.server) {
    return
  }

  const scrollDepthService = $dep(ScrollDepthService)
  scrollDepthService.resetCurrentThreshold()
}
