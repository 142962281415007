import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__classifieds_view_messages',
    path: '/classifieds/:id/messages',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/view/messages-in-edit.vue' /* webpackChunkName: "pages-shared-classifieds-view-messages-in-edit" */
        )
      )
  }
]
