import { MutationTree } from 'vuex'
import {
  SET_FETCHABLE_FEEDS,
  SET_LANDING_CLASSIFIEDS_COUNT,
  DELETE_FETCHABLE_FEED,
  SET_LANDING_UNREAD_MESSAGES_COUNT,
  SET_QUICK_SEARCHES,
  SET_STATISTICS
} from './mutation-types'
import { IndexState } from './state'
import { createMutationCreator } from '~/factories/store/mutation'

const { setter } = createMutationCreator<IndexState>()

export default {
  ...setter(SET_STATISTICS, 'statistics'),
  [SET_FETCHABLE_FEEDS](state, fetchableFeeds) {
    state.fetchableFeeds = fetchableFeeds
  },
  [SET_QUICK_SEARCHES](state, quickSearches) {
    state.quickSearches = quickSearches
  },
  [SET_LANDING_CLASSIFIEDS_COUNT](state, count) {
    state.count = count
  },
  [SET_LANDING_UNREAD_MESSAGES_COUNT](state, count) {
    state.unreadMessagesCount = count
  },
  [DELETE_FETCHABLE_FEED](state, feedUrlToDelete) {
    state.fetchableFeeds = state.fetchableFeeds.filter(
      f => f.fetchUrl !== feedUrlToDelete
    )
  }
} as MutationTree<IndexState>
