import actions from '~/store/modules/shared/advertising/form/actions'
import getters from '~/store/modules/shared/advertising/form/getters'
import mutations from '~/store/modules/shared/advertising/form/mutations'
import state from '~/store/modules/shared/advertising/form/state'
import { createModule } from '~/factories/store'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
