import { interopDefault } from '~/router/utils'

export const adminContentManagementToolsRoutes = [
  {
    name: '__admin_content_management_tools',
    path: '/admin/content-management-tools',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/index.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-index" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_action_list',
    path: '/admin/content-management-tools/actions',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/action/list.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-action-list" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_action_edit',
    path: '/admin/content-management-tools/action/:actionId/edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/action/edit.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-action-edit" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_category_new',
    path: '/admin/content-management-tools/category/:categoryId/new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/category/new.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-category-new" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_category_move',
    path: '/admin/content-management-tools/category/:categoryId/move',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/category/move.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-category-move" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_category_delete',
    path: '/admin/content-management-tools/category/:categoryId/delete',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/category/delete.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-category-delete" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_field_new',
    path: '/admin/content-management-tools/category/:categoryId/field/new',
    props: true,
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/field/new.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-field-new" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_field_edit',
    path:
      '/admin/content-management-tools/category/:categoryId/field/:fieldId/edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/field/edit.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-field-edit" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_extra_new',
    path: '/admin/content-management-tools/extra/:categoryId/new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/extra/new.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-extra-new" */
        )
      )
  },
  {
    name: '__admin_content_management_tools_extra_edit',
    path: '/admin/content-management-tools/extra/:extraId/edit',
    props: true,
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/content-management-tools/extra/edit.vue' /* webpackChunkName: "pages-shared-admin-content-management-tools-extra-edit" */
        )
      )
  }
]
