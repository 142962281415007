import { ErrorPageError } from '~/models/error'

function createCustomErrorFn(defaultErrorFn: any) {
  return function(errorProps: ErrorPageError) {
    defaultErrorFn(errorProps)
  }
}
export default ({ error }: any, inject: any) => {
  const errorFunction = createCustomErrorFn(error.bind({}))
  inject('error', errorFunction)
}
