import { interopDefault } from '~/router/utils'

const meta = { noFooter: true }
export default [
  {
    name: '__dealer_category_map',
    path: '/xml/category-map/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/integration/categories/category-map.vue' /* webpackChunkName: "pages-dealers-integration-categories-category-map.vue" */
        )
      ),
    meta
  }
]
