import { StoreModuleCreator } from '~/models/store/factory'
import { Module } from 'vuex'

export function createModule<S>({
  actions,
  getters,
  mutations,
  state,
  modules
}: StoreModuleCreator<S>) {
  const m: Module<S, any> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
  }
  // precaution to avoid modules overwrite when used with the spread operator
  if (modules) {
    m.modules = modules
  }
  return m
}
