import actions from '~/store/modules/shared/classifieds/view/actions'
import mutations from '~/store/modules/shared/classifieds/view/mutations'
import getters from '~/store/modules/shared/classifieds/view/getters'
import state from '~/store/modules/shared/classifieds/view/state'
import { createModule } from '~/factories/store'

export default createModule({
  actions,
  mutations,
  getters,
  state
})
