import Vue from 'vue'
import Router from 'vue-router'
import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import { container } from 'tsyringe'
import { scrollBehavior, setScrollRestoration } from './utils'
import indexRoutes from './routes/car'
import sharedAdminRoutes from './routes/shared/admin'
import adminRoutes from './routes/car/admin'
import blacklistRoutes from './routes/car/blacklist'
import editRoutes from './routes/shared/classifieds/edit'
import newFormRoutes from './routes/car/classifieds/new'
import classifiedSearchRoutes from './routes/car/classifieds/search'
import classifiedViewRoutes from './routes/car/classifieds/view'
import pathDealerSiteRoutes from './routes/car/dealers/site/path'
import dealersMapRoutes from './routes/car/dealers/map'
import dealersRegionRoutes from './routes/car/dealers/index'
import dealersPerRegionListRoutes from './routes/car/dealers/list'
import dealerIntegrationRoutes from './routes/car/dealers/integration'
import informationRoutes from './routes/car/information'
import quickSearchRoutes from './routes/car/quick-search'
import messagesRoutes from './routes/shared/messages'
import parkingRoutes from './routes/car/parking'
import promoteRoutes from './routes/car/promote'
import autotouchRoutes from './routes/car/autotouch'
import playgroundRoutes from './routes/shared/playground'
import searchClassifiedByIdRoutes from './routes/car/search-classified-by-id'
import usersRoutes from './routes/car/users'
import listsRoutes from './routes/shared/lists'
import compareRoutes from './routes/shared/compare'
import advertising from './routes/car/advertising'
import xymaRouter from './routes/car/xyma.ts'
import dealerFaqRouter from './routes/car/faq/dealer'
import partsRouter from './routes/car/parts.ts'
import dispatchedFormRoutes from './routes/car/classifieds/dispatched-form.ts'
import shareSettingsRoutes from './routes/shared/settings'
import externalCertificatesRoutes from './routes/car/external-certificates.ts'
import marketingRoutes from './routes/marketing'
import qnaRoutes from './routes/car/qna'
import notificationsRoutes from './routes/shared/notifications'
import analyticsRoutes from './routes/shared/analytics'
import feedsRoutes from './routes/shared/feeds'
import filterRoutes from './routes/car/filters.ts'
import tradesRoutes from './routes/shared/trades.ts'
import accountRoutes from './routes/car/account'
import restoreTouchWithCodeRoutes from './routes/shared/classifieds/restore-touch-with-code'
import oneClickTouchRoutes from './routes/shared/classifieds/one-click-touch'
import auditsLogin from './routes/car/audits-login'
import landingPages from './routes/car/landing-pages'
import SubdomainService from '../services/SubdomainService'
import loginRegister from './routes/car/login-register'
import acceptAuditRequest from './routes/car/accept-audit-request'
import unsubscribeRoutes from './routes/shared/unsubscribe'
import changeEmail from './routes/shared/change-email'
import deleteRatingForm from './routes/car/classifieds/delete-rating-form'
import shortsRoutes from '~/router/routes/shared/shorts.ts'
import vehicleServiceRoutes from './routes/car/vehicle-service'
import sharedLandingPages from './routes/shared/landing-pages'
import marketplaceRoutes from './routes/car/marketplace'
import { isProductionEnv } from '~/constants/environment.ts'
import saleRequestsRoutes from './routes/car/sale-requests'
import piraeusLoanTerms from './routes/shared/piraeus-loan-terms.ts'
import xmlRoutes from './routes/car/xml.ts'

Vue.use(Router)

setScrollRestoration()

const router = routes =>
  new Router({
    mode: 'history',
    scrollBehavior,
    routes
  })

/**
 *
 * @param serverSideCtx {Context | undefined} This is undefined on the client side.
 * @returns {VueRouter}
 */
export function createRouter(serverSideCtx) {
  const subdomainService = container.resolve(SubdomainService)
  subdomainService.setContext(serverSideCtx)

  if (subdomainService.shouldCreateSubdomainRouter()) {
    const subdomainRoutes = subdomainService.getRoutes() // this needs to be here before the handleSubdomainRouterCreationCookie
    subdomainService.handleSubdomainRouterCreationCookie()
    return router(subdomainRoutes)
  }

  // Pre-emptive deletion to guarantee that no cookie is being left over.
  subdomainService.deleteSubdomainRouterCreationCookieFromClient()

  return router([
    ...accountRoutes,
    ...(isProductionEnv ? [] : playgroundRoutes),
    ...newFormRoutes,
    ...editRoutes,
    ...dispatchedFormRoutes,
    ...quickSearchRoutes,
    ...compareRoutes,
    ...usersRoutes,
    ...classifiedViewRoutes,
    ...dealersMapRoutes,
    ...classifiedSearchRoutes,
    ...searchClassifiedByIdRoutes,
    ...parkingRoutes,
    ...pathDealerSiteRoutes,
    ...dealerIntegrationRoutes,
    ...dealersRegionRoutes,
    ...dealersPerRegionListRoutes,
    ...informationRoutes,
    ...sharedAdminRoutes,
    ...adminRoutes,
    ...messagesRoutes,
    ...blacklistRoutes,
    ...indexRoutes,
    ...listsRoutes,
    ...advertising,
    ...xymaRouter,
    ...partsRouter,
    ...dealerFaqRouter,
    ...shareSettingsRoutes,
    ...qnaRoutes,
    ...promoteRoutes,
    ...externalCertificatesRoutes,
    ...marketingRoutes,
    ...notificationsRoutes,
    ...autotouchRoutes,
    ...analyticsRoutes,
    ...feedsRoutes,
    ...filterRoutes,
    ...tradesRoutes,
    ...auditsLogin,
    ...restoreTouchWithCodeRoutes,
    ...oneClickTouchRoutes,
    ...landingPages,
    ...changeEmail,
    ...loginRegister,
    ...unsubscribeRoutes,
    ...acceptAuditRequest,
    ...deleteRatingForm,
    ...shortsRoutes,
    ...sharedLandingPages,
    ...vehicleServiceRoutes,
    ...piraeusLoanTerms,
    ...xmlRoutes,
    ...saleRequestsRoutes,
    ...marketplaceRoutes
  ])
  // When adding new routes remember: if they start with /user/ add them on top to avoid override by userProfileRoutes
}
