import { CIcon } from '~/icons/types'

export const ciChevronDown: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12.707 16.457a1 1 0 0 1-1.414 0l-7.5-7.5a1 1 0 0 1 1.414-1.414L12 14.336l6.793-6.793a1 1 0 1 1 1.414 1.414l-7.5 7.5Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  name: 'chevron-down',
  type: 'regular'
}
