import { interopDefault } from '~/router/utils'
import { smoothenRoutesScroll } from '~/utils/router'

import { RouteConfig } from 'vue-router'

export function getAuditsExternalCertificatesRoutes(
  namePrefix: string = '',
  meta: any = {}
): RouteConfig[] {
  const prefix = namePrefix ? '_' + namePrefix : ''
  return smoothenRoutesScroll([
    {
      path: 'audits/external-certificates',
      name: `_${prefix}_account_audits_external_certificates`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/audits/external-certificates/index.vue' /* webpackChunkName: "pages-shared-account-audits-external-certificates-index" */
          )
        ),
      meta
    },
    {
      path: 'audits/external-certificates/new',
      name: `_${prefix}_account_audits_external_certificates_new`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/audits/external-certificates/new.vue' /* webpackChunkName: "pages-shared-account-audits-external-certificates-new.vue" */
          )
        ),
      meta
    },
    {
      path: 'audits/external-certificates/:reportId',
      name: `_${prefix}_account_audits_external_certificates_view`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/audits/external-certificates/view.vue' /* webpackChunkName: "pages-shared-account-audits-external-certificates-view.vue" */
          )
        ),
      meta
    },

    {
      path: 'audits/external-certificates/:reportId/edit',
      name: `_${prefix}_account_audits_external_certificates_edit`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/audits/external-certificates/edit.vue' /* webpackChunkName: "pages-shared-account-audits-external-certificates-edit.vue" */
          )
        ),
      meta
    }
  ])
}

export const auditsExternalCertificatesRoutes = getAuditsExternalCertificatesRoutes()
