import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faHeart as fortawesomefreeregularsvgicons_faHeart } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faHeart)

    import  { faEye as fortawesomefreeregularsvgicons_faEye } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEye)

    import  { faEyeSlash as fortawesomefreeregularsvgicons_faEyeSlash } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEyeSlash)

    import  { faCode as fortawesomefreesolidsvgicons_faCode } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCode)

    import  { faCreditCard as fortawesomefreesolidsvgicons_faCreditCard } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCreditCard)

    import  { faMoneyCheck as fortawesomefreesolidsvgicons_faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMoneyCheck)

    import  { faMoneyBill as fortawesomefreesolidsvgicons_faMoneyBill } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMoneyBill)

    import  { faHandshake as fortawesomefreesolidsvgicons_faHandshake } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHandshake)

    import  { faPaperPlane as fortawesomefreesolidsvgicons_faPaperPlane } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPaperPlane)

    import  { faShoppingCart as fortawesomefreesolidsvgicons_faShoppingCart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faShoppingCart)

    import  { faTag as fortawesomefreesolidsvgicons_faTag } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTag)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faCheckCircle as fortawesomefreesolidsvgicons_faCheckCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheckCircle)

    import  { faSort as fortawesomefreesolidsvgicons_faSort } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSort)

    import  { faChevronRight as fortawesomefreesolidsvgicons_faChevronRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronRight)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faStore as fortawesomefreesolidsvgicons_faStore } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStore)

    import  { faQuestionCircle as fortawesomefreesolidsvgicons_faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faQuestionCircle)

    import  { faCaretDown as fortawesomefreesolidsvgicons_faCaretDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretDown)

    import  { faInfoCircle as fortawesomefreesolidsvgicons_faInfoCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faInfoCircle)

    import  { faPrint as fortawesomefreesolidsvgicons_faPrint } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPrint)

    import  { faHeart as fortawesomefreesolidsvgicons_faHeart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHeart)

    import  { faPlusSquare as fortawesomefreesolidsvgicons_faPlusSquare } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlusSquare)

    import  { faStar as fortawesomefreesolidsvgicons_faStar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStar)

    import  { faMapMarkerAlt as fortawesomefreesolidsvgicons_faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMapMarkerAlt)

    import  { faMap as fortawesomefreesolidsvgicons_faMap } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMap)

    import  { faCommentDots as fortawesomefreesolidsvgicons_faCommentDots } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCommentDots)

    import  { faBell as fortawesomefreesolidsvgicons_faBell } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBell)

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faPhone as fortawesomefreesolidsvgicons_faPhone } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPhone)

    import  { faPlus as fortawesomefreesolidsvgicons_faPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlus)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
