import { MutationTree } from 'vuex'
import { SnackbarState } from './state'
import {
  SET_SNACKBAR,
  SET_TIMEOUT
} from '~/store/modules/shared/snackbar/mutation-types'
import { Snackbar } from '~/models/app/snackbar'

export default {
  [SET_SNACKBAR](state, snackbar: Snackbar) {
    state.snackbar = snackbar
  },
  [SET_TIMEOUT](state, timeout) {
    state.timeout = timeout
  }
} as MutationTree<SnackbarState>
