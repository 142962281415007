export const SET_PARAM = 'SET_PARAM'
export const SET_LOADING = 'SET_LOADING'
export const ADD_DEALERS = 'ADD_DEALERS'
export const SET_MOBILE_LIST_VISIBLE = 'SET_MOBILE_LIST_VISIBLE'
export const SET_HOVERED_DEALER = 'SET_HOVERED_DEALER'
export const SET_ACTIVE_DEALER = 'SET_ACTIVE_DEALER'
export const SET_ACTIVE_GEOLOCATION = 'SET_ACTIVE_GEOLOCATION'
export const TOGGLE_MOBILE_FACETS_VISIBLE = 'TOGGLE_MOBILE_FACETS_VISIBLE'
export const RESET_PARAMS = 'RESET_PARAMS'
export const SET_PARAMS = 'SET_PARAMS'
export const SET_DEALER_DATA = 'SET_DEALER_DATA'
export const INCREMENT_PAGE = 'INCREMENT_PAGE'
export const TOGGLE_MOBILE_LIST_VISIBLE = 'TOGGLE_MOBILE_LIST_VISIBLE'
export const SET_HAS_REACHED_LAST_PAGE = 'SET_HAS_REACHED_LAST_PAGE'
