import { render, staticRenderFns } from "./AdvertisingError.vue?vue&type=template&id=467a1e20&scoped=true&"
import script from "./AdvertisingError.vue?vue&type=script&lang=ts&"
export * from "./AdvertisingError.vue?vue&type=script&lang=ts&"
import style0 from "./AdvertisingError.vue?vue&type=style&index=0&id=467a1e20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467a1e20",
  null
  
)

export default component.exports