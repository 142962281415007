
























































import { defineComponent } from '@nuxtjs/composition-api'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CCircleProgressBar: defineVue3AsyncComponent(() =>
      import(
        '~/components/shared/configurable/progress-bar/CCircleProgressBar.vue'
      )
    )
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    opacity: {
      type: Number,
      required: false,
      default: 0.6
    },
    spinnerVariant: {
      type: String,
      default: 'primary'
    },
    foregroundContainerClass: {
      type: String,
      default: ''
    },
    rounded: {
      type: [Boolean, String],
      default: null
    },
    noFade: {
      type: Boolean,
      default: false
    },
    spinnerSize: {
      type: String,
      default: null
    },
    hideForegroundContent: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    },
    progress: {
      type: Number,
      default: null
    },
    darkLayer: {
      type: Boolean,
      default: false
    }
  }
})
