import { TopicMap, NotificationGroup } from '~/models/notification/types'

export interface NotificationTopicSubscriptionState {
  subscriptions: TopicMap
  loading: boolean
  groups: Array<NotificationGroup>
}

export const NOTIFICATION_TOPIC_SUBSCRIPTION_NS =
  'notification/topic/subscription'

export default function getDefaultState(): NotificationTopicSubscriptionState {
  return {
    subscriptions: new Map(),
    loading: false,
    groups: []
  }
}
