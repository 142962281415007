import { Search } from '~/router/routes/car/classifieds/search/components'

export const adminSearchRoutes = [
  {
    name: '__classifieds_search_admin',
    path: '/admin/classifieds/',
    component: Search,
    meta: { noFooter: true }
  }
]
