export function removeWhiteSpaces(str: string) {
  return str.replace(/ /g, '')
}

export function concatUrl(url: string, urlToConcat: string) {
  if (!url.includes('?')) {
    return url + '/?' + urlToConcat
  } else {
    return url + '&' + urlToConcat
  }
}

export function replaceAllStrings(
  str: string,
  subStrToReplace: string,
  subStrReplacement: string
) {
  const re = new RegExp(subStrToReplace, 'g')
  return str.replace(re, subStrReplacement)
}
type Options = {
  allUppercase?: boolean
  replaceAll?: boolean
}
export function capitalizeSubStrings(
  str: string,
  subStrings: string[],
  { allUppercase = false, replaceAll = false }: Options = {}
) {
  for (const subString of subStrings) {
    const uppercaseSubstr = allUppercase
      ? subString.toUpperCase()
      : subString.charAt(0).toUpperCase() + subString.slice(1)
    if (replaceAll) {
      str = replaceAllStrings(str, subString, uppercaseSubstr)
    } else {
      str = str.replace(subString, uppercaseSubstr)
    }
  }
  return str
}

export function capitalizeString(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeSentences(
  str: string,
  { withSpaces = true }: { withSpaces: boolean } = { withSpaces: true }
) {
  const divider = withSpaces ? ' ' : ''
  return str
    .split(`.${divider}`)
    .map(sentence => capitalizeString(sentence.trim()))
    .join(`.${divider}`)
}

export function capitalizeAllWords(str: string) {
  return str
    .split(' ')
    .map(word => capitalizeString(word.trim()))
    .join(' ')
}

export const capitalizeWords = capitalizeAllWords // alias

export function getLines(str: string) {
  return str.split(/\r?\n/)
}

/**
 * Naive implementation.
 * @param {string} str
 * @returns {boolean}
 */
export function isEmail(str: string) {
  const indexOfAtSign = str.indexOf('@')
  return Boolean(
    str &&
      indexOfAtSign > -1 &&
      indexOfAtSign === str.lastIndexOf('@') &&
      indexOfAtSign < str.lastIndexOf('.')
  )
}

export function textExistsInString(text: string, str: string) {
  function clean(s: string) {
    return s
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
  }
  return clean(str).includes(clean(text))
}

/**
 * Encodes string to base64.
 * @param {string | *} str
 * @returns {string} Base64 encoded string
 */
export function toBase64(str: string) {
  return Buffer.from(String(str)).toString('base64')
}

export function trimEnd(str: string, char: string = ' '): string {
  if (char.length !== 1) {
    throw new TypeError(`Character should have length 1: ${char}`)
  }

  let trimmed: string = str
  for (let i = str.length - 1; i > 0; i--) {
    if (trimmed[i] !== char) {
      return trimmed
    }

    trimmed = trimmed.substring(0, trimmed.length - 1)
  }
  return ''
}
