import { inject } from 'tsyringe'
import VueRouter from 'vue-router'
import { containerScoped } from '~/decorators/dependency-container'
import { ParkingTab, RecentParkingTab } from '~/models/parking'
import { ActionResult } from '~/models/shared/result'
import { getParkingRoutes } from '~/router/routes/shared/account/account-parking'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { PARKING_NS } from '~/store/modules/shared/parking/state'
import { removeKeysWithNoValues } from '~/utils/object'
import { ParkingClassifieds } from '~/models/classified/parking/types'
import RequestBuilder from '~/builders/http/RequestBuilder'
@containerScoped()
export class ClassifiedsFavoritesService {
  constructor(
    @inject(VueRouter) private router: VueRouter,
    @inject(Store) private store: Store<RootState>,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  clearNotificationForClassifieds(
    ids: number[],
    isParking: boolean = false
  ): Promise<ActionResult> {
    const params = removeKeysWithNoValues({
      parking: isParking ? 1 : null
    })
    return this.requestBuilder
      .request('post', '/api/user/notifications/read/')
      .params(params as { [key: string]: any })
      .data({ classified_id: ids })
      .action()
      .send()
  }

  getRouteHrefFromTab(tab?: ParkingTab): string {
    if (tab && tab !== 'classifieds') {
      const prefix = this.router.currentRoute.name?.startsWith('__account')
        ? 'account'
        : ''
      const accountParkingRoutes = getParkingRoutes(prefix)
      const routeName = accountParkingRoutes.find(r => r.meta.tabName === tab)
        ?.name
      if (routeName) {
        return this.router.resolve({ name: routeName }).href + '/'
      }
    }

    return this.router.resolve({ name: '__parking' }).href + '/'
  }

  getRouteHrefFromRecentTab(recentTab?: RecentParkingTab): string {
    const prefixFormatted = this.router.currentRoute.name?.startsWith(
      '__account'
    )
      ? '_account'
      : ''

    if (recentTab && recentTab !== 'recent-classifieds') {
      return (
        this.router.resolve({
          name: `_${prefixFormatted}_parking_recent_searches`
        }).href + '/'
      )
    }

    return (
      this.router.resolve({
        name: `_${prefixFormatted}_parking_recent_classifieds`
      }).href + '/'
    )
  }

  getTabFromRouteName(name: string): string | undefined {
    const prefix = name.startsWith('__account') ? 'account' : ''
    const accountParkingRoutes = getParkingRoutes(prefix)
    return accountParkingRoutes.find(r => r.name === name)?.meta.tabName
  }

  getRecentTabFromRouteName(name: string): string | undefined {
    const prefix = name.startsWith('__account') ? 'account' : ''
    const accountParkingRoutes = getParkingRoutes(prefix)
    const formattedPrefix = prefix ? '_' + prefix : ''
    return accountParkingRoutes
      .find(r => r.name === `_${formattedPrefix}_parking_recent`)
      ?.children?.find(c => c.name === name)?.meta.tabName
  }

  getToForRouteName(name: string): string | null {
    const useChildren = this.store.getters[`${PARKING_NS}/useChildren`]

    if (!useChildren) {
      return null
    }
    return this.getHrefForRouteName(name)
  }

  getHrefForRouteName(name: string): string {
    return this.router.resolve({ name }).href + '/'
  }

  getClassifiedFromId(id: number) {
    const parkedClassifieds = this.store.getters[
      `${PARKING_NS}/getClassifiedsList`
    ]
    return parkedClassifieds.find((clsfd: any) => clsfd.id === id)
  }

  getFavoriteClassifieds(
    category: number,
    sort: string
  ): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('get', '/api/classifieds/favorites/')
      .params({ category, sort, per_page: 1000 })
      .validate(body => body.data?.classifieds)
      .map(body => ({ ...body.data, sortOptions: body.data.sort_options }))
      .send()
  }

  addClassifiedToFavorites(classified: number): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('put', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }

  removeClassifiedFromFavorites(
    classified: number
  ): Promise<{ classifieds: number[] }> {
    return this.requestBuilder
      .request('delete', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }
}
