import { auditsPackagesRoutes } from '~/router/routes/car/account/audits/packages'
import { auditShopsRoutes } from '~/router/routes/car/account/audits/shops'
import { auditsAuditorsRoutes } from '~/router/routes/car/account/audits/auditors'
import { auditsExternalCertificatesRoutes } from '~/router/routes/car/account/audits/external-certificates'
import { auditsRequestsRoutes } from '~/router/routes/car/account/audits/requests'

export const accountsAuditsRoutes = [
  ...auditsPackagesRoutes,
  ...auditShopsRoutes,
  ...auditsAuditorsRoutes,
  ...auditsExternalCertificatesRoutes,
  ...auditsRequestsRoutes
]
