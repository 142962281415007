import { ActionTreeWithRootState } from '~/store/types'
import { SnackbarState } from './state'
import { sleep } from '~/utils/time'
import {
  SET_SNACKBAR,
  SET_TIMEOUT
} from '~/store/modules/shared/snackbar/mutation-types'
import { SnackbarOptions } from '~/models/app/snackbar'

export default {
  async show(
    { state, commit },
    { message, options = {} }: { message: string; options: SnackbarOptions }
  ) {
    if (process.server) {
      return
    }

    if (state.snackbar) {
      commit(SET_SNACKBAR, null)
      if (state.timeout) {
        clearTimeout(state.timeout)
      }

      await sleep(400)
    }

    commit(SET_SNACKBAR, {
      message,
      options
    })

    commit(
      SET_TIMEOUT,
      setTimeout(() => {
        commit(SET_SNACKBAR, null)
      }, options.timeout || 3000)
    )
  }
} as ActionTreeWithRootState<SnackbarState>
