import { GetterTreeWithRootState } from '~/store/types'
import { StorageState } from './state'

export default {
  /**
   *
   * Returns an object's nested property using dot notation
   */
  getItem(state) {
    return (key: string) => {
      try {
        // the key is in dot notation form, eg 'some.example.key' represents { some: { example: key: {} } }
        return key.split('.').reduce((a, b) => (a as any)[b], state.state || {})
      } catch (e) {
        return null
      }
    }
  }
} as GetterTreeWithRootState<StorageState>
