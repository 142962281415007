import { interopDefault } from '~/router/utils'

export const adminTickets = [
  {
    path: '/admin/tickets/',
    name: '__admin_tickets',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/tickets/list.vue' /* webpackChunkName: "pages-shared-admin-tickets-list.vue" */
        )
      ),
    meta: {
      scrollTopSmooth: true
    }
  },
  {
    path: '/admin/tickets/:id/',
    name: '__admin_tickets_view',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/tickets/view.vue' /* webpackChunkName: "pages-shared-admin-tickets-list.vue" */
        )
      )
  }
]
