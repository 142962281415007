import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'
import { containerScoped } from '~/decorators/dependency-container'
import LocaleMessage = VueI18n.LocaleMessage
import { SnackbarOptions, SnackbarVariant } from '~/models/app/snackbar'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { SNACKBAR_NS } from '~/store/modules/shared/snackbar/state'

@containerScoped()
/**
 *
 * @deprecated Use the useSnackbar composable instead
 */
export default class SnackbarService {
  constructor(
    @inject(VueI18n) private i18n: VueI18n,
    @inject(Store) private store: Store<RootState>
  ) {}

  show(message: string | LocaleMessage, options: SnackbarOptions = {}) {
    this.store.dispatch(`${SNACKBAR_NS}/show`, {
      message,
      options
    })
  }

  success(text: string | LocaleMessage, options?: any) {
    this.show(text, this.generateOptions('success', options))
  }

  savedChanges() {
    this.success(this.i18n.t('success_saved_changes') as string, {
      time: 1600
    })
  }

  saved() {
    this.success(this.i18n.t('saved::verb') as string, {
      time: 1600
    })
  }

  error(text: string | LocaleMessage | Error, options?: any) {
    this.show(
      text instanceof Error ? text.message : text,
      this.generateOptions('danger', options)
    )
  }

  info(text: string | LocaleMessage, options?: any) {
    this.show(text, this.generateOptions('info', options))
  }

  private generateOptions(
    variant: SnackbarVariant,
    options?: any
  ): SnackbarOptions {
    return {
      variant,
      action: options && options.action,
      classes: (options && options.classes) || [],
      timeout: options && options.time
    }
  }
}
