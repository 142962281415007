import { I18nLocale } from '~/models/shared/types'

export const I18N_NS = 'i18n'
export interface I18nState {
  locale: I18nLocale
}

function getDefaultState(): I18nState {
  return {
    locale: I18nLocale.EL
  }
}

export default getDefaultState
