import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { APP_NS } from '~/store/modules/shared/app/state'
import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { Route } from 'vue-router/types/router'

@ClientOnly
@containerScoped()
export default class AdmanAdsService {
  constructor(@inject(Store) private store: Store<RootState>) {}

  private getAdman() {
    return window.Adman || { ws() {} }
  }

  private getAdmanAds() {
    return window._admanLastWsAsHash || {}
  }

  public createAd(ws: string, size: string, elementId: string): void {
    const adman = this.getAdman()
    if (!adman) {
      return
    }
    adman.ws({
      ws,
      s: size,
      h: 'altair.adman.gr',
      async: true,
      friendlyIframe: true,
      elementId
    })
  }

  public refreshAllSlots() {
    const admanAds = this.getAdmanAds()

    this.refreshInnovativeFormats()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_ws, ad] of Object.entries(admanAds) as any) {
      try {
        ad.reload('', true)
      } catch (_error) {
        // ignore adman errors
      }
    }
  }

  public refreshInnovativeFormats(route?: Route) {
    const isPlot = this.store.getters[`${APP_NS}/isPlot`]

    if (isPlot) {
      return
    }

    const adman = this.getAdman()

    adman.globalProfileOption = {
      viewCls: route?.meta?.viewCls ? 'enable' : undefined
    }

    if (adman?.adunit) {
      const admanElems = document.querySelectorAll(
        '.adman-sticky, #adman-floating-player'
      )
      for (const elem of Array.from(admanElems)) {
        elem.remove()
      }

      adman.adunit({
        id: 397,
        h: 'https://x.grxchange.gr',
        inline: true
      })
    }
  }

  public refreshSlot(ws: string) {
    const admanAds = this.getAdmanAds()
    const ad: any = admanAds[ws]
    if (!ad) {
      return
    }
    ad.reload('', true)
  }
}
