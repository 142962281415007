import { createModule } from '~/factories/store'
import getters from '~/store/modules/shared/preload/image/getters'
import mutations from '~/store/modules/shared/preload/image/mutations'
import state from '~/store/modules/shared/preload/image/state'

export default createModule({
  getters,
  mutations,
  state
})
