import state from '~/store/modules/shared/dealers/integration/categories/state'
import getters from '~/store/modules/shared/dealers/integration/categories/getters'
import actions from '~/store/modules/shared/dealers/integration/categories/actions'
import mutations from '~/store/modules/shared/dealers/integration/categories/mutations'

import { createModule } from '~/factories/store'

export const DEALER_INTEGRATION_CATEGORIES_NS = 'dealers/integration/categories'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
