import StorageService from '~/services/user/StorageService'
import { ActionTreeWithRootState } from '~/store/types'
import { SET_STATE } from './mutation-types'
import { StorageState } from './state'

export default {
  async load({ commit }) {
    const storageService = this.$dep(StorageService)

    try {
      const { state } = await storageService.getState()
      commit(SET_STATE, state)
    } catch (error) {}
  },
  async set({ commit }, item) {
    const storageService = this.$dep(StorageService)

    try {
      const { state } = await storageService.setItem(item)
      commit(SET_STATE, state)
    } catch (error) {}
  }
} as ActionTreeWithRootState<StorageState>
