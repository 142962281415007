import UserExtrasService from '~/services/user/UserExtrasService'
import { ActionTreeWithRootState } from '~/store/types'
import { SET_ADMIN_EXTRA_DATA } from './mutation-types'
import { AdminUserState } from '~/store/modules/shared/admin/user/state'

export default {
  async loadAdminExtras({ commit }) {
    const { adminExtras } = await this.$dep(UserExtrasService).getAdminExtras()
    commit(SET_ADMIN_EXTRA_DATA, adminExtras)
  }
} as ActionTreeWithRootState<AdminUserState>
