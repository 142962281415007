import { singleton } from '~/decorators/dependency-container'
import { SocialMediaLinks } from '~/models/dealer/types'

@singleton()
export default class UrlFormatter {
  /**
   * Assumes output should be https since all of the major social media support it.
   */
  formatSocialMediaUrl(url: string): string {
    return this.getValidHttpUrl(url)
  }

  /**
   * Converts to https if not already https or http.
   */
  getValidHttpUrl(url: string): string {
    const schemes = ['http://', 'https://']

    if (!schemes.some(s => url.indexOf(s) === 0)) {
      return 'https://' + url
    }

    return url
  }

  formatSocialMediaLinks(socialMediaLinks: SocialMediaLinks): SocialMediaLinks {
    const links = { ...socialMediaLinks }
    for (const [key, url] of Object.entries(links)) {
      if (url) {
        links[key] = this.formatSocialMediaUrl(url)
      }
    }
    return links
  }
}
