





















import { ErrorPageError } from '~/models/error'
import plotErrorImage from '~/assets/img/errors/plot-error.png'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { getRouteMetaItem } from '~/utils/router'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  },
  computed: {
    errorImage() {
      return plotErrorImage
    },
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    // TODO: move this to the store
    isIframe() {
      return getRouteMetaItem(this.$route, 'isIframe')
    }
  }
})
