import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export default class StatsService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  record(statNamespace: string, classifiedIds: number[]) {
    return this.requestBuilder
      .request('get', '/api/stats/record/')
      .params({
        stat: statNamespace,
        classified: classifiedIds
      })
      .action()
      .send()
  }
}
