import {
  FetchableFeed,
  LandingClassifiedsCount,
  QuickSearches
} from '~/models/user/types'
import { AnalyticsGlimpse } from '~/models/analytics/classifieds'
export const INDEX_NS = 'index'
export interface IndexState {
  fetchableFeeds: FetchableFeed[]
  count: LandingClassifiedsCount | null
  unreadMessagesCount: number
  quickSearches?: QuickSearches
  statistics: AnalyticsGlimpse | null
}

function getDefaultState(): IndexState {
  return {
    fetchableFeeds: [],
    count: null,
    unreadMessagesCount: 0,
    quickSearches: undefined,
    statistics: null
  }
}

export default getDefaultState
