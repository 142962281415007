import {
  Config,
  Facet,
  QuickSearch,
  Stats,
  Targets
} from '~/models/quick-search/types'
import { CategoryId, CategoryMap } from '~/models/category/types'
import { Breadcrumb } from '~/models/common/types'

export const QUICK_SEARCH_NS = 'quickSearch'

export interface QuickSearchState {
  facets: Facet[]
  config: Config
  targets: Targets
  stats: Stats
  breadcrumbs: Breadcrumb[]
  categoryMap: CategoryMap
  params: Record<string, any>
  randomBackgroundImageUrl: string
  quickSearches?: QuickSearch[]
  loading: boolean
}

function getDefaultState(): QuickSearchState {
  return {
    facets: [],
    config: {
      rootCategoryId: CategoryId.PLOT,
      title: '',
      logo: '',
      backgroundImagesUrls: [],
      baseUrl: '/api/plot/quick-search/'
    },
    targets: {},
    stats: {
      total: 0
    },
    breadcrumbs: [],
    categoryMap: new Map(),
    params: {},
    randomBackgroundImageUrl: '',
    quickSearches: [],
    loading: false
  }
}

export default getDefaultState
