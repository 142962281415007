import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

export default function({ route, redirect, store }: any) {
  let isPcCustom = store.getters[`${USER_AGENT_NS}/isPc`]
  if (process.client && window) {
    isPcCustom = window.innerWidth > 991 // rethink that number maybe?
  }

  if (route.params?.cid) {
    if (isPcCustom && route.name !== '__trades_swipe') {
      const query = route.query
      if (route.query?.['sub-tab']) {
        query.tab = 'likes'
      }
      return redirect({
        name: '__trades_swipe',
        params: route.params,
        query,
        hash: route.hash
      })
    } else if (!isPcCustom && route.name === '__trades_swipe') {
      // in mobile
      if (process.client && route.hash) {
        // probably viewing details in likes tab
        return
      }
      const query = { ...route.query }
      if (route.query.tab) {
        delete query.tab
      }
      if (route.query.tab === 'likes' || route.query?.['sub-tab']) {
        return redirect({
          name: '__trades_likes',
          params: route.params,
          query,
          hash: route.hash
        })
      } else if (route.query.tab === 'matches') {
        return redirect({
          name: '__trades_matches',
          params: route.params,
          query,
          hash: route.hash
        })
      }
    }
  }
}
