import { ActionTreeWithRootState } from '~/store/types'
import {
  DELETE_AD,
  DELETE_AD_ITEM,
  DELETE_CAMPAIGN,
  SET_AD_ITEM_PAGINATION,
  SET_AD_ITEM_ROWS,
  SET_AD_PAGINATION,
  SET_AD_ROWS,
  SET_BALANCE,
  SET_CAMPAIGN_PAGINATION,
  SET_CAMPAIGN_ROWS,
  SET_LOADING,
  UPDATE_AD,
  UPDATE_CAMPAIGN
} from '~/store/modules/shared/advertising/dashboard/mutation-types'
import AdvertiseService from '~/services/ads/AdvertiseService'
import { AdvertiseDashboardState } from '~/store/modules/shared/advertising/dashboard/state'
import { AdItemStatus } from '~/models/advertising/types'

export default {
  async fetchCampaigns({ commit }, pg?: string | number) {
    try {
      commit(SET_LOADING, true)

      const queryPg = this.$router.currentRoute.query?.cpg?.toString() || 1

      const { campaigns } = await this.$dep(AdvertiseService).fetchCampaigns(
        pg?.toString() || queryPg
      )

      const { rows, pagination } = campaigns

      commit(SET_CAMPAIGN_ROWS, rows)
      commit(SET_CAMPAIGN_PAGINATION, pagination)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async fetchAds(
    { commit, state },
    {
      pg,
      status
    }: { pg: number | string; status: AdItemStatus | AdItemStatus[] }
  ) {
    try {
      commit(SET_LOADING, true)
      const queryPg = this.$router.currentRoute.query?.apg?.toString() || 1
      const queryStatus =
        this.$router.currentRoute.query?.ad_status?.toString() || undefined
      const { ads } = await this.$dep(AdvertiseService).fetchAds(
        state.selectedCampaigns,
        pg?.toString() || queryPg,
        status || queryStatus
      )

      const { rows, pagination } = ads

      const transformedRows = rows.map(r => {
        const rejectedItem = r.items.find(
          i => i.status === AdItemStatus.REJECTED
        )
        if (rejectedItem) {
          return {
            ...r,
            rejectedItem
          }
        }
        return r
      })
      commit(SET_AD_ROWS, transformedRows)
      commit(SET_AD_PAGINATION, pagination)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async fetchAdItems(
    { commit, state },
    {
      pg,
      status
    }: { pg: number | string; status: AdItemStatus | AdItemStatus[] }
  ) {
    try {
      commit(SET_LOADING, true)
      const queryPg = this.$router.currentRoute.query?.ipg?.toString() || 1
      const queryStatus =
        this.$router.currentRoute.query?.ad_status?.toString() || undefined
      const { items } = await this.$dep(AdvertiseService).fetchAdItems(
        state.selectedCampaigns,
        state.selectedAds,
        pg?.toString() || queryPg,
        status || queryStatus
      )

      const { rows, pagination } = items

      commit(SET_AD_ITEM_ROWS, rows)
      commit(SET_AD_ITEM_PAGINATION, pagination)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async setCampaignStatus(
    { commit },
    { id, enabled }: { id: number | string; enabled: boolean }
  ) {
    try {
      commit(SET_LOADING, true)

      const updatedCampaign = await this.$dep(AdvertiseService).updateCampaign(
        { enabled },
        id
      )

      commit(UPDATE_CAMPAIGN, updatedCampaign.campaign)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteCampaign({ commit }, campaignId: number | string) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteCampaign(campaignId)
      commit(DELETE_CAMPAIGN, campaignId)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteCampaigns({ commit }, campaignIds: number[] | string[]) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteCampaigns(campaignIds)
      campaignIds.forEach((id: number | string) => commit(DELETE_CAMPAIGN, id))
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async setAdStatus(
    { commit },
    { id, enabled }: { id: number | string; enabled: boolean }
  ) {
    try {
      commit(SET_LOADING, true)

      const updatedAd = await this.$dep(AdvertiseService).updateAd(
        { enabled },
        id
      )

      commit(UPDATE_AD, updatedAd.ad)
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteAd({ commit }, adId: number | string) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteAd(adId)
      commit(DELETE_AD, adId)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteAdItem(
    { commit },
    { adId, itemId }: { adId: number | string; itemId: number | string }
  ) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteAdItem(adId, itemId)
      commit(DELETE_AD_ITEM, itemId)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteAds({ commit }, adIds: number[] | string[]) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteAds(adIds)
      adIds.forEach((id: number | string) => commit(DELETE_AD, id))
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)

      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async fetchBalance({ commit }) {
    try {
      commit(SET_LOADING, true)
      const { balance } = await this.$dep(AdvertiseService).getAdBalance()
      commit(SET_BALANCE, balance)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  }
} as ActionTreeWithRootState<AdvertiseDashboardState>
