import { interopDefault } from '~/router/utils'

export const adminSearchEmailsRoutes = [
  {
    name: '__admin_search_emails',
    path: '/admin/emails/search/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/emails/search.vue' /* webpackChunkName: "pages-admin-search-emails" */
        )
      ),
    meta: { noFooter: true }
  }
]
