import { MutationTree } from 'vuex'
import {
  Subscription,
  SubscriptionInfo,
  SubscriptionCounts
} from '~/models/search/subscriptions/types'
import { StringMap } from '~/models/shared/types'
import {
  SET_LOADING_STATE,
  ADD_SUBSCRIPTIONS,
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  OPEN_SUBSCRIPTION,
  CLOSE_SUBSCRIPTION,
  CLOSE_ALL_SUBSCRIPTIONS,
  SET_COUNTS,
  INCREMENT_TOTAL_COUNT,
  DECREMENT_TOTAL_COUNT,
  SET_SUBSCRIPTIONS_LOADED,
  ADD_SUBSCRIPTION_INFO_SET,
  ADD_SUBSCRIBABLE_SEARCH_ID,
  ADD_SUBSCRIBABLE_SEARCH_IDS
} from './mutation-types'
import { SearchSubscriptionsState } from './state'

export default {
  [ADD_SUBSCRIPTIONS](state, subscriptions: StringMap<Subscription>) {
    state.subscriptions = new Map([...state.subscriptions, ...subscriptions])

    const subscriptionsBySearch = new Map(
      [...subscriptions.values()].map(s => [s.searchId, s.id])
    )
    state.subscriptionsBySearch = new Map([
      ...state.subscriptionsBySearch,
      ...subscriptionsBySearch
    ])
  },
  [ADD_SUBSCRIPTION](state, subscription: Subscription) {
    const map = new Map(state.subscriptions)
    map.set(subscription.id, subscription)
    state.subscriptions = map

    const subscriptionsBySearch = new Map(state.subscriptionsBySearch)
    subscriptionsBySearch.set(subscription.searchId, subscription.id)
    state.subscriptionsBySearch = subscriptionsBySearch
  },
  [DELETE_SUBSCRIPTION](state, searchId: string) {
    const id = state.subscriptionsBySearch.get(searchId)
    if (!id) {
      throw new Error(`no subscription for search ${searchId}`)
    }
    const map = new Map(state.subscriptions)
    map.delete(id)
    state.subscriptions = map

    const subscriptionsBySearch = new Map(state.subscriptionsBySearch)
    subscriptionsBySearch.delete(searchId)
    state.subscriptionsBySearch = subscriptionsBySearch
  },
  [SET_LOADING_STATE](state, loading: boolean) {
    state.loading = loading
  },
  [SET_SUBSCRIPTIONS_LOADED](state) {
    state.subscriptionsLoaded = true
  },
  [OPEN_SUBSCRIPTION](state, searchId: string) {
    state.openSubscriptions = new Set([
      ...state.openSubscriptions.values(),
      searchId
    ])
  },
  [CLOSE_SUBSCRIPTION](state, searchId: string) {
    const openSubscriptions = new Set(state.openSubscriptions)
    openSubscriptions.delete(searchId)
    state.openSubscriptions = openSubscriptions
  },
  [CLOSE_ALL_SUBSCRIPTIONS](state) {
    state.openSubscriptions = new Set()
  },
  [SET_COUNTS](state, count: SubscriptionCounts) {
    state.totalCount = count.total
    state.maxCount = count.max
    state.countLoaded = true
  },
  [INCREMENT_TOTAL_COUNT](state) {
    if (typeof state.totalCount === 'undefined') {
      throw new TypeError('Cannot increment undefined total count')
    }

    state.totalCount = state.totalCount + 1
  },
  [DECREMENT_TOTAL_COUNT](state) {
    if (typeof state.totalCount === 'undefined') {
      throw new TypeError('Cannot decrement undefined total count')
    }

    state.totalCount = state.totalCount - 1
  },
  [ADD_SUBSCRIPTION_INFO_SET](state, infoSet: SubscriptionInfo) {
    const infoSets = new Map<string, SubscriptionInfo>(
      state.subscriptionInfoSets
    )
    infoSets.set(infoSet.searchId, infoSet)
    state.subscriptionInfoSets = infoSets
  },
  [ADD_SUBSCRIBABLE_SEARCH_ID](state, searchId: string) {
    const subscribableSearchIds = new Set(state.subscribableSearchIds)
    subscribableSearchIds.add(searchId)
    state.subscribableSearchIds = subscribableSearchIds
  },
  [ADD_SUBSCRIBABLE_SEARCH_IDS](state, searchIds: string[] | Set<string>) {
    state.subscribableSearchIds = new Set([
      ...state.subscribableSearchIds,
      ...searchIds
    ])
  }
} as MutationTree<SearchSubscriptionsState>
