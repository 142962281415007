import {
  PopularityTable,
  PopularityTableColumn,
  PopularityTableColumnTarget,
  PopularityVisualizationType
} from '~/models/popularity/types'
import { toCamelCase } from '~/utils/object'

export function formatPopularityVisualization(v: any): any {
  return {
    type: v.type,
    data: formatPopularityVisualizationData(v.data, v.type)
  }
}

export function formatPopularityVisualizationData(
  d: any,
  type: PopularityVisualizationType
): any {
  switch (type) {
    case PopularityVisualizationType.TABLE: {
      return formatPopularityTableVisualization(d)
    }
    default: {
      return toCamelCase(d)
    }
  }
}

export function formatPopularityTableVisualization(d: any): PopularityTable {
  const { rows, columns } = d

  const formatTarget = (t: any): PopularityTableColumnTarget => ({
    href: t.href,
    label: t.label,
    tags: t.tags,
    value: t.value && {
      filter: t.value.filter,
      label: t.value.label
    },
    dataSource: t.data_source
  })

  const formatColumn = (c: any): PopularityTableColumn => ({
    data: c.data,
    targets: (c.targets || []).map(formatTarget),
    value: c.value,
    label: c.label,
    isBaseDimension: c.is_base_dimension,
    total: c.total
  })

  return {
    rows: rows.map((r: any) => ({
      columns: (r.columns || []).map(formatColumn)
    })),
    columns: columns.map(formatColumn)
  }
}
