import { CategoryId } from '~/models/category/types'
import { getStaticUrl } from '~/utils/static'

export function getPlaceholderImage(
  categoryIds: CategoryId[] = []
): string | null {
  function getStaticForCategoryName(name: string) {
    return getStaticUrl(`/nuxt-static/img/category/placeholders/${name}.png`)
  }

  // spreading the categoryIds here to avoid mutating reactive objects
  for (const categoryId of [...categoryIds].reverse()) {
    switch (categoryId) {
      case CategoryId.RENTALS_CARS:
      case CategoryId.CARS:
      case CategoryId.RENTALS_TAXIS: {
        return getStaticForCategoryName('car')
      }
      case CategoryId.RENTALS_BIKES:
      case CategoryId.BIKES: {
        return getStaticForCategoryName('bike')
      }
      case CategoryId.RENTALS_CAMPERS: {
        return getStaticForCategoryName('camper')
      }
      case CategoryId.RENTALS_BOATS: {
        return getStaticForCategoryName('boat')
      }
      case CategoryId.RENTALS_TRUCKS: {
        return getStaticForCategoryName('van')
      }
      case CategoryId.RENTALS_BICYCLES: {
        return getStaticForCategoryName('bicycle')
      }
    }
  }
  return null
}
