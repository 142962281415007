import { MutationTree } from 'vuex'
import {
  ADD_PRELOADED_IMAGE,
  ADD_PRELOADED_IMAGES
} from '~/store/modules/shared/preload/image/mutation-types'
import { ImagePreloadState } from '~/store/modules/shared/preload/image/state'

export default {
  [ADD_PRELOADED_IMAGE](state, url: string) {
    const urls = new Set(state.urls)
    urls.add(url)
    state.urls = urls
  },
  [ADD_PRELOADED_IMAGES](state, urls: string[]) {
    const stateUrls = new Set(state.urls)
    for (const url of urls) {
      stateUrls.add(url)
    }
    state.urls = stateUrls
  }
} as MutationTree<ImagePreloadState>
