import Vue from 'vue'
import Vuex from 'vuex'
import { createModule as m } from '~/factories/store'

import adminCategoriesModule from '~/store/modules/shared/admin/categories'
import adminUserModule from '~/store/modules/shared/admin/user'
import adminUsersSearchModule from '~/store/modules/shared/admin/users/search'

import advertiseFormModule from '~/store/modules/shared/advertising/form'
import advertiseDashboardModule from '~/store/modules/shared/advertising/dashboard'

import classifiedsActionsModules from '~/store/modules/shared/classifieds/actions'
import classifiedsEditModule from '~/store/modules/shared/classifieds/edit'
import classifiedsFormModule from '~/store/modules/shared/classifieds/form'
import classifiedsSearchModule from '~/store/modules/shared/classifieds/search'
import classifiedsSearchActionsModule from '~/store/modules/shared/classifieds/search/actions/index'
import classifiedsViewModule from '~/store/modules/shared/classifieds/view'

import dealersIntegrationClassifiedsFieldModule from '~/store/modules/shared/dealers/integration/classifieds/field'
import dealersIntegrationCategoriesModule from '~/store/modules/shared/dealers/integration/categories'
import dealersMapModule from '~/store/modules/shared/dealers/map'
import dealersSiteModule from '~/store/modules/shared/dealers/site'
import dealersSiteAnalyticsModule from '~/store/modules/shared/dealers/site/analytics'
import dealersSiteErrorModule from '~/store/modules/shared/dealers/site/error'
import dealersSiteLayoutModule from '~/store/modules/shared/dealers/site/layout'

import quickSearchModule from '~/store/modules/shared/quickSearch'

import parkingModule from '~/store/modules/shared/parking'
import parkingSearchSubscriptionsModule from '~/store/modules/shared/parking/searchSubscriptions'

import pageModule from '~/store/modules/shared/page'
import notificationTopicSubscriptionModule from '~/store/modules/shared/notification/topic/subscription'
import indexModule from '~/store/modules/shared/index/index.ts'
import i18nModule from '~/store/modules/shared/i18n'
import adsModule from '~/store/modules/shared/ads'
import appModule from '~/store/modules/shared/app'
import userAgentModule from '~/store/modules/shared/userAgent'
import storageModule from '~/store/modules/shared/storage'
import userModule from '~/store/modules/shared/user'
import classifiedsAnalyticsModule from '~/store/modules/shared/classifieds-analytics'
import backgroundJobsModule from '~/store/modules/shared/backgroundJobs'
import preloadImageModule from '~/store/modules/shared/preload/image'
import flashModule from '~/store/modules/shared/flash'
import storeIndex from '~/store/modules/shared/index.ts'
import qnaModule from '~/store/modules/shared/qna/index.ts'
import tradesModule from '~/store/modules/shared/trades/index.ts'
import accountModule from '@/store/modules/shared/account/index.ts'
import accountAuditsModule from '@/store/modules/shared/account/audits/index.ts'
import loginModalModule from '@/store/modules/shared/login-modal/index.ts'
import appConfirmationModalModule from '@/store/modules/shared/app-confirmation-modal/index.ts'
import snackbarModule from '@/store/modules/shared/snackbar/index.ts'

Vue.use(Vuex)

export const createStore = () => {
  return new Vuex.Store({
    ...storeIndex,
    modules: {
      account: m({
        ...accountModule,
        modules: {
          audits: accountAuditsModule
        }
      }),
      admin: m({
        modules: {
          categories: adminCategoriesModule,
          user: adminUserModule,
          users: m({
            modules: {
              search: adminUsersSearchModule
            }
          })
        }
      }),
      advertise: m({
        modules: {
          dashboard: advertiseDashboardModule,
          form: advertiseFormModule
        }
      }),
      app: appModule,
      ads: adsModule,
      userAgent: userAgentModule,
      classifieds: m({
        modules: {
          actions: classifiedsActionsModules,
          edit: classifiedsEditModule,
          form: classifiedsFormModule,
          search: m({
            ...classifiedsSearchModule,
            modules: {
              actions: classifiedsSearchActionsModule
            }
          }),
          view: classifiedsViewModule
        }
      }),
      dealers: m({
        modules: {
          integration: m({
            modules: {
              classifieds: m({
                modules: {
                  field: dealersIntegrationClassifiedsFieldModule
                }
              }),
              categories: dealersIntegrationCategoriesModule
            }
          }),
          map: dealersMapModule,
          site: m({
            ...dealersSiteModule,
            modules: {
              analytics: dealersSiteAnalyticsModule,
              error: dealersSiteErrorModule,
              layout: dealersSiteLayoutModule
            }
          })
        }
      }),
      i18n: i18nModule,
      index: indexModule,
      notification: m({
        modules: {
          topic: m({
            modules: {
              subscription: notificationTopicSubscriptionModule
            }
          })
        }
      }),
      page: pageModule,
      backgroundJobs: backgroundJobsModule,
      parking: m({
        ...parkingModule,
        modules: {
          searchSubscriptions: parkingSearchSubscriptionsModule
        }
      }),
      quickSearch: quickSearchModule,
      storage: storageModule,
      user: userModule,
      classifiedsAnalytics: classifiedsAnalyticsModule,
      preload: m({
        modules: {
          image: preloadImageModule
        }
      }),
      qna: qnaModule,
      flash: flashModule,
      trades: tradesModule,
      loginModal: loginModalModule,
      appConfirmationModal: appConfirmationModalModule,
      snackbar: snackbarModule
    }
  })
}
