import { interopDefault } from '~/router/utils'

const ListView = () =>
  interopDefault(
    import(
      '~/pages/shared/lists/classifieds-list.vue' /* webpackChunkName: "pages-shared-lists-classifieds-list.vue" */
    )
  )

export default [
  {
    name: '__classifieds_list_view',
    path: '/l/:listId',
    component: ListView
  }
]
