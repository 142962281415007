// TODO: Support generic type as promise value. Requires type of module so that `default` is typed.
import { Route } from 'vue-router/types/router'
import qs from 'qs'

export function interopDefault(promise: Promise<any>) {
  return promise.then(m => m.default || m)
}

export function setScrollRestoration() {
  if (process.client) {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'

      // reset scrollRestoration to auto when leaving page, allowing page reload
      // and back-navigation from other pages to use the browser to restore the
      // scrolling position.
      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          window.history.scrollRestoration = 'auto'
        }
      })

      // Setting scrollRestoration to manual again when returning to this page.
      window.addEventListener('load', () => {
        window.history.scrollRestoration = 'manual'
      })
    }
  }
}

export function scrollBehavior(to: Route, from: Route, savedPosition: any) {
  // triggerScroll is only fired when a new component is loaded
  if (to.meta) {
    if (to.meta.ignoreScrollBehavior) {
      return
    }
    if (to.meta.scrollTopSmooth) {
      if (to.name === from.name) {
        return
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return
    }
  }
  let position = { x: 0, y: 0 }

  if (savedPosition) {
    position = savedPosition
  }
  const nuxt = window.$nuxt
  if (to.path === from.path && to.hash !== from.hash) {
    nuxt.$nextTick(() => nuxt.$emit('triggerScroll'))
  }

  // special handling for back in classified view
  const sp =
    to.query.sp ||
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    }).sp
  if (sp) {
    position = { x: 0, y: parseInt(sp.toString()) }
  }

  return new Promise(resolve => {
    // wait for the out transition to complete (if necessary)
    // TODO Nuxt3: Replace this with call to callOnce in Nuxt 3
    nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        const hash = '#' + window.CSS.escape(to.hash.substr(1))

        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            // @ts-ignore
            position = { selector: hash }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).'
          )
        }
      }
      resolve(position)
    })
  })
}
