import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__marketing_rg_campaign_terms',
    path: '/marketing/rg-campaign-terms/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/marketing/campaigns/rg-campaign-terms.vue' /* webpackChunkName: "rg-campaign-terms.vue" */
        )
      )
  }
]
