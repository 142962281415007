import { interopDefault } from '~/router/utils'

export const adminMissingMakeModelsRoutes = [
  {
    name: '__admin_missing_makemodels',
    path: '/admin/models/scrapper',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/models/scrapper.vue' /* webpackChunkName: "pages-admin-models-scrapper" */
        )
      )
  }
]
