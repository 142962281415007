import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'

import { containerScoped } from '~/decorators/dependency-container'
import {
  StorageStatePatchResult,
  StorageStateResult
} from '~/models/user/types'
import { invalidBodyError } from '../errors'

@containerScoped()
export default class StorageService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getState(): Promise<StorageStateResult> {
    const response: AxiosResponse = await this.http.get('/api/user/storage/')
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body.data
  }

  async setItem(item: object): Promise<StorageStatePatchResult> {
    const response: AxiosResponse = await this.http.patch(
      '/api/user/storage/',
      item
    )
    const { data: body } = response
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    } else if (!body.data.patched) {
      throw new Error(
        `Failed to patch the storage for item ${item} and user id ${body._user &&
          body._user.id}`
      )
    }
    return body.data
  }
}
