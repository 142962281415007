import { Page } from '~/models/common/types'
import {
  CategoryMappingId,
  CategoryMapping
} from '~/models/dealer/integration/category/mapping'
import { storePartitionedMapBuilder } from '~/store/modules/shared/dealers/integration/categories/factories'
import { GetterTreeWithRootState } from '~/store/types'
import { DealerIntegrationCategoriesState } from '~/store/modules/shared/dealers/integration/categories/state'

export default {
  page: (
    { pageNumber },
    { filteredMappings }
  ): Required<Pick<Page, 'number' | 'perPage' | 'totalCount'>> => ({
    number: pageNumber,
    totalCount: filteredMappings.size,
    perPage: 80
  }),
  rangeOfPages: (_, { page, filteredMappings }): [number, number] => [
    1,
    Math.ceil(filteredMappings.size / page.perPage)
  ],
  mappings: (state): Map<CategoryMappingId, CategoryMapping> =>
    storePartitionedMapBuilder('mappings')
      .buildFromState(state)
      .getCollection(),
  filteredMappings: (state): Map<CategoryMappingId, CategoryMapping> =>
    storePartitionedMapBuilder('filteredMappings')
      .buildFromState(state)
      .getCollection()
} as GetterTreeWithRootState<DealerIntegrationCategoriesState>
