import { GetterTreeWithRootState } from '~/store/types'
import { UserAgentState } from './state'

export default {
  isPc(state) {
    return state.isPc
  },
  isMobile(state) {
    return !state.isPc
  },
  isBot(state) {
    return state.isBot
  },
  isTablet(state) {
    return state.isTablet
  }
} as GetterTreeWithRootState<UserAgentState>
