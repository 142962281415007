import { QuickSearchState } from './state'
import { GetterTreeWithRootState } from '~/store/types'

export default {
  pageCategory(state, getters) {
    return state.categoryMap.get(getters.rootCategoryId.toString())
  },
  rootCategoryId(state) {
    return state.config.rootCategoryId
  }
} as GetterTreeWithRootState<QuickSearchState>
