import { interopDefault } from '~/router/utils'
import { smoothenRoutesScroll } from '~/utils/router'

export const auditShopsRoutes = smoothenRoutesScroll([
  {
    path: 'audits/shops',
    name: '__account_audits_shops',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/shops/index.vue' /* webpackChunkName: "pages-shared-account-audits-shops-index.vue" */
        )
      )
  },
  {
    path: 'audits/shops/new',
    name: '__account_audits_shops_new',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/shops/new.vue' /* webpackChunkName: "pages-shared-account-audits-shops-new.vue" */
        )
      )
  },
  {
    path: 'audits/shops/:shopId/edit',
    name: '__account_audits_shops_edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/audits/shops/edit/index.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-index.vue" */
        )
      ),
    redirect: { name: '__account_audits_shops_edit_details' },
    children: [
      {
        path: 'details',
        name: '__account_audits_shops_edit_details',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/details.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-details.vue" */
            )
          )
      },
      {
        path: 'auditors',
        name: '__account_audits_shops_edit_auditors',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/auditors/index.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-auditors-index.vue" */
            )
          )
      },
      {
        path: 'auditors/new',
        name: '__account_audits_shops_edit_auditors_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/auditors/new.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-auditors-new.vue" */
            )
          )
      },
      {
        path: 'auditors/:auditorId/edit',
        name: '__account_audits_shops_edit_auditors_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/auditors/edit.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-auditors-edit.vue" */
            )
          )
      },
      {
        path: 'service-locations',
        name: '__account_audits_shops_edit_service_locations',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/service-locations/index.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-servicing-locations-index.vue" */
            )
          )
      },
      {
        path: 'service-locations/new',
        name: '__account_audits_shops_edit_service_locations_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/service-locations/new.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-servicing-locations-new.vue" */
            )
          )
      },
      {
        path: 'service-locations/:serviceLocationId/edit',
        name: '__account_audits_shops_edit_service_locations_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/audits/shops/edit/service-locations/edit.vue' /* webpackChunkName: "pages-shared-account-audits-shops-edit-servicing-locations-edit.vue" */
            )
          )
      }
    ]
  }
])
