import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__xml_doc',
    path: '/xmldoc/',
    redirect: { name: '__specifications' }
  },
  {
    path: '/xmldoc/specifications',
    name: '__specifications',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/xml/specifications.vue' /* webpackChunkName: "pages-xml-specifications.vue" */
        )
      )
  },
  {
    name: '__xyma_parts_xml_docs',
    path: '/xmldoc/xyma-parts',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/xml/xyma-parts.vue' /* webpackChunkName: "pages-xml-xyma-parts.vue" */
        )
      )
  },
  {
    name: '__vehicles_xml_docs',
    path: '/xmldoc/vehicles',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/xml/vehicles.vue' /* webpackChunkName: "pages-xml-vehicles.vue" */
        )
      )
  },
  {
    name: '__validator_xml_docs',
    path: '/xmldoc/validator',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/xml/validator.vue' /* webpackChunkName: "pages-xml-validator.vue" */
        )
      )
  }
]
