











import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { InputSize } from '~/models/app/input'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    roundnessClass: {
      type: String,
      default: 'tw-rounded-full'
    },
    withAction: {
      type: Boolean,
      default: false
    },
    isPrepended: {
      type: Boolean,
      required: false,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    forId: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const {
      size,
      roundnessClass,
      withAction,
      isPrepended,
      hasError,
      success,
      warning
    } = toRefs(props)

    const sizeClasses = computed(() => {
      switch (size.value) {
        case 'lg': {
          return ['tw-text-lg', isPrepended.value ? 'tw-pl-4' : 'tw-pr-4']
        }
        case 'sm':
        case 'md':
        default: {
          return ['tw-text-base', isPrepended.value ? 'tw-pl-3' : 'tw-pr-3']
        }
      }
    })
    const colorClass = computed(() => {
      if (!isPrepended.value) {
        if (hasError.value) {
          return 'tw-text-red-400'
        } else if (success.value) {
          return 'tw-text-green-500'
        } else if (warning.value) {
          return 'tw-text-amber-500'
        }
        return 'tw-text-blue-500'
      }
      return 'tw-text-grey-600'
    })

    const cursorClass = computed(() => {
      return withAction.value ? 'tw-cursor-pointer' : 'tw-select-none'
    })
    const classes = computed(() => [
      ...sizeClasses.value,
      roundnessClass.value,
      cursorClass.value,
      colorClass.value
    ])

    return { classes }
  }
})
