import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const dealsSearchConfig: SearchConfig = {
  settings: {
    isDealsSearch: true,
    viewType: {
      availableViewTypes: [ViewType.GALLERY],
      defaultViewTypeMobile: ViewType.LIST,
      defaultViewTypeDesktop: ViewType.GALLERY
    },
    searchName: 'deals',
    showAdminMassActions: false,
    searchbar: {
      autocompleteEndpoint: '/api/deals/query/',
      usesNuxtLinks: false
    },
    galleryLgPerRow: 4,
    hideSubtitle: true,
    rowsExtraQueryParams: { 'from-deals': true },
    hideViewTypeDesktop: true,
    showDisableButtons: true,
    displayAds: true,
    isCompareButtonVisible: true,
    isMapSearchBtnVisible: true
  },
  rootParams: {
    sort: ['nd'],
    discount: ['2'],
    discountsonly: ['1'],
    category: [20001]
  }
}
