import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__feeds_latest',
    path: '/latest/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/feeds/latest.vue' /* webpackChunkName: "pages-shared-feeds-latest" */
        )
      )
  }
]
