import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__autotouch-modal-iframe',
    path: '/autotouch-modal-iframe/',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/iframes/autotouch-iframe-modal.vue' /* webpackChunkName: "pages-iframes-autotouch-iframe-modal.vue" */
        )
      )
  }
]
