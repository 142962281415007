import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { defaultQsStringifyOptions } from '~/constants/http'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import { getHashableFromConfig, preprocessParams } from '~/utils/http'
import { Context } from '@nuxt/types'
import qs from 'qs'
// @ts-ignore
import hash from 'object-hash'
import { cacheableUrls } from './misc'
import { USER_HEADER_COOKIE_NAME } from '~/constants/cookies'

export default function onRequest(
  config: AxiosRequestConfig,
  route: Context['route'],
  req: Context['req'],
  cache: CacheService,
  cookies: CookiesService
): AxiosRequestConfig {
  config.params = modifyParams({ params: config.params, route, req, cookies })
  config.headers = modifyHeaders({
    headers: config.headers,
    req,
    cookies,
    url: config.url
  })
  config.paramsSerializer = params =>
    qs.stringify(params, defaultQsStringifyOptions)

  if (cookies.get('ait')) {
    // audits iframe token
    let tokenToUse = cookies.get('ait')

    if (req?.headers['access-token']) {
      // if we have an access token in request headers then use that
      tokenToUse = req?.headers['access-token'] as string
    }
    config.headers.common['Access-Token'] = tokenToUse
  }

  if (process.client) {
    const cachedConfig = attemptToGetFromCache(config, cache)
    if (cachedConfig) {
      config = cachedConfig
    }
  }
  return config
}
function modifyParams({
  params,
  route,
  req,
  cookies
}: {
  params: AxiosRequestConfig['params']
  route: Context['route']
  req: Context['req']
  cookies: CookiesService
}): AxiosRequestConfig['params'] {
  // preprocess params, removes null values etc
  params = params ? preprocessParams(params) : {}
  // set the language param
  const langParam = route.query && route.query.lang
  if (langParam) {
    params.lang = langParam
  } else if (req) {
    const langCookie = cookies.get('lang')
    if (langCookie) {
      params.lang = langCookie
    }
    if (process.env.NODE_ENV === 'production') {
      delete params._test
    }
  }
  return params
}

function attemptToGetFromCache(
  config: AxiosRequestConfig,
  cache: CacheService
) {
  const urlIsCacheable = cacheableUrls.some(
    url => config.url && url.regex.test(config.url)
  )
  if (urlIsCacheable && cache) {
    const hashable = getHashableFromConfig(config)
    const key = hash(hashable)
    if (cache.has(key)) {
      const response: AxiosResponse = cache.get(key)
      // @ts-ignore
      config.adapter = () =>
        // Set the request adapter to send the cached response
        // and prevent the request from actually running
        // @ts-ignore
        Promise.resolve({
          data: response.data,
          status: response.status,
          statusText: response.statusText,
          header: response.headers,
          config: response,
          response
        })
      return config
    }
  }
}

function modifyHeaders({
  headers,
  req,
  cookies,
  url
}: {
  headers: AxiosRequestConfig['headers']
  req: Context['req']
  cookies: CookiesService
  url?: string
}) {
  const isExternalHost = url && !url.startsWith('/')
  if (isExternalHost) {
    return headers
  }

  let appHost

  if (req) {
    appHost = req.headers.host
  } else if (process.client && typeof window !== 'undefined') {
    appHost = window.location.hostname
  }
  headers.common['Request-Domain'] = appHost

  if (process.env.APP_NAME) {
    headers.common.site = process.env.APP_NAME
  }

  if (process.env.NODE_ENV === 'development') {
    const userHeaderCookie = cookies.get(USER_HEADER_COOKIE_NAME)
    if (userHeaderCookie) {
      headers.common.user = userHeaderCookie
    }
  }

  return headers
}
