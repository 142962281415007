import { RouteConfig } from 'vue-router'
import { getDealerSiteRouteName } from '~/utils/router'
import { interopDefault } from '~/router/utils'
import { classifiedSlugOrIdPattern } from '../../classifieds/view'

const Classified = () =>
  interopDefault(
    import(
      '~/pages/car/dealers/site/classified.vue' /* webpackChunkName: "pages-dealers-site-classified.vue" */
    )
  )

/**
 *
 * @param basePath
 */
export function getDealerSiteRoutes(basePath: string): RouteConfig[] {
  return [
    {
      name: getDealerSiteRouteName(),
      path: basePath,
      component: () =>
        interopDefault(
          import(
            '~/layouts/car/dealer/site/layout.vue' /* webpackChunkName: "layouts-dealer-site-layout.vue" */
          )
        ),
      meta: {
        isDealerSiteRoute: true
      },
      children: [
        {
          name: getDealerSiteRouteName('classified_with_short_url'),
          path: `${classifiedSlugOrIdPattern}/`,
          component: Classified,
          meta: {
            isDealerSiteRoute: true
          }
        },
        {
          name: getDealerSiteRouteName('classified'),
          path: `:searchPath/view/${classifiedSlugOrIdPattern}/`,
          component: Classified,
          meta: {
            isDealerSiteRoute: true
          }
        },
        {
          name: getDealerSiteRouteName('page'),
          path: ':pagePath([^\\/]*)/',
          component: () =>
            interopDefault(
              import(
                '~/pages/car/dealers/site/page-renderer.vue' /* webpackChunkName: "pages-dealers-site-page-renderer.vue" */
              )
            ),
          meta: {
            isDealerSiteRoute: true
          }
        }
      ]
    }
  ]
}
