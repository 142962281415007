import { BackgroundJob } from '~/models/background-jobs/types'

export const BACKGROUND_JOBS_NS = 'backgroundJobs'

export interface BackgroundJobsState {
  jobs: BackgroundJob[]
}
function getDefaultState(): BackgroundJobsState {
  return {
    jobs: []
  }
}

export default getDefaultState
