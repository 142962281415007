export const SET_RECOMMENDATIONS_DATA = 'SET_RECOMMENDATIONS_DATA'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_TRADABLE_CLASSIFIEDS = 'SET_TRADABLE_CLASSIFIEDS'
export const SET_ALLOWED_TRADES_MAX = 'SET_ALLOWED_TRADES_MAX'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_CLASSIFIED_DETAILS = 'SET_CLASSIFIED_DETAILS'
export const SET_MATCHES = 'SET_MATCHES'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_LIKED = 'SET_LIKED'
export const SET_LIKED_BY = 'SET_LIKED_BY'
export const UNMATCHED = 'UNMATCHED'
export const REMOVE_LIKED_BY = 'REMOVE_LIKED_BY'
export const ADD_LIKED = 'ADD_LIKED'
export const ADD_MATCH = 'ADD_MATCH'
export const SET_MATCH_SCREEN_VISIBILITY = 'SET_MATCH_SCREEN_VISIBILITY'
export const SET_MATCHED_CLASSIFIED = 'SET_MATCHED_CLASSIFIED'
export const REMOVE_ROW = 'REMOVE_ROW'
export const ADD_ROW = 'ADD_ROW'
export const SET_DETAILS_CLASSIFIED_ID = 'SET_DETAILS_CLASSIFIED_ID'
export const SET_NAVBAR_VISIBILITY = 'SET_NAVBAR_VISIBILITY'
export const RESET_RECOMMENDATIONS_DATA = 'RESET_RECOMMENDATIONS_DATA'
export const SET_MATCH_THREAD_READ = 'SET_MATCH_THREAD_READ'
export const SET_ACTIONS_VISIBILITY = 'SET_ACTIONS_VISIBILITY'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const ADD_TO_HISTORY = 'ADD_TO_HISTORY'
export const REMOVE_TRADABLE_CLASSIFIED = 'REMOVE_TRADABLE_CLASSIFIED'
