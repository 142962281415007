var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dragscroller",class:{ 'arrows-on-hover': _vm.arrowsOnHover, 'tw-inline-flex': _vm.inline }},[_c('div',{ref:"dragscrollCont",staticClass:"dragscroll-container",class:_vm.dragScrollContClasses},[_c('div',{ref:"dragscroll",staticClass:"dragscroll",class:[
        _vm.containerClass,
        {
          transit: _vm.transit,
          dragging: !_vm.transit,
          '!tw-flex-col': _vm.vertical
        }
      ],style:({
        width: _vm.dsWidthValue || undefined,
        height: _vm.dsHeightValue || undefined,
        minHeight: [_vm.imagesLoaded || !_vm.imagesInSlot ? undefined : '80px'],
        transform: ("translate3d(" + _vm.scrollX + "px, " + _vm.scrollY + "px, 0px)"),
        'tw-inline-flex': _vm.inline
      })},[_vm._t("default",null,{"imageLoad":_vm.imgLoad})],2)]),_vm._v(" "),(!_vm.hideArrows && !_vm.lessSlidesThanWidth && !_vm.vertical)?[(!_vm.caretArrows)?[_c('div',{staticClass:"dragarrow hidden-print left tw-select-none",class:[_vm.btnVariantToUse, { disabled: _vm.scrollX >= 0 }],on:{"click":_vm.moveLeftClicked}},[_c('fa',{attrs:{"icon":_vm.icons.chevronLeft}})],1),_vm._v(" "),_c('div',{staticClass:"dragarrow hidden-print right tw-select-none",class:[
          _vm.btnVariantToUse,
          {
            disabled: _vm.$refs.dragscrollCont
              ? _vm.scrollX <= -(_vm.dsWidth - _vm.$refs.dragscrollCont.offsetWidth)
              : false
          }
        ],on:{"click":_vm.moveRightClicked}},[_c('fa',{attrs:{"icon":_vm.icons.chevronRight}})],1)]:[_c('div',{staticClass:"dragarrow-caret hidden-print left tw-select-none",class:[_vm.btnVariantToUse, { disabled: _vm.scrollX >= 0 }],on:{"click":_vm.moveLeftClicked}},[_c('fa',{attrs:{"icon":_vm.icons.caretLeft}})],1),_vm._v(" "),_c('div',{staticClass:"dragarrow-caret hidden-print right tw-select-none",class:[
          _vm.btnVariantToUse,
          {
            disabled: _vm.$refs.dragscrollCont
              ? _vm.scrollX <= -(_vm.dsWidth - _vm.$refs.dragscrollCont.offsetWidth)
              : false
          }
        ],on:{"click":_vm.moveRightClicked}},[_c('fa',{attrs:{"icon":_vm.icons.caretRight}})],1)]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }