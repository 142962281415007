var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"tw-relative tw-w-full",class:[
    _vm.colsClass,
    _vm.lgClass,
    _vm.smClass,
    _vm.xlClass,
    _vm.mdClass,
    _vm.offsetClass,
    _vm.getSmOffsetClass,
    _vm.getMdOffsetClass,
    _vm.getLgOffsetClass,
    _vm.getXlOffsetClass
  ],on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }