import { interopDefault } from '~/router/utils'

export const adminShortsRoutes = [
  {
    name: '__admin_shorts',
    path: '/admin/shorts',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/shorts.vue' /* webpackChunkName: "pages-shared-admin-shorts" */
        )
      )
  }
]
