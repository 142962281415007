import { CIcon } from '~/icons/types'
export const ciCheckCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6Zm4.4 8-4.8 4.8c-.5.5-1.2.5-1.7 0l-2.4-2.4c-.5-.5-.5-1.2 0-1.7s1.2-.5 1.7 0l1.6 1.6 4-4c.5-.5 1.2-.5 1.7 0 .4.5.4 1.3-.1 1.7Z'
    }
  ],
  name: 'check-circle',
  type: 'solid'
}
