import { CIcon } from '~/icons/types'
export const ciAudit: CIcon = {
  width: 356.2,
  height: 512,
  paths: [
    {
      d:
        'M340.7 83.6h-51.6V66.9c0-8.6-6.9-15.5-15.5-15.5h-46.8v-2.2c0-26.9-21.9-48.7-48.7-48.7s-48.7 21.9-48.7 48.7v2.2H82.6c-8.6 0-15.5 6.9-15.5 15.5v16.7H15.5C6.9 83.6 0 90.5 0 99.1v397.4c0 8.6 6.9 15.5 15.5 15.5h325.2c8.6 0 15.5-6.9 15.5-15.5V99.1c0-8.6-6.9-15.5-15.5-15.5zm-82.6-1.2v33.4h-160V82.4h160zm-97.7-33.3c0-9.8 7.9-17.7 17.7-17.7 9.8 0 17.7 7.9 17.7 17.7v2.2h-35.4v-2.2zM325.2 481H31V114.6h36.1v16.7c0 8.6 6.9 15.5 15.5 15.5h191c8.6 0 15.5-6.9 15.5-15.5v-16.7h36.1V481z'
    },
    {
      d:
        'M79.7 263.6h44.7c11 0 19.9-8.9 19.9-19.9V199c0-11-8.9-19.9-19.9-19.9H79.7c-11 0-19.9 8.9-19.9 19.9v44.7c0 11 8.9 19.9 19.9 19.9zm11.1-53.4h22.4v22.4H90.8v-22.4z'
    },
    {
      d:
        'M124.4 368.3H79.7c-11 0-19.9 8.9-19.9 19.9v44.7c0 11 8.9 19.9 19.9 19.9h44.7c11 0 19.9-8.9 19.9-19.9v-44.7c0-11-9-19.9-19.9-19.9zm-11.2 53.5H90.8v-22.4h22.4v22.4z'
    },
    {
      d:
        'm124.1 282-33.7 35.5-10.1-11.2c-5.7-6.4-15.5-6.9-21.9-1.2-6.4 5.7-6.9 15.5-1.2 21.9l21.3 23.7c2.9 3.2 7 5.1 11.3 5.1h.2c4.3 0 8.3-1.7 11.2-4.8l45.3-47.7c5.9-6.2 5.6-16-.6-21.9-6.1-5.9-15.9-5.6-21.8.6z'
    },
    {
      d:
        'M193.1 210.2h66.4c8.6 0 15.5-6.9 15.5-15.5s-6.9-15.5-15.5-15.5h-66.4c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5z'
    },
    {
      d:
        'M281.8 227.7h-88.7c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5h88.7c8.6 0 15.5-6.9 15.5-15.5s-7-15.5-15.5-15.5z'
    },
    {
      d:
        'M193.1 307.2h25.3c8.6 0 15.5-6.9 15.5-15.5s-6.9-15.5-15.5-15.5h-25.3c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5z'
    },
    {
      d:
        'M193.1 355.7h80.5c8.6 0 15.5-6.9 15.5-15.5s-6.9-15.5-15.5-15.5h-80.5c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5z'
    },
    {
      d:
        'M281.8 373.2h-88.7c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5h88.7c8.6 0 15.5-6.9 15.5-15.5 0-8.5-7-15.5-15.5-15.5z'
    },
    {
      d:
        'M233.3 421.8h-40.2c-8.6 0-15.5 6.9-15.5 15.5s6.9 15.5 15.5 15.5h40.2c8.6 0 15.5-6.9 15.5-15.5s-6.9-15.5-15.5-15.5z'
    }
  ],
  name: 'audit',
  type: 'regular'
}
