import { inject, DependencyContainer } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { requestContainerToken } from '~/constants/dependency-injection/tokens'
import InternalRequestBuilder from '~/builders/http/InternalRequestBuilder'
import { Method } from 'axios'

@containerScoped()
export default class RequestBuilder {
  constructor(
    @inject(requestContainerToken) private requestContainer: DependencyContainer
  ) {}

  request<T>(method: Method, url: string): InternalRequestBuilder {
    return this.new<T>().request(method, url)
  }

  method<T>(method: Method): InternalRequestBuilder {
    return this.new<T>().method(method)
  }

  url<T>(url: string): InternalRequestBuilder {
    return this.new<T>().url(url)
  }

  /**
   * Generic is return type of `send` method.
   */
  new<T>(): InternalRequestBuilder<T> {
    return this.requestContainer.resolve<InternalRequestBuilder<T>>(
      InternalRequestBuilder
    )
  }
}
