import { Search } from '~/router/routes/car/classifieds/search/components'

export const myClassifiedRoutes = [
  {
    name: '__classifieds_search_my_classifieds',
    path: '/classifieds/my/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_my_classifieds_seo',
    path: '/classifieds/my/*.html',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
