import { MutationTree } from 'vuex'
import {
  ClassifiedsAnalyticsState,
  getDefaultFacetValues
} from '~/store/modules/shared/classifieds-analytics/state'
import {
  SET_FACET,
  RESET_FACETS,
  SET_DATE_RANGE_PRESETS,
  SET_MARKET_SECTIONS,
  SET_USER_SECTIONS,
  SET_SUB_FILTER,
  SET_MONTHS_AVAILABLE,
  SET_DATE_AVAILABLE_FROM,
  SET_USER_CATEGORIES_ROOT,
  RESET_DATE_FACETS,
  RESET_SUB_FILTERS,
  ADD_SECTION_FILTER,
  REMOVE_SECTION_FILTER,
  RESET_SECTION_FILTERS,
  SET_OUTLETS_DATA,
  SET_ERROR
} from '~/store/modules/shared/classifieds-analytics/mutation-types'
import { createMutationCreator } from '~/factories/store/mutation'
import { vue3Delete, vue3Set } from '~/utils/nuxt3-migration'

const { setter } = createMutationCreator<ClassifiedsAnalyticsState>()

export default {
  ...setter(SET_USER_SECTIONS, 'userSections'),
  ...setter(SET_MARKET_SECTIONS, 'marketSections'),
  ...setter(SET_DATE_RANGE_PRESETS, 'dateRangePresets'),
  ...setter(SET_MONTHS_AVAILABLE, 'monthsAvailable'),
  ...setter(SET_DATE_AVAILABLE_FROM, 'dateAvailableFrom'),
  ...setter(SET_USER_CATEGORIES_ROOT, 'userCategoriesRoot'),
  ...setter(SET_OUTLETS_DATA, 'outletsData'),
  ...setter(SET_ERROR, 'error'),
  [SET_FACET](state, { name, value }) {
    state.facets[name] = value
  },
  [RESET_FACETS](state) {
    state.facets = getDefaultFacetValues()
  },
  [RESET_SUB_FILTERS](state) {
    for (const key of Object.keys(state.subFilters)) {
      vue3Delete(state.subFilters, key)
    }
  },
  [RESET_DATE_FACETS](state) {
    state.facets.dateFrom = null
    state.facets.dateTo = null
  },
  [SET_SUB_FILTER](state, { name, value }) {
    if (value) {
      vue3Set(state.subFilters, name, value)
    } else {
      vue3Delete(state.subFilters, name)
    }
  },
  [ADD_SECTION_FILTER](
    state,
    { sectionType, filterName, filterValue, filterLabel }
  ) {
    const filter = state.sectionFilters.find(
      f => f.filterName === filterName && f.sectionType === sectionType
    )
    if (filter) {
      filter.filterValue = filterValue
      filter.filterLabel = filterLabel
    } else {
      state.sectionFilters.push({
        sectionType,
        filterName,
        filterValue,
        filterLabel
      })
    }
  },
  [REMOVE_SECTION_FILTER](state, { sectionType, filterName }) {
    state.sectionFilters = state.sectionFilters.filter(
      f => f.sectionType !== sectionType && f.filterName !== filterName
    )
  },
  [RESET_SECTION_FILTERS](state) {
    state.sectionFilters = []
  }
} as MutationTree<ClassifiedsAnalyticsState>
