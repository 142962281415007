export const DATE_FROM_PARAM_NAME = 'date-from'
export const DATE_TO_PARAM_NAME = 'date-to'
export const CATEGORY_PARAM_NAME = 'category'
export const QUERY_PARAM_NAME = 'query'
export const DATE_PRESET_PARAM_NAME = 'date-preset'

export const QUERY_PARAM_SHOP = 'sf-shop'

export const LAST_DAY_DATE_PRESET_NAME = 'lastDay'
export const LAST_WEEK_DATE_PRESET_NAME = 'lastWeek'
export const LAST_MONTH_DATE_PRESET_NAME = 'lastMonth'
export const LAST_TWO_MONTHS_DATE_PRESET_NAME = 'lastTwoMonths'
export const LAST_THREE_MONTHS_DATE_PRESET_NAME = 'lastThreeMonths'
export const LAST_SIX_MONTHS_DATE_PRESET_NAME = 'lastSixMonths'
export const LAST_YEAR_DATE_PRESET_NAME = 'lastYear'

export const SUBSCRIPTIONS_FEATURE_ENABLED = false

export const analyticsSubFilterProps = {
  name: {
    type: String,
    required: true
  },
  filters: {
    type: Object,
    required: false
  },
  values: {
    type: Array,
    required: false
  },
  urlArgName: {
    type: String,
    default: ''
  }
}
