import BasePageSnippet from './BasePageSnippet'
import ContactPageSnippet from './classes/ContactPageSnippet'
import CustomPageSnippet from './classes/CustomPageSnippet'
import SearchPageSnippet from './classes/SearchPageSnippet'
import { NonClassifiedPageSnippet } from './types'

export function pageSnippet(ps: NonClassifiedPageSnippet): BasePageSnippet {
  switch (ps.type) {
    case 'custom_page':
      return new CustomPageSnippet(ps)
    case 'search':
    case 'search_custom_page':
      return new SearchPageSnippet(ps)
    case 'contact':
      return new ContactPageSnippet(ps)
    default:
      return new CustomPageSnippet(ps)
  }
}
