import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import {
  Template,
  PageType,
  Outlet,
  StateOwner
} from '~/models/dealer/site/types'
import { StringMap } from '~/models/shared/types'

export interface DealerSiteState {
  title: string
  pageSnippets: StringMap<PageSnippet>
  customPageBodies: StringMap<string>
  indexPageId?: string
  indexPageType?: PageType
  navigatedToOtherPage: boolean
  loadingPageId?: string
  loadedPageId?: string
  template?: Template
  websiteId: string
  loading: boolean
  outlets: StringMap<Outlet>
  activeOutletId?: string
  siteLoaded: boolean
  owner?: StateOwner
}

export const DEALER_SITE_NS = 'dealers/site'

export default function getDefaultState(): DealerSiteState {
  return {
    customPageBodies: new Map(),
    indexPageId: '',
    indexPageType: undefined,
    navigatedToOtherPage: false,
    pageSnippets: new Map(),
    loadingPageId: undefined,
    loadedPageId: undefined,
    template: undefined,
    title: '',
    websiteId: '',
    loading: false,
    outlets: new Map(),
    activeOutletId: undefined,
    siteLoaded: false,
    owner: undefined
  }
}
