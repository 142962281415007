import { MutationTree } from 'vuex'
import { SET_ADMIN_EXTRA_DATA, SET_EDITING_USER_TYPE } from './mutation-types'
import { AdminUserState } from '~/store/modules/shared/admin/user/state'
import { AdminExtras, UserType } from '~/models/user/types'

export default {
  [SET_ADMIN_EXTRA_DATA](state, adminExtras: AdminExtras) {
    state.adminExtras = adminExtras
  },
  [SET_EDITING_USER_TYPE](state, userType: UserType) {
    state.editingUserType = userType
  }
} as MutationTree<AdminUserState>
