import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__classified_one_click_touch',
    path: '/classifieds/:classifiedId/one-click-touch/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/one-click-touch.vue' /* webpackChunkName: "pages-shared-classifieds-one-click-touch" */
        )
      )
  }
]
