import '~/polyfills/reflect-metadata' // Tsyringe requirement
import { container } from 'tsyringe'
import {
  createDepGetter,
  createArgDepGetter
} from '~/plugins/dependency-container/utils'
import {
  httpToken,
  redirectToken,
  requestContainerToken,
  requestToken,
  responseToken,
  sentryToken
} from '~/constants/dependency-injection/tokens'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import { Store } from 'vuex'

export default function(
  {
    app: { $sentry: sentry, i18n, router },
    store,
    $axios,
    req,
    res,
    redirect
  }: any,
  inject: any
) {
  const requestContainer = container.createChildContainer()

  requestContainer.register(requestToken, { useValue: req || false })
  requestContainer.register(responseToken, { useValue: res || false })
  requestContainer.register(redirectToken, { useValue: redirect })
  requestContainer.register(sentryToken, { useValue: sentry })
  requestContainer.register(requestContainerToken, {
    useValue: requestContainer
  })
  requestContainer.register(httpToken, { useValue: $axios })
  requestContainer.register(VueRouter, { useValue: router })
  requestContainer.register(VueI18n, { useValue: i18n })
  requestContainer.register(Store, { useValue: store })

  inject('requestContainer', requestContainer)
  inject('dep', createDepGetter(requestContainer))
  inject('deps', createArgDepGetter(requestContainer))
}
