import { interopDefault } from '~/router/utils'
export const adminShortUlrRoutes = [
  {
    name: '__admin_short_url',
    path: '/admin/short-url/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/short-url.vue' /* webpackChunkName: "pages-admin-short-url" */
        )
      ),
    meta: { noFooter: true }
  }
]
