import { DEALER_SITE_NS } from '../state'

export interface DealerSiteLayoutState {
  navOpen: boolean
}

// TODO: Move non-page state vars in this section of the dealer site store.

export const DEALER_SITE_LAYOUT_NS = `${DEALER_SITE_NS}/layout`

export default function getDefaultState(): DealerSiteLayoutState {
  return {
    navOpen: false
  }
}
