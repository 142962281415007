export const SET_SITE_CONTENT = 'SET_SITE_CONTENT'
export const SET_TEMPLATE_CONF = 'SET_TEMPLATE_CONF`'
export const SET_WEBSITE_META = 'SET_WEBSITE_META'
export const SET_LOADED_PAGE_ID = 'SET_LOADED_PAGE_ID'
export const SET_LOADING_PAGE_ID = 'SET_LOADING_PAGE_ID'
export const ADD_PAGE_SNIPPET = 'ADD_PAGE_SNIPPET'
export const ADD_CUSTOM_PAGE_BODY = 'ADD_CUSTOM_PAGE_BODY'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const SET_OUTLETS = 'SET_OUTLETS'
export const SET_ACTIVE_OUTLET_ID = 'SET_ACTIVE_OUTLET_ID'
export const SET_SITE_LOADED = 'SET_SITE_LOADED'
export const REGISTER_NAVIGATION_TO_OTHER_PAGE =
  'REGISTER_NAVIGATION_TO_OTHER_PAGE'
