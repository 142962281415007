import { inject } from 'tsyringe'
import { Route } from 'vue-router/types/router'
import { Store } from 'vuex'
import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { nonClassifiedDealerSiteRouteNames } from '~/constants/dealer-site-route-names'
import { THREE_SECONDS_MS, ONE_SECOND_MS } from '~/constants/duration'
import { pageViewRecordFunctionCacheKeys } from '~/constants/telemetry/page-view-recorder'
import { MeasurementId } from '~/models/analytics'
import { NamespacedStore } from '~/models/store/types'
import AnalyticsService from '~/services/AnalyticsService'
import LoggerService from '~/services/LoggerService'
import FunctionCache from '~/services/cache/FunctionCache'
import StatsService from '~/services/stats/StatsService'
import {
  ClassifiedViewState,
  CLASSIFIED_VIEW_NS
} from '~/store/modules/shared/classifieds/view/state'
import {
  DealerSiteAnalyticsState,
  DEALER_SITE_ANALYTICS_NS
} from '~/store/modules/shared/dealers/site/analytics/state'
import { StoreWithRootState } from '~/store/types'
import { UserState, USER_NS } from '~/store/modules/shared/user/state'
import { getRouteMetaItem } from '~/utils/router'
import { getNamespacedStore } from '~/utils/store'
import VueRouter from 'vue-router'

@containerScoped()
@ClientOnly
export default class PageViewRecorder {
  private userStore: NamespacedStore<UserState>
  private classifiedViewStore: NamespacedStore<ClassifiedViewState>
  private dealerSiteAnalyticsStore: NamespacedStore<DealerSiteAnalyticsState>

  constructor(
    @inject(AnalyticsService) private analytics: AnalyticsService,
    @inject(StatsService) private statsService: StatsService,
    @inject(Store) store: StoreWithRootState,
    @inject(LoggerService) private logger: LoggerService,
    @inject(VueRouter) private router: VueRouter,
    @inject(FunctionCache) private functionCache: FunctionCache
  ) {
    this.userStore = getNamespacedStore(store, USER_NS)
    this.classifiedViewStore = getNamespacedStore(store, CLASSIFIED_VIEW_NS)
    this.dealerSiteAnalyticsStore = getNamespacedStore(
      store,
      DEALER_SITE_ANALYTICS_NS
    )
  }

  recordPluginPageView() {
    const measurementIds: MeasurementId[] = []

    const ga4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ga4MeasurementId && measurementIds.push(ga4MeasurementId)

    const currentRoute = this.router.currentRoute

    if (
      currentRoute.name &&
      nonClassifiedDealerSiteRouteNames.includes(currentRoute.name)
    ) {
      const { code, codeV4 } = this.dealerSiteAnalyticsStore.state.google
      code && measurementIds.push(code)
      codeV4 && measurementIds.push(codeV4)
    }

    this.analytics.recordPageView(measurementIds)
  }

  recordMiddlewarePageView(route: Route) {
    const isIframeRoute = getRouteMetaItem(route, 'isIframe')
    if (isIframeRoute || route.params.noAnalytics) {
      return
    }

    const measurementIds: MeasurementId[] = []

    const ownGa4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ownGa4MeasurementId && measurementIds.push(ownGa4MeasurementId)

    if (route.name && nonClassifiedDealerSiteRouteNames.includes(route.name)) {
      const { code, codeV4 } = this.dealerSiteAnalyticsStore.state.google
      code && measurementIds.push(code)
      codeV4 && measurementIds.push(codeV4)
    }

    this.debouncedRecordMiddlewarePageView(measurementIds, {
      url: route.fullPath
    })
  }

  get debouncedRecordMiddlewarePageView() {
    const { middleware } = pageViewRecordFunctionCacheKeys
    return this.functionCache.cache(middleware, () =>
      this.analytics.createDebouncedPageViewRecordFunction(ONE_SECOND_MS)
    )
  }

  get debouncedRecordPageViewForFacetChange() {
    const { facetChange } = pageViewRecordFunctionCacheKeys
    return this.functionCache.cache(facetChange, () =>
      this.analytics.createDebouncedPageViewRecordFunction(THREE_SECONDS_MS)
    )
  }

  recordPageViewForFacetChange(url: string) {
    const measurementIds = []

    const ownGa4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ownGa4MeasurementId && measurementIds.push(ownGa4MeasurementId)

    const {
      code: dealerSiteCode,
      codeV4: dealerSiteCodeV4
    } = this.dealerSiteAnalyticsStore.state.google
    dealerSiteCode && measurementIds.push(dealerSiteCode)
    dealerSiteCodeV4 && measurementIds.push(dealerSiteCodeV4)

    this.debouncedRecordPageViewForFacetChange(measurementIds, {
      url
    })
  }

  handleSellerPageViewOfClassified(route: Route) {
    const url = route.fullPath
    const configAndRecordPageView = (code: MeasurementId) => {
      this.analytics.createGtagConfig(code)
      this.analytics.recordPageView(code, { url, includeFacebookPixel: false })
    }

    const sellerAnalytics = this.classifiedViewStore.getters(
      'getSellerAnalytics'
    )
    sellerAnalytics?.code && configAndRecordPageView(sellerAnalytics?.code)

    const sellerAnalyticsV4 = this.classifiedViewStore.getters(
      'sellerAnalyticsV4'
    )

    sellerAnalyticsV4?.code && configAndRecordPageView(sellerAnalyticsV4?.code)
  }

  recordStatClassifiedPageView() {
    const classifiedId = this.classifiedViewStore.state.classified?.id
    if (!classifiedId) {
      return
    }

    const classifiedIsOwn = this.classifiedViewStore.getters('isOwn')
    const userIdAdmin = this.userStore.getters('isAdmin')
    if (classifiedIsOwn || userIdAdmin) {
      return
    }

    this.statsService.record('events.clsfds.view', [classifiedId])
  }

  recordPerformanceConsentAcceptancePageView() {
    this.analytics.recordPageView()
  }
}
