import { containerScoped } from '~/decorators/dependency-container'
import {
  SET_LEASING_AVAILABLE_DURATIONS,
  SET_LEASING_DURATION,
  SET_LEASING_OPTION,
  SET_LEASING_WITH_VAT
} from '~/store/modules/shared/classifieds/view/mutation-types'
import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import {
  LEASING_DOWNPAYMENT_PARAM_NAME,
  LEASING_DURATION_PARAM_NAME,
  LEASING_LEASE_PARAM_NAME,
  LEASING_MILEAGE_PARAM_NAME,
  LEASING_WITH_VAT_PARAM_NAME
} from '~/constants/finance/leasing'
import { LeasingOption } from '~/models/classified/leasing/types'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import { ClassifiedViewLeasing } from '~/models/classified/view/leasing'

@containerScoped()
export default class LeasingService {
  constructor(@inject(Store) private store: Store<RootState>) {}

  public setClassifiedViewLeasingDefaults(
    leasing: ClassifiedViewLeasing,
    queryParams: Record<string, any>
  ) {
    let leasingOptions = leasing?.options

    // Sort options by duration
    if (leasing?.options) {
      leasingOptions = leasing.options.sort((a, b) =>
        a.duration.value.localeCompare(b.duration.value)
      )
    }

    if (!leasingOptions?.length) {
      return
    }
    const {
      [LEASING_WITH_VAT_PARAM_NAME]: leasingWithVat,
      [LEASING_DURATION_PARAM_NAME]: leasingDuration,
      [LEASING_MILEAGE_PARAM_NAME]: leasingMileage,
      [LEASING_DOWNPAYMENT_PARAM_NAME]: leasingDownpayment,
      [LEASING_LEASE_PARAM_NAME]: leasingLease
    } = queryParams

    // TODO: is this even needed?
    const getDefaultOption = () => {
      if (
        leasingDuration &&
        leasingMileage &&
        leasingDownpayment &&
        leasingLease
      ) {
        return (
          leasingOptions.find((option: LeasingOption) => {
            return (
              option.duration.value === leasingDuration &&
              option.mileage.value === leasingMileage &&
              this.store.getters[
                `${CLASSIFIED_VIEW_NS}/getLeasingOptionFieldVatValue`
              ](option.downpayment) === leasingDownpayment &&
              this.store.getters[
                `${CLASSIFIED_VIEW_NS}/getLeasingOptionFieldVatValue`
              ](option.lease) === leasingLease
            )
          }) || leasingOptions[0]
        )
      }
      return leasingOptions[0]
    }

    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_LEASING_WITH_VAT}`,
      leasingWithVat === 'true'
    )

    // TODO: this is a bit hacky, refactor later
    const selectedDuration = this.setDefaultLeasingDuration(
      leasingOptions,
      leasingDuration
    )

    const defaultOption =
      leasingOptions.find(o => Number(o.duration.value) === selectedDuration) ||
      getDefaultOption()

    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_LEASING_OPTION}`,
      defaultOption
    )
  }

  private setDefaultLeasingDuration(
    leasingOptions: LeasingOption[],
    leasingDurationParam: string
  ) {
    const numLeasingDurationParam = Number(leasingDurationParam)

    const availableDurations = Array.from(
      new Set(
        leasingOptions.map((option: LeasingOption) =>
          Number(option.duration.value)
        )
      )
    ).sort((a, b) => a - b)

    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_LEASING_AVAILABLE_DURATIONS}`,
      availableDurations
    )

    const getSelectedDuration = () => {
      if (
        leasingDurationParam &&
        availableDurations.includes(numLeasingDurationParam)
      ) {
        return numLeasingDurationParam
      } else if (!availableDurations.includes(48)) {
        return availableDurations[0]
      } else {
        return 48
      }
    }

    const selectedDuration = getSelectedDuration()

    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_LEASING_DURATION}`,
      selectedDuration
    )

    return selectedDuration
  }

  public formOptionIsEmpty(option: any) {
    return (
      option.downpayment === null &&
      option.duration === null &&
      option.lease === null &&
      option.mileage === null &&
      option.purchase_price === null
    )
  }
}
