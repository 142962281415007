import { isObject } from '~/utils/object'

export function camelToUnderscore(key: string) {
  const result = key.replace(/([A-Z])/g, ' $1')
  return result
    .split(' ')
    .join('_')
    .toLowerCase()
}

export function toSnakeCase(o: any): any {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach(k => {
      if (o[k] instanceof Date) {
        n[camelToUnderscore(k)] = o[k]
      } else {
        n[camelToUnderscore(k)] = toSnakeCase(o[k])
      }
    })

    return n
  } else if (Array.isArray(o)) {
    return o.map(i => {
      return toSnakeCase(i)
    })
  }

  return o
}

export function arrayOfStringToSnakeCase(array: Array<string>): any {
  return array.map(a => camelToUnderscore(a))
}
