






























































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'
import { useFormComponent } from '~/compositions/form-component'
import { InputSize } from '~/models/app/input'
import { vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String as PropType<HTMLInputElement['type']>,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    inGroup: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: false,
      default: 'any'
    },
    noArrows: {
      type: Boolean,
      default: false
    },
    beforeAllowEvents: {
      type: Boolean,
      default: false
    },
    afterAllowEvents: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    inputClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    roundnessClass: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['change', 'input'],
  setup(props, { emit, slots }) {
    const {
      trim,
      hasError,
      autofocus,
      id,
      roundnessClass,
      labelClass,
      size,
      beforeAllowEvents,
      afterAllowEvents,
      type,
      success,
      warning,
      inGroup
    } = toRefs(props)

    const { createRandomId } = useId()
    const {
      labelClasses,
      helperTextClasses,
      controlClasses,
      helperTextIcon
    } = useFormComponent(labelClass, hasError, success, warning, 'input')

    const internalValue = ref(props.modelValue)
    const internalId = id.value || createRandomId()
    const inputTemplateRef = ref<HTMLInputElement | null>()

    watch(
      () => props.modelValue,
      (newValue: string) => {
        internalValue.value = newValue
      }
    )

    function handleKeydown(event: KeyboardEvent) {
      const forbiddenCharacters = ['e', 'E', '+']
      const allowsNegative =
        inputTemplateRef.value?.min && Number(inputTemplateRef.value.min) < 0

      if (!allowsNegative) {
        forbiddenCharacters.push('-')
      }
      if (type.value === 'number' && forbiddenCharacters.includes(event.key)) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        emit('keydown', event)
      }
    }

    function handleInput(event: InputEvent) {
      const text = extractText(event)

      emit('update:modelValue', text)
      emit('input', text)
    }

    function handleChange(event: any) {
      emit('change', extractText(event))
    }

    function extractText(event: InputEvent) {
      const text = (event.target as HTMLInputElement).value
      return trim.value ? text.trim() : text
    }

    const inputSizeClasses = computed(() => {
      switch (size.value) {
        case 'sm': {
          return inGroup.value // compensate for group border
            ? ['tw-h-[30px]', 'tw-min-h-[30px]', 'tw-text-base']
            : ['tw-h-[32px]', 'tw-min-h-[32px]', 'tw-text-base']
        }
        case 'lg': {
          return inGroup.value
            ? ['tw-h-[46px]', 'tw-min-h-[46px]', 'tw-text-lg']
            : ['tw-h-[48px]', 'tw-min-h-[48px]', 'tw-text-lg']
        }
        case 'md':
        default: {
          return inGroup.value
            ? ['tw-h-[38px]', 'tw-min-h-[38px]', 'tw-text-base']
            : ['tw-h-[40px]', 'tw-min-h-[40px]', 'tw-text-base']
        }
      }
    })

    const roundnessToUse = computed(() => {
      if (roundnessClass.value) {
        return roundnessClass.value
      }

      switch (size.value) {
        case 'sm': {
          return 'tw-rounded-md'
        }
        case 'lg': {
          return 'tw-rounded-xl'
        }
        case 'md':
        default: {
          return 'tw-rounded-lg'
        }
      }
    })

    onMounted(() => {
      emit('mounted')
      handleAutofocus()
    })

    function handleAutofocus() {
      if (!autofocus.value) {
        return
      }

      focus()
    }

    function select() {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.select()
        })
      })
    }

    function focus(moveCursor = false) {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.focus()
          if (moveCursor && inputTemplateRef.value) {
            inputTemplateRef.value.setSelectionRange(-1, -1)
          }
        })
      })
    }

    function blur() {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.blur()
        })
      })
    }

    const inputBeforeAndAfterClasses = computed(() => {
      const c = []
      if (slots.before) {
        c.push(size.value === 'sm' ? '!tw-pl-9' : '!tw-pl-10')
      } else if (!inGroup.value) {
        c.push('!tw-pl-3')
      }
      if (slots.after) {
        c.push(size.value === 'sm' ? '!tw-pr-9' : '!tw-pr-10')
      } else if (!inGroup.value) {
        c.push('!tw-pr-3')
      }
      return c
    })

    const beforeAndAfterSizeClasses = computed(() => {
      switch (size.value) {
        case 'sm': {
          return ['tw-text-sm']
        }
        case 'lg': {
          return ['tw-text-lg']
        }
        case 'md':
        default: {
          return []
        }
      }
    })
    const beforeClasses = computed(() => {
      return generateSlotClasses('before')
    })

    const afterClasses = computed(() => {
      return generateSlotClasses('after')
    })

    const slotClasses = computed(() => {
      return [
        'tw-absolute',
        'tw-inset-y-0',
        'tw-flex',
        'tw-items-center',
        'tw-text-grey-500'
      ]
    })

    function generateSlotClasses(slotName: string) {
      let classes = []
      const event = slotName === 'before' ? beforeAllowEvents : afterAllowEvents
      if (!event.value) {
        classes.push('tw-pointer-events-none')
      }
      classes = classes.concat(slotClasses.value)
      return [...classes, ...beforeAndAfterSizeClasses.value]
    }

    function onWheel(event: InputEvent) {
      if (type.value === 'number' && document.activeElement === event.target) {
        event.preventDefault()
      }
    }

    return {
      internalValue,
      internalId,
      helperTextClasses,
      inputTemplateRef,
      labelClasses,
      handleInput,
      handleKeydown,
      handleChange,
      controlClasses,
      inputSizeClasses,
      inputBeforeAndAfterClasses,
      beforeClasses,
      afterClasses,
      focus,
      select,
      blur,
      onWheel,
      roundnessToUse,
      helperTextIcon
    }
  }
})
