import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__promote-modal-iframe',
    path: '/promote-modal-iframe',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/iframes/promote-iframe-modal.vue' /* webpackChunkName: "pages-iframes-promote-iframe-modal.vue" */
        )
      )
  }
]
