export const ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS'
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const ADD_SUBSCRIPTION_INFO_SET = 'ADD_SUBSCRIPTION_INFO_SET'
export const SET_SUBSCRIPTIONS_LOADED = 'SET_SUBSCRIPTIONS_LOADED'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const OPEN_SUBSCRIPTION = 'OPEN_SUBSCRIPTION'
export const CLOSE_SUBSCRIPTION = 'CLOSE_SUBSCRIPTION'
export const CLOSE_ALL_SUBSCRIPTIONS = 'CLOSE_ALL_SUBSCRIPTIONS'
export const SET_COUNTS = 'SET_COUNTS'
export const INCREMENT_TOTAL_COUNT = 'INCREMENT_TOTAL_COUNT'
export const DECREMENT_TOTAL_COUNT = 'DECREMENT_TOTAL_COUNT'
export const ADD_SUBSCRIBABLE_SEARCH_ID = 'ADD_SUBSCRIBABLE_SEARCH_ID'
export const ADD_SUBSCRIBABLE_SEARCH_IDS = 'ADD_SUBSCRIBABLE_SEARCH_IDS'
