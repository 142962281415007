import { injectable } from '~/decorators/dependency-container'
import { inject, DependencyContainer } from 'tsyringe'
import {
  httpToken,
  requestContainerToken
} from '~/constants/dependency-injection/tokens'
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method
} from 'axios'
import { AxiosProgressEvent, RequestBuilderQueryParams } from '~/models/http'
import { toCamelCase } from '~/utils/object'
import { invalidBodyError } from '~/services/errors'
import CancelableRequestMap from '~/services/http/CancelableRequestMap'
import {
  defaultActionResponseMapper,
  defaultActionResponseValidator
} from '~/constants/http'
import { noop } from '~/utils/function'

const defaultResponseBodyMapper = (body: any) => toCamelCase(body.data)
const defaultResponseBodyValidator = (body: any) => body && body.data
const defaultResponseBodyValidationError = invalidBodyError
const defaultOnUploadProgressHandler = noop

type ResponseBody = AxiosResponse['data']

@injectable()
export default class InternalRequestBuilder<T = any> {
  private requestUrl: string = ''
  private requestMethod: Method = 'get'
  private requestData: any = null
  private requestParams: any = null
  private responseBodyMapper: any = defaultResponseBodyMapper
  private responseBodyValidator: any = defaultResponseBodyValidator
  private responseBodyValidationError: any = defaultResponseBodyValidationError
  private previousRequestIsCancelable: boolean = false
  private onUploadProgressHandler: (
    progressEvent: AxiosProgressEvent
  ) => void = defaultOnUploadProgressHandler

  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(requestContainerToken)
    private requestContainer: DependencyContainer,
    @inject(CancelableRequestMap)
    private cancelableRequestMap: CancelableRequestMap
  ) {}

  url(url: string): InternalRequestBuilder {
    this.requestUrl = url
    return this
  }

  method(method: Method = 'get'): InternalRequestBuilder {
    this.requestMethod = method
    return this
  }

  request(method: Method, url: string) {
    return this.method(method).url(url)
  }

  params(
    paramsOrFn:
      | RequestBuilderQueryParams
      | (() => RequestBuilderQueryParams) = {}
  ): InternalRequestBuilder {
    this.requestParams =
      typeof paramsOrFn === 'function' ? paramsOrFn() : paramsOrFn
    return this
  }

  data(data: any = {}): InternalRequestBuilder {
    this.requestData = data
    return this
  }

  map(
    responseBodyMapper: (body: ResponseBody) => T = defaultResponseBodyMapper
  ): InternalRequestBuilder {
    this.responseBodyMapper = responseBodyMapper
    return this
  }

  validate(
    responseBodyValidator: (
      body: ResponseBody
    ) => {} = defaultResponseBodyValidator,
    responseBodyValidationError: (
      body: ResponseBody
    ) => Error = defaultResponseBodyValidationError
  ): InternalRequestBuilder {
    this.responseBodyValidator = responseBodyValidator
    this.responseBodyValidationError = responseBodyValidationError
    return this
  }

  previousRequestCancelable() {
    this.previousRequestIsCancelable = true
    return this
  }

  error(
    responseBodyValidationError: (
      body: ResponseBody
    ) => Error = defaultResponseBodyValidationError
  ) {
    this.responseBodyValidationError = responseBodyValidationError
    return this
  }

  action() {
    this.responseBodyValidator = defaultActionResponseValidator
    this.responseBodyMapper = defaultActionResponseMapper
    return this
  }

  async send(): Promise<T> {
    const {
      requestMethod: method,
      requestUrl: url,
      requestData: data,
      requestParams: params,
      previousRequestIsCancelable,
      cancelableRequestMap
    } = this

    if (!url) {
      throw new Error('No request url is set')
    }

    const requestConfig: AxiosRequestConfig = {
      method,
      url,
      data,
      params
    }

    if (process.client) {
      requestConfig.onUploadProgress = this.onUploadProgressHandler
    }

    if (
      previousRequestIsCancelable &&
      (process.client || process.env.NODE_ENV === 'test')
    ) {
      const prevToken = cancelableRequestMap.get(url)
      if (prevToken) {
        prevToken.cancel('Previous request canceled by Request Builder')
      }
      const source = axios.CancelToken.source()
      cancelableRequestMap.set(url, source)
      requestConfig.cancelToken = source.token
    }

    const response = await this.http.request(requestConfig)
    if (process.client) {
      cancelableRequestMap.delete(url)
    }
    const { data: body } = response
    if (!this.responseBodyValidator(body)) {
      throw this.responseBodyValidationError(body)
    }
    return this.responseBodyMapper(body)
  }

  onUploadProgress(
    onUploadProgressHandler: (progressEvent: AxiosProgressEvent) => void
  ) {
    this.onUploadProgressHandler = onUploadProgressHandler
    return this
  }

  clone<U = T>(): InternalRequestBuilder {
    return this.requestContainer
      .resolve<InternalRequestBuilder<U>>(InternalRequestBuilder)
      .url(this.requestUrl)
      .method(this.requestMethod)
      .params(this.requestParams)
      .data(this.requestData)
      .map(this.responseBodyMapper)
      .validate(this.responseBodyValidator)
      .error(this.responseBodyValidationError)
  }
}
