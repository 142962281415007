import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { QuickSearchResult } from '~/models/quick-search/types'

@containerScoped()
export default class QuickSearchService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  search(
    url: string,
    params: Record<string, any> = {}
  ): Promise<QuickSearchResult> {
    return this.requestBuilder
      .request('get', url)
      .params(params)
      .send()
  }
}
