import { CIcon } from '~/icons/types'
export const ciTimes2: CIcon = {
  width: 15,
  height: 15,
  paths: [
    {
      d:
        'M1.127 1.127a.893.893 0 0 1 1.263 0l5.322 5.322 5.323-5.322a.893.893 0 1 1 1.263 1.263L8.975 7.712l5.323 5.323a.893.893 0 1 1-1.263 1.263L7.712 8.975 2.39 14.298a.893.893 0 1 1-1.263-1.263l5.322-5.323L1.127 2.39a.893.893 0 0 1 0-1.263Z',
      fill: 'currentColor'
    }
  ],
  name: 'times-2',
  type: 'regular'
}
