import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__sale_requests_classified',
    path: '/sale-requests/classified/:id',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/sale-requests/classified.vue' /* webpackChunkName: "pages-sale-requests-classified.vue" */
        )
      )
  },
  {
    name: '__sale_requests_matched',
    path: '/sale-requests/matched/:id',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/sale-requests/matched.vue' /* webpackChunkName: "pages-sale-requests-matched.vue" */
        )
      )
  },
  {
    name: '__sale_requests_new',
    path: '/sale-requests/new',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/sale-requests/new.vue' /* webpackChunkName: "pages-sale-requests-new.vue" */
        )
      )
  },
  {
    name: '__sale_requests_edit',
    path: '/sale-requests/:id/edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/sale-requests/edit.vue' /* webpackChunkName: "pages-sale-requests-edit.vue" */
        )
      )
  },
  {
    name: '__sale_requests_report',
    path: '/sale-requests/:id/report',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/sale-requests/report.vue' /* webpackChunkName: "pages-sale-requests-report.vue" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__sale_requests_landing',
    path: '/landing/sale-requests',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/sale-requests.vue' /* webpackChunkName: "pages-shared-landing-sale-requests.vue" */
        )
      )
  }
]
