import { User } from '../user/types'
import { Category } from '~/models/search/types'

export interface QnaQuestion {
  id: string
  questionText: string
  questionTextJson: object
  questionTitle: string
  makemodelYear: number
  categoryId: number
  questionType: number
  makemodel?: {
    id: number
    name: string
  }
  questionMetadata?: []
  questionReplies: number
  lastEdit: string
  added?: Date
  questionViews: number
  user?: User
  deleted?: boolean
  isDeleted: boolean
  myReactions: Array<string>
  reactions: { [key: string]: any }
  questionFirstComment: QnaComment
  questionCategory: Category
  questionMake: { id: number; name: string }
  seoUrl: string
}

export interface QnaComment {
  comment: string
  isNonPublic: boolean
  myReactions: Array<string>
  reactions: { [key: string]: any }
  added: string
  user: User
  replies: Array<QnaComment>
  repliesCount: number
  id: number
  isDeleted: boolean
  parentId: number
  lastΕdit?: string
}

export enum QuestionType {
  MODEL_QUESTION = 1,
  XYMA_QUESTION = 2,
  PLOT_QUESTION = 3
}

export enum QuestionCategoryType {
  CARS = 'cars',
  BIKES = 'bikes',
  GENERAL = 'general'
}

export enum QnaTabs {
  LATEST = 'latest',
  MY = 'my',
  POPULAR = 'popular'
}

export interface QnaFormData {
  id?: number
  categoryId: number
  title: string
  text: string
  recaptchaToken: string
  year?: number
  makeId?: [number]
  modelId?: [number]
  tagId?: number
  fuelId?: number
  questionType?: QuestionType
}

export interface QnaQueryParams {
  category: number
  tag: number | Array<number>
  fuelType: number
  fromYear: number
  toYear: number
  q: string
  make: number
  model: number
  pg: number
  userId: number
  deleted: boolean
}

export enum QnaMetadataMapper {
  MAKEMODEL_YEAR = 'from_year',
  FUEL_TYPE = 'fuel_type',
  QUESTION_TAG = 'tag'
}

export interface SelectedParam {
  id: number
  label: string
  queryName: string
}

export interface Leaderboard {
  score: number
  user: User
}
