export function arraysEqual(arrayA: any[], arrayB: any[]) {
  try {
    return (
      arrayA.length === arrayB.length && arrayA.every(e => arrayB.includes(e))
    )
  } catch (e) {
    return false
  }
}

/**
 *
 * Creates array tuples from an object
 * e.g {like: 5, something: 4} -> [['like', 6], ['something', 4]]
 */
export function createArrayPairsFromObject(obj: Object): Array<Array<any>> {
  return Object.keys(obj).map(k => [k, (obj as any)[k]])
}

export function getRandomArrayItem(array: any[]) {
  return array[Math.floor(Math.random() * array.length)]
}

/**
 * Used only for string and numbers
 * @deprecated Try to merge the logic of this into arraysEqual
 */
export function isArraysEquals(
  arrayA: [number | string],
  arrayB: [number | string]
) {
  const a = arrayA.sort()
  const b = arrayB.sort()

  return a.toString() === b.toString()
}

/**
 * Creates an array range of numbers based on a min, max and step
 */
export function range(min: number, max: number, step = 1): number[] {
  if (max < min) {
    return []
  }
  return [...Array(Math.floor((max - min) / step) + 1)].map(
    (_, i) => min + i * step
  )
}

/*
 * Divide an array into many smaller arrays based on the size param
 * e.g. [1,2,3,4,5,6] size=2 -> [[1,2],[3,4],[5,6]]
 */
export function chunk(arr: any[], size: number): Array<any[]> {
  return arr.reduce(
    (rows: any, key, index: number) =>
      (index % size === 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows,
    []
  )
}

export function shuffleArray(array: any[]) {
  let currentIndex = array.length
  let randomIndex

  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}
