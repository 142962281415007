import { interopDefault } from '~/router/utils'
import { getParkingRoutes } from '~/router/routes/shared/account/account-parking'

export default [
  {
    path: '/parking',
    name: '__parking',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/parking/index.vue' /* webpackChunkName: "pages-shared-account-classifieds-index.vue" */
        )
      ),
    meta: {
      ignoreScrollBehavior: true,
      showOneTap: true
    },
    redirect: { name: '__parking_classifieds_favorite' },
    children: getParkingRoutes()
  },
  {
    name: '__parking_modal',
    path: '/parking-modal',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/parking/modal-list.vue' /* webpackChunkName: "pages-shared-account-parking-modal-list.vue" */
        )
      )
  }
]
