import { toCamelCase } from '~/utils/object'
import { AdForm } from '~/models/advertising/types'
import { format } from '~/utils/date'

export function formatAdvertiseResponse(r: any) {
  return toCamelCase(r, [
    'banner_300_250_url',
    'banner_970_250_url',
    'banner_320_100_url',
    'banner_728_90_url',
    'square_image_url'
  ])
}

export function formatAdFormTimes(form: AdForm) {
  const transformed = { ...form }
  if (transformed.startDate) {
    transformed.startDate = format(transformed.startDate, 'dd-MM-yyyy HH:mm:ss')
  }
  if (transformed.finishDate) {
    transformed.finishDate = format(
      transformed.finishDate,
      'dd-MM-yyyy HH:mm:ss'
    )
  }
  return transformed
}
