export const fetchRecommendations = 'fetchRecommendations'
export const fetchCategories = 'fetchCategories'
export const fetchTradableClassifieds = 'fetchTradableClassifieds'
export const seveSettings = 'seveSettings'
export const fetchMatches = 'fetchMatches'
export const fetchLiked = 'fetchLiked'
export const fetchSettings = 'fetchSettings'
export const likeClassified = 'likeClassified'
export const passClassified = 'passClassified'
export const fetchClassifiedDetails = 'fetchClassifiedDetails'
export const fetchNotifications = 'fetchNotifications'
export const fetchLikedBy = 'fetchLikedBy'
export const removeLikedBy = 'removeLikedBy'
export const unmatched = 'unmatched'
export const matchScreenVisibilityChange = 'matchScreenVisibilityChange'
export const matchedClassified = 'matchedClassified'
export const rewind = 'rewind'
export const readNotifications = 'readNotifications'
export const setDetailsClassifiedId = 'setDetailsClassifiedId'
export const changeNavbarVisibility = 'changeNavbarVisibility'
export const changeActionsVisibility = 'changeActionsVisibility'
export const updateSeetingsCategories = 'updateSeetingsCategories'
export const closeDetails = 'closeDetails'
export const setPage = 'setPage'
export const addToHistory = 'addToHistory'
export const checkForRefetch = 'checkForRefetch'
export const removeTradableClassified = 'removeTradableClassified'
