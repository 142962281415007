import { ActionTreeWithRootState } from '~/store/types'
import { BackgroundJobsState } from '~/store/modules/shared/backgroundJobs/state'
import {
  ADD_BACKGROUND_JOB,
  SET_BACKGROUND_JOBS,
  TERMINATE_JOB,
  UPDATE_JOB
} from '~/store/modules/shared/backgroundJobs/mutation-types'
import { HttpStatus } from '~/constants/http'
import { USER_NS } from '~/store/modules/shared/user/state'
import { SET_CREDITS } from '~/store/modules/shared/user/mutation-types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import BackgroundJobsService from '~/services/background-jobs/BackgroundJobsService'

export default {
  async fetchAllBackgroundJobs({ commit }) {
    const backgroundJobsService = this.$dep(BackgroundJobsService)
    const jobs = await backgroundJobsService.getAllBackgroundJobs()
    await commit(SET_BACKGROUND_JOBS, jobs)
  },
  async fetchBackgroundJobsWithStatus({ commit }, status: string) {
    const backgroundJobsService = this.$dep(BackgroundJobsService)
    const jobs = await backgroundJobsService.getBackgroundJobsByStatus(status)
    commit(SET_BACKGROUND_JOBS, jobs)
  },
  async fetchBackgroundJobsWithName({ commit }, name: string | string[]) {
    const backgroundJobsService = this.$dep(BackgroundJobsService)
    const jobs = await backgroundJobsService.getBackgroundJobsByName(name)
    jobs.forEach(j => commit(ADD_BACKGROUND_JOB, j)) // this will add it only if it doesn't exist
  },
  async updateJobs({ commit, getters }) {
    const backgroundJobsService = this.$dep(BackgroundJobsService)
    const ids = getters.getPendingAndRunningIDs
    if (ids.length) {
      const {
        jobs,
        credits
      } = await backgroundJobsService.getBackgroundJobsWithIDs(ids)
      if (credits) {
        commit(`${USER_NS}/${SET_CREDITS}`, credits, { root: true })
      }

      jobs.map(job => {
        commit(UPDATE_JOB, { job })
      })
    }
  },
  async refreshResults({ dispatch }) {
    this.$cache.reset()
    await dispatch(
      `${CLASSIFIED_SEARCH_NS}/searchForResults`,
      {},
      { root: true }
    )
  },
  async terminateJob({ commit, dispatch }, jobId: number) {
    const backgroundJobsService = this.$dep(BackgroundJobsService)
    const response = await backgroundJobsService.terminateBackgroundJob(jobId)
    if (response.data.status === HttpStatus.OK) {
      if (response.data.data) {
        const { job, credits } = response.data.data
        if (credits) {
          commit(`${USER_NS}/${SET_CREDITS}`, credits, { root: true })
        }

        if (job) {
          // this update the job without changing its status to terminated
          commit(UPDATE_JOB, { job, excludedKeys: ['status'] })

          if (process.client) {
            await new Promise(resolve => setTimeout(resolve, 2000))
          }
          // update jobs one last time so we can get real results
          await dispatch('updateJobs')
        }
      } else {
        await dispatch('updateJobs')
      }

      commit(TERMINATE_JOB, jobId)
    } else if (response.data.error) {
      this.$logger.captureError(new Error(response.data.error))
      this.$snackbar.error(response.data.error)
    } else {
      this.$logger.captureError(
        new Error('error occurred while terminating background job')
      )
    }
  }
} as ActionTreeWithRootState<BackgroundJobsState>
