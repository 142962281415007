import { CIcon } from '~/icons/types'
export const ciInfoCircle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm0 5c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1Zm1 10h-1c-.6 0-1-.4-1-1v-4c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1v4c.6 0 1 .4 1 1s-.4 1-1 1Z'
    }
  ],
  name: 'info-circle',
  type: 'solid'
}
