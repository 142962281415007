import { tree } from '~/models/shared/tree'
import { GetterTreeWithRootState } from '~/store/types'
import {
  Category,
  CategoryId,
  HierarchicalCategory
} from '~/models/category/types'
import { Popularity } from '~/models/popularity/types'
import Tree from '~/models/shared/tree/Tree'
import { AdminCategoriesState } from './state'

export default {
  tree: (state): Tree<HierarchicalCategory> => tree(state.categories),
  categoryInEdit: (state): Category | undefined =>
    (state.idInEdit && state.categories.get(state.idInEdit)) || undefined,
  categoryInEditHasSearchSettings(_, getters): boolean {
    const { XYMA, PARTS } = CategoryId
    const { categoryInEdit } = getters
    const rootIdsWithSearchSettings = new Set<string>([
      XYMA.toString(),
      PARTS.toString()
    ])
    return (
      categoryInEdit &&
      [...categoryInEdit.ancestorIds, categoryInEdit.id].some((id: string) =>
        rootIdsWithSearchSettings.has(id)
      )
    )
  },
  pathOfCategoryInEdit(_, getters): HierarchicalCategory[] {
    const { categoryInEdit, tree } = getters
    if (!categoryInEdit) {
      return []
    }
    return [...tree.getAncestors(categoryInEdit.id), categoryInEdit]
  },
  getCategory: state => (id: string): HierarchicalCategory | undefined => {
    return state.categories.get(id)
  },
  getCategoryPopularity: state => (
    categoryId: string
  ): Popularity | undefined =>
    state.popularityMap && state.popularityMap.map.get(categoryId)
} as GetterTreeWithRootState<AdminCategoriesState>
