import { CategoryId } from '~/models/category/types'
import { PropOptions } from '@nuxtjs/composition-api'

export const formFieldProps: Record<any, PropOptions> = Object.freeze({
  disabled: {
    type: Boolean,
    required: true
  },
  specs: {
    type: Object,
    default: null
  },
  humanName: {
    type: String,
    required: true
  },
  required: {
    type: Boolean,
    default() {
      return false
    }
  },
  type: {
    type: String,
    default: null
  },
  name: {
    type: String,
    required: true
  },
  fieldValue: {
    type: [Object, Array, String, Number, Boolean],
    default: null
  },
  fieldError: {
    type: [String, Object],
    required: false,
    default: null
  },
  fieldSuggestions: {
    type: Array,
    default() {
      return []
    }
  },
  fieldPlaceholder: {
    type: String,
    required: false,
    default: ''
  }
})

export const releasedFormCategoriesIds = Object.freeze([
  CategoryId.JOBS,
  CategoryId.RENTALS,
  CategoryId.WANTED_PARTS
])
