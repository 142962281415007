export const CLASSIFIED_ACTIONS_NS = 'classifieds/actions'

export interface ClassifiedActionsState {
  pending: boolean
}
function getDefaultState(): ClassifiedActionsState {
  return {
    pending: false
  }
}

export default getDefaultState
