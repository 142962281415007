export enum GoogleMapControlPosition {
  BOTTOM_CENTER = 11,
  BOTTOM_LEFT = 10,
  BOTTOM_RIGHT = 12,
  LEFT_BOTTOM = 6,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  RIGHT_BOTTOM = 9,
  RIGHT_CENTER = 8,
  RIGHT_TOP = 7,
  TOP_CENTER = 2,
  TOP_LEFT = 1,
  TOP_RIGHT = 3
}

export const defaultGoogleMapCircleOptions: google.maps.CircleOptions = {
  editable: false,
  strokeColor: '#1976d2',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#1976d2',
  fillOpacity: 0.35
}
