export const SET_FACET = 'SET_FACET'
export const RESET_FACETS = 'RESET_FACETS'
export const SET_DATE_RANGE_PRESETS = 'SET_DATE_RANGE_PRESETS'
export const SET_MARKET_SECTIONS = 'SET_MARKET_SECTIONS'
export const SET_USER_SECTIONS = 'SET_USER_SECTIONS'
export const SET_SUB_FILTER = 'SET_SUB_FILTER'
export const SET_MONTHS_AVAILABLE = 'SET_MONTHS_AVAILABLE'
export const SET_DATE_AVAILABLE_FROM = 'SET_DATE_AVAILABLE_FROM'
export const SET_USER_CATEGORIES_ROOT = 'SET_USER_CATEGORIES_ROOT'
export const RESET_DATE_FACETS = 'RESET_DATE_FACETS'
export const RESET_SUB_FILTERS = 'RESET_SUB_FILTERS'
export const ADD_SECTION_FILTER = 'ADD_SECTION_FILTER'
export const REMOVE_SECTION_FILTER = 'REMOVE_SECTION_FILTER'
export const RESET_SECTION_FILTERS = 'RESET_SECTION_FILTERS'
export const SET_OUTLETS_DATA = 'SET_OUTLETS_DATA'
export const SET_ERROR = 'SET_ERROR'
