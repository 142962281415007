export const qnaSearch = 'qnaSearch'
export const createQuestion = 'createQuestion'
export const updateQuestion = 'updateQuestion'
export const deleteQuestion = 'deleteQuestion'
export const createComment = 'createComment'
export const fetchAllCategories = 'fetchAllCategories'
export const fetchModelsByMake = 'fetchModelsByMake'
export const fetchMakesByCategoryId = 'fetchMakesByCategoryId'
export const createQuestionReaction = 'createQuestionReaction'
export const addCommentReply = 'addCommentReply'
export const addCommentReaction = 'addCommentReaction'
export const updateComment = 'updateComment'
export const deleteComment = 'deleteComment'
export const changeCommentVisibility = 'changeCommentVisibility'
export const fetchMoreComments = 'fetchMoreComments'
export const fetchQuestionDataById = 'fetchQuestionDataById'
