










import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    noGap: { type: Boolean, default: false },
    tag: { type: String, default: 'div' },
    cols: { type: [String, Number], default: null },
    gap: { type: String, default: 'tw-gap-2.5' }
  },
  setup(props) {
    const { cols, noGap, gap } = toRefs(props)

    const colsClass = computed(() => {
      switch (cols.value?.toString()) {
        case '1':
          return 'tw-grid-cols-1'
        case '2':
          return 'tw-grid-cols-2'
        case '3':
          return 'tw-grid-cols-3'
        case '4':
          return 'tw-grid-cols-4'
        case '5':
          return 'tw-grid-cols-5'
        case '6':
          return 'tw-grid-cols-6'
        case '7':
          return 'tw-grid-cols-7'
        case '8':
          return 'tw-grid-cols-8'
        case '9':
          return 'tw-grid-cols-9'
        case '10':
          return 'tw-grid-cols-10'
        case '11':
          return 'tw-grid-cols-11'
        case '12':
          return 'tw-grid-cols-12'
        default:
          return 'tw-grid-cols-12'
      }
    })

    const gapClass = computed(() => {
      if (noGap.value) {
        return []
      }
      return gap.value
    })

    return {
      colsClass,
      gapClass
    }
  }
})
