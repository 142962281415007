import { ONE_MINUTE_MS } from '~/constants/duration'
import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'

// @ts-ignore
const LRUCache = require('lru-cache')
@ClientOnly
@containerScoped()
export default class CacheService {
  private cache: typeof LRUCache
  constructor() {
    if (process.server) {
      return
    }

    this.cache = new LRUCache({
      maxAge: ONE_MINUTE_MS,
      max: 5 // number of items to allow in the cache
    })
  }

  public set(key: string, data: any, maxAge = ONE_MINUTE_MS): void {
    this.cache.set(key, data, maxAge)
  }

  public get(key: string) {
    return this.cache.get(key)
  }

  public has(key: string): boolean {
    return this.cache.has(key)
  }

  public reset(): void {
    this.cache.reset()
  }

  public keys(): void {
    return this.cache.keys()
  }
}
