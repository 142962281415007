import { MutationTree } from 'vuex'
import { DealerSiteErrorType } from '~/models/dealer/site/error/types'
import { SET_ERROR, CLEAR_ERROR } from './mutation-types'
import { DealerSiteErrorState } from './state'

export default {
  [SET_ERROR](state, { type }: { type: DealerSiteErrorType }) {
    state.type = type
  },
  [CLEAR_ERROR](state) {
    state.type = undefined
  }
} as MutationTree<DealerSiteErrorState>
