export const SET_FACETS = 'SET_FACETS'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_TARGETS = 'SET_TARGETS'
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'
export const SET_CATEGORY_MAP = 'SET_CATEGORY_MAP'
export const SET_STATS = 'SET_STATS'
export const SET_PARAM = 'SET_PARAM'
export const SET_QUICK_SEARCHES = 'SET_QUICK_SEARCHES'
export const RESET_PARAMS = 'RESET_PARAMS'
export const SET_LOADING = 'SET_LOADING'
