









import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { DynamicIconSize, Icon } from '~/models/icons/types'

export default defineComponent({
  props: {
    icon: {
      type: Object as PropType<Icon>,
      required: true
    },
    noColors: {
      type: Boolean,
      default: false
    },
    size: {
      type: Object as PropType<DynamicIconSize>,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const { icon, noColors, size } = toRefs(props)
    const isFaIcon = computed(
      () => 'prefix' in icon.value && icon.value.prefix.startsWith('fa')
    )

    const tag = computed(() => {
      if (isFaIcon.value) {
        return 'fa'
      }
      return 'CIcon'
    })

    const sizeToUse = computed(() => {
      if (size.value && !isFaIcon.value) {
        return size.value.number
      }
      return undefined
    })

    const sizeClass = computed(() => {
      if (size.value && isFaIcon.value) {
        return size.value.class
      }
      return undefined
    })

    const attrs = computed(() => {
      if (isFaIcon) {
        return {}
      }
      return {
        noColors: noColors.value
      }
    })

    return { isFaIcon, tag, attrs, sizeToUse, sizeClass }
  }
})
