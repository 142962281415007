import {
  CategoryMappingId,
  InternalCategoryIdentifier,
  UnmappedCategoryScanError
} from '~/models/dealer/integration/category/mapping'
import { partitionedMapInitialState } from '~/store/modules/shared/dealers/integration/categories/factories'

export interface DealerIntegrationCategoriesState {
  transitInternalCategories: Map<
    CategoryMappingId,
    Map<number, InternalCategoryIdentifier>
  >
  pageNumber: number
  mappingFilterText: string
  lastPickerSelectedCategory: [
    CategoryMappingId | null,
    InternalCategoryIdentifier | null
  ]
  unmappedCategoryScanErrors: UnmappedCategoryScanError[]
  showErrorAlert: boolean
  markedCategoryMappingIds: Set<CategoryMappingId>
  allCategoriesMapped: boolean
}

export default function getDefaultState(): DealerIntegrationCategoriesState {
  return {
    ...partitionedMapInitialState('mappings'),
    ...partitionedMapInitialState('filteredMappings'),
    transitInternalCategories: new Map<
      CategoryMappingId,
      Map<number, InternalCategoryIdentifier>
    >(),
    pageNumber: 1,
    mappingFilterText: '',
    lastPickerSelectedCategory: [null, null],
    unmappedCategoryScanErrors: [],
    showErrorAlert: false,
    markedCategoryMappingIds: new Set<CategoryMappingId>(),
    allCategoriesMapped: false
  }
}
