import Tree from '~/models/shared/tree/Tree'
import { TreeNode, Key, TupleSourceNode } from '~/models/shared/tree/types'

export function tree<T extends TreeNode = TreeNode>(
  map: Map<string, T>
): Tree<T> {
  return new Tree<T>(map)
}

export function treeFromJsObject<SN, N extends TreeNode = TreeNode>(
  objectOrArray: SN[] | SN,
  keyGetter: (node: SN) => Key,
  childrenGetter: (node: SN) => SN[]
): Tree<N> {
  const buildTree = (obj: SN, parent: SN | undefined, map: Map<Key, N>) => {
    // @ts-ignore TODO: Fix ts error
    const node: N = {
      id: keyGetter(obj),
      parentId: parent && keyGetter(parent)
    }
    map.set(node.id, node)

    const sourceChildren = childrenGetter(obj) || []
    const childrenIds = []
    for (const child of sourceChildren) {
      childrenIds.push(keyGetter(child))
      buildTree(child, obj, map)
    }
    node.childrenIds = childrenIds
  }

  const map = new Map<Key, N>()
  if (Array.isArray(objectOrArray)) {
    for (const object of objectOrArray) {
      buildTree(object, undefined, map)
    }
  } else {
    buildTree(objectOrArray, undefined, map)
  }
  return new Tree<N>(map)
}

export function treeFromTuples<
  SN extends TupleSourceNode = TupleSourceNode,
  N extends TreeNode = TreeNode
>(tuples: SN[]): Tree<N> {
  return treeFromJsObject<SN, N>(
    tuples,
    o => {
      if (typeof o === 'string') {
        return o
      }
      return o[0]!
    },
    // @ts-ignore
    o => {
      if (typeof o === 'string') {
        return []
      } else if (typeof o[1] === 'string') {
        return [o[1]]
      }

      return o.length > 1 ? o[1]! : []
    }
  )
}
