import { createModule } from '~/factories/store'
import mutations from '~/store/modules/shared/account/mutations'
import getters from '~/store/modules/shared/account/getters'
import state from '~/store/modules/shared/account/state'
import actions from '~/store/modules/shared/account/actions'

export default createModule({
  actions,
  mutations,
  getters,
  state
})
