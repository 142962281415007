import {
  Subscription,
  SubscriptionInfo
} from '~/models/search/subscriptions/types'
import { StringMap } from '~/models/shared/types'

export interface SearchSubscriptionsState {
  subscriptions: StringMap<Subscription>
  openSubscriptions: Set<string>
  subscriptionsBySearch: StringMap<string>
  subscriptionsLoaded: boolean
  subscribableSearchIds: Set<string>
  /**
   * Key is search id
   */
  subscriptionInfoSets: StringMap<SubscriptionInfo>
  loading: boolean
  countLoaded: boolean
  totalCount?: number
  maxCount?: number
}

export const SEARCH_SUBSCRIPTION_NS = 'parking/searchSubscriptions'

export default function getDefaultState(): SearchSubscriptionsState {
  return {
    subscriptions: new Map(),
    openSubscriptions: new Set(),
    subscriptionsBySearch: new Map(),
    subscriptionInfoSets: new Map(),
    subscriptionsLoaded: false,
    subscribableSearchIds: new Set(),
    loading: false,
    countLoaded: false,
    totalCount: undefined,
    maxCount: undefined
  }
}
