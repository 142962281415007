import { CIcon } from '~/icons/types'
export const ciExclamationTriangle: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 13.8c.6 0 1-.4 1-1V9c0-.6-.4-1-1-1s-1 .4-1 1v3.8c0 .5.4 1 1 1ZM12 14.8c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Z'
    },
    {
      d:
        'M22.2 15.6 14.8 2.9C13.5.7 10.4.7 9.2 2.9L1.8 15.6c-1.2 2.2.3 4.9 2.8 4.9h14.7c2.6 0 4.1-2.7 2.9-4.9Zm-2.8 2.9H4.6c-1 0-1.6-1-1.1-1.9l7.4-12.7c.5-.8 1.7-.8 2.2 0l7.4 12.7c.4.9-.2 1.9-1.1 1.9Z'
    }
  ],
  name: 'exclamation-triangle',
  type: 'regular'
}
