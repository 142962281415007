import { Merge } from 'type-fest'
import { GetterTree, ActionTree, Store } from 'vuex'
import { AdminCategoriesState } from '~/store/modules/shared/admin/categories/state'
import { UserSearchState } from '~/store/modules/shared/admin/users/search/state'
import { SearchActionsState } from '~/store/modules/shared/classifieds/search/actions/state'
import { SearchState } from '~/store/modules/shared/classifieds/search/state'
import { ClassifiedViewState } from '~/store/modules/shared/classifieds/view/state'
import { DealerClassifiedFieldState } from '~/store/modules/shared/dealers/integration/classifieds/field/state'
import { DealersMapState } from '~/store/modules/shared/dealers/map/state'
import { DealerSiteAnalyticsState } from '~/store/modules/shared/dealers/site/analytics/state'
import { DealerSiteLayoutState } from '~/store/modules/shared/dealers/site/layout/state'
import { DealerSiteState } from '~/store/modules/shared/dealers/site/state'
import { I18nState } from '~/store/modules/shared/i18n/state'
import { IndexState } from '~/store/modules/shared/index/state'
import { NotificationTopicSubscriptionState } from '~/store/modules/shared/notification/topic/subscription/state'
import { PageState } from '~/store/modules/shared/page/state'
import { SearchSubscriptionsState } from '~/store/modules/shared/parking/searchSubscriptions/state'
import { ParkingState } from '~/store/modules/shared/parking/state'
import { QuickSearchState } from '~/store/modules/shared/quickSearch/state'
import { StorageState } from '~/store/modules/shared/storage/state'
import { UserState } from '~/store/modules/shared/user/state'
import { UserAgentState } from '~/store/modules/shared/userAgent/state'
import { ImagePreloadState } from '~/store/modules/shared/preload/image/state'
import { ClassifiedsAnalyticsState } from '~/store/modules/shared/classifieds-analytics/state'
import { EditState } from './modules/shared/classifieds/edit/state'
import { AccountState } from '~/store/modules/shared/account/state'
import { AdminUserState } from '~/store/modules/shared/admin/user/state'

export interface RootState {
  account: AccountState
  admin: {
    categories: AdminCategoriesState
    users: {
      search: UserSearchState
    }
    user: AdminUserState
  }
  classifieds: {
    search: Merge<
      SearchState,
      {
        actions: SearchActionsState
      }
    >
    view: ClassifiedViewState
    form: any // TODO: Replace with ClassifiedFormState once implemented
    edit: EditState
  }
  dealers: {
    integration: {
      classifieds: {
        field: DealerClassifiedFieldState
      }
    }
    map: DealersMapState
    site: Merge<
      DealerSiteState,
      {
        layout: DealerSiteLayoutState
        analytics: DealerSiteAnalyticsState
      }
    >
  }
  i18n: I18nState
  index: IndexState
  userAgent: UserAgentState
  page: PageState
  parking: Merge<
    ParkingState,
    { searchSubscriptions: SearchSubscriptionsState }
  >
  notification: {
    topic: {
      subscription: NotificationTopicSubscriptionState
    }
  }
  preloadedImage: ImagePreloadState
  quickSearch: QuickSearchState
  storage: StorageState
  user: UserState
  classifiedsAnalytics: ClassifiedsAnalyticsState
}

export type ActionTreeWithRootState<S> = ActionTree<S, RootState>

export type GetterTreeWithRootState<S> = GetterTree<S, RootState>

export type StoreWithRootState = Store<RootState>

export enum LoadStatus {
  UNLOADED = 'unloaded',
  LOADING = 'loading',
  LOADED = 'loaded'
}
