const nonPermittedFormatError = () =>
  new Error('Only non-alpha hexadecimal/rgb colors are permitted')

export function colorIsDark(color: string): boolean {
  // Red
  let r: number
  // Green
  let g: number
  // Blue
  let b: number

  if (color[0] === '#') {
    if (color.length > 7) {
      throw nonPermittedFormatError()
    } else if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16)
      g = parseInt(color.slice(3, 5), 16)
      b = parseInt(color.slice(5, 7), 16)
    } else if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16)
      g = parseInt(color[2] + color[2], 16)
      b = parseInt(color[3] + color[3], 16)
    } else {
      throw new Error('Invalid hex format given')
    }
  } else if (color.slice(0, 4) === 'rgb(') {
    if (color[color.length - 1] !== ')') {
      throw new Error('Invalid rgb format given')
    }

    const rgb: string[] = color.slice(4, color.length - 1).split(',')
    r = parseInt(rgb[0], 10)
    g = parseInt(rgb[1], 10)
    b = parseInt(rgb[2], 10)
  } else {
    throw nonPermittedFormatError()
  }

  // Value 186 per https://stackoverflow.com/a/3943023/3860574
  return r * 0.299 + g * 0.587 + b * 0.114 <= 186
}

export function convertHexToRgb(hex: string): number[] {
  if (hex.length < 7 || hex[0] !== '#') {
    return []
  }

  const convert = (h: string, start: number, end: number): number =>
    parseInt(h.slice(start, end), 16)
  return [convert(hex, 1, 3), convert(hex, 3, 5), convert(hex, 5, 7)]
}

export function bgColorContrast(
  bgColor: string,
  lightColor: string,
  darkColor: string
): string {
  if (bgColor.length !== 7) {
    throw new Error('Background color is not valid. Example #dddddd')
  }
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return ((col + 0.055) / 1.055) ** 2.4
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
}
