































import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import { USER_NS } from '~/store/modules/shared/user/state'
import { defineComponent } from '@nuxtjs/composition-api'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    SellerTags: defineVue3AsyncComponent(() =>
      import('~/components/car/classifieds/view/partials/SellerTags')
    )
  },
  props: {
    title: {
      type: String,
      default: null
    },
    titleParts: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapState(CLASSIFIED_VIEW_NS, {
      seller: state => state.seller,
      discount: state => state.classified.discount
    }),
    ...mapGetters(CLASSIFIED_VIEW_NS, {
      isWanted: 'isWanted',
      isDamaged: 'isDamaged',
      isForRent: 'isForRent',
      isNew: 'isNew',
      isDealersOnly: 'isDealersOnly'
    }),
    ...mapGetters(USER_NS, {
      userIsAdmin: 'isAdmin'
    })
  }
})
