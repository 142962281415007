import mutations from '~/store/modules/shared/backgroundJobs/mutations'
import state from '~/store/modules/shared/backgroundJobs/state'
import getters from '~/store/modules/shared/backgroundJobs/getters'
import actions from '~/store/modules/shared/backgroundJobs/actions'
import { createModule } from '~/factories/store'

export default createModule({
  mutations,
  state,
  getters,
  actions
})
