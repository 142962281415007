import { interopDefault } from '~/router/utils'

export const adminInvoicesRoutes = [
  {
    name: '__admin_invoices_search',
    path: '/admin/invoices/search/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/invoices/search.vue' /* webpackChunkName: "pages-admin-invoices-search" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__admin_invoices_create',
    path: '/admin/invoices/create/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/invoices/create.vue' /* webpackChunkName: "pages-admin-invoices-create" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__admin_invoices_unified_payments',
    path: '/admin/payments/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/invoices/unified-payments.vue' /* webpackChunkName: "pages-admin-invoices-unified-payments" */
        )
      ),
    meta: { noFooter: true }
  }
]
