import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import { Context } from '@nuxt/types'
import PageViewRecorder from '~/services/telemetry/PageViewRecorder'

/**
 * Middleware that records page views to google analytics.
 * @param route
 * @param $requestContainer
 */
export default function({ route, app: { $requestContainer } }: Context) {
  if (process.server) {
    return
  }

  const pageViewRecorder = $requestContainer.resolve(PageViewRecorder)
  pageViewRecorder.recordMiddlewarePageView(route)
}
