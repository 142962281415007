import state from '~/store/modules/shared/classifieds-analytics/state'
import getters from '~/store/modules/shared/classifieds-analytics/getters'
import actions from '~/store/modules/shared/classifieds-analytics/actions'
import mutations from '~/store/modules/shared/classifieds-analytics/mutations'
import { createModule } from '~/factories/store'

export default createModule({
  actions,
  getters,
  mutations,
  state
})
