import { MutationTree } from 'vuex'

import { QuickSearchState } from './state'
import {
  RESET_PARAMS,
  SET_BREADCRUMBS,
  SET_CATEGORY_MAP,
  SET_CONFIG,
  SET_FACETS,
  SET_LOADING,
  SET_PARAM,
  SET_QUICK_SEARCHES,
  SET_STATS,
  SET_TARGETS
} from '~/store/modules/shared/quickSearch/mutation-types'
import {
  Config,
  Facet,
  QuickSearch,
  Stats,
  Targets
} from '~/models/quick-search/types'
import { Breadcrumb } from '~/models/common/types'
import { CategoryMap } from '~/models/category/types'
import { getRandomArrayItem } from '~/utils/array'

export default {
  [SET_FACETS](state, facets: Facet[]) {
    state.facets = facets
  },
  [SET_CONFIG](state, config: Config) {
    state.config = config
    state.randomBackgroundImageUrl = getRandomArrayItem(
      config.backgroundImagesUrls || []
    )
  },
  [SET_TARGETS](state, targets: Targets) {
    state.targets = targets
  },
  [SET_BREADCRUMBS](state, breadcrumbs: Breadcrumb[]) {
    state.breadcrumbs = breadcrumbs
  },
  [SET_CATEGORY_MAP](state, categoryMap: CategoryMap) {
    state.categoryMap = categoryMap
  },
  [SET_STATS](state, stats: Stats) {
    state.stats = stats
  },
  [SET_PARAM](state, { name, value }) {
    state.params[name] = value
  },
  [RESET_PARAMS](state) {
    state.params = {}
  },
  [SET_QUICK_SEARCHES](state, quickSearches: QuickSearch[] = []) {
    state.quickSearches = quickSearches
  },
  [SET_LOADING](state, loading: boolean) {
    state.loading = loading
  }
} as MutationTree<QuickSearchState>
