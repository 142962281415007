import { Page } from '~/models/common/types'
import {
  SearchableUser,
  UserSearchFilters,
  UserSearchBooleanFilter,
  UserSearchSchema
} from '~/models/user/search'

export interface UserSearchState {
  users: Map<string, SearchableUser>
  searchText: string
  filters: Omit<UserSearchFilters, 'boolean'>
  booleanFilters: Set<UserSearchBooleanFilter>
  sorting: string
  schema?: UserSearchSchema
  loading: boolean
  page: Page
}

export const USER_SEARCH_NS = 'admin/users/search'

export default function getDefaultState(): UserSearchState {
  return {
    users: new Map(),
    searchText: '',
    filters: getEmptyFilters(),
    booleanFilters: new Set(),
    sorting: '',
    schema: undefined,
    loading: false,
    page: {
      number: 1
    }
  }
}

export function getEmptyFilters(): Omit<UserSearchFilters, 'boolean'> {
  return {
    'last-activity': undefined,
    'user-type': [],
    site: []
  }
}
