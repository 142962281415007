import { GetterTree } from 'vuex'
import { AdvertiseFormState } from './state'
import { diffInDays } from '~/utils/date'

export default {
  daysBetween(state) {
    if (state.newAdValues.startDate && state.newAdValues.finishDate) {
      return diffInDays(
        state.newAdValues.finishDate,
        state.newAdValues.startDate
      )
    }

    return null
  },
  daysBetweenForAdId: state => (adId: number | string) => {
    if (!adId) {
      return null
    }

    const campaignAd = state.campaignAds.find(
      a => a?.ad?.id.toString() === adId.toString()
    )

    if (campaignAd?.values?.startDate && campaignAd?.values?.finishDate) {
      return diffInDays(
        campaignAd.values.finishDate,
        campaignAd.values.startDate
      )
    }

    return null
  },
  getCampaignAds(state) {
    return state.campaignAds
  },
  getCampaignAd: state => (adId: number | string) => {
    if (!adId) {
      return undefined
    }
    return state.campaignAds.find(a => a?.ad?.id.toString() === adId.toString())
  },
  getCampaignAdItem: state => (
    adId: number | string,
    itemId: number | string
  ) => {
    if (!adId && !itemId) {
      return undefined
    }
    const campaignAd = state.campaignAds.find(
      a => a?.ad?.id?.toString() === adId?.toString()
    )
    return campaignAd?.items?.find(
      i => i.item.id?.toString() === itemId?.toString()
    )
  }
} as GetterTree<AdvertiseFormState, any>
