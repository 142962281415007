import { Params, SortOption, Category } from '~/models/dealer/map/types'
import { Pagination } from '~/models/search/types'
import { Geolocation } from '~/models/common/types'
import { Dealer } from '~/models/dealer/types'

export const DEALERS_MAP_NS = 'dealers/map'

export interface DealersMapState {
  loading: boolean
  categoriesCounts: Category[] | null
  params: Params
  numOfShimmers: number
  sortOptions: SortOption[] | null
  rows: Dealer[]
  pagination: Pagination | null
  totalDealers: number
  markers: object[] | null
  mapDragging: boolean
  hoveredDealer: Dealer | null
  activeDealer: Dealer | null
  activeGeolocation: Geolocation | null
  mobile: {
    facetsVisible: boolean
    listVisible: boolean
  }
  hasReachedLastPage: boolean
}

function getDefaultState(): DealersMapState {
  return {
    loading: true,
    categoriesCounts: null,
    pagination: null,
    params: {
      geolocation: null,
      ca: null,
      q: null,
      pg: 1,
      postcode: null,
      sort: null,
      distance: null
    },
    numOfShimmers: 8,
    sortOptions: null,
    rows: [],
    totalDealers: 0,
    markers: null,
    mapDragging: false,
    hoveredDealer: null,
    activeDealer: null,
    activeGeolocation: null,
    mobile: {
      listVisible: true,
      facetsVisible: false
    },
    hasReachedLastPage: false
  }
}

export default getDefaultState
