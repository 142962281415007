import { ActionTree } from 'vuex'

import { getRandomNumberBetween } from '~/utils/number'
import { ADMAN_ADS_DISPLAY_PROBABILITY } from '~/constants/ads'
import { userIsBot } from '~/utils/user'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { USER_NS } from '~/store/modules/shared/user/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import {
  SET_IS_BOT,
  SET_IS_PC,
  SET_IS_TABLET
} from '~/store/modules/shared/userAgent/mutation-types'
import { ADS_NS } from '~/store/modules/shared/ads/state'
import { SET_SHOW_ADMAN_ADS } from '~/store/modules/shared/ads/mutation-types'
import { APP_NS } from '~/store/modules/shared/app/state'
import { SET_APP_NAME } from '~/store/modules/shared/app/mutation-types'

export default {
  actions: {
    async nuxtServerInit({ dispatch }, { app, req, route }) {
      await Promise.all([
        dispatch('setUserAgents', { app, req }),
        dispatch('predetermineAdsProvider', { route }),
        dispatch('setApp'),
        dispatch(`${STORAGE_NS}/load`, {}, { root: true }),
        dispatch(`${USER_NS}/loadUser`, {}, { root: true })
      ])
    },
    setUserAgents({ commit }, { app, req }) {
      const { $ua } = app
      const isFromPc = $ua.isFromPc()
      const isFromTablet = $ua.isFromTablet()

      commit(`${USER_AGENT_NS}/${SET_IS_PC}`, isFromPc, { root: true })
      commit(`${USER_AGENT_NS}/${SET_IS_TABLET}`, isFromTablet, { root: true })
      commit(`${USER_AGENT_NS}/${SET_IS_BOT}`, userIsBot(req), { root: true })
    },
    setApp({ commit }) {
      commit(`${APP_NS}/${SET_APP_NAME}`, process.env.APP_NAME, {
        root: true
      })
    },
    predetermineAdsProvider({ commit }, { route }) {
      if (process.env.ADMAN_ADS_DISABLED) {
        commit(`${ADS_NS}/${SET_SHOW_ADMAN_ADS}`, false)
        return
      } else if (route.query && route.query.am) {
        commit(`${ADS_NS}/${SET_SHOW_ADMAN_ADS}`, true)
        return
      }
      const dice = getRandomNumberBetween(1, 100)
      if (dice < ADMAN_ADS_DISPLAY_PROBABILITY) {
        commit(`${ADS_NS}/${SET_SHOW_ADMAN_ADS}`, true)
      }
    }
  } as ActionTree<any, any>
}
